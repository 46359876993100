import { GENERATE_CERTIFICATE as API_GENERATE_CERTIFICATE } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function dowloadWorkingCertificate(context, payload) {
  const url = `${API_GENERATE_CERTIFICATE}?path=certificate.pdf&admin_name=Digitaltolk&dob=${payload.dob}&created_at=${payload.created}&name=${payload.name}`
  const method = 'GET'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      headers: { Accept: 'application/pdf' }
    })
      .then((r) => {
        const link = document.createElement('a')
        link.href = r.data.url
        link.target = '_blank'
        link.click()
        resolve(r)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export default dowloadWorkingCertificate
