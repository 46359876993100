import axios from 'axios'
import qs from 'qs'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'

const isValidFunction = (f) => !isNil(f) && isFunction(f)
const isNotNullNorEmpty = (v) => !isNil(v) && !isEmpty(v)

const commonLogCss = ['color: #ffffff', 'padding: 2px 5px', 'border-radius: 5px']

const successLogCss = [...commonLogCss, 'background-color: #44bcfd'].join(';')

let errorLogCss = [...commonLogCss, 'background-color: #db3246'].join(';')

const customAxios = axios.create()

/**
 * Brief Documentation
 *
 * @param {Object} options - Mostly same as available options for axios
 * @param {boolean} [options.isStringifyData=false]
 * @param {boolean} [options.isShowErrorNotif=false]
 * @param {boolean} [options.isShowLogs=false]
 * @param {Function} [options.onSuccessCallback=null]
 * @param {Function} [options.onErrorCallback=null]
 * @param {Function} [options.onFinallyCallback=null]
 */

/**
 * @returns {*|Promise}
 */
async function apiCall(options = {}, axiosInstance = null) {
  if (isNil(options.url)) {
    throw 'Missing required value options.url'
  }

  if (isNil(options.method)) {
    throw 'Missing required value options.method'
  }

  const isStringifyData = options.isStringifyData ?? false
  const isShowErrorNotif = options.isShowErrorNotif ?? false
  const isShowLogs = options.isShowLogs ?? true
  const onSuccessCallback = options.onSuccessCallback ?? null
  const onErrorCallback = options.onErrorCallback ?? null
  const onFinallyCallback = options.onFinallyCallback ?? null

  delete options.isStringifyData
  delete options.isEnableErrorNotif
  delete options.onSuccess
  delete options.onError
  delete options.onFinally

  if (isNotNullNorEmpty(options.data) && isStringifyData) {
    // noinspection JSUndefinedPropertyAssignment
    options.data = qs.stringify(options.data)
  }

  let axiosCall = customAxios
  if (axiosInstance) {
    axiosCall = axiosInstance
  }

  try {
    // noinspection JSCheckFunctionSignatures
    const response = await axiosCall(options)

    if (isShowLogs) {
      console.groupCollapsed('%c' + 'API Request successfull.', successLogCss)
      console.log(response)
      console.groupEnd()
    }

    if (isValidFunction(onSuccessCallback)) {
      onSuccessCallback(response)
    }

    if (isValidFunction(onFinallyCallback)) {
      onFinallyCallback(response)
    }

    return response
  } catch (e) {
    if (isShowLogs) {
      console.group('%c' + 'API Request failed.', errorLogCss)
      console.error(e)
      console.groupEnd()
    }

    // Show error notification if it is enabled.
    if (isShowErrorNotif) {
      showSmallErrorNotif(e)
    }

    if (isValidFunction(onErrorCallback)) {
      onErrorCallback()
    }

    if (isValidFunction(onFinallyCallback)) {
      onFinallyCallback()
    }

    throw e
  }
}

export default apiCall
