<template>
  <div class="app-form" :class="getBem(blockClass)">
    <el-form ref="form" :model="iValue" :rules="formRules">
      <!-- Name Field -->
      <el-form-item
        prop="name"
        :class="getBem(blockClass, ['name-container'])"
        :label="$t('your_name')"
        :required="true"
      >
        <el-input
          v-model="iValue.name"
          :class="getBem(blockClass, ['name-field'])"
          :placeholder="$t('name')"
          aria-required="true"
          aria-label="Please enter your name"
          tabindex="0"
        />
      </el-form-item>

      <!-- Company Field -->
      <el-form-item
        v-if="['request'].includes(iType)"
        prop="company"
        :class="getBem(blockClass, ['company-container'])"
        :label="$t('contact_company_name')"
      >
        <el-input
          v-model="iValue.company"
          :class="getBem(blockClass, ['name-field'])"
          :placeholder="$t('company')"
          aria-required="true"
          aria-label="Please enter your company name"
          tabindex="0"
        />
      </el-form-item>

      <!-- Company Field -->
      <el-form-item
        v-if="['request'].includes(iType)"
        prop="company"
        :class="getBem(blockClass, ['company-container'])"
        :label="$t('company_address')"
      >
        <el-input
          v-model="iValue.company_address"
          :class="getBem(blockClass, ['company_address-field'])"
          :placeholder="$t('company_address')"
          aria-required="true"
          aria-label="Please enter your company address"
          tabindex="0"
        />
      </el-form-item>

      <!-- Email Field -->
      <el-form-item
        prop="email"
        :class="getBem(blockClass, ['email-container'])"
        :label="$t('your_email')"
        :required="true"
      >
        <el-input
          v-model="iValue.email"
          :class="getBem(blockClass, ['email-field'])"
          :placeholder="$t('email')"
          aria-required="true"
          tabindex="0"
        />
      </el-form-item>

      <!-- Phone Field -->
      <el-form-item
        v-if="['request', 'ekonomi', 'booking'].includes(iType)"
        prop="phone"
        :class="getBem(blockClass, ['phone-container'])"
        :label="$t('telephone')"
      >
        <el-input
          v-model="iValue.phone"
          :class="getBem(blockClass, ['phone-field'])"
          :placeholder="$t('telephone')"
          aria-required="true"
          tabindex="0"
        />
      </el-form-item>

      <!-- booking_id Field -->
      <el-form-item
        v-if="['ekonomi', 'booking'].includes(iType)"
        prop="booking_id"
        :class="getBem(blockClass, ['booking_id-container'])"
        :label="$t('booking_id')"
      >
        <el-input
          v-model="iValue.booking_id"
          :class="getBem(blockClass, ['booking_id-field'])"
          :placeholder="$t('booking_id')"
          aria-required="true"
          tabindex="0"
        />
      </el-form-item>

      <!-- Subject Field -->
      <el-form-item :class="getBem(blockClass, ['subject-container'])" :label="$t('subject')" prop="subject">
        <el-input
          v-model="iValue.subject"
          :class="getBem(blockClass, ['subject-field'])"
          :placeholder="$t('subject')"
          tabindex="0"
        />
      </el-form-item>

      <!-- Message Field -->
      <el-form-item :class="getBem(blockClass, ['message-container'])" :label="$t('message')" prop="message">
        <el-input
          v-model="iValue.message"
          :class="getBem(blockClass, ['message-field'])"
          type="textarea"
          :placeholder="$t('message')"
          aria-label="Enter you message"
          :rows="4"
          tabindex="0"
        />
      </el-form-item>

      <!-- Actions -->
      <el-form-item :class="getBem(blockClass, ['actions-container'])">
        <el-button
          :class="getBem(blockClass, ['send-button'])"
          class="app-button-secondary"
          aria-label="Send the Message"
          tabindex="0"
          @click="handleClickSend"
        >
          {{ $t('btn_send') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import isEmpty from 'lodash/isEmpty'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'normal'
    }
  },
  emits: ['update:modelValue', 'input', 'submit'],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'contact-form'
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * Interface for this.value.
     */
    iValue: {
      get() {
        return this.modelValue || {}
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },
    /**
     * Interface for this.type.
     */
    iType: {
      get() {
        return this.type
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    /**
     * Returns validation rules for the form.
     *
     * @returns {Object}
     */
    formRules() {
      const nameRequiredMsg = this.$t('is_required', {
        value: this.$t('name').toLowerCase()
      })
      const emailRequiredMsg = this.$t('is_required', {
        value: this.$t('email').toLowerCase()
      })
      const emailFormatMsg = this.$t('should_be_valid_email')
      const messageRequiredMsg = this.$t('is_required', {
        value: this.$t('message').toLowerCase()
      })
      const subjectRequiredMsg = this.$t('is_required', {
        value: this.$t('subject').toLowerCase()
      })

      return {
        name: [{ required: true, message: nameRequiredMsg, trigger: 'blur' }],
        email: [
          { required: true, message: emailRequiredMsg, trigger: 'blur' },
          { type: 'email', message: emailFormatMsg, trigger: 'blur' }
        ],
        message: [{ required: true, message: messageRequiredMsg, trigger: 'blur' }],
        subject: [{ required: true, message: subjectRequiredMsg, trigger: 'blur' }]
      }
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    /**
     * Handler when the Send button was clicked.
     */
    handleClickSend() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let message = ''
          message =
            message +
            (!isEmpty(this.iValue.company) ? `${this.$t('company_name')}: ${this.iValue.company} <br>` : '') +
            (!isEmpty(this.iValue.company_address)
              ? `${this.$t('company_address')}: ${this.iValue.company_address} <br>`
              : '') +
            (!isEmpty(this.iValue.phone) ? `${this.$t('phone')}: ${this.iValue.phone} <br>` : '') +
            (!isEmpty(this.iValue.booking_id) ? `${this.$t('booking_id')}: ${this.iValue.booking_id} <br>` : '')

          this.iValue.message = message + this.iValue.message
          this.$emit('submit')
        }
      })
    }
  }
}
</script>
