import { createWebHistory, createWebHashHistory, createRouter } from 'vue-router'
import routes from './routes'

const baseURL = import.meta.env.VITE_BASE_URL || '/'
const isPreviewMode = baseURL !== '/'

const router = new createRouter({
  history: isPreviewMode ? createWebHashHistory(baseURL) : createWebHistory(baseURL),
  routes
})

// Listen to each route for preferred locale.
router.beforeEach((to, from, next) => {
  next()
})

export default router
