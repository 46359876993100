let daySchedule = () => {
  return {
    start_time: '',
    end_time: '',
    booking_types: []
  }
}
let freshForm = () => {
  return {
    Monday: {
      active: false,
      day_schedule: [daySchedule()]
    },
    Tuesday: {
      active: false,
      day_schedule: [daySchedule()]
    },
    Wednesday: {
      active: false,
      day_schedule: [daySchedule()]
    },
    Thursday: {
      active: false,
      day_schedule: [daySchedule()]
    },
    Friday: {
      active: false,
      day_schedule: [daySchedule()]
    },
    Saturday: {
      active: false,
      day_schedule: [daySchedule()]
    },
    Sunday: {
      active: false,
      day_schedule: [daySchedule()]
    }
  }
}

let initialForm = () => {
  return {
    visible: true,
    translatorSchedule: freshForm(),
    start_date: '',
    end_date: ''
  }
}

export { daySchedule, freshForm, initialForm }
