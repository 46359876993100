import LS from '@/modules/constants/localStorage'
import USER_TYPE from '@/modules/constants/userType'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { getLocaleRoutePath } from '@/modules/helpers/routes'
import appStorage from '@/modules/helpers/appStorage'

/**
 * @param next
 * @param router
 * @returns {*}
 */
function translator({ next, router }) {
  const token = appStorage.get(LS.TOKEN)
  const isUserAuthenticated = !isNil(token) && !isEmpty(token)

  const user = appStorage.get(LS.USER)
  const isUserTranslator = !isNil(user) && !isEmpty(user) && user.type === USER_TYPE.TRANSLATOR

  // const employedTranslator = appStorage.get(LS.IS_EMPLOYED_TRANSLATOR)
  // const isEmployedTranslator = !isNil(employedTranslator)

  if (!isUserAuthenticated) {
    const path = getLocaleRoutePath('/login', router)
    next({ path })
    // } else if (isEmployedTranslator) {
    //   const path = getLocaleRoutePath('/today-dashboard', router)
    //   next({ path })
  } else if (!isUserTranslator) {
    const path = getLocaleRoutePath('/today-dashboard', router)
    next({ path })
  } else {
    return next()
  }
}

export default translator
