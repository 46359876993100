export default [
  {
    value: 'google_meet',
    label: 'Google Meet'
  },
  {
    value: 'skype',
    label: 'Skype'
  },
  {
    value: 'ms_teams',
    label: 'Microsoft Teams'
  },
  {
    value: 'visba_care',
    label: 'Visba Care'
  },
  {
    value: 'zoom',
    label: 'Zoom'
  },
  {
    value: 'clinic',
    label: 'Clinic24'
  },
  {
    value: 'dt_mote',
    label: 'Digitalt möte'
  },
  {
    value: 'other',
    label: 'Other'
  }
]
