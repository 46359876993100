/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getPotentialBookingCostEstimation
|--------------------------------------------------------------------------
|
*/
import { POTENTIAL_BOOKING_INVOICE_PREVIEW } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  context.commit('setValidateLoading', true)

  const url = POTENTIAL_BOOKING_INVOICE_PREVIEW + '?sum=1'
  console.log('POTENTIAL_BOOKING_INVOICE_PREVIEW', POTENTIAL_BOOKING_INVOICE_PREVIEW)
  const method = 'post'

  const data = {
    ...payload
  }

  const options = {
    enable_error_notif: true,
    data,
    on_success(r, ctx) {
      ctx.commit('setValidateLoading', false)
      ctx.commit('setPriceQuote', {
        total: r.data.data.sum
      })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
