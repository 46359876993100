/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > availableTranslators
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to fetch for available translators,
 * based on language, duration, Gender and translator_levels preferences
 * Note: language and duration fields are required
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @return {*}
 */
export default function (context, payload) {
  // Prepare the payload to be sent across the API.

  const url = `${API.BOOKINGS}/availableTranslators`

  const method = 'post'

  const options = {
    query_params: payload.query_params,

    on_success(r, ctx) {
      // Set the following states.
      ctx.commit('setAvailableTranslatorsByPreferences', r.data)
      // ctx.commit('setAvailableTranslators', r.data.total)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
