<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="isShowModal"
      class="app-modal app-modal-secondary"
      :class="getBem(blockClass)"
      :title="$t('btn_edit_session_time')"
      :close-on-click-modal="isMaskClosable"
      :close-on-press-escape="!isPublic"
      :show-close="!isPublic"
      top="3%"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

      <booking-session-time-form ref="booking-session-time-form" v-model="form" />
      <br />

      <!-- Action Buttons -->
      <div :class="getBem(blockClass, 'actions')" class="text-right">
        <el-button class="app-button-default" :class="getBem(blockClass, ['close-button'])" @click="handleClickClose">
          {{ $t('btn_close') }}
        </el-button>

        <el-button
          :class="getBem(blockClass, ['update-button'])"
          class="app-button-secondary"
          @click="handleClickUpdate"
        >
          {{ $t('btn_update_session_time') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- Component DIV Wrapper -->
  </focus-trap>
</template>

<script>
import { mapActions } from 'vuex'
import BookingSessionTimeForm from '@/modules/booking/components/forms/BookingSessionTimeForm'
import appStorage from '@/modules/helpers/appStorage'
import LS from '@/modules/constants/localStorage'
import isNil from 'lodash/isNil'
import { showSmallSuccessNotif, showSmallErrorNotif } from '@/modules/helpers/notifications'
import { getQueryParam } from '../../../helpers/routes'
import moment from 'moment-timezone'
import momentJs from 'moment'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
  components: {
    BookingSessionTimeForm
  },

  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    isPublic: {
      type: Boolean,
      default: false
    },

    isMaskClosable: {
      type: Boolean,
      default: true
    },

    isLoadingDownloadPdf: {
      type: Boolean,
      default: false
    }
  },

  emits: ['end', 'handleCosting', 'reload'],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'booking-session-time-modal',
      isShowModal: false,
      booking: {},
      form: {
        session_time: '',
        comment: '',
        session_comment_translator: '',
        session_comment: ''
      }
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > Computed
    |--------------------------------------------------------------------------
    */
  computed: {
    userType() {
      const user = appStorage.get(LS.USER)
      const type = getQueryParam(this, 'user_type', '', (v) => parseInt(v))
      return !isNil(user) ? user.type : type
    },

    /**
     * @returns {boolean}
     */
    isUserTranslator() {
      return this.$store.getters['auth/isUserTranslator']
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions('booking', ['updateSessionTime']),

    /**
     * Handle when the update button was clicked.
     *
     * @returns {void}
     */
    handleClickUpdate() {
      // Validate first before submitting.
      this.$refs['booking-session-time-form'].validate()

      if (!this.$refs['booking-session-time-form'].isValidated) {
        return
      }

      // Validate session time
      if (this.validateSessionTime() == false) {
        this.$confirm(this.$t('session_time_longer_message'), this.$t('warning'), {
          confirmButtonText: this.$t('YES'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        })
          .then(() => {
            this.reportSessionTime()
          })
          .catch(() => {
            return false
          })
      } else {
        this.reportSessionTime()
      }
    },

    reportSessionTime() {
      if (this.userType === 3) {
        this.form.session_comment_translator = this.form.comment
      } else if (this.userType === 2) {
        this.form.session_comment = this.form.comment
      } else {
        delete this.form.comment
      }

      let payload = {
        form: {
          booking_id: this.booking.id,
          data: this.form
        },
        booking: this.booking
      }

      if (this.isPublic) {
        this.$emit('end', payload)
      } else {
        // HANDLE SUBMISSION IN THE MODAL
        this.updateSessionTime(payload.form)
          .then(() => {
            showSmallSuccessNotif(this.$t('booking_session_time_success'))

            if (this.isLoadingDownloadPdf) {
              this.$emit('handleCosting', payload.booking)
            }
            this.close()
            this.$emit('reload')
          })
          .catch((e) => {
            this.close()
            let errTitle = `${this.$t('error')} - ${this.$t('booking_session_time_fail')}`
            showSmallErrorNotif(e, errTitle)
          })
      }
    },

    /**
     * check if reported session time is longer than the booking duration
     * @returns {boolean}
     */
    validateSessionTime() {
      if (this.isValidationRequiredForSessionTime() == false) {
        return true
      }

      const sessionTimeMinutes = momentJs.duration(this.form.session_time).asMinutes()
      if (sessionTimeMinutes > this.booking.duration) {
        return false
      }

      return true
    },

    /**
     * Returns boolean wether to validate session time.
     * @returns {Boolean}
     */
    isValidationRequiredForSessionTime() {
      if (this.isUserTranslator == false) {
        return false
      }
      const isSameMonth = moment(this.booking.due).isSame(moment(), 'month')
      if (isSameMonth && this.booking.status.code === 'completed') {
        return true
      }
      return false
    },

    /**
     * Handler when the Close button was clicked.
     *
     * @returns {void}
     */
    handleClickClose() {
      // redirect if public modal
      if (this.isPublic) {
        this.$router.push({ path: '/' })
      } else {
        this.close()
      }
    },

    /**
     * Helper method for setting the initial values to the form.
     *
     * @param {object} booking - The current target booking.
     * @returns {void}
     */
    setInitalValues(booking) {
      this.form.session_time =
        booking.type === 'convey'
          ? moment('00:00:00', 'HH:mm:ss').add(booking.duration, 'minutes').format('HH:mm:ss')
          : moment(booking.session_time, 'HH:mm:ss').format('HH:mm:ss')
      this.form.comment = ''
      if (this.userType === 3 && booking.session_comment_translator) {
        this.form.session_comment_translator = booking.session_comment_translator
        this.form.comment = booking.session_comment_translator
      } else if (this.userType === 2 && booking.session_comment) {
        this.form.session_comment = booking.session_comment
        this.form.comment = booking.session_comment
      }
      //  this.form.comment = ''
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking) {
      this.booking = booking
      this.setInitalValues(booking)
      this.isShowModal = true
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
    }
  }
}
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.booking-session-time-modal {
  .el-dialog {
    width: 600px;
  }
  .el-form {
    &-item {
      &__label {
        justify-content: start;
      }
    }
  }

  // **************************************************
  // Extra Small Viewport
  // **************************************************
  @media (max-width: $screen-xs-max) {
    .el-dialog {
      width: 90%;
    }
  }

  // **************************************************
  // Small Viewport
  // **************************************************
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .el-dialog {
      width: 80%;
    }
  }

  // **************************************************
  // Medium Viewport
  // **************************************************
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .el-dialog {
      width: 70%;
    }
  }
}
</style>
