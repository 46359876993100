/*
|--------------------------------------------------------------------------
| Store > Multi-Tenancy > State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/

export default function () {
  return {
    isTenantLoading: false,
    tenantDomain: '',
    tenant: {
      uuid: '',
      name: '',
      url: ''
    },
    tenantConfig: {
      logo: '',
      primary_color: '',
      secondary_color: ''
    }
  }
}
