export default {
  getListData: (state) => state.list.data,
  getListPagination: (state) => state.list.pagination,
  isLoading: (state) => state.list.loading,
  getItem: (state) => state.item,
  getLoading: (state) => state.loading,

  /**
   * @returns {Array}
   */
  customerNormalBookingListData: (state) => state.customerNormalBookingList.data,

  /**
   * @returns {Object}
   */
  customerNormalBookingListPagination: (state) => state.customerNormalBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingCustomerNormalBookingList: (state) => state.customerNormalBookingList.loading,

  /**
   * @returns {Boolean}
   */
  isLoadingValidate: (state) => state.isLoadingValidate,

  /**
   * @returns {Boolean}
   */
  isLoadingSubmit: (state) => state.isLoadingSubmit,

  /**
   * @returns {Boolean}
   */
  isShowBookingConfirmModal: (state) => state.isShowBookingConfirmModal,
  /**
  /**
   * @returns {Boolean}
   */
  isShowManualPriceQuote: (state) => state.isShowManualPriceQuote,
  /**
   * @returns {Object}
   */
  createdBooking: (state) => state.createdBooking,

  /**
   * @returns {Object}
   */
  createdBookings: (state) => state.createdBookings,

  /**
   * @returns {Object}
   */
  bcfConfig: (state) => state.bcfConfig,

  /**
   * @returns {Object}
   */
  confirmSuggestions: (state) => state.confirmSuggestions,

  /**
   * @returns {Integer}
   */
  availableTranslators: (state) => state.availableTranslators,

  /**
   * @returns {Boolean}
   */
  isNotSufficientTranslators: (state) => state.availableTranslators < 10,

  /**
   * @returns {Integer}
   */
  selectedLanguage: (state) => state.form.language,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of specific translators.
   *
   * @returns {boolean}
   */
  hasShortageInSpecificTranslator: (state) => state.confirmForm.q_specifics.has_shortage_translator,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of specific gender.
   *
   * @returns {Boolean}
   */
  hasShortageInTranslatorGender: (state) => state.confirmForm.q_specifics.has_shortage_gender,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of specific translator levels.
   *
   * @returns {Boolean}
   */
  hasShortageInTranslatorLevel: (state) => state.confirmForm.q_specifics.has_shortage_translator_level,

  /**
   * Returns boolean value for determining if there are shortage
   * regarding Booking of physical (type) bookings.
   *
   * @returns {Boolean}
   */
  hasShortageInTranslatorPhysical: (state) => state.confirmForm.q_specifics.has_shortage_physical,

  /**
   * Returns boolean value for determining if user can
   * accept another translator
   *
   * @returns {Boolean}
   */
  canAcceptAnotherTranslator: (state) => state.confirmForm.q_specifics.can_accept_another_translator,

  // --------------------------------------------------
  // Misc
  // --------------------------------------------------

  // --------------------------------------------------
  // Translator Normal Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  translatorNormalBookingListData: (state) => state.translatorNormalBookingList.data,

  /**
   * @returns {Object}
   */
  translatorNormalBookingListPagination: (state) => state.translatorNormalBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingTranslatorNormalBookingList: (state) => state.translatorNormalBookingList.loading,

  // --------------------------------------------------
  // Customer Historic Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  customerHistoricBookingListData: (state) => state.customerHistoricBookingList.data,

  /**
   * @returns {Object}
   */
  customerHistoricBookingListPagination: (state) => state.customerHistoricBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingCustomerHistoricBookingList: (state) => state.customerHistoricBookingList.loading,

  // --------------------------------------------------
  // Translator Historic Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  translatorHistoricBookingListData: (state) => state.translatorHistoricBookingList.data,

  /**
   * @returns {Object}
   */
  translatorHistoricBookingListPagination: (state) => state.translatorHistoricBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingTranslatorHistoricBookingList: (state) => state.translatorHistoricBookingList.loading,

  /**
   *
   * @param {*} state
   * @returns {Array}
   */
  languagePairLevels: (state) => state.bookingFormFresh.language_pair_levels,

  /**
   *
   * @param {*} state
   * @returns {Array}
   */
  awaitingQuotes: (state) => state.awaitingQuotes,

  /**
   *
   * @param {*} state
   * @returns {Object}
   */
  bookingForm: (state) => state.bookingForm,

  /**
   *
   * @param {*} state
   * @returns {Number}
   */
  totalWords: (state) => {
    if (state.bookingForm.text_translation_type === 'document') {
      return state.bookingForm.text_translation_document_files.reduce((total, file) => total + file.words_count, 0)
    } else if (state.bookingForm.text_translation_type === 'text') {
      return state.bookingForm.additional_text.trim().split(/\s+/).length
    } else {
      return 1
    }
  },
  /**
   *
   * @param {*} state
   * @returns {String}
   */
  textTranslationType: (state) => {
    return state.bookingForm.text_translation_type
  },

  /**
   *
   * @param {*} state
   * @returns
   */
  costEstimations: (state) => state.costEstimations,

  /**
   * @returns {Boolean}
   */
  hasUnsupportedWordsCountDocument: (state) => {
    if (['image', 'audio', 'video'].includes(state.bookingForm.text_translation_type)) {
      return true
    }

    if (state.bookingForm.text_translation_type === 'text') {
      return false
    }
    return (
      state.bookingForm.text_translation_type === 'document' &&
      state.bookingForm.text_translation_document_files.some((file) => file.words_count === 0)
    )
  },

  /**
   *
   * @param {*} state
   * @returns
   */
  textTranslationTestUrl: (state) => state.textTranslationTestUrl,
  getManualSubmissionTillDate: (state) => state.manualSubmissionTillDate,
  getIsManualSubmissionEnabled: (state) => state.manualSubmissionEnabled,
  isValidTextTranslator: (state) => state.isValidTextTranslator,

  customerMarketings: (state) => state.customerMarketings,
  getSuggestedDeadlines: (state) => state.textTranslationSuggestedDeadlines,
  getExpressHours: (state) => state.textTranslationExpressHours
}
