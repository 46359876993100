/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitPrefillSalaryItems
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  // Define axios values.
  const url = `${API.FINANCIAL}/${payload.id}/prefill-salary-items`
  const method = 'POST'

  let options = {
    data: payload.data
  }

  return reach(this, context, url, method, options, axiosInstance)
}
