/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > getUserSurveys
|--------------------------------------------------------------------------
|
*/

// import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to fetch the user surveys list
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 *
 * @return {Promise}
 */

// eslint-disable-next-line no-unused-vars
function getSurveys(context, payload) {
  // Define axios values.
  const url = `${API.AUTH_USER}?include=survey.survey_question`

  const method = 'GET'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method
    })
      .then((r) => {
        var todayDate = new Date()
        let surveysData = []
        r.data?.data?.user?.survey?.forEach((element) => {
          var surveyEndDate = new Date(element.end_date)
          if (element.pivot.status != 'completed' && surveyEndDate > todayDate) {
            surveysData.push(element)
          }
        })

        context.commit('setSurveys', surveysData)

        resolve(r)
      })
      .catch((e) => {
        context.commit('setSurveys', [])
        reject(e)
      })
  })
}

export default getSurveys
