/*************************************************************************/
/* A better interface to access Web Storage
/*************************************************************************/

import cookies from 'vue-cookies'
import useUtils from '@/composables/useUtils'
import isObject from 'lodash/isObject'

export default function useStorage(type) {
  const { isJSON } = useUtils()

  /** Make sure a valid web storage type is defined */
  const types = ['local', 'session', 'cookie']
  if (!(type && types.includes(type))) {
    return {
      set: () => console.error('No valid type defined for useStorage() - e.g. local, session'),
      get: () => console.error('No valid type defined for useStorage() - e.g. local, session'),
      remove: () => console.error('No valid type defined for useStorage() - e.g. local, session'),
      clear: () => console.error('No valid type defined for useStorage() - e.g. local, session')
    }
  }

  /** Set value for given web storage */
  function set(key, value) {
    const stringify = isObject(value) ? JSON.stringify(value) : value // convert array/object to string
    type = type.toLowerCase()
    switch (type) {
      case 'local': {
        return localStorage.setItem(key, stringify)
      }
      case 'session': {
        return sessionStorage.setItem(key, stringify)
      }
      case 'cookie': {
        return cookies.set(key, stringify)
      }
    }
  }

  /** Get value for given web storage */
  function get(key) {
    switch (type) {
      case 'local': {
        const value = localStorage.getItem(key)
        if (!value) return value
        const parsed = isJSON(value) ? JSON.parse(value) : !isNaN(value) ? parseInt(value) : value // convert string back to array/object
        return parsed
      }
      case 'session': {
        const value = sessionStorage.getItem(key)
        if (!value) return value
        const parsed = isJSON(value) ? JSON.parse(value) : !isNaN(value) ? parseInt(value) : value // convert string back to array/object
        return parsed
      }
      case 'cookie': {
        const value = cookies.get(key)
        if (!value) return value
        const parsed = isJSON(value) ? JSON.parse(value) : !isNaN(value) ? parseInt(value) : value // convert string back to array/object
        return parsed
      }
    }
  }

  /** Remove value for given web storage */
  function remove(key) {
    switch (type) {
      case 'local': {
        return localStorage.removeItem(key)
      }
      case 'session': {
        return sessionStorage.removeItem(key)
      }
    }
  }

  /** Clear all values for given web storage */
  function clear() {
    switch (type) {
      case 'local': {
        return localStorage.clear()
      }
      case 'session': {
        return sessionStorage.clear()
      }
    }
  }

  return {
    set,
    get,
    remove,
    clear
  }
}
