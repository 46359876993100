/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > readUserNotificationPreference
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.user_id - Authenticated User ID.
 * @returns {*}
 */
export default function (context, payload) {
  const method = 'GET'
  const url = `${API.NOTIFICATION_PREFERENCE}/${payload.user_id}`
  context.commit('setUserNotificationLoading', true)
  let options = {
    on_success(r, ctx) {
      if (r.data.data) {
        ctx.commit('setUserNotificationPreference', r.data.data.item.preferences)
      }
    },
    on_finally(ctx) {
      ctx.commit('setUserNotificationLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
