/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > Get Text Translation Test Url
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @returns {*}
 */
export default function (context, payload) {
  const method = 'GET'

  const base_url = import.meta.env.VITE_RECRUITMENT_API_BASE || 'https://stage-rekrytering.digitaltolk.com'

  const url = `${base_url}/api/candidate/${payload}/written-test-link`
  let options = {
    on_success(r, ctx) {
      ctx.commit('setTextTranslationTestUrl', r.data.test_url)
    },
    headers: {
      'X-Recruitment-Client': 1
    }
  }
  return reach(this, context, url, method, options)
}
