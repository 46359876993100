/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitFeedback
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for ending a booking.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = `${API.BOOKING_ISSUES_SETTING}`
  const method = 'GET'

  let query_params = {
    ...{
      'filter[type]': 'low_feedback',
      'all': true
    },
    ...payload
  }
  let options = {
    enable_error_notif: false,
    query_params
  }

  return reach(this, context, url, method, options, axiosInstance)
}
