import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'

export default function (context, payload = {}) {
  const url = `${API.BOOKINGS}/${payload.id}/translator-batch-entries`
  const method = 'get'

  let options = {}

  return reach(this, context, url, method, options)
}
