import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function deleteTranslatorUnavailableTimes(context, payload) {
  const url = `${API.TRANSLATOR_UNAVAILABLE_TIMES}/${payload.id}`
  const method = 'DELETE'
  context.commit('setLoadingForUnavailableTime', true)
  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method
    })
      .then((r) => {
        context.commit('setLoadingForUnavailableTime', false)
        resolve(r) // Resolve with the result of loadTranslatorUnavailableTimes
      })
      .catch((e) => {
        context.commit('setLoadingForUnavailableTime', false)
        reject(e)
      })
  })
}

export default deleteTranslatorUnavailableTimes
