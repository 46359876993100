import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function getBookingAssignmentProbability(context, payload) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = API.CALCULATE_PROBABILITES_FOR_ASSIGN_BOOKING
  const method = 'POST'

  let params = payload.queryParams

  if (params.customer_id === undefined && context.rootGetters['auth/isUserAdmin']) {
    // this is the case where admin is trying to create a booking, and hasn't selected a customer yet, but changes the booking fields, this will return a 422 response from the backend so we shouldn't make the call.
    return
  }
  context.commit('setAvailableDatesLoading', true)

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      data: params
    })
      .then((r) => {
        context.commit('setAvailableDatesLoading', false)
        context.commit('setAssignmentProbability', r.data.data.assignment_probabilities)
        resolve(r)
      })
      .catch((e) => {
        context.commit('setAvailableDatesLoading', false)

        reject(e)
      })
  })
}

export default getBookingAssignmentProbability
