/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import appStorage from '@/modules/helpers/appStorage'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

/**
 * Action to load translator options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context) {
  const url = API.TRANSLATOR_LEVELS
  const method = 'GET'
  context.commit('auth/setTranslatorLevelOptsLoading', true, { root: true })

  let options = {
    query_params: {
      all: true,
      sort: 'id'
    },
    on_success(r, ctx) {
      ctx.commit('setTranslatorLevelOpts', cloneDeep(r))
      appStorage.set('dt_translator_levels', r)
      appStorage.set('dt_translator_levels_updated_at', JSON.stringify(moment()))
    },
    on_finally(ctx) {
      ctx.commit('auth/setTranslatorLevelOptsLoading', false, { root: true })
    }
  }

  return loadAll(this, context, url, method, 'setTranslatorLevelOpts', options, axiosInstance)

  // if (isNil(levels) || difference >= 30) {
  //   return loadAll(this, context, url, method, 'setTranslatorLevelOpts', options);
  // } else {
  //   return context.commit('setTranslatorLevelOpts', levels);
  // }
}
