/*
|--------------------------------------------------------------------------
| Store > Statistics > Actions > loadCustomerStatistics
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for loading Statistics data.
 *
 * @param {object} context - Current context of Vuex module.
 * @param {object} payload - Contains necessary values.
 * @param {int} [payload.user_type=2] - Defines to which user type that statistics to be loaded are for.
 * @param {string} [payload.from_date=''] - Date (from) range value to filter the data.
 * @param {string} [payload.to_date=''] - Date (to) range value to filter the data.
 * @param {string} [payload.sector_scope=''] - Sector scope to filter the data.
 * @returns {*}
 */
export default function (context, payload = {}) {
  context.commit('setCustomerStatisticsLoading', true)
  // Set defaults for payload values.
  payload.user_type = payload.user_type || 2
  payload.from_date = payload.from_date || ''
  payload.to_date = payload.to_date || ''
  if (payload.from_date && payload.to_date) {
    payload.date_range = `due,${payload.from_date},${payload.to_date}`
  }
  payload.customer_level = payload.sector_scope || ''

  const method = 'POST'
  let url = API.EXPORT_STATISTICS_EXCEL

  return new Promise((resolve, reject) => {
    axiosInstance({
      method,
      url,
      params: payload
    })
      .then((r) => {
        context.commit('setCustomerStatisticsLoading', false)
        const link = document.createElement('a')
        link.href = r.data.data.url
        link.download = 'customer_statisitics.xlsx'
        link.click()

        resolve(r)
      })
      .catch((e) => {
        console.error(e)
        context.commit('setCustomerStatisticsLoading', false)

        reject(e)
      })
  })
}
