/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > initToken
|--------------------------------------------------------------------------
|
*/

import LS from '@/modules/constants/localStorage'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import appStorage from '@/modules/helpers/appStorage'

/**
 * Pullout the token from the local storage upon initial Vue run.
 *
 * @param {Object} context - Current context of Vuex module.
 * @returns {void}
 */
export default function (context) {
  // Get token from localStorage
  const storedToken = appStorage.get(LS.TOKEN)
  const refresh_token = appStorage.get(LS.REFRESH_TOKEN)

  context.dispatch('auth/initAuthCollection', null, { root: true })
  context.commit('auth/setIsEmployedTranslator', false, { root: true })

  // Set the state of token
  if (!isNil(storedToken) && !isEmpty(storedToken)) {
    const user = appStorage.get(LS.USER)
    context.state.user = !isNil(user) ? user : {}

    let token

    if (isString(storedToken)) {
      token = storedToken
    } else if (isObject(storedToken)) {
      token = storedToken.access_token
    } else {
      throw new Error('Unknown token format!')
    }

    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    context.dispatch('auth/addToAuthCollection', { authenticated: { token, user, refresh_token } }, { root: true })

    // Explicitly set if user is authenticated.
    context.commit('auth/setIsUserAuthenticated', true, { root: true })

    // Get the meta data for the authenticated user.
    context.dispatch('auth/readAuthUserMeta', {}, { root: true })

    if (user && user.type == 3) {
      setTimeout(() => {
        context.dispatch('auth/getTranslatorEmploymentStatus', { authenticated: { token, user } }, { root: true })
      }, 1000)
    }
  } else {
    // Warn user that there is no token.
    console.warn('There is no token value.')

    // Explicitly set if user is NOT authenticated.
    context.commit('auth/setIsUserAuthenticated', false, { root: true })
  }
}
