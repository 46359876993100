export default {
  namespaced: true,
  getters: {
    type: () => {
      // 1 : Sweden
      // 2 : Denmark
      return parseInt(localStorage.getItem('dt_multitenancy_type'))
    }
  },
  mutations: {
    setType(state, type) {
      localStorage.setItem('dt_multitenancy_type', type)
    }
  }
}
