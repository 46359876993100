import _, { isInteger } from 'lodash'
import moment from 'moment-timezone'
import trim from 'lodash/trim'
import i18n from '@/locales/i18nClient.js'
import appStorage from '@/modules/helpers/appStorage'
import LS from '@/modules/constants/localStorage'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import store from '../../store'
import { DEFAULT_REFERENCE_FIELD_LABEL, DEFAULT_REFERENCE_FIELD_PLACEHOLDER } from '../constants/html'

export function mapEndpoint(endpoint, payload) {
  // Get all the placeholder inside endpoint
  // noinspection RegExpRedundantEscape
  let keys = (endpoint.match(/\{.+?\}/g) || []).map(function (str) {
    return str.slice(1, -1)
  })

  let obj = {} // Create container for data object
  if (_.isUndefined(payload.id) && !_.isUndefined(payload.data) && !_.isUndefined(payload.data.id)) {
    obj = payload.data
  } else {
    obj = payload
  }

  _.each(keys, (k) => {
    // If the value within the obj[k] is not undefined,
    // run this block...
    if (!_.isUndefined(obj[k])) {
      // Define the placeholder
      let placeholder = '{' + k + '}'
      // Define the endpoint
      endpoint = _.replace(endpoint, placeholder, obj[k])
    }
  })

  return endpoint
} // End of mapEndpoint() method

export function formatDateTime(value, isDateTime = true) {
  if (!trim(value)) {
    return ''
  }
  const locale = i18n.locale === 'se' ? 'sv' : i18n.locale
  const momentObj = moment(value).locale(locale)

  if (isDateTime) {
    return momentObj.format('YYYY-MM-DD HH:mm')
  } else {
    return momentObj.format('YYYY-MM-DD')
  }
}

export function formatTime(value) {
  if (!trim(value)) {
    return ''
  }
  const locale = i18n.locale === 'se' ? 'sv' : i18n.locale
  const momentObj = moment(value).locale(locale)

  return momentObj.format('HH:mm')
}

export function getPercentage(whole, portion) {
  if (whole === 0 && portion === 0) return '0%'
  let percentage = (portion / whole) * 100
  return (!isInteger(percentage) ? percentage.toFixed(2) : percentage) + '%'
}

export function fullScreenLoader(value) {
  store.commit('fullscreenLoader/setVisible', value)
}

export function getReferenceLabelValue(value) {
  return value === 'null' || !value ? DEFAULT_REFERENCE_FIELD_LABEL : value
}

export function getReferencePlaceHolderValue(value) {
  return value === 'null' || !value ? DEFAULT_REFERENCE_FIELD_PLACEHOLDER : value
}

export function isNextDay(date, startTime, endTime) {
  let start = moment(startTime, 'HH:mm')
  let end = moment(endTime, 'HH:mm')
  let nextDay = false

  if (end.isBefore(start)) {
    end.add(1, 'day')
    nextDay = true
  }

  let duration = end.diff(start, 'hours')

  return nextDay && duration < 12 && date
}

export function createTimeSlots(date, startTime, endTime, duration) {
  if (!date || !startTime || !endTime) {
    return []
  }

  let start = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm')
  let end = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm')
  let newDuration = end.diff(start, 'minutes')

  if (newDuration < duration) {
    return []
  }

  let slots = [
    {
      date: start.format('YYYY-MM-DD'),
      start_time: start.format('HH:mm'),
      end_time: start.add(duration, 'minutes').format('HH:mm')
    }
  ]

  for (let i = 0; i < 3; i++) {
    let slotDate = start.format('YYYY-MM-DD')
    let slotStart = start.format('HH:mm')
    let slotEnd = start.add(duration, 'minutes')

    if (slotEnd.isAfter(moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm'))) {
      return slots
    }

    slots.push({
      date: slotDate,
      start_time: slotStart,
      end_time: slotEnd.format('HH:mm')
    })
  }

  return slots
}

export function getAccessToken() {
  const token = appStorage.get(LS.TOKEN)
  if (!isNil(token) && !isEmpty(token)) {
    if (isString(token)) {
      return token
    } else if (isObject(token)) {
      return token.access_token
    } else {
      return ''
    }
  }
  return ''
}
