/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitBooking
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import isEmpty from 'lodash/isEmpty'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import moment from 'moment'

export default function (context, payload) {
  const url = API.BOOKINGS
  const method = 'post'

  let data = { ...payload }

  if (isEmpty(data.descriptions)) {
    data.descriptions = 'Instant Text Translation'
  }

  data.gender = ''
  data.date = moment().format('YYYY-MM-DD')
  data.date = moment().format('HH:mm:ss')
  data.created_via = context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'

  // If the AI text translation type is present, set it to text translation type
  if (data?.ai_text_translation_type) {
    data.text_translation_type = data.ai_text_translation_type
  }

  let options = {
    data,
    is_data_raw: true
  }

  // Call the reach function to make the API call
  return reach(this, context, url, method, options, axiosInstance)
}
