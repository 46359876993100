/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > Booking Export
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to update session time.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = `${API.EXPORT_BOOKING}`
  const method = 'POST'

  let options = {
    query_params: payload
  }

  return reach(this, context, url, method, options, axiosInstance)
}
