<template>
  <div class="app-form" :class="getBem(blockClass)">
    <!-- Added submit.prevent to avoid the collapse triggering a submit..
      Solution suggested in the element-plus repo here:
      https://github.com/element-plus/element-plus/issues/14304#issuecomment-1722086457
     -->
    <el-form ref="form" :model="iValue" :rules="formRules" @submit.prevent>
      <el-form-item
        class="text-center"
        :class="[getBem(blockClass, 'rating-container'), { 'auth-lines': isReadOnly }]"
        prop="rating"
      >
        <label v-if="!isReadOnly" :class="getBem(blockClass, 'rating-label')">
          {{ $t('rate_your_experience') }}
        </label>
        <!-- already had feedback -->
        <template v-else>
          <p :class="getBem(blockClass, 'submitted-already-label')">
            {{ $t('booking_feedback_already_submitted') }}
          </p>
          <!-- <label :class="getBem(blockClass, 'rating-label')">
            {{ $t('your_feedback') }}
          </label> -->
        </template>

        <!-- Stars Rating Field -->
        <el-rate
          ref="booking-feedback-form-rating-field"
          v-model="iValue.rating"
          :class="`${getBem(blockClass, 'rating-field')} ${getBem(blockClass, ratingLevel)}`"
          :disabled="isReadOnly"
          void-icon-class="el-icon-star-on star-void"
          void-color="#FFFFFF"
          :colors="['#FFFFFF', '#FFFFFF', '#FFFFFF']"
          @change="handleChangeRating"
        />
        <!-- <el-icon><StarFilled /></el-icon> -->
      </el-form-item>

      <!-- has feedback already -->
      <template v-if="isReadOnly">
        <el-row :class="getBem(blockClass, 'feedbacks')">
          <el-col :span="12">
            <label :class="getBem(blockClass, 'rating-label')">
              {{ $t('your_feedback') }}
            </label>
          </el-col>
          <el-col :span="12" class="text-right">
            {{ formatFeeabackCreatedAt(iValue.createdAt) }}
          </el-col>

          <el-col :span="24" class="text-left" :class="getBem(blockClass, ['feedbacks', 'messages'])">
            {{ iValue.comment }}
          </el-col>
        </el-row>
      </template>

      <h4 v-if="!isReadOnly" class="text-center" :class="getBem(blockClass, 'rating-note')">
        {{ $t('booking_feedback_note') }}
      </h4>

      <template v-if="!isReadOnly && iIsShowOtherFields && hasRating">
        <p class="text-center">
          {{ isRatingPositive ? $t('booking_feedback_positive') : $t('booking_feedback_negative') + '?' }}
        </p>

        <el-form-item :class="getBem(blockClass, 'block-container')" prop="reason">
          <el-collapse v-model="commentPanel" :class="getBem(blockClass, 'comment-container')">
            <el-collapse-item :title="$t('select_atleast_one_or_more_options')" :name="1">
              <div v-if="showReasonError" class="text-danger">{{ $t('select_reason_warning') }}</div>
              <!-- Comment List of Selections -->
              <ul
                :class="[
                  getBem(blockClass, 'comment-list'),
                  {
                    'comment-positive': isRatingPositive,
                    'comment-negative': isRatingNegative
                  }
                ]"
              >
                <el-row v-for="(v, idx) in commentOptsRows" :key="idx">
                  <template v-for="(option, idx2) in v">
                    <el-col
                      v-if="option"
                      :key="`${option}_${idx2}`"
                      :md="12"
                      :lg="12"
                      :sm="24"
                      :class="selectedTags.indexOf(option) > -1 ? 'selected' : ''"
                    >
                      <li tabindex="0" @click="handleClickCommentOpts(option)" @keyup.enter="handleEnterCommentOpts">
                        {{ option }}
                        <div v-if="showTimeDropdown(option)" class="sub-option">
                          {{ $t('question_translator_late_minutes') }}
                          <el-select v-model="iValue.translator_delay_time" size="small">
                            <el-option v-for="item in timeOptions" :key="item" :label="item" :value="item" />
                          </el-select>
                        </div>
                        <div v-if="showCallUsInfo(option)" class="sub-option">
                          {{ $t('arragement_contact') }}
                        </div>
                      </li>
                    </el-col>
                  </template>
                </el-row>
              </ul>
              <br />
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <!-- Comment Field -->
        <div :class="getBem(blockClass, 'block-container')">
          <el-form-item :class="getBem(blockClass, 'comment-field-container')" prop="comment">
            <el-input
              v-model="iValue.comment"
              type="textarea"
              :class="getBem(blockClass, ['comment-field'])"
              :rows="4"
              tabindex="0"
            />
          </el-form-item>
          <p :class="getBem(blockClass, 'comment-label')">
            {{ commentFieldLabel }}
          </p>
        </div>

        <el-form-item v-if="isUserCustomer && isRatingNegative" :class="getBem(blockClass, 'block-container')">
          <!-- <p class="no-margin-top">
            <b>{{ $t('block_for_bad_rating_ask') }}</b>
          </p> -->
          <el-radio-group
            v-model="showBlockTranslatorFields"
            class="ant-radio-group-vertical"
            :class="getBem(blockClass, ['block-field'])"
            @change="toggleBlockTranslator"
          >
            <el-radio :label="true" size="large">
              {{ $t('block_for_bad_rating_yes') }}
            </el-radio>

            <el-radio :label="false" size="large">
              {{ $t('block_for_bad_rating_no') }}
            </el-radio>
          </el-radio-group>
          <br />
          <div v-show="showBlockTranslatorFields">
            <div v-if="showBlockOptionsError" class="text-danger" style="margin-top: -5px; margin-bottom: 10px">
              {{ $t('select_one_of_block_option') }}
            </div>
            <el-checkbox
              v-model="isBlockTranslator"
              :disabled="isBlockTranslatorForDepartment"
              :label="$t('block_for_me')"
            />
            <el-checkbox v-model="isBlockTranslatorForDepartment" :label="$t('block_for_my_department')" />
          </div>
        </el-form-item>

        <p
          v-if="
            isUserCustomer &&
            isRatingNegative &&
            (isBlockTranslator || isBlockTranslatorForDepartment) &&
            translatorOtherJobs.length > 0
          "
          class="text-center"
        >
          {{ $t('remove_translator_jobs') }}
        </p>

        <el-collapse
          v-if="
            isUserCustomer &&
            isRatingNegative &&
            (isBlockTranslator || isBlockTranslatorForDepartment) &&
            translatorOtherJobs.length > 0
          "
          v-model="translatorOtherJobsPanel"
          :class="getBem(blockClass, 'translator-other-jobs-container')"
        >
          <el-collapse-item :title="$t('select_on_or_more_jobs')" :name="1">
            <table class="table" :class="getBem(blockClass, 'other-jobs-table')">
              <thead>
                <tr>
                  <th>
                    <el-checkbox
                      :class="getBem(blockClass, 'select-other-jobs-all')"
                      @change="handleSelectAllOtherJobs($event)"
                    />
                  </th>
                  <th style="width: 70%">
                    {{ $t('translators_other_jobs') }}
                  </th>
                  <th>
                    {{ $t('action') }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(job, idx) in translatorOtherJobs" :key="idx">
                  <td>
                    <!--suppress HtmlFormInputWithoutLabel -->
                    <input
                      :id="job.id"
                      v-model="selectedTranslatorOtherJobs"
                      type="checkbox"
                      :class="getBem(blockClass, 'select-other-job')"
                      :value="job"
                    />
                  </td>
                  <td>
                    {{ job.id }} - {{ job.due_date }}
                    {{ job.due_time }}
                  </td>
                  <td>
                    <el-button class="app-button app-button-secondary" @click="handleOtherJobClick(job)">
                      {{ $t('remove') }}
                    </el-button>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="selectedTranslatorOtherJobs.length > 0">
                <tr>
                  <td colspan="3">
                    <p class="text-center">
                      <el-button class="app-button app-button-secondary" @click="handleRemoveSelectedJobs">
                        {{ $t('remove_selected') }}
                      </el-button>
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </el-collapse-item>
        </el-collapse>
      </template>
    </el-form>

    <low-feedback-message ref="low-feedback-message" :booking="booking" />

    <div v-if="isUserCustomer" class="feedback_disclaimer">
      <h5><i class="fa-solid fa-user-secret"></i> {{ $t('feedback_disclaimer_heading') }}</h5>
      <p>{{ $t('feedback_disclaimer') }}</p>
    </div>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
// import includes from 'lodash/includes'
import cloneDeep from 'lodash/cloneDeep'
import LowFeedbackMessage from '@/modules/booking/components/displays/LowFeedbackMessage'
import { CUSTOMER, TRANSLATOR } from '@/modules/constants/userType'
export default {
  components: {
    LowFeedbackMessage
  },

  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    /**
     * Expected Object properties are:
     *
     * rating: {Integer}
     * comment: {String}
     * reason: {Array}
     */
    modelValue: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    userType: {
      type: Number,
      default: 2
    },

    booking: {
      type: Object,
      required: true
    },

    isReadOnly: {
      type: Boolean,
      default: false
    },

    // iIsBlockTranslator: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },

    isShowOtherFields: {
      type: Boolean,
      required: true
    }
  },

  emits: [
    'update:modelValue',
    'update:isBlockTranslator',
    'update:isBlockTranslatorForDepartment',
    'update:isShowOtherFields',
    'reloadList'
  ],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'booking-feedback-form',
      lastHoverRating: 0,
      ratingLevel: 'rating-0',
      commentPanel: 1,
      translatorOtherJobsPanel: 0,
      selectedTranslatorOtherJobs: [],
      prevRating: 0,
      prevComment: '',
      isValidated: false,
      isBlockTranslator: false,
      isBlockTranslatorForDepartment: false,
      showBlockTranslatorFields: false,
      selectedOtionIndex: -1,
      selectedOption: '',
      selectedTags: [],
      selectedTimeLate: '',
      feedbackOptions: [],
      showReasonError: false,
      showBlockOptionsError: false
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * Get current user details.
     */
    ...mapGetters({
      user: 'auth/user',
      translatorOtherJobs: 'booking/customerAndTranslatorOtherBookingListData',
      isCustomer: 'auth/isUserCustomer',
      isTranslator: 'auth/isUserTranslator'
    }),
    ...mapState('auth', ['language']),

    isUserCustomer() {
      if (this.isPublic) {
        return this.userType == CUSTOMER
      } else {
        return this.isCustomer
      }
    },
    isUserTranslator() {
      if (this.isPublic) {
        return this.userType == TRANSLATOR
      } else {
        return this.isTranslator
      }
    },

    /**
     * Interface for this.value.
     */
    iValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    /**
     * Interface for the this.iIsBlockTranslator prop.
     */
    iIsBlockTranslator: {
      get() {
        return this.isBlockTranslator
      },
      set(v) {
        this.$emit('update:isBlockTranslator', v)
      }
    },

    iIsBlockTranslatorForDepartment: {
      get() {
        return this.isBlockTranslatorForDepartment
      },
      set(v) {
        this.$emit('update:isBlockTranslatorForDepartment', v)
      }
    },

    /**
     * Interface for the this.iIsShowOtherFields prop.
     */
    iIsShowOtherFields: {
      get() {
        return this.isShowOtherFields
      },
      set(v) {
        this.$emit('update:isShowOtherFields', v)
      }
    },

    /**
     * Returns boolean to determine if a rating has been given already.
     */
    hasRating() {
      return !isNil(this.iValue.rating) && this.iValue.rating !== 0
    },

    /**
     * Returns boolean whether the rating given is a positive feedback.
     *
     * @returns {Boolean}
     */
    isRatingPositive() {
      return this.iValue.rating >= 3
    },

    /**
     * Returns boolean whether the rating given is a negative feedback.
     *
     * @returns {Boolean}
     */
    isRatingNegative() {
      return this.iValue.rating < 3
    },

    /**
     * Returns comment options based on the grade of ratings and user type.
     *
     * @returns {Array}
     */
    commentOpts() {
      const o = {
        customers: {
          high: this.isBookingTextTranslation
            ? [
                this.$t('completed_on_time'),
                this.$t('delivered_acc_to_expectations'),
                this.$t('formatted_correctly_with_our_specification'),
                this.$t('clear_comprehend_compared_to_original'),
                this.$t('had_right_content_maintained_message')
              ]
            : [
                this.$t('customer_high_feedback_1'),
                this.$t('customer_high_feedback_2'),
                this.$t('customer_high_feedback_3'),
                this.$t('customer_high_feedback_4')
              ],
          low: this.isBookingTextTranslation
            ? [
                this.$t('was_delivered_late'),
                this.$t('not_met_expectations'),
                this.$t('not_formatted_accordance_with_specification'),
                this.$t('not_clear_comprehend_compared_to_original'),
                this.$t('not_contain_right_content_maintained_message'),
                this.$t('was_not_legible')
              ]
            : [
                this.$t('customer_low_feedback_1'),
                this.$t('customer_low_feedback_2'),
                this.$t('customer_low_feedback_3'),
                this.$t('customer_low_feedback_4'),
                this.$t('customer_low_feedback_5'),
                this.$t('customer_low_feedback_6'),
                this.$t('customer_low_feedback_7'),
                this.$t('customer_low_feedback_8'),
                this.$t('customer_low_feedback_9')
              ]
        },
        translators: {
          high: this.isBookingTextTranslation
            ? [
                this.$t('customer_provided_clear_instructions'),
                this.$t('useful_context_made_booking_easy'),
                this.$t('booking_has_suitable_delivery_dates'),
                this.$t('customer_provided_prepared_original_documents'),
                this.$t('respected_my_expertise_and_translation_choices')
              ]
            : [
                this.$t('translator_high_feedback_1'),
                this.$t('translator_high_feedback_2'),
                this.$t('translator_high_feedback_3')
              ],
          low: this.isBookingTextTranslation
            ? [
                this.$t('customer_provided_unclear_instructions'),
                this.$t('customer_expectations_were_not_aligned'),
                this.$t('unrealistic_delivery_deadlines_and_changes'),
                this.$t('original_documents_poorly_prepared')
              ]
            : [
                this.$t('translator_low_feedback_1'),
                this.$t('translator_low_feedback_2'),
                this.$t('translator_low_feedback_3'),
                this.$t('translator_low_feedback_4')
              ]
        }
      }

      const type = this.isUserCustomer ? 'customers' : 'translators'
      const grade = this.isRatingPositive ? 'high' : 'low'

      return o[type][grade]
    },
    commentOptsRows() {
      let feedbackOptions = []
      for (let i = 0; i < this.commentOpts.length; i++) {
        if (i % 2 === 0) {
          feedbackOptions.push([this.commentOpts[i], this.commentOpts[i + 1]])
        }
        i++
      }
      return feedbackOptions
    },

    /**
     * Returns proper label for comment field.
     *
     * @returns {String}
     */
    commentFieldLabel() {
      return this.isUserCustomer ? this.$t('rating_desc_for_customer') : this.$t('rating_desc_for_translator')
    },

    formRules() {
      return {
        // reason: [
        //   {
        //     required: true,
        //     message: this.$t('mandatory_tag_rule'),
        //     trigger: 'blur'
        //   }
        // ],
        comment: [
          {
            required: this.iValue.rating <= 2,
            message: this.$t('tell_reason_for_low_feedback'),
            trigger: 'blur'
          }
        ]
      }
    },

    currentLocale() {
      return this.language === 'se' ? 'sv' : this.language
    },
    timeOptions() {
      return [5, 10, 15, 20, 25, 30]
    },

    isBookingTextTranslation() {
      return (
        !isNil(this.booking) &&
        !isEmpty(this.booking) &&
        !isNil(this.booking.type) &&
        String(this.booking.type) === 'text_translation'
      )
    }
  },

  watch: {
    'iValue.rating'(newValue, oldValue) {
      this.setRatingLevelCls(this.iValue.rating)
      this.resetReasons(newValue, oldValue)
    },
    'isBlockTranslator': function (v) {
      this.$emit('update:isBlockTranslator', v)
    },
    'isBlockTranslatorForDepartment': function (v) {
      if (v) {
        this.isBlockTranslator = true
      }
      this.$emit('update:isBlockTranslatorForDepartment', v)
    },
    'showBlockTranslatorFields': function (v) {
      if (!v) {
        this.isBlockTranslator = false
        this.isBlockTranslatorForDepartment = false
      }
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
  mounted() {
    // If read only, set the rateLevel Class explicitly.
    if (this.isReadOnly) {
      this.setRatingLevelCls(this.iValue.rating)
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions('auth', ['readAuthUserMeta']),

    ...mapActions('booking', [
      'getBookingByTranslatorAndCustomer',
      'removeTranslatorFromBooking',
      'removeTranslatorsFromBooking',
      'notCarriedOutToCustomer'
    ]),

    isReasonSelected() {
      return this.iValue.reason.length > 0
    },

    formatFeeabackCreatedAt(date) {
      return moment(date).locale(this.currentLocale).format('HH:mm, Do MMMM YYYY')
    },

    /**
     * Handler when a rate selector value has changed.
     *
     * @returns {void}
     */
    handleChangeRating(v) {
      this.ratingLevel = 'rating-' + v

      // Toggle the other fields.
      if (!this.iIsShowOtherFields) {
        this.iIsShowOtherFields = true
      }

      if ((this.prevRating <= 2 && v > 2) || (this.prevRating > 2 && v <= 2)) {
        let currComment = this.iValue.comment
        this.iValue.comment = this.prevComment
        this.prevComment = currComment
        this.prevRating = v
      }
    },
    resetReasons(newRating, oldRating) {
      if (oldRating <= 2 && newRating > 2) {
        this.selectedTags = []
        this.iValue.reason = []
      } else if (oldRating > 2 && newRating <= 2) {
        this.selectedTags = []
        this.iValue.reason = []
      }
    },
    /**
     * Helper method for setting the rating level css class.
     *
     * @param {int} rating - The Rating Score.
     * @return {String}
     */
    setRatingLevelCls(rating) {
      this.ratingLevel = 'rating-' + rating
      let el = document.getElementsByClassName('booking-feedback-form__rating-field')

      for (let p of el) {
        let currentStar = 1
        for (let c of p.children) {
          for (let star of c.children) {
            star.classList.add('star-void')
          }
        }
        for (let c of p.children) {
          for (let star of c.children) {
            if (currentStar <= rating) {
              star.classList.remove('star-void')
            }
            currentStar++
          }
        }
      }
    },

    /**
     * Handler when a comment option was clicked.
     *
     * @param {String} v - the comment value.
     * @returns {void}
     */
    // handleClickCommentOptsOld(v) {
    //   if (!includes(this.iValue.comment, v)) {
    //     if (this.iValue.comment.length > 0) {
    //       this.iValue.comment += ', '
    //     }
    //     this.iValue.comment += v
    //     this.iValue.reason.push(v)

    //     if (this.isUserTranslator && this.prevRating <= 3 && v === this.$t('translator_low_feedback_3')) {
    //       this.$refs['low-feedback-message'].open()
    //     } else {
    //       this.$refs['low-feedback-message'].closeSilently()
    //     }
    //   }
    // },
    handleClickCommentOpts(v) {
      if (v) {
        // this.selectedOtionIndex = this.commentOptsRows.findIndex((row) => {
        //   return row.includes(v)
        // })
        // this.selectedOption = v
        if (this.selectedTags.indexOf(v) === -1) {
          this.selectedTags.push(v)
          this.iValue.reason.push(v)
        } else {
          this.selectedTags = this.selectedTags.filter((tag) => {
            return tag !== v
          })
          this.iValue.reason = this.iValue.reason.filter((tag) => {
            return tag !== v
          })
        }
      }
    },
    showTimeDropdown(v) {
      if (this.selectedTags.indexOf(v) === -1) {
        return false
      }
      if (v) {
        return v === this.$t('customer_low_feedback_1')
      }
      return false
    },
    showCallUsInfo(v) {
      if (this.selectedTags.indexOf(v) === -1) {
        return false
      }
      if (v) {
        return [this.$t('customer_low_feedback_2'), this.$t('customer_low_feedback_3')].includes(v)
      }
      return false
    },
    /**
     * Handler when a comment option was entered.
     *
     * @returns {void}
     */
    handleEnterCommentOpts(event) {
      event.target.click()
    },

    /**
     * Handle checking and unchecking of other bookings.
     *
     * @returns {void}
     */
    handleSelectAllOtherJobs(e) {
      for (let booking of this.translatorOtherJobs) {
        if (this.selectedTranslatorOtherJobs.includes(booking) && !e) {
          this.selectedTranslatorOtherJobs.splice(this.selectedTranslatorOtherJobs.indexOf(booking), 1)
        } else if (!this.selectedTranslatorOtherJobs.includes(booking) && e) {
          this.selectedTranslatorOtherJobs.push(booking)
        }
      }
    },

    /**
     * Handle Removal of Other Booking Click
     *
     * @returns {void}
     */
    handleOtherJobClick(booking) {
      // noinspection JSValidateTypes
      this.removeTranslatorFromBooking({
        booking_id: booking.id
      }).then((r) => {
        if (r.data.status === 'success') {
          this.translatorOtherJobs.splice(this.translatorOtherJobs.indexOf(booking), 1)

          let sBooking = this.selectedTranslatorOtherJobs.find((sto) => {
            return sto.id === booking.id
          })

          if (sBooking != null) {
            this.selectedTranslatorOtherJobs.splice(this.selectedTranslatorOtherJobs.indexOf(sBooking), 1)
          }

          this.$notify.success({
            title: this.$t('success'),
            message: this.$t('translator_is_blacklisted')
          })
        }
      })
    },

    /**
     * Handle remove selected jobs.
     *
     * @returns {void}
     */
    handleRemoveSelectedJobs() {
      let ids = []
      for (let booking of this.selectedTranslatorOtherJobs) {
        ids.push(booking.id)
      }

      // noinspection JSValidateTypes
      this.removeTranslatorsFromBooking({
        booking_ids: ids
      }).then((r) => {
        if (r.data.status === 'success') {
          let selectedTranslatorOtherJobs = cloneDeep(this.selectedTranslatorOtherJobs)
          for (let booking of selectedTranslatorOtherJobs) {
            this.selectedTranslatorOtherJobs.splice(this.selectedTranslatorOtherJobs.indexOf(booking), 1)

            let tbooking = this.translatorOtherJobs.find((tto) => {
              return tto.id === booking.id
            })

            if (tbooking != null) {
              this.translatorOtherJobs.splice(this.translatorOtherJobs.indexOf(tbooking), 1)
            }
          }

          this.$notify.success({
            title: this.$t('success'),
            message: this.$t('translator_is_blacklisted')
          })
        }
      })
    },

    validate() {
      this.$refs.form.validate((valid) => {
        this.isValidated = valid
      })
      return this.isValidated
    },

    toggleBlockTranslator() {
      // this.isBlockTranslator = v
      // this.$emit('update:isBlockTranslator', v)
    },

    reloadList() {
      this.close()
      this.$emit('reloadList')
    }
  }
}
</script>

<style lang="scss" scope>
.booking-feedback-modal {
  .el-dialog {
    &__body {
      padding-left: 0;
      padding-right: 0;
      .booking-feedback-form {
        .auth-lines {
          border-bottom: 1px solid #fafafa;
          padding-bottom: 5px;

          .el-rate {
            margin-top: 5px;
          }
        }

        &__feedbacks {
          padding: 0 20px;
          border-bottom: 1px solid #fafafa;

          &__messages {
            padding: 18px 0;
          }
        }
        &__rating-label {
          color: #606266;
          font-size: 15px;
        }
        &__submitted-already-label {
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 20px;
          text-align: left;
          padding: 0 20px;
        }
      }
    }
  }

  &__actions {
    padding-left: 20px;
    padding-right: 20px;
    .ok-btn {
      padding: 13px 40px;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.booking-feedback-form {
  &__rating-container {
    .el-form-item__content {
      flex-direction: column;
    }
    .el-rate {
      height: 65px;
    }

    .el-form-item__label {
      text-align: center;
      float: none;
      padding: 0;
      margin: 0;
      color: #a0a0a0;
    }

    .el-rate__icon {
      font-size: 45px;
      padding: 5px;
      border-radius: 5px;
      background-color: #c6d1de;
      color: #ffffff;

      svg:nth-child(1) {
        display: block !important;
      }
      svg:nth-child(2) {
        display: none !important;
      }
    }

    .el-rate__icon.el-icon-star-on.star-void {
      background-color: #c6d1de !important;
    }
  }

  &__rating-field[aria-valuenow='5'],
  &__rating-field[aria-valuenow='4'] {
    .el-rate__icon.el-icon-star-on,
    .el-rate__icon.is-active {
      // background-color: var(--app-primary-color);
      background-color: #55a37c;
    }
  }

  &__rating-field[aria-valuenow='3'] {
    .el-rate__icon.el-icon-star-on,
    .el-rate__icon.is-active {
      background-color: #fedb19;
    }
  }

  &__rating-field[aria-valuenow='1'],
  &__rating-field[aria-valuenow='2'] {
    .el-rate__icon.el-icon-star-on,
    .el-rate__icon.is-active {
      background-color: #ef6e0d;
    }
  }

  &__rating-label {
    text-align: center;
    font-weight: 600;
    color: $app-form-gray;
  }

  &__rating-note {
    font-size: 14px;
    font-weight: normal;
    margin-top: 0;
  }

  &__comment-container,
  &__translator-other-jobs-container {
    margin-top: 10px;

    .el-collapse-item__wrap {
      border-bottom: 0;
    }

    .el-collapse-item__header {
      background-color: #f5f5f5;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      height: 34px;
      width: inherit;
    }

    .el-collapse-item__content {
      padding: 7px 10px 0 10px;
    }
  }

  &__comment-list,
  &__comment-list > li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__comment-list {
    /*border-radius: 5px;*/
    /*border: 1px solid #ddd;*/
  }

  &__comment-list {
    .el-row {
      flex-wrap: nowrap;
      .el-col {
        border: 1px solid #ddd;
        margin: 1px;
        &.selected {
          border: 1px solid #000;
        }
        li {
          padding: 10px 15px;
          // border-bottom: 1px solid #ddd;
          // border: 1px solid #ddd;
          cursor: pointer;
          font-size: 14px;
          .sub-option {
            font-size: 12px;
            color: #a0a0a0;
            .el-select .el-input .el-input__wrapper {
              height: 24px;
            }
          }
        }
      }
    }
  }

  &__comment-field {
    margin-top: 7px;
  }

  &__comment-label {
    font-size: 12px;
    font-weight: 600;
  }

  &__block-container {
    margin-top: 15px;
    padding: 0px 15px;
    .el-form-item__label {
      line-height: 1.2em;
      padding: 0;
    }

    .el-form-item__content {
      display: block;
      text-align: left;
    }

    .el-checkbox {
      text-align: left;
      display: block;
      margin-left: 0;
      margin-bottom: 3px;
    }

    p {
      text-align: left !important;
      margin-bottom: 0px;
    }
  }

  &__other-jobs-table {
    $td-border-color: #e8e8e8;
    $td-bg-color: #f3f3f3;

    width: 100%;

    tr > td {
      border-bottom: 1px solid $td-border-color;
      padding: 7px 15px;
      text-align: center;
    }

    tr:last-child > td {
      border-bottom: 0;
    }

    tr:nth-child(even) > td {
      background-color: $td-bg-color;
    }
  }

  &__comment-field-container {
    .el-textarea__inner {
      min-height: 200px !important;
      line-height: 1.5em !important;
    }
  }

  .feedback_disclaimer {
    padding: 8px 4px;
    max-width: 700px;
    margin: 10px auto;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    font-size: 12px;
    background: #ebebeb;
    h5 {
      font-size: 14px;
      margin: 0px;
      text-align: center;
      font-weight: 600;
      i {
        color: #939393;
        margin-right: 5px;
        font-size: 12px;
      }
    }

    div {
      margin-top: 0px;
    }
  }

  // **************************************************
  // Extra Small Viewport
  // **************************************************
  @media (max-width: $screen-xs-max) {
    &__rating-container {
      .el-rate {
        height: 50px;
      }

      .el-form-item__label {
        font-size: 14px;
      }

      .el-rate__icon {
        font-size: 28px;
      }
    }

    &__comment-list > li {
      font-size: 12px;
      padding: 5px 7px;
    }
  }

  .text-danger {
    color: red;
    padding: 0px 0px;
  }
}
</style>
