/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadMunicipalityUserOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the municipality user opts according to the given municipality id.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @param {string|int} payload.municipality_id - The target municipality id in which the set of users should be under.
 * @param {object} payload.queryParams
 * @returns {Promise|*}
 */
export default function (context, payload = {}) {
  if (isNil(payload.municipality_id) || payload.municipality_id === '' || payload.municipality_id === 0) {
    const errMsg = 'payload.municipality_id value Should exist!'
    console.error(errMsg)
    throw new Error(errMsg)
  }

  context.commit('auth/setMunicipalityUserOptsLoading', true, { root: true })

  const url = `${API.MUNICIPALITIES}/${payload.municipality_id}/customers`
  const method = 'GET'

  let options = {
    query_params: !isNil(payload.queryParams) ? payload.queryParams : {},
    on_success(r, ctx) {
      ctx.commit('auth/setMunicipalityUserOpts', r.data.data, { root: true })
    },
    on_finally(ctx) {
      ctx.commit('auth/setMunicipalityUserOptsLoading', false, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
