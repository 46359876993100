/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > Sectors
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @returns {*}
 */
export default function (context, payload) {
  const method = 'GET'
  const url = API.SECTORS
  let options = {
    query_params: {
      all: 1,
      is_enabled: payload.is_enabled
    },
    headers: {
      'X-Recruitment-Client': 1
    }
  }

  return reach(this, context, url, method, options)
}
