import axios from 'axios'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

const token = import.meta.env.VITE_HANDESK_TOKEN
const handeskUrl = import.meta.env.VITE_HANDESK_URL
import { NOTIFICATIONS, NOTIFICATIONS_MARK_READ, USERS } from '@/modules/constants/api'

console.log('handeskUrl', handeskUrl)
const handesk = axios.create({
  baseURL: handeskUrl,
  headers: {
    token: token
  }
})

export default {
  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  markAllAsRead(context, payload) {
    const method = 'GET'
    let url = `${NOTIFICATIONS_MARK_READ}/${payload.user.id}/?all=true`

    const params = {
      user: payload.user,
      per_page: payload.per_page,
      page: payload.page
    }

    if (payload.unread_only) {
      params.unread_only = payload.unread_only // request unread notification(s) only
    }

    return axiosInstance({ method, url })
      .then(() => {
        // re-load the exact same number of notifications which are currently loaded, except with the new `read status`
        context.dispatch('fetchNotifications', params)
      })
      .catch((e) => {
        console.error(e)
      })
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  markOneAsRead(context, payload) {
    const method = 'PUT'
    const url = `${NOTIFICATIONS}/${payload.user.id}/${payload.notification.id}`

    const data = {
      read_at: payload.time
    }
    return axiosInstance({ method, url, data })
      .then(() => {
        return true
      })
      .catch((e) => {
        console.error(e)
        return false
      })
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  fetchNotifications(context, payload) {
    const method = 'GET'
    const url = `${USERS}/${payload.user.id}/notifications`
    const params = {
      per_page: payload.per_page ? payload.per_page : 7, // show x number of notifications per page
      offset: payload.offset, // skip x number of notifications before calculating pagination and results
      page: payload.page, // return notifications from a specific page
      sort: '-created_at' // sort notifications by field (use `-` for reverse order)
    }

    if (payload.type) {
      params.type = payload.type // request notification(s) of a certain type only
    }

    if (payload.unread_only) {
      params.unread_only = payload.unread_only // request unread notification(s) only
    }

    context.commit('setLoadingStatus', true)
    axiosInstance({ method: method, url, params })
      .then((r) => {
        if (r.data.meta.pagination.current_page > r.data.meta.pagination.total_pages) {
          context.commit('setNotificationsEndStatus', true)
          context.commit('setLoadingStatus', false)
        } else {
          context.commit('setNotificationsEndStatus', false)
          context.commit('setNotifications', r)
          context.commit('setUnreadInboxCount', r.data.total_unread_inbox_notifications)
          context.commit('setUnreadNotificationsCount', r.data.total_unread_notifications)
          context.commit('setUnreadImportantCount', r.data.total_unread_important_notifications)
          context.commit('setLoadingStatus', false)
        }
      })
      .catch((e) => {
        console.error(e)
        context.commit('setLoadingStatus', false)
      })
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  fetchEmails(context, payload) {
    if (!payload.dt_user_id) {
      return
    }
    context.commit('setLoading', true)
    return new Promise((resolve, reject) => {
      const method = 'GET'
      const url = `/api/inbox`
      const params = {
        per_page: 10,
        dt_user_id: payload.dt_user_id
      }

      if (payload.type) {
        params.type = payload.type
      }

      if (payload.unread) {
        params.unread = payload.unread
      }

      if (payload.page) {
        params.page = payload.page
      }
      handesk({ method, url, params })
        .then((r) => {
          context.commit('setEmailsData', r.data)

          context.commit('setLoading', false)
          resolve(r)
        })
        .catch((e) => {
          context.commit('setLoading', false)
          console.error(e)
          reject(e)
        })
    })
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  getEmailById(context, payload) {
    return new Promise((resolve, reject) => {
      const method = 'GET'
      const url = `/api/inbox/${payload.id}`
      const params = {}

      handesk({ method, url, params })
        .then((r) => {
          context.commit('setEmail', r.data)
          resolve(r)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },

  /**
   *
   * @param context
   * @param payload
   */
  postComment(context, payload) {
    return new Promise((resolve, reject) => {
      const method = 'post'
      const url = `/api/tickets/${payload.id}/comments`
      const params = {
        body: payload.body
      }

      handesk({ method, url, params })
        .then((r) => {
          context.commit('setEmail', r.data)
          resolve(r)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },

  updateEmail(context, payload) {
    return new Promise((resolve, reject) => {
      const method = 'put'
      const url = `/api/tickets/${payload.id}`
      const data = payload.params

      handesk({ method, url, data })
        .then((r) => {
          resolve(r)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },

  createTicket(context, payload) {
    return new Promise((resolve, reject) => {
      const method = 'post'
      const url = `/api/tickets`
      const data = payload

      handesk({ method, url, data })
        .then((r) => {
          resolve(r)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },

  downloadAttachment() {}
}
