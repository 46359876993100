<template>
  <div>
    <div v-if="isShowCookieAlert" :class="getBem(blockClass)">
      <el-row :class="getBem(blockClass, ['alert-cookie-usage'])">
        <el-col :md="4" justify="center" align="right">
          <font-awesome-icon
            :class="getBem(blockClass, ['warning-message'])"
            :icon="['fa', 'exclamation-triangle']"
            size="4x"
          />
        </el-col>
        <el-col v-if="criticalBlogs && criticalBlogs.blog" :md="16" :class="getBem(blockClass, ['text-container'])">
          <h4>{{ criticalBlogs.blog[0].title }}</h4>
          <p>
            {{ criticalBlogs.blog[0].critical_message }}
            <a
              ><u @click="handleClickBlog()"> {{ $t('read_more') }} </u></a
            >
          </p>
        </el-col>
        <el-col
          type="flex"
          justify="center"
          align="right"
          :md="4"
          :class="getBem(blockClass, ['close-button'])"
          @click.native="handleSeenBlog()"
        >
          <el-row>
            <el-col :md="22">
              <small>Stäng meddelande</small>
            </el-col>
            <el-col :md="2">
              <font-awesome-icon :icon="['fa', 'times']" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div v-if="loadingCriticalBlogs" class="cookie-urgent-placeholder">{{ $t('component_is_loading') }}...</div>
  </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapState, mapMutations } from 'vuex'
import appStorage from '@/modules/helpers/appStorage'
import concat from 'lodash/concat'
import EventBus from '@/modules/helpers/eventBus'

library.add(faExclamationTriangle, faTimes)

export default {
  name: 'CookieUrgentInformationAlert',

  components: {
    FontAwesomeIcon
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'cookie-urgent-information-alert-partial',
      blogToShow: {}
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    ...mapState('blog', {
      loadingCriticalBlogs: 'loadingCriticalBlogs',
      criticalBlogs: 'criticalBlogs',
      isShowCookieAlert: 'isShowCookieAlert'
    })
  },

  /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
  mounted() {
    this.prepareCriticalBlogFeature()
    this.enableEventBus()
  },
  beforeUnmount() {
    this.disableEventBus()
  },

  /*
    |--------------------------------------------------------------------------
    | Component > method
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions('blog', {
      getCriticalBlogs: 'getCriticalBlogs'
    }),

    ...mapMutations('blog', {
      setCriticalBlogDataList: 'setCriticalBlogDataList',
      checkIsAlreadySeenInCookie: 'checkIsAlreadySeenInCookie',
      setIsShowCookieAlert: 'setIsShowCookieAlert'
    }),

    /**
     * Prepare feature
     */
    prepareCriticalBlogFeature() {
      this.getCriticalBlogs().then(() => {
        this.checkIsAlreadySeenInCookie(this.criticalBlogs)
      })
    },

    /**
     * Handle Click Blog
     */
    handleClickBlog() {
      this.$router.push({
        name: `blog.details`,
        params: { slug: this.criticalBlogs.blog[0].slug }
      })
    },

    /**
     * Setting blog to seen
     */
    handleSeenBlog() {
      let oldCookie = JSON.parse(appStorage.get('blog_seen'))
      if (oldCookie) {
        oldCookie = concat(oldCookie, [this.criticalBlogs.blog[0].id])
        appStorage.set('blog_seen', JSON.stringify(oldCookie))
      } else {
        appStorage.set('blog_seen', JSON.stringify([this.criticalBlogs.blog[0].id]))
      }
      this.setIsShowCookieAlert(false)
      this.getCriticalBlogs().then(() => {
        this.checkIsAlreadySeenInCookie(this.criticalBlogs)
      })
    },
    enableEventBus() {
      EventBus.on('language-changed', this.prepareCriticalBlogFeature)
    },
    disableEventBus() {
      EventBus.off('language-changed')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.cookie-urgent-placeholder {
  background: #f3f6f4;
  height: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie-urgent-information-alert-partial {
  background-color: #f3f6f4;
  &__alert-cookie-usage {
    width: 100%;
    padding: 0 40px;
    margin-bottom: 7px;
    border-radius: 3px;
    color: $app-lighter-gray;
    border-bottom: 1px solid $app-lightest-gray;
  }

  &_icon-container {
    margin-top: 10000px !important;
  }

  &__text-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__text-container u {
    cursor: pointer;
  }

  &__text-container h4 {
    color: #e05353;
    margin-bottom: 0px;
  }

  &__close-button {
    margin-top: 10px;
    cursor: pointer;
  }

  &__text-container p {
    color: #585858;
    margin-top: 10px;
  }

  &__text-container a {
    color: #3a75ba;
  }

  &__warning-message {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 20px;
    text-align: left;
    color: #e05353;
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .cookie-urgent-placeholder {
    height: 254px;
  }
  .cookie-urgent-information-alert-partial {
    &__alert-cookie-usage {
      /*padding: 0 25px;*/
      text-align: left;
      padding-left: 15px;
      padding-right: 10px;
    }

    &__warning-message {
      margin-top: 10px;
      margin-right: 15px;
      color: #e05353;
      float: left;
    }

    &__close-button {
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 16px;
    }
  }
}
</style>
