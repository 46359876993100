/*
|--------------------------------------------------------------------------
| Store > Earnings > Actions
|--------------------------------------------------------------------------
| File that contains the actions property for the current Vuex scope.
*/

import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import forOwn from 'lodash/forOwn'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default {
  /**
   * Method for the BROWSE action.
   *
   * @param {object} context - The Vuex current scope, plus global.
   * @param {object} [payload] - contains necessary values
   *                             usable inside the method.
   *
   * @return {Promise}
   */
  browse(context, payload = {}) {
    let url = API.PAYROLLS
    const method = 'GET'

    // Define helper for setting default value.
    const sd = (val, def) => (isNil(val) ? def : val)

    payload.params = sd(payload.params, {})
    payload.params.per_page = sd(payload.per_page, 15)

    if (!isEmpty(payload.params)) {
      let qp = []

      // Attach the query parameters in the URL.
      forOwn(payload.params, (v, i) => qp.push(`${i}=${v}`))
      url += `?${qp.join('&')}`
    }

    return new Promise((resolve, reject) => {
      axiosInstance({ url, method })
        .then((r) => {
          context.commit('setListData', r)
          context.commit('setAmountsTotal', r)
          context.commit('setGraph', r)

          resolve(r)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },

  /**
   * Method for the downloading PDF files.
   *
   * @param {object} context - The Vuex current scope, plus global.
   * @param {object} payload - Contains necessary values.
   * @param {int} payload.id - The id of the target payroll record.
   * @param {string} payload.date - The date of the target payroll record.
   *
   * @return {*}
   */
  downloadPdf(context, payload = {}) {
    if (isNil(payload.id)) throw new Error('Missing required value payload.id!')
    if (isNil(window) || isNil(document)) {
      console.error('This feature is for browsers only')
      return
    }

    const url = `${API.PAYROLLS}/${payload.id}/download?path=${payload.id}.pdf`
    const method = 'GET'

    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        responseType: 'blob',
        headers: { Accept: 'application/pdf' }
      })
        .then((r) => {
          resolve(r)
          let blob = new Blob([r.data], { type: 'application/pdf' })
          let url = window.URL.createObjectURL(blob)
          let link = document.createElement('a')
          link.href = url
          link.download = `${payload.date}.pdf`
          link.click()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}
