import LS from '@/modules/constants/localStorage'
import USER_TYPE from '@/modules/constants/userType'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { getLocaleRoutePath } from '@/modules/helpers/routes'
import appStorage from '@/modules/helpers/appStorage'

/**
 * @param next
 * @param router
 * @returns {*}
 */
function notTranslator({ next, router }) {
  const user = appStorage.get(LS.USER)
  const isUserTranslator = !isNil(user) && !isEmpty(user) && user.type === USER_TYPE.TRANSLATOR

  if (isUserTranslator) {
    const path = getLocaleRoutePath('/today-dashboard', router)
    next({ path })
  } else {
    return next()
  }
}

export default notTranslator
