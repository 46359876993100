/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > Validate Text Translator
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @returns {*}
 */
export default function (context, payload) {
  console.log('call validate text payload', payload)
  const method = 'POST'

  const base_url = import.meta.env.VITE_RECRUITMENT_API_BASE || 'https://stage-rekrytering.digitaltolk.com'

  const url = `${base_url}/api/validate/text-translator`
  let options = {
    data: {
      email: payload
    },
    on_success(r, ctx) {
      ctx.commit('setValidTextTranslator', r.data.is_existing_translator)
    },
    headers: {
      'X-Recruitment-Client': 1
    }
  }
  return reach(this, context, url, method, options)
}
