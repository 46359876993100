import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { reach } from '@/modules/helpers/vuexActions'
import api from '../../../../constants/api'

export default function (context, payload = {}) {
  const customerId = context.rootGetters['auth/userMetaData'].id

  // Add customer id
  payload.customer_id = customerId

  const url = api.TEXT_TRANSLATION_EXPRESS_HOURS

  const method = 'post'
  let options = {
    data: payload,
    on_success(r, ctx) {
      const expressHours = r?.data?.data ?? []
      ctx.commit('setTextTranslationExpressHours', expressHours)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
