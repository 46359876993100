import LS from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'

function switchAuthUser(context, userId) {
  const selected = appStorage.get(LS.AUTH_COLLECTION_USER + userId)

  context.commit('auth/destroyToken', null, { root: true })
  context.commit('auth/destroyUser', null, { root: true })
  context.commit('customerIntegration/removeCSToken', null, { root: true })

  context.commit('auth/setToken', selected.token, { root: true })
  context.commit('auth/setRefreshToken', selected.refresh_token, { root: true })
  context.commit('auth/setUser', selected.user, { root: true })

  location.reload()
}

export default switchAuthUser
