/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadUserVideoSettings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 *
 * @returns {*}
 */
export default function (context, userID) {
  const method = 'GET'
  let url = `${API.CUSTOMERS}/${userID}?include=customer_data.department.company.municipality,customer_notification_types,booking_types,blacklisted_users.translator,user_notification_preference.template,booking_field`

  return reach(this, context, url, method, {}, axiosInstance)
}
