// import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function addTranslatorUnavailableTimes(context, payload) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = API.TRANSLATOR_UNAVAILABLE_TIMES
  const method = 'POST'
  // const options = {
  //   query_params: payload.queryParams
  // }
  let params = payload.queryParams
  context.commit('setLoadingForUnavailableTime', true)
  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      params
    })
      .then((r) => {
        let translator_id = r?.data?.data?.translator_unavailable_time?.translator_id

        context
          .dispatch('loadTranslatorUnavailableTimes', {
            queryParams: {
              'filter[translator_id]': translator_id,
              'page': 1,
              'per_page': 10,
              'include': 'translator_unavailable_booking_types'
            }
          })
          .then(() => {
            context.commit('setLoadingForUnavailableTime', false)
            resolve(r) // Resolve with the result of loadTranslatorUnavailableTimes
          })
      })
      .catch((e) => {
        context.commit('setLoadingForUnavailableTime', false)
        reject(e)
      })
  })
}

export default addTranslatorUnavailableTimes
