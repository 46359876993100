/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > updateSessionTime
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to update session time.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = `/api/v3/core/convey/${payload.id}/file/${payload.file_id}`
  const method = 'DELETE'
  let options = {
    data: {}
  }

  return reach(this, context, url, method, options, axiosInstance)
}
