/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > addTextTranslationCandidate
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
/**
 * Reach out to API to get data for the authenticated user.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {*}
 */
export default function (context, payload = {}) {
  const method = 'PUT'
  const url = API.AUTH_USER
  let options = {
    data: { recruitment_candidate_id: payload.id }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
