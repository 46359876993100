import LS from '@/modules/constants/localStorage'
import isNil from 'lodash/isNil'
import includes from 'lodash/includes'
import getAuthCollection from '@/modules/auth/store/shared/getAuthCollection'
import setAuthCollection from '@/modules/auth/store/shared/setAuthCollection'
import appStorage from '@/modules/helpers/appStorage'

/**
 *
 * @param {object} context
 * @param {object} payload
 * @param {object} payload.authenticated
 * @param {boolean} [payload.isSetToken=false]
 * @param {boolean} [payload.isSetUser=false]
 */
function addToAuthCollection(context, payload = {}) {
  if (isNil(payload.authenticated)) throw new Error('Value for parameter (authenticated) is required.')

  payload.isSetToken = payload.isSetToken || false
  payload.isSetUser = payload.isSetUser || false

  // Include the User's ID into the AUTH COLLECTION localStorage.

  let collection = getAuthCollection()

  const userId = parseInt(payload.authenticated.user?.id)

  if (userId) {
    if (!includes(collection, userId)) collection.push(userId)

    setAuthCollection(collection)

    // Include the token and user data to a separate localStorage prefixed by user id.
    appStorage.set(LS.AUTH_COLLECTION_USER + userId, payload.authenticated)

    context.commit('auth/setAuthenticatedUsers', collection, { root: true })
    /* eslint-disable */
    if (payload.isSetToken) {
      context.commit('auth/setToken', authenticated.token)
      context.commit('auth/setRefreshToken', authenticated?.refresh_token)
    }
    if (payload.isSetUser) {
      context.commit('auth/setUser', authenticated.user)
    }
  }
  /* eslint-enable */
}

export default addToAuthCollection
