/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > logFileUploadError
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  const url = API.TEXT_TRANSLATION + '/file-upload-error'
  const method = 'post'

  const data = {
    ...payload
  }

  const options = {
    enable_error_notif: true,
    is_data_raw: true,
    data
  }

  return reach(this, context, url, method, options, axiosInstance)
}
