/*
|--------------------------------------------------------------------------
| Custom > Helpers > Vuex Mutations
|--------------------------------------------------------------------------
|
| This file contains helpers that are most used inside Vuex Mutations files.
*/

import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import forEach from 'lodash/forEach'

/**
 * Helper method for setting list states.
 *
 * @param {Object} state - The state property of the current Vuex module.
 * @param {Object} payload - Value(s) to be assigned.
 * @param {Object} refs - Key references to where the list and pagination
 *                       should be assigned.
 * @param {String} refs.data - Key references to the state container of the list data.
 * @param {String} refs.pagination - Key references to the state container of the list pagination.
 * @returns {void}
 */
export function listSetter(state, payload, refs) {
  // Set the list data.
  let data = payload.data.data
  let collection = data[Object.keys(data)[0]]
  set(state, refs.data, collection)

  // Set the list pagination.
  if (!isNil(payload.data.meta) && !isNil(payload.data.meta.pagination) && !isEmpty(payload.data.meta.pagination)) {
    set(state, refs.pagination, payload.data.meta.pagination)
  }
}

/**
 * Helper method for setting list data to be used for option/selection purpose.
 *
 * @param {object} state - The state property of the current Vuex module.
 * @param {object} payload - Value(s) to be assigned.
 * @param {string} ref  - Key references to where the list and pagination
 *                       should be assigned.
 * @param {object} [identifiers={}] - Property value identifiers for the Key and Value pair.
 * @param {string} [identifiers.key='id'] - Identifier reference for Key.
 * @param {string} [identifiers.value='name'] - Identifier reference for Value.
 * @param {function} [identifiers.key_mutator=null] - Mutator for Key, with Key as the method param.
 * @param {function} [identifiers.value_mutator=null] - Mutator for Value, with Value as the method param.
 * @param {object} [options={}] - Option values to be used inside the method.
 * @param {string} [options.collection_ref] - Collection reference value if you don't want to use the default one.
 * @returns {void}
 */
export function listAsOptionsSetter(state, payload, ref, identifiers = {}, options = {}) {
  // Set default values for identifiers.
  identifiers.key = identifiers.key || 'id'
  identifiers.value = identifiers.value || 'name'
  identifiers.key_mutator = identifiers.key_mutator || null
  identifiers.value_mutator = identifiers.value_mutator || null

  // Prepare the collection.
  let collection = []

  if (options.collection_ref === undefined) {
    const data = payload.data.data
    collection = data[Object.keys(data)[0]]
  } else if (options.collection_ref === '') {
    collection = payload
  } else {
    collection = get(payload, options.collection_ref)
  }

  let result = {} // Prepare result container.

  forEach(collection, (item) => {
    let key = !isNull(identifiers.key_mutator) ? identifiers.key_mutator(item[identifiers.key]) : item[identifiers.key]

    let value = !isNull(identifiers.value_mutator)
      ? identifiers.value_mutator(item[identifiers.value])
      : item[identifiers.value]

    result[key] = value
  })

  set(state, ref, result)
}

/**
 * Method to set the state.list.data and state.list.pagination values.
 *
 * @param {Object} state - The state property of the current Vuex module.
 * @param {Object} payload - Value(s) to be assigned.
 * @returns {void}
 */
export function setList(state, payload) {
  listSetter(state, payload, {
    data: 'list.data',
    pagination: 'list.pagination'
  })
}

/**
 * Method to set the list formatted specially for option/selection usage.
 *
 * @param {Object} state - The state property of the current Vuex module.
 * @param {Object} payload - Value(s) to be assigned.
 * @returns {void}
 */
export function setListAsOptions(state, payload) {
  listAsOptionsSetter(state, payload, {
    data: 'listAsOptions'
  })
}
