/*
|--------------------------------------------------------------------------
| Store > Expense > Mutations
|--------------------------------------------------------------------------
|
| This file is where the Module's mutation methods are registered.
|
| Feel free to edit this file if you have mutation methods you want
| to include.
|
*/

// import {listAsOptionsSetter, setList} from "@/modules/helpers/vuexMutations";
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export default {
  /**
   *
   * @param state
   * @param payload
   */
  setExpenseTypes(state, payload) {
    state.expenseTypes = payload.data.data.expense_types
    // listAsOptionsSetter(state, payload, 'expenseTypes');
  },

  setExpenseList(state, payload) {
    state.list = payload.data.data.expenses
  },

  resetForm(state) {
    state.form = cloneDeep(state.formFresh)
  },

  setForm(state, payload) {
    if (!isEmpty(payload)) {
      let data = payload
      if (!isNil(data.attachments) && data.attachments.length > 0) {
        for (let i = 0; i < data.attachments.length; i++) {
          data.attachments[i].name = data.attachments[i].path
        }
      }
      state.form = cloneDeep(data)
    }
  },

  setLoading(state, payload) {
    state.loading = payload
  }
}
