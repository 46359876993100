/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerAndTranslatorOtherBooking
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to load customer normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const { translator_id, customer_id } = payload

  // Make error loggers to make sure required values are filled in.
  if (isNil(translator_id)) {
    console.error('Translator ID should exist!')
  } else if (isNil(customer_id)) {
    console.error('Customer ID should exist!')
  }

  context.commit('setCustomerAndTranslatorOtherBookingListLoading', true)

  const url = `${API.BOOKINGS}/customer/jobs-by-translator`
  const method = 'GET'

  let options = {
    query_params: {
      //'filter[status_id]': '1,2,3,11',
      translator_id,
      customer_id
    },

    on_finally(ctx) {
      ctx.commit('setCustomerAndTranslatorOtherBookingListLoading', false)
    }
  }

  // const filterQueries = bookingsListFilterOptions(payload);

  // assignIn(options.query_params, filterQueries);

  return loadAll(this, context, url, method, 'setCustomerAndTranslatorOtherBookingList', options, axiosInstance)
}
