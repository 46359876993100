<template>
  <div class="app-layout" :class="layoutClass">
    <div :class="getBem(layoutClass, 'wrapper')">
      <main>
        <fullscreen-loader
          :visible="$store.getters['fullscreenLoader/visible']"
          :message="$store.getters['fullscreenLoader/message']"
        />
        <slot />
      </main>
    </div>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import FullscreenLoader from '@/modules/auth/components/displays/FullscreenLoader'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
  components: {
    FullscreenLoader
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'blur-layout'
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * @returns {array}
     */
    layoutClass() {
      const viewportClass = this.isOnMobileViewport ? 'on-mobile-viewport' : 'on-desktop-viewport'
      const authClass = this.isUserAuthenticated ? 'user-authenticated' : 'user-not-authenticated'
      const showMobileNavClass = this.isShowMobileNav ? 'mobile-nav-shown' : 'mobile-nav-hidden'

      if (document != null) document.body.className = [viewportClass, authClass, showMobileNavClass].join(' ')

      return [this.getBem(this.blockClass)]
    }
  }
}
</script>

<!--suppress CssUnknownTarget -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
.v-modal {
  z-index: 2000 !important;
}

.blur-layout {
  margin: 0 auto;
  // background-image: url('@/assets/images/home-blurred.jpg');
  background-image: url('@/assets/images/rebrand/DT-rebrand-blurred.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;

  &__wrapper {
    background-color: #fff;
  }

  .app-page__content {
    padding: 20px 50px;
  }
}
</style>
