import { BOOKING_ISSUES as API_BOOKING_ISSUES } from '@/modules/constants/api'
import { apiCall } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

function addBookingIssueCommentPublic(context, payload = {}) {
  const { booking_issue_id, signature } = payload

  // Make error loggers to make sure required values are filled in.
  if (isNil(booking_issue_id)) {
    console.error('Booking Issue ID should exist!')
    return
  } else if (isNil(signature)) {
    console.error('Signature should exist!')
    return
  }

  let o = {
    url: `${API_BOOKING_ISSUES}/${booking_issue_id}/signed-add-comment`,
    method: 'POST',
    query_params: {
      signature
    },
    data: !isNil(payload.data) ? payload.data : {}
  }

  return apiCall(o, axiosInstance)
}

export default addBookingIssueCommentPublic
