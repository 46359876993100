/*
|--------------------------------------------------------------------------
| Custom > Helpers > Vuex Common
|--------------------------------------------------------------------------
|
| This file contains helpers that are most used inside Vuex files.
*/

import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import LS from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'
import includes from 'lodash/includes'
import get from 'lodash/get'

/**
 * Will combine the value for the extended and the source object. If the two
 * Objects have the same key with different value, the key:value in 'source'
 * will override/replace what's in the extended's key:value pair.
 *
 * @param  {object} extended
 *   : the object to be extended, and which values will be overridden.
 *   : this will be the Parent
 *
 * @param  {object} source
 *   : the object which values will be appended or force replace the extended
 *   : this will be the Child
 *
 * @return {object}
 *
 */
export function extendObj(extended, source) {
  let container = cloneDeep(extended)

  let customizer = (objValue, srcValue) => {
    if (isArray(srcValue)) {
      return srcValue
    }
  }

  return mergeWith(container, source, customizer)
}

/**
 * Helper method to convert an object to querystring
 *
 * @param  {object} obj
 *   : the object to be converted
 *
 * @return {string}
 */
export function querystringify(obj) {
  let has = Object.prototype.hasOwnProperty
  let pairs = []

  for (let key in obj) {
    if (has.call(obj, key)) {
      if (isArray(obj[key])) {
        for (let v in obj[key]) {
          // noinspection JSUnfilteredForInLoop
          pairs.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(obj[key][v]))
        }
      } else {
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
      }
    }
  }
  return pairs.join('&')
}

/**
 * This helper method checks if the data given is empty,
 * and then populates it.
 *
 * @param {Object} va - The Vue App instance also known as "this".
 * @param {String} action - The action that will be called.
 * @param {String} data - The data that will be evaluated if empty or not.
 * @param {Object} options - The set of option values that will be used all through out the method.
 * @param {Object} [options.force=false] - A switch value wether to force the population
 *                                         regardless if data is empty or not.
 * @param {String} [options.label="The list"] - String reference for showing information on console log.
 * @param {Object} [options.payload={}] - Value that will be sent when the vuex action is dispatched.
 * @param {Function} [options.before_cb=null] - Method to call before reaching out to API.
 * @param {Function} [options.success_cb=null] - Method to call after successfull API request.
 * @param {Function} [options.error_cb=null] - Method to call after failure of API request.
 * @param {Function} [options.finally_cb=null] - Method to call after the API request regardless of status.
 * @returns {void}
 */
export function populate(va, action, data = '', options = {}) {
  // Set default values for the option nodes.
  options.force = options.force || false
  options.label = options.label || 'The list'
  options.payload = options.payload || {}
  options.before_cb = options.before_cb || null
  options.success_cb = options.success_cb || null
  options.error_cb = options.error_cb || null
  options.finally_cb = options.finally_cb || null

  // If data is empty if force is enabled, run this block.
  if (isEmpty(va[data]) || options.force) {
    console.log(`${options.label}  is currently being populated...`)

    if (!isNull(options.before_cb)) options.before_cb(va)

    if (typeof va[action] === 'function') {
      const result = va[action](options.payload)
      if (result !== undefined && result instanceof Promise) {
        result
          .then((r) => {
            console.log(`${options.label} is successfully filled with data.`)
            if (!isNull(options.success_cb)) options.success_cb(r, va)
            if (!isNull(options.finally_cb)) options.finally_cb(va)
          })
          .catch((e) => {
            if (!isNull(options.error_cb)) options.error_cb(e, va)
            if (!isNull(options.finally_cb)) options.finally_cb(va)
          })
      } else {
        console.warn(`The action ${action} did not return a Promise.`)
      }
    } else {
      console.warn(`The action ${action} is not defined or is not a function.`)
    }
  } else {
    console.log(`${options.label} is already filled with data. No need to refill.`)
  }
}

/**
 * Helper file for getting the token from localStorage and formatting it
 * according to its varierty.
 *
 * @returns {string|boolean} - Returns string the storage is not empty, otherwise returns false.
 */
export function getStoredToken() {
  let token = appStorage.get(LS.TOKEN)

  if (!isNil(token) && !isEmpty(token)) {
    if (isString(token)) {
      return token
    } else if (isObject(token)) {
      return token.access_token
    } else {
      throw new Error('Unknown token format!')
    }
  } else {
    console.warn('Token storage is currently empty.')
    return false
  }
}

/**
 * @param {Object} obj
 * @param {string} path
 * @param {string[]} [options]
 * @return {boolean}
 */
export function isValidObjProp(obj, path, options = ['null']) {
  const props = path.split('.')
  let current = ''
  let r = true

  for (let i = 0; i < props.length; i++) {
    current = i === 0 ? (current = props[i]) : (current += '.' + props[i])
    const v = get(obj, current)

    if (includes(options, 'null') && isNil(v)) {
      r = false
      break
    } else if (includes(options, 'empty') && isEmpty(v)) {
      r = false
      break
    }
  }

  return r
}
