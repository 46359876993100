/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > acceptBookingPublic
|--------------------------------------------------------------------------
|
*/

import { PUBLIC_ACCEPT_BOOKING } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to accept booking via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  // Make error loggers to make sure required values are filled in.
  if (isNil(payload.booking_id)) console.error('Booking ID should exist!')
  if (isNil(payload.signature)) console.error('Signature should exist!')
  if (isNil(payload.translator_id)) console.error('Translator ID should exist!')
  if (isNil(payload.accepted_via)) console.error('Accepted Via value should exist!')

  let options = {
    query_params: {
      accepted_via: payload.accepted_via,
      booking_id: payload.booking_id,
      translator_id: payload.translator_id,
      signature: payload.signature
    }
  }

  // Define axios values.
  const url = PUBLIC_ACCEPT_BOOKING
  const method = 'POST'

  return reach(this, context, url, method, options, axiosInstance)
}
