import appStorage from '@/modules/helpers/appStorage'
import { CUSTOMER_INTEGRATION_USER } from '@/modules/constants/localStorage'
import { CUSTOMER_INTEGRATION_API } from '@/modules/constants/api'

import apiCall from '@/modules/helpers/apiCall'

export default function (context, payload = {}) {
  const existingCSUser = appStorage.get(CUSTOMER_INTEGRATION_USER)

  const url = `${CUSTOMER_INTEGRATION_API}/api/user-webhooks`

  return apiCall({
    isShowLogs: false,
    headers: {
      Authorization: `Bearer ${existingCSUser.cs_token}`
    },
    url,
    method: 'GET',
    params: payload
  })
}
