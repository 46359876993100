import { BOOKING_TIME_SLOT } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  const url = `${BOOKING_TIME_SLOT}/${payload.id}/accept`

  const method = 'POST'
  let options = {
    enable_error_notif: false,
    data: payload
  }

  return reach(this, context, url, method, options, axiosInstance)
}
