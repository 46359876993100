/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'

/**
 * Action to load translator normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  context.commit('setTranslatorHistoricBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    // 'customer',
    // 'translator_levels',
    // 'assigned_translator',
    // 'notification_types',
    'specific_translators',
    'video_booking'
    // 'text_translation.files'
  ].join(',')
  const topControls = await store.getters['bookingList/topControls']
  let statuses = topControls?.booking_statuses?.toString()

  const append = ['rejected_files_count']
  let options = {
    query_params: {
      include,
      // sort: 'due',
      'filter[status_id]': statuses,
      'filter[type]': 'text_translation',
      append
    },

    on_finally(ctx) {
      ctx.commit('setTranslatorHistoricBookingListLoading', false)
      store.commit('booking/setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }

  const filterQueries = bookingsListFilterOptions(payload, {
    exclude: ['customer_id']
  })

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, 'setTranslatorHistoricBookingList', options, axiosInstance)
}
