/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > downloadFile
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
/**
 * Action to fetch the booking details via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  let data = {
    params: `path=${payload.path}&display_name=${payload.display_name}`,
    filename: payload.display_name
  }

  let url = `${API.STORAGE}/get?${data.params}`

  return axiosInstance.get(url).then((r) => {
    window.open(r.data.data.url, '_blank')
  })
}
