/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll, bookingsListFilterOptions } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'

/**
 * Action to load customer normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  //exclude from normal booking list

  context.commit('setCustomerHistoricBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    // 'customer.department',
    'translator_levels',
    'assigned_translator',
    // 'notification_types',
    'specific_translators',
    'video_booking',
    'text_translation.files',
    'deviation_report'
    // 'towns',
    // 'feedback'
  ].join(',')
  const topControls = await store.getters['bookingList/topControls']
  let statuses = topControls?.booking_statuses?.toString()
  let excludeOptions = {}

  if (topControls?.show_department_bookings) {
    excludeOptions = {
      exclude: ['customer_id']
    }
  }

  let options = {
    query_params: {
      include,
      // sort: 'due',
      'filter[status_id]': statuses,
      'filter[type]': 'text_translation'
    },

    on_finally(ctx) {
      ctx.commit('setCustomerHistoricBookingListLoading', false)
      store.commit('booking/setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }

  const filterQueries = bookingsListFilterOptions(payload, excludeOptions)

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, 'setCustomerHistoricBookingList', options, axiosInstance)
}
