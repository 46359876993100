import i18n from '@/locales/i18nClient.js'
import store from '@/store'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { showBookingSmallErrorNotif, showSmallSuccessNotif } from './notifications'
import EventBus from './eventBus'
import * as EVENT from '../constants/event'
import isNil from 'lodash/isNil'
// import filter from 'lodash/filter'
const { t: $t } = i18n.global

export function getBookingTableColumnsList(bookingType, isHistoricPage = false) {
  let bookingTableColumns = {
    date: $t('date'),
    day: $t('day'),
    booking_id: $t('booking_id'),
    from_language_id: '',
    to_language_id: '',
    language: '',
    duration: $t('duration'),
    booking_type_1: $t('booking_type_1'),
    booking_status: $t('booking_status'),
    reference: $t('reference'),
    booker: $t('booker'),
    staff: $t('staff'),
    // translator_phone: $t('translator_phone'),
    gender: $t('gender'),
    town: $t('town'),
    translator_level: $t('translator_level'),
    assigned_translator: $t('assigned_translator'),
    translator_mobile: $t('translator_mobile')
  }

  if (isHistoricPage) {
    bookingTableColumns['deviation_report'] = $t('deviation_report')
  }

  if (bookingType === 'normal') {
    bookingTableColumns.language = $t('language')
  }

  if (bookingType === 'textTranslation') {
    bookingTableColumns.from_language_id = $t('text_translation_from_language_id')
    bookingTableColumns.to_language_id = $t('text_translation_to_language_id')
  }
  return omitBy(bookingTableColumns, isEmpty)
}

export function getSelectedBookingTableColumnsList($key) {
  let defaultCols = ['date', 'day', 'booking_id', 'booking_type_1', 'booking_status']
  if ($key === 'normal') {
    const isRefColumnSelected = localStorage.getItem('is_reference_column_selected')
    if (isRefColumnSelected === undefined || isRefColumnSelected == null) {
      let storedColumns = JSON.parse(localStorage.getItem($key)) || []
      if (!storedColumns.length) {
        storedColumns = [...defaultCols, 'reference', 'language']
      }
      if (!storedColumns.includes('reference')) {
        storedColumns.push('reference')
      }
      localStorage.setItem($key, JSON.stringify(storedColumns))
    }
  }

  if (!JSON.parse(localStorage.getItem($key))?.length) {
    if ($key === 'normal') {
      defaultCols.push('language')
    }
    if ($key === 'textTranslation') {
      defaultCols.push('from_language_id')
      defaultCols.push('to_language_id')
    }
    localStorage.setItem($key, JSON.stringify(defaultCols))
  }

  return JSON.parse(localStorage.getItem($key))
}

export function isColumnSelected($key, column) {
  if (store.getters['auth/isUserCustomer']) {
    return getSelectedBookingTableColumnsList($key).includes(column)
  }

  return true
}

export function acceptBookingCommon(payload) {
  return new Promise((resolve, reject) => {
    // if (!isEmpty(payload.time_slots)) {
    //   EventBus.emit('open-accept-booking-suggested-times-modal', payload.booking_id)
    //   reject()
    //   return
    // }
    store
      .dispatch('booking/acceptBooking', payload)
      .then(() => {
        showSmallSuccessNotif($t('booking_accept_success'))
        EventBus.emit(EVENT.BOOKING_ACCEPTED)
        EventBus.emit(EVENT.BOOKING_POTENTIAL)
        resolve()
      })
      .catch((e) => {
        let errTitle = `${$t('error')} - ${$t('booking_accept_fail')}`
        showBookingSmallErrorNotif(e, errTitle, payload.booking_id)
        reject(e)
      })
  })
}

export function isBookingHasTimeSlots(booking) {
  let filtered = booking?.booking_time_slots
    ?.filter((i) => i.is_valid)
    ?.filter((timeSlot) => {
      return !timeSlot.translator?.translator_unavailable_times.some(
        (unavailableTime) =>
          new Date(`${timeSlot.date} ${timeSlot.start_time}`) >= new Date(unavailableTime.unavailable_from) &&
          new Date(`${timeSlot.date} ${timeSlot.start_time}`) <= new Date(unavailableTime.unavailable_until)
      )
    })
  return filtered?.length
}

export function bookingHasSuggestedTimeSlots(booking, translator_id) {
  return booking?.booking_time_slots
    ?.filter((i) => isNil(i.translator_id) || i.translator_id === translator_id)
    ?.filter((i) => i.is_valid)?.length
}

export function suggestedTimeSlots(booking, translator_id) {
  return booking?.booking_time_slots?.filter((i) => i.translator_id === translator_id)
}

export function bookingTimeSlotsFromCustomer(booking) {
  return booking?.booking_time_slots?.filter((i) => i.translator_id === null)?.filter((i) => i.is_valid)?.length
}

export default {
  isColumnSelected,
  getBookingTableColumnsList,
  getSelectedBookingTableColumnsList
}
