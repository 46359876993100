/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadUserVideoSettings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @returns {*}
 */
export default function (context, userID) {
  const method = 'GET'
  let url
  if (userID) {
    url = `${API.CUSTOMERS}/${userID}/video-settings`
  } else {
    url = `${API.USERS}/video-settings`
  }
  let options = {
    on_success(r, ctx) {
      ctx.commit('setVideoSettings', r.data.data.settings)
      context.commit('booking/setVideoOptions', r.data.data.settings.video_platform_options, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
