/*
|--------------------------------------------------------------------------
| Store > Booking > > Text Translation State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/

import cloneDeep from 'lodash/cloneDeep'
import { freshForm, initialForm } from './shared'

let formFresh = {
  id: '',
  description: '',
  type: '',
  address: '',
  unavailable_from: '',
  unavailable_till: ''
}

export default () => {
  return {
    form: cloneDeep(formFresh),
    loading: false,
    submitError: false,
    item: {},
    list: {
      data: [],
      pagination: {},
      loading: true
    },

    multiSchedule: [initialForm()],

    scheduleForm: {
      translatorSchedule: freshForm(),
      start_date: '',
      end_date: ''
    },
    scheduleFormList: {
      data: [],
      pagination: {},
      loading: false
    }
  }
}
