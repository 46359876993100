<template>
  <div :class="baseClass">
    <el-dropdown
      :popper-class="getBem(baseClass, 'menu-wrapper')"
      trigger="click"
      :tabindex="0"
      @visible-change="isDropdownVisible"
    >
      <span class="el-dropdown-link">
        <el-icon :class="iconClass"><BellFilled /></el-icon>
        <em v-if="getTotalUnreadNotificationsCount + getTotalUnreadInboxCount > 0" class="counter">{{
          getTotalUnreadNotificationsCompact
        }}</em>
      </span>
      <template #dropdown>
        <el-dropdown-menu slot="dropdown" :class="getBem(baseClass, 'menu')" @keydown.prevent.native="offset + 1">
          <header class="menu-header">
            <h4 class="title" tabindex="0">
              <span class="label">{{ $t('notifications_label') }}</span>
              <el-tooltip placement="top" popper-class="notification-inbox" persistent>
                <template #content>
                  <div slot="content">
                    <ul>
                      <li>
                        {{ $t('unread_notifications_label') }}:
                        <span>{{ getTotalUnreadNotificationsCount }}</span>
                      </li>
                      <li>
                        {{ $t('unread_messages') }}:
                        <span>{{ getTotalUnreadInboxCount }}</span>
                      </li>
                      <li v-if="getTotalUnreadImportantCount > 0" class="important">
                        {{ $t('unread_important_notifications') }}:
                        <span>{{ getTotalUnreadImportantCount }}</span>
                      </li>
                    </ul>
                  </div>
                </template>
                <span class="counter">{{ getTotalUnreadNotificationsCount + getTotalUnreadInboxCount }}</span>
              </el-tooltip>
            </h4>
            <div class="toggle" tabindex="0" @keydown.space="showOnlyUnread = !showOnlyUnread">
              <span class="label">{{ $t('show_only_unread') }}</span>
              <el-switch v-model="showOnlyUnread" />
            </div>
          </header>
          <div class="status-bar">
            <span class="status">
              <template v-if="showOnlyUnread">{{ $t('unread_notifications_label') }}</template>
              <template v-else>{{ $t('all_notifications_label') }}</template>
            </span>
            <a href="#" class="mark-as-read" tabindex="0" @click.prevent="markAllAsReadRemote">{{
              $t('mark_all_as_read')
            }}</a>
          </div>

          <!-- infinite scroll area -->
          <perfect-scrollbar class="scroll-area" :options="settings" @ps-scroll-y="handleInfiniteScroll">
            <el-dropdown-item
              v-for="(notification, $index) in list"
              :key="$index"
              :class="{
                important: isImportantNotification(notification.data.notification_type, notification.read_at)
              }"
              :icon="notificationIcons[notification.data.icon]"
              :tabindex="0"
              @click.native="handleNotificationClick(notification)"
            >
              <div class="content">
                <h5 class="title">
                  {{ $t(notification.data.title, notification.data) }}
                  <span v-if="!notification.read_at" class="unread-status" />
                </h5>
                <div class="text">
                  {{ notificationMessage(notification) }}
                </div>
                <div class="timestamp">
                  <span class="day">{{ humanDateFormatTimeAgo(notification.created_at) }}</span>
                  <span class="time">{{ humanTimeFormat(notification.created_at) }}</span>
                </div>
              </div>
            </el-dropdown-item>
            <p v-if="getNotificationsEndsStatus" class="end-of-line">
              {{ $t('no_notifications') }}
            </p>
          </perfect-scrollbar>

          <p v-if="list.length === 0" class="no-notifications">
            {{ $t('no_notifications') }}
          </p>
          <p v-if="getLoadingStatus" class="loading-status">{{ $t('loading') }}...</p>

          <p v-if="newNotifStatus" class="new-notifications-status" @click="goToLatesNotif">
            {{ $t('new_notifications') }} <span class="icon">&#8593;</span>
          </p>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import EVENT from '@/modules/constants/event'
import USER from '@/modules/constants/userType'
import EventBus from '@/modules/helpers/eventBus'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import debounce from 'lodash/debounce'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'

import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import appStorage from '@/modules/helpers/appStorage'
import LS from '@/modules/constants/localStorage'
import { formatDateTime } from '@/modules/helpers/common'
import {
  CircleClose,
  Star,
  Timer,
  SuccessFilled,
  StarFilled,
  List,
  Search,
  Message,
  RemoveFilled,
  BellFilled,
  Failed
} from '@element-plus/icons-vue'

export default {
  name: 'EmailNotification',

  components: {
    PerfectScrollbar
  },

  props: {
    identifier: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      baseClass: 'email-notification',
      isOpen: false,
      nextPage: 1,
      list: [],
      offset: 0,
      isWebSocket: false,
      activeWebSocketEvent: '',
      isMarkingAsRead: false,
      isReadWebSocketMessages: false,
      isVisibile: false,
      scrollDeep: false,
      showOnlyUnread: false,
      isReplacingAll: false,
      settings: {
        minScrollbarLength: 50,
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
      notificationIcons: {
        'el-icon-circle-close': CircleClose,
        'el-icon-star-off': Star,
        'el-icon-timer': Timer,
        'el-icon-success': SuccessFilled,
        'el-icon-star-on': StarFilled,
        'el-icon-s-order': List,
        'el-icon-search': Search,
        'el-icon-message': Message,
        'el-icon-remove': RemoveFilled,
        'el-icon-message-solid': BellFilled,
        'el-icon-s-release': Failed
      }
    }
  },
  computed: {
    ...mapGetters('email', [
      'getLoadingStatus',
      'getNotifications',
      'getTotalUnreadInboxCount',
      'getTotalUnreadNotificationsCount',
      'getTotalUnreadImportantCount',
      'getNotificationsEndsStatus'
    ]),

    ...mapGetters('booking', ['languageOpts']),
    ...mapGetters({ isTranslator: 'auth/isUserTranslator' }),

    /**
     * @returns {string[]}
     */
    iconClass() {
      let color = 'el-icon-bell'

      if (this.priority === 1) {
        color += 'red'
      } else if (this.priority === 2) {
        color += 'orange'
      } else if (this.priority === 3) {
        color += 'purple'
      }

      return [this.baseClass + '-bell', color]
    },

    newNotifStatus: function () {
      return this.offset > 0 && this.scrollDeep && !this.isReadWebSocketMessages
    },

    /**
     * @returns {boolean}
     */
    isOnMobileViewport() {
      return this.$store.getters['auth/isOnMobileViewport']
    },

    getTotalUnreadNotificationsCompact() {
      const originalCount = this.getTotalUnreadNotificationsCount + this.getTotalUnreadInboxCount
      return originalCount > 99 ? '99+' : originalCount
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > watch
  |--------------------------------------------------------------------------
  */
  watch: {
    getNotifications(newNotifications) {
      if (newNotifications && newNotifications.length > 0) {
        console.log('getNotifications is changed.........................')
        console.log(`Websocket: ${this.activeWebSocketEvent} - `, this.isWebSocket)

        if (this.isWebSocket) {
          // make sure no duplicate notification gets added
          const isDuplicate = this.list.some((el) => el.id === newNotifications[0].id)
          // console.log(`NOTIFCATION ID? ${newNotifications[0].id}`)
          // console.log(`NOTIFCATION? ${newNotifications[0]}`)
          // console.log(`DUPLICATE? ${isDuplicate}`)

          if (newNotifications.length > 0 && !isDuplicate) {
            console.log('Populating Real-Time Notificaiton...')
            console.log('Websocket Notification: ', newNotifications)

            this.offset += 1 // increase offset by 1 (to make sure pagination is accurate)
            this.list.unshift(...newNotifications) // preppend live-reloaded notificaitons at beginning of list
            this.isReadWebSocketMessages = false
          } else {
            this.isReadWebSocketMessages = !this.isReadWebSocketMessages ? false : true
          }
          this.isWebSocket = false // make sure to disable isWebSocket request status isMarkingAsRead
          console.log('Real-Time notifications loaded...')
        } else if (this.isMarkingAsRead) {
          console.log('Marking All as Read...')
          this.list = newNotifications // replace whole list with the result
          this.isMarkingAsRead = false // make sure to disable isMarkingAsRead request status isMarkingAsRead
        } else if (this.isReplacingAll) {
          console.log('Replacing all notifications...')
          this.list = newNotifications // replace whole list with the result
          this.nextPage += 1
          this.isReplacingAll = false // make sure to disable isReplacingAll request status isReplacingAll
        } else {
          console.log('Populating Next Batch of Notificaitons...')
          this.nextPage += 1
          this.list.push(...newNotifications) // append the newly loaded notifications to the end of the list
        }
      } else {
        this.list = newNotifications // replace whole list with the result
        this.isReplacingAll = false // make sure to disable isReplacingAll request status isReplacingAll
      }
    },
    isVisibile(visibility) {
      const dropdown = document.querySelector('ul.email-notification__menu')
      const initialFocus = dropdown.querySelector('.menu-header .title')

      if (visibility) {
        // Initiate focus on notifications dropdown
        setTimeout(() => {
          dropdown.addEventListener('keydown', this.handleKeyboardNavigation)
          initialFocus.focus() // focus the first element in dropdown
        }, 500)
      } else {
        // remove event listner from notifications dropdown
        dropdown.removeEventListener('keydown', this.handleKeyboardNavigation)
      }
    },
    showOnlyUnread() {
      setTimeout(() => {
        document.querySelector('.scroll-area').scrollTop = 0 // go to the top
        this.isReplacingAll = true
        this.nextPage = 1 // reset the pagination to 1
        this.offset = 0 // reset the offset to 0
        this.loadNotifications()
      }, 500)
    }
  },

  mounted() {
    // Load Notifications (on initial page load)
    this.loadNotifications()

    // Reset all notifications (on new user authentication)
    EventBus.on('user.authenticated', () => {
      console.log('A USER JUST AUTHETICATED................')
      this.resetNotifications() // reset notificaitons local state
      this.loadNotifications() // load notifications with the new token
    })

    // Load Notifications (real-time on websocket event)
    const events = Object.values(EVENT)

    forEach(events, (event, index) => {
      const blacklist = ['booking.started', 'booking.ended'] // add events for which we don't want to make API request
      if (blacklist.includes(event)) return // dont't proceed if event is blacklisted

      const delayedNotifications = ['booking.accepted', 'booking-feedback.reminder', 'booking-report-session.reminder'] // add events which are often triggered simultaneously

      EventBus.on(event, (payload) => {
        let interval
        if (payload && payload.action) {
          interval = delayedNotifications.includes(payload.action) ? 2000 : 0 // in milliseconds (add this interval between each simultaneouse ws event handler)
        } else {
          interval = 0
        }

        if (payload && payload.action) {
          setTimeout(() => {
            console.log('-------------- WS EVENT START --------------')
            console.log(`Event Time: ${new Date().toLocaleString()}`)
            console.log(`Event Name: ${event}`)
            console.log('Event Payload: ', payload)
            this.isWebSocket = true
            this.activeWebSocketEvent = payload.action
            switch (payload.action) {
              case 'notification.read.single':
                this.markOneAsReadLocal(payload.notification.id, payload.notification.data.notification_type)
                break
              case 'notification.read.all.regular':
                this.markAllAsReadLocal('regular')
                break
              case 'notification.read.all.inbox':
                this.markAllAsReadLocal('inbox')
                break
              default:
                this.loadRealTimeNotification(payload.action) // request the last notification by TYPE (best way is to request notificaion by ID)
            }
            console.log('-------------- WS EVENT END --------------')
          }, interval * index)
        }
      })
    })
  },

  methods: {
    ...mapActions('email', ['fetchNotifications', 'markAllAsRead', 'markOneAsRead']),
    ...mapMutations('email', ['setUnreadNotificationsCount', 'setUnreadInboxCount']),

    formatDateTime,
    notificationMessage(notification) {
      const newBookingValues = notification.data?.new_booking
      let notificationDetails = {
        ...notification.data,
        booking_id: notification.data.booking_id,
        deadline: this.humanDateFormat(notification.data.deadline),
        deadline_text: this.translateDeadlineText(notification.data.deadline_text),
        time: notification.data.time,
        rating: notification.data.rating,
        inbox_title: notification.data.inbox_title,
        type: this.$t(`booking_type_${newBookingValues?.type}`),
        from_language: this.languageOpts[newBookingValues?.from_language_id],
        address: newBookingValues?.address,
        video_provider: newBookingValues?.video_provider ? this.$t(newBookingValues?.video_provider) : null,
        due: this.formatDateTime(newBookingValues?.due),
        due_date: this.formatDateTime(newBookingValues?.due_date, false),
        end_time: newBookingValues?.end_time
      }

      return this.$t(notification.data.message, notificationDetails)
    },

    translateDeadlineText(text) {
      if (!text) return
      const splitText = text.toString().split(' ')
      let endText = splitText.pop()
      return `${splitText[0]} ${this.$t(endText.toLowerCase())?.toLowerCase()}`
    },

    goToLatesNotif() {
      document.querySelector('.scroll-area').scrollTop = 0
      this.isReadWebSocketMessages = true
    },

    loadNotifications() {
      const user = this.$store.getters['auth/user'] // get current user object

      if (isNil(user) || isNil(appStorage.get(LS.TOKEN))) {
        return
      }
      // load next batch of notifications (usually for infinite-scroll)
      this.fetchNotifications({
        user,
        per_page: 7,
        page: this.nextPage,
        offset: this.offset, // offset the result by x number
        unread_only: this.showOnlyUnread
      })
    },

    loadRealTimeNotification(event) {
      if (!this.isWebSocket) return

      const user = this.$store.getters['auth/user'] // get current user object
      if (isNil(user) || isNil(appStorage.get(LS.TOKEN))) {
        return
      }
      // load only the latest real-time notification
      this.fetchNotifications({
        user,
        per_page: 1, // request only the latest notificaiton
        page: 1,
        offset: 0,
        type: event // WS event name
      })
    },

    isImportantNotification(notificationType, readStatus) {
      const user = this.$store.getters['auth/user'] // get current user object

      if (user.type == USER.CUSTOMER && !readStatus) {
        switch (notificationType) {
          case 'survey.created':
          case 'blog.created':
          case 'ticket.created':
          case 'booking-issue.events.reminder':
          case 'booking-issue.customer.report':
            return true
          default:
            return false
        }
      } else if (user.type == USER.TRANSLATOR && !readStatus) {
        switch (notificationType) {
          case 'booking.accepted.by.admin':
          case 'booking.cancelled':
          case 'booking.late_cancel':
          case 'survey.created':
          case 'ticket.created':
          case 'booking.not-pickup-warning':
          case 'booking-issue.events.reminder':
            return true
          default:
            return false
        }
      }
    },

    humanDateFormat(timestamp) {
      if (!timestamp) return
      return moment(timestamp).format('DD-MM-YYYY HH:mm')
    },

    humanDateFormatTimeAgo(timestamp) {
      let storedLocale = localStorage.getItem('app_language')
      storedLocale = !isNil(storedLocale) ? storedLocale : 'se'

      let date = moment(timestamp)
      date.locale(storedLocale)

      return date.calendar(null, {
        sameDay: `[${this.$t('today_text')}]`, // [] represent static text
        lastDay: `[${this.$t('yesterday_text')}]`,
        lastWeek: 'DD MMMM YYYY',
        sameElse: 'DD MMMM YYYY'
      })
    },

    humanTimeFormat(timestamp) {
      let time = moment(timestamp).format('HH:mm')
      return time
    },

    // Mark ALL (regular or inbox) notifications as read in remote state + request the updated notifications from remote
    async markAllAsReadRemote(type) {
      const user = this.$store.getters['auth/user']
      this.isMarkingAsRead = true
      await this.markAllAsRead({
        user,
        per_page: this.list.length,
        page: 1,
        type,
        unread_only: this.showOnlyUnread
      })
    },

    // Mark ALL (regular or inbox) notifications as read in local state ONLY (not touching the remote)
    async markAllAsReadLocal(type) {
      // mark each notification as read (local state)
      this.list.map((notification) => {
        const time = moment().format() // get current time
        if (type == 'inbox' && notification.data.notification_type == 'ticket.created') {
          notification.read_at = time
        } else if (type == 'regular' && notification.data.notification_type != 'ticket.created') {
          notification.read_at = time
        }
      })

      // update the relevant counter (local state)
      if (type == 'inbox') {
        this.setUnreadInboxCount(0)
      } else if (type == 'regular') {
        this.setUnreadNotificationsCount(0)
      }
    },

    // Mark SINGLE notification as read in remote state + update it in local state
    async markOneAsReadRemote(notification) {
      console.log(`Marking notification # ${notification.id} as read...`)

      const user = this.$store.getters['auth/user']
      const time = moment().format() // get current time
      let params = {
        user,
        notification,
        time
      }

      const updated = await this.markOneAsRead(params) // update notification time (remote)

      if (notification.read_at) return // don't proceed if notification is already marked as read

      // update the local counter
      if (updated) {
        const index = this.list.indexOf(notification) // get the notification local index
        this.list[index].read_at = time // mutate that specific object `read_at` property (locally)
        this.decreaseCounter(notification.data.notification_type) // decrease the relevant counter if the notification is not already marked as read
      } else {
        console.log('The notificaiton object not updated')
      }

      // remove notification if showing unread only
      if (updated && this.showOnlyUnread) {
        const index = this.list.indexOf(notification) // get the notification local index
        console.log('Remove notificaiton at index...', index)
        setTimeout(() => {
          this.list.splice(index, 1)
        }, 500)
      }
    },

    // Mark SINGLE notification as read in local state ONLY (not touching the remote)
    async markOneAsReadLocal(id, type) {
      // mark individual notification as read (local state)
      const time = moment().format() // get current time
      const notification = this.list.find((i) => i.id == id)

      // don't proceed if notification is already marked as read
      if (notification?.read_at) return

      const index = this.list.indexOf(notification) // get the notification local index
      if (index >= 0) {
        this.list[index].read_at = time // mutate that specific object `read_at` property (locally)
        console.log(`Marked SINGLE notification as read at index ${index}`)
      }

      // update the relevant counter (local state)
      this.decreaseCounter(type) // decrease the relevant counter if the notification is not already marked as read
    },

    // Helper function to decrease counter value
    decreaseCounter(type) {
      if (type == 'ticket.created') {
        const unreadInbox = parseInt(this.getTotalUnreadInboxCount) - 1
        if (unreadInbox >= 0) {
          this.setUnreadInboxCount(unreadInbox)
        }
      } else {
        const unreadNotifications = parseInt(this.getTotalUnreadNotificationsCount) - 1
        if (unreadNotifications >= 0) {
          this.setUnreadNotificationsCount(unreadNotifications)
        }
      }
    },

    // Reset local state of notifications and make it blank
    resetNotifications() {
      this.list = []
      this.nextPage = 1
      this.offset = 0
      this.showOnlyUnread = false
    },

    // Handle what happens when we click on a certain notification
    async handleNotificationClick(notification) {
      let response

      const bookingID = notification.data.booking_id
      const notificationType = notification.data.notification_type
      const bookingReportComment = notification.data.comment
      const bookingIssueID = notification.data.booking_issue_id
      const emailID = notification.data.email_id
      const surveyID = notification.data.survey_id

      switch (notificationType) {
        case 'booking.accepted':
        case 'booking.accepted.by.admin':
        case 'booking.expired':
        case 'booking.cancelled':
        case 'booking.late_cancel':
        case 'translator.update.session':
        case 'booking.started.reminder':
        case 'booking.ended':
        case 'booking.not-pickup-warning':
        case 'booking.penalty':
        case 'text_translation.translation_rejected':
        case 'text_translation.translator_resubmitted':
          response = await this.$store.dispatch(
            'booking/getBookingDetails',
            { id: bookingID },
            {
              root: true
            }
          )

          if (this.isTranslator) {
            EventBus.emit('notifications.booking.translator.details', response.data.data.booking)
            break
          }

          EventBus.emit('notifications.booking.details', response.data.data.booking) // emitting event to an event bus (usable throughout app)
          // this.$emit('notifications.booking.details', response.data.data.booking) // emitting event to immediate parent
          break

        case 'booking-feedback.reminder':
          response = await this.$store.dispatch(
            'booking/getBookingDetails',
            { id: bookingID },
            {
              root: true
            }
          )
          EventBus.emit('notifications.feedback.modal', response.data.data.booking)
          break

        case 'booking-report-session.reminder':
          response = await this.$store.dispatch(
            'booking/getBookingDetails',
            { id: bookingID },
            {
              root: true
            }
          )
          EventBus.emit('notifications.session_time.modal', response.data.data.booking)
          break

        case 'booking-issue.customer.report':
          response = await this.$store.dispatch('booking/getBookingIssueDetails', bookingIssueID, {
            root: true
          })
          EventBus.emit('notifications.customer.report', {
            booking_issue: response.data.data.booking_issue,
            booking_report_comment: bookingReportComment
          })
          break

        case 'booking-issue.events.reminder':
          response = await this.$store.dispatch('auth/getUserFeedbacks', null, {
            root: true
          })
          EventBus.emit('notifications.feedback.list', {
            issue_id: bookingIssueID,
            feedbacks: response.data.data.booking_issue_comments
          })
          break

        case 'ticket.created':
          this.$router.push({
            name: 'emails.details',
            params: { id: emailID }
          })
          break

        case 'survey.created':
          response = await this.$store.dispatch('auth/getUserSurveys', null, {
            root: true
          })
          if (response.data?.data?.user?.survey?.length > 1) {
            EventBus.emit('notifications.survey.list', response.data.data.user.survey)
          } else {
            this.$router.push({
              name: 'survey.details',
              params: { id: surveyID }
            })
          }
          break

        case 'feedback.created':
          this.$router
            .push({
              name: 'feedbacks',
              query: {
                booking_id: notification.data?.booking_id
              }
            })
            .catch(() => {})
          break

        case 'blog.created':
          this.$router.push(notification.data.redirect_url).catch(() => {})
          break

        case 'text-translation.can.resubmit':
        case 'text-translation.completed':
        case 'text-translation.deadline-reminder':
          this.$router.push(notification.data.redirect_url).catch(() => {})
          break

        case 'translator-did-not-match-after-customer-changes':
        case 'translator-match-after-customer-changes':
        case 'translator-assigned-after-customer-changes':
          this.$store.commit('email/setBookingChangesNotification', notification.data)
          this.$store.commit('email/setBookingChangesModal', true)
          break
        case 'booking.create.by.colleague':
          response = await this.$store.dispatch(
            'booking/getBookingDetails',
            { id: bookingID },
            {
              root: true
            }
          )
          EventBus.emit('notifications.booking.details', response.data.data.booking)
          break
        case 'text.translation.cost.notification':
          response = await this.$store.dispatch(
            'booking/getBookingDetails',
            { id: bookingID },
            {
              root: true
            }
          )
          EventBus.emit('notifications.booking.details', response.data.data.booking)
          break

        case 'leave-request-approved':
        case 'leave-request-rejected':
        case 'leave-request-cancelled':
          this.$router.push({ name: 'my-leaves' }).catch(() => {})
          break
        case 'translator.travel-time.rejected':
          response = await this.$store.dispatch(
            'booking/getBookingDetails',
            { id: bookingID },
            {
              root: true
            }
          )
          EventBus.emit('notifications.session_time.modal', response.data.data.booking)
          break

        default:
          showSmallErrorNotif(this.$t('notifications_fallback_action'))
      }

      this.markOneAsReadRemote(notification)
    },

    handleInfiniteScroll: debounce(function () {
      if (this.getNotificationsEndsStatus) return
      const listElm = document.querySelector('.scroll-area')
      if (listElm) {
        this.scrollDeep = listElm.scrollTop > listElm.clientHeight
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 1) {
          this.loadNotifications()
        }
      }
    }, 250),

    isDropdownVisible(visibility) {
      const el = document.querySelector('html')
      this.isVisibile = visibility // update the local state
      if (this.isOnMobileViewport && visibility) {
        el.classList.add('noscroll')
      } else {
        el.classList.remove('noscroll')
      }
    },

    // Convert given notifcation `type` to websocket `event` name
    normalizesNotificationType(type) {
      switch (type) {
        case 'booking.late_cancel':
          return 'booking.withdrawn'
        default:
          return type
      }
    },

    // Handle keyboard navigation via (up/down) arrow keys manually
    handleKeyboardNavigation(e) {
      const dropdown = document.querySelector('ul.email-notification__menu')

      let nodeList = dropdown.querySelectorAll('[tabindex="0"]') // reference to all focusable elements
      let focusableEls = Array.from(nodeList) // convert it to array
      focusableEls.splice(4, 2) // remove redundant elements

      let isArrowKey = e.key === 'ArrowDown' || e.key === 'ArrowUp'
      let index = focusableEls.indexOf(document.activeElement)

      if (!isArrowKey) return // return if it's not UP or DOWN arrow key

      if (e.key === 'ArrowUp') {
        focusableEls[index - 1].focus() // move backword if UP arrow key
      } else {
        focusableEls[index + 1].focus() // move backword if DOWN arrow key
      }
    },
    handleDeadlineText(val = null) {
      if (val === null) {
        return ''
      }

      let time = val.split(' ')

      return time[0] + ' ' + this.$t(time[1].toLowerCase())
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
html.noscroll {
  position: relative;

  body {
    overflow: hidden;
  }
}

$notifications-menu-height: 440px; // 500px

// Tooltip
.el-tooltip__popper,
.el-popper.notification-inbox {
  width: 150px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      line-height: 1.6;

      span {
        font-weight: 600;
      }

      &.important {
        justify-content: space-between;
        background: #484848;
        border-radius: 3px;
        padding: 1px 5px;
        margin: 5px -5px 0 -5px;
      }
    }
  }
}

// Dropdown Menu
.email-notification {
  &__menu-wrapper {
    .el-scrollbar {
      .is-vertical {
        display: none;
      }
    }
  }
  &__menu {
    position: relative;
    width: 400px;
    max-height: $notifications-menu-height;
    // overflow: hidden;
    margin-top: 0 !important;
    padding: 0 0 15px 0 !important;
    border-left: 0 !important;

    // Menu Header
    .menu-header {
      position: sticky;
      top: 0;
      left: 0;
      padding: 25px 25px;
      background: #fff;
      z-index: 999;
      border-bottom: 1px solid #efefef;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        align-items: center;
        margin: 0;

        .label {
          font-weight: 600;
          font-size: 22px;
          color: #43425d;
        }

        .counter {
          display: inline-block;
          padding: 4px 7px;
          background: #f8f8f8;
          border-radius: 3px;
          font-style: bold;
          font-size: 12px;
          font-weight: 500;
          color: #000;
          margin-left: 7px;
          line-height: 1.4;
        }
      }

      .toggle {
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
          color: #808495;
          margin-right: 5px;
          letter-spacing: -0.1px;
        }

        .el-switch {
          height: 18px;
          line-height: 18px;

          .el-switch__core {
            height: 18px;

            &:after {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }

    // Status Bar
    .status-bar {
      background: #f9f9f9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;

      .status {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        color: #bababa;
      }

      .mark-as-read {
        font-size: 14px;
        color: var(--app-primary-color);
        letter-spacing: -0.5px;
      }
    }

    // Scroll Area
    .scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      max-height: $notifications-menu-height - 100px;
      background: #fefefe;
      .ps__rail-y {
        z-index: 100;
      }
    }

    .loading-status {
      display: inline-block;
      position: absolute;
      bottom: 10px;
      right: 30px;
      background: var(--app-primary-color);
      color: #fff;
      border-radius: 5px;
      padding: 1px 5px;
      font-size: 13px;
      z-index: 100;
    }

    .end-of-line {
      display: inline-block;
      // background: #d9534f;
      color: #d9534f;
      border-radius: 5px;
      padding: 1px 5px;
      font-size: 14px;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .new-notifications-status {
      display: inline-block;
      position: absolute;
      top: 105px;
      right: 50%;
      transform: translateX(50%);
      background: #3975b8;
      color: #fff;
      border-radius: 5px;
      padding: 1px 5px;
      font-size: 13px;
      cursor: pointer;

      .icon {
        font-size: 15px;
      }
    }

    .no-notifications {
      font-size: 15px;
      padding: 25px 25px 10px 20px;
      margin: 0;
    }

    // Notification Item
    .el-dropdown-menu__item {
      display: flex;
      padding: 15px 25px;
      border-bottom: 1px solid #efefef;
      position: relative;
      z-index: 90;
      background: #f9f9f9;
      &:focus,
      &:not(.is-disabled):hover {
        background: #f9f9f9;
        color: #000;

        i,
        .content .title {
          color: var(--app-primary-color);
        }

        i {
          background: #fff;
        }
      }

      &:first-of-type:hover {
        // border-top: 1px solid #f1f1f1;
        box-shadow: inset 0 1px 0 0 #f1f1f1;
      }

      &:last-of-type {
        border: 0;
      }

      &.important {
        border-left: 4px solid var(--app-primary-color);
        background: #fff;
        background: url('@/assets/images/metro-warning.svg') 110% 10px no-repeat;
        background-size: 22%;
        &:hover {
          background-image: url('@/assets/images/metro-warning-white.svg');
          background-position: 110% 10px;
          background-repeat: no-repeat;
          background-size: 22%;
        }
      }

      i[class^='el-icon-'],
      i.el-icon {
        position: relative;
        display: block;
        background: #f8f8f8;
        margin-right: 20px;
        width: 45px;
        height: 45px;
        font-size: 20px;
        border-radius: 100%;
        align-self: start;
        display: flex;

        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
          font-size: 14px;
          font-weight: 600;
          margin: 0 0 7px 0;
          line-height: 1.7;
          white-space: normal;

          .unread-status {
            display: inline-block;
            width: 6px;
            height: 6px;
            background: red;
            border-radius: 100%;
            position: absolute;
            top: 20px;
            right: 20px;
          }
        }

        .text {
          font-size: 14px;
          line-height: 1.4;
          white-space: normal;
        }

        .timestamp {
          font-size: 12px;
          opacity: 0.7;
          line-height: 2.2;

          .day {
            &:after {
              content: '·';
              padding: 3px;
              font-size: 18px;
              line-height: 1px;
            }
          }
        }
      }
    }

    // Popper Arrow
    .popper__arrow.popper__arrow {
      border-width: 8px !important;
      top: -9px !important;

      &:after {
        border-width: 8px !important;
        margin-left: -8px !important;
      }
    }
  }
}

.email-notification {
  display: flex;
  .el-dropdown-link {
    position: relative;
    padding: 0 10px 0 20px;

    .el-icon-bell {
      font-size: 19px;
    }

    .counter {
      display: inline-block;
      position: absolute;
      left: 30px;
      top: -5px;
      background: #e05353;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.2;
      text-align: center;
      padding: 1px 4px;
      border-radius: 100%;
    }
  }

  .el-dropdown-menu {
    margin: 0 25px;
  }

  &__menu {
    padding: 15px;
  }

  .top-heading-notification {
    .link {
      margin: 0 20px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  $notifications-menu-height: 80vh;

  .email-notification__menu {
    position: fixed !important;
    margin: 0 auto;
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%);
    width: 92%;
    max-height: $notifications-menu-height;
    background: #fff;
    .scroll-area {
      width: 100%;
      max-height: $notifications-menu-height - 0vh;
    }
  }
}
</style>
