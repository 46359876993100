/*
|--------------------------------------------------------------------------
| Store > Statistics > Actions
|--------------------------------------------------------------------------
| File that contains the actions property for the current Vuex scope.
*/

import loadStatistics from './loadStatistics'
import exportToExcel from './exportToExcel'
import createSubscription from './createSubscription'
import loadSubscriptions from './loadSubscriptions'
import deleteSubscription from './deleteSubscription'
export default {
  loadStatistics,
  exportToExcel,
  createSubscription,
  loadSubscriptions,
  deleteSubscription
}
