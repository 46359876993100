/*
|--------------------------------------------------------------------------
| Custom > Helpers > Notifications
|--------------------------------------------------------------------------
|
| This file contains helpers that shows custom notifications.
|
| Feel free to edit this file if you have a helper that you want to define
| specifically for this file's purpose.
|
*/
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import isNil from 'lodash/isNil'
// import { Notification } from 'element-ui'
import { ElNotification } from 'element-plus'
// import { MessageBox } from 'element-ui'
import { ElMessageBox } from 'element-plus'
import { isArray, each, isEmpty } from 'lodash'
import i18n from '@/locales/i18nClient.js'
const { t: $t } = i18n.global

// const Notification = () => {}
// const MessageBox = () => {}

/**
 * Helper for showing custom formatted Ant Design UI $notification.
 *
 * @param {String} type - Varies between default, primary, success, info, warning, danger.
 * @param {{duration: number, title: string, message: string}} options - Set of Options to be used for notification.
 * @param {String} options.title - Verbiage to be displayed in the notif header.
 * @param {String} options.message - Verbiage to be displayed in the notif body.
 * @param {String} options.duration - Time before the notification auto closes.
 * @param {String} options.position - Position of the notification.
 * @param {String} options.custom_icon - Element UI icon class reference.
 * @param {String} options.on_click - callback function when notification clicked.
 * @return {void}
 */
export function showSmallNotif(type, options = {}) {
  // Set Default values.
  options.title = options.title || ''
  options.message = options.message || ''
  options.duration = options.duration || 500000
  options.position = options.position || 'top-right' // top-left, bottom-right, bottom-left.
  options.custom_icon = options.custom_icon || ''
  options.on_click = options.on_click || ''

  // Set $notify options container.
  let notifyOpts = {
    title: options.title,
    message: options.message,
    duration: options.duration,
    placement: options.placement,
    customClass: `app-notification-sm-${type}`,
    dangerouslyUseHTMLString: options.dangerouslyUseHTMLString
  }

  // Set Default icon for success, info, warning, and, danger.
  // if (type === 'success') notifyOpts.iconClass = 'el-icon-success'
  // else if (type === 'info') notifyOpts.iconClass = 'el-icon-info'
  // else if (type === 'warning') notifyOpts.iconClass = 'el-icon-warning'
  // else if (type === 'danger') notifyOpts.iconClass = 'el-icon-error'

  if (type !== '') {
    notifyOpts['type'] = type
  }

  // But if custom_icon is provided, override the default icon definitions.
  if (options.custom_icon !== '') {
    notifyOpts.iconClass = options.custom_icon
  }

  if (options.on_click !== '') {
    notifyOpts.onClick = options.on_click
  }

  // Notification(notifyOpts)
  ElNotification(notifyOpts)
}

/**
 * Helper method for showing a small success notification using showSmallNotif().
 *
 * @returns {void}
 */
export function showSmallSuccessNotif(message = '', title = '') {
  showSmallNotif('success', { title, message, duration: 7000 })
}

/**
 * Helper method for showing a small error notification using showSmallNotif().
 *
 * @returns {void}
 */
export function showSmallErrorNotif(message = '', title = '') {
  if (isObject(message)) {
    const error = message

    if (!isNil(error.response)) {
      if (isString(error.response.data.data)) {
        message = error.response.data.data
      } else if (isString(error.response.data.message)) {
        message = error.response.data.message
      } else {
        message = 'Please Contact your Administrator'
      }
    } else if (!isNil(error.message) && isString(error.message)) {
      message = error.message
    }
  }

  showSmallNotif('danger', { title, message, duration: 7000 })
}

export function showValidationErrors(error, title = 'error', type = 'danger') {
  let message = error
  title = $t(title)

  if (isObject(error)) {
    const response = error?.response?.data
    const responseData = response?.data

    if (!isEmpty(responseData) && (isArray(responseData) || isObject(responseData))) {
      let counter = 1
      message = ''
      each(responseData, (val) => {
        if (val) {
          message += `<b>${counter}</b>. ${$t(val)}<br>`
        }
      })
    } else if (!isNil(responseData)) {
      message = $t(responseData)
    } else if (!isNil(response?.message)) {
      message = $t(response.message)
    } else {
      message = $t(error.message)
    }
  }
  showSmallNotif(type, { title, message, duration: 7000, dangerouslyUseHTMLString: true })
}

/**
 * Helper method for showing a small error notification using showSmallNotif().
 *
 * @returns {void}
 */
export function showBookingSmallErrorNotif(message, title, bookingId) {
  if (isNil(message?.response?.data)) {
    return
  }
  const error = message.response.data
  const reasons = error.data?.reasons ?? []

  message = isEmpty(reasons) ? `<span><b>1.</b> ${$t(error?.message)}</span><br>` : ''

  each(reasons, (key, index) => {
    const increment = isEmpty(reasons) ? 2 : 1
    message += `<span><b>${index + increment}.</b> ${$t(key, { booking_id: bookingId })}</span><br>`
  })

  showSmallNotif('danger', { title, message, duration: 7000, dangerouslyUseHTMLString: true })
}

/**
 * Helper for showing success notif for Booking Success Events.
 *
 * @param {Object} createElement - Vue method for creating JSX (this.$createElement).
 * @param {Object} booking - Contains booking details.
 * @param {Function} callback - Method to be run after pressing the OK.
 * @returns {void}
 */
export function showBookingSuccessNotif(createElement, booking, callback = null) {
  const bookingId = !isNil(booking) && !isNil(booking.id) ? booking.id : 0

  // noinspection JSValidateTypes
  const icon = createElement('div', { class: 'notification-icon-container' }, [
    createElement('i', { class: 'notification-icon el-icon-circle-check' })
  ])

  // noinspection JSValidateTypes
  const message = createElement('h3', { class: 'notification-title-container' }, [
    createElement('div', { class: 'notification-title' }, 'Din tolkbokning är nu registrerad')
  ])

  // noinspection JSValidateTypes
  const description = createElement('div', { class: 'notification-message-container' }, [
    createElement('div', { class: 'notification-message' }, [
      createElement('div', { class: 'notification-message-label' }, 'Bokningsnummer'),
      createElement('div', { class: 'notification-message-id' }, bookingId)
    ])
  ])

  // noinspection JSValidateTypes
  const content = createElement('div', { class: 'notification-content' }, [icon, message, description])

  const msgBoxOpts = {
    message: content,
    customClass: 'app-notification-lg-success app-notification-booking-success',
    closeOnClickModal: false
  }

  // noinspection JSIgnoredPromiseFromCall
  ElMessageBox(msgBoxOpts)
    .then(() => {
      if (callback !== null) callback()
    })
    .catch(() => {})
}
