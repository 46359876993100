<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="isShowModal"
      class="app-modal app-modal-secondary"
      :class="getBem(blockCls)"
      :title="$t('survey_list')"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

      <div :class="getBem(blockCls, 'main-form')">
        <el-table :data="survey" style="width: 100%">
          <el-table-column :label="$t('title')">
            <template #default="{ row }">
              <div slot="reference" class="name-wrapper">
                <p>{{ row.title }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('end_date')">
            <template #default="{ row }">
              <!-- <i class="el-icon-time" /> -->
              <el-icon><Clock /></el-icon>
              <span>{{ formatCurrentDate(row.end_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('operations')">
            <template #default="{ row, $index }">
              <el-button size="mini" type="success" @click="handleClickSurvey($index, row)">
                {{ $t('start_survey') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </focus-trap>
  <!-- Component DIV Wrapper -->
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockCls: 'show-survey-list',
      isShowModal: false,
      survey: [],
      log: console.log
    }
  },
  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    ...mapState('auth', ['language']),
    currentLocale() {
      return this.language === 'se' ? 'sv' : this.language
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    open(survey) {
      this.survey = survey
      this.isShowModal = true
    },
    /**
     * Router push method
     */
    handleClickSurvey(index, row) {
      this.$router.push({
        name: `survey.details`,
        params: { id: row.id }
      })

      if (this.$route.name === 'survey.details') {
        this.$router.go() // temporary fix to reload the survey page because it's not-rendering if we are on the survey page already
      }

      this.isShowModal = false
    },
    formatCurrentDate(date) {
      return moment(date)
        .locale(this.currentLocale)
        .format('D MMM YYYY [' + this.$i18n.t('at') + '] HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
.show-survey-list {
  &__main-form {
    color: var(--app-primary-color);
  }
}
</style>
