import { reach } from '@/modules/helpers/vuexActions'
import { REGISTER_TEXT_TRANSLATION } from '@/modules/constants/api'

export default function (context, payload) {
  const url = REGISTER_TEXT_TRANSLATION
  const method = 'post'
  let options = {
    data: payload,
    headers: {
      'X-Recruitment-Client': 1
    },
    is_form_data: true
  }

  return reach(this, context, url, method, options)
}
