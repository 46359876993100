export default {
  referrals: [],
  translator_referral_code: '',
  settings: null,
  invitations: [],
  transactions: [],
  discounts: [],
  transaction_search_term: '',
  transaction_search_date: '',
  send_invite_loading: false
}
