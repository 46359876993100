import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import forOwn from 'lodash/forOwn'
import set from 'lodash/set'
import get from 'lodash/get'
import has from 'lodash/has'

/**
 * Helper for generating route paths according to the given locales.
 *
 * @param {object[]} paths
 * @param {string[]} locales
 * @return {Array}
 */
export function generateLocaleRoute(paths, locales) {
  let localePaths = []

  locales.forEach((locale) => {
    paths.forEach((path) => {
      let base = '/' + locale

      let o = { ...path }

      o.path = base + o.path
      o.name = locale + '__' + path.name

      let meta = o.meta == null ? {} : { ...o.meta }
      meta.locale = locale
      o.meta = { ...meta } // Just to make sure that object won't be mutated accross distribution of items.

      localePaths.push(o)
    })
  })

  return localePaths
}

/**
 * @param obj
 * @returns {string}
 */
function getLocaleFromObj(obj) {
  let locale = ''

  if (has(obj, '$route')) {
    locale = obj.$route.meta.locale
  } else if (has(obj, 'app') && has(obj, 'app._route')) {
    locale = obj.app._route.meta.locale
  }

  if (isString(locale) && locale.trim() === '') locale = null

  return locale
}

/**
 * Helper method for getting the route path associated to the current locale used.
 *
 * @param {String} path - The URL path to be converted.
 * @param {Object} [va] - Optional Vue App Instance when for script usage.
 * @returns {String}
 */
export function getLocaleRoutePath(path, va) {
  let self = !isNil(this) ? this : va
  const locale = getLocaleFromObj(self)

  if (!isNil(locale)) {
    return path.length === 1 && path === '/' ? '/' + locale : '/' + locale + path
  } else {
    return path
  }
}

/**
 * Helper method for getting the route name associated to the current locale used.
 *
 * @param {String} name - The route name to be converted.
 * @param {Object} [va] - Optional Vue App Instance when for script usage.
 * @returns {String}
 */
export function getLocaleRouteName(name, va) {
  const self = !isNil(this) ? this : va
  const locale = getLocaleFromObj(self)

  if (!isNil(locale)) {
    return locale + '__' + name
  }
}

/**
 * Helper method for updating the Query Parameters in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {*} value - Value to be assigned.
 * @param {String} [key] - Key that will be used as the identifier.
 * @param {Boolean} [allowNull=false] - Value to determine if nulls will be included in the query param.
 * @returns {void}
 */
export function updateQueryParams(va, value, key = null, allowNull = false) {
  let newQueryParams = { ...va.$route.query }

  if (isObject(value)) {
    key = isNull(key) ? '' : key + '_'

    forOwn(value, (v, i) => {
      set(newQueryParams, `${key}${i}`, v)
    })
  } else {
    set(newQueryParams, key, value)
  }

  newQueryParams = allowNull ? newQueryParams : omitBy(newQueryParams, isNil)

  va.$router
    .replace({
      query: newQueryParams
    })
    .catch((e) => {
      if (e.name !== 'NavigationDuplicated') throw e
    })
}

/**
 * Helper method for removing the Query Parameters in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {String|String[]} keys - Key that will be used as the identifier.
 * @returns {void}
 */
export function removeQueryParams(va, keys) {
  let newQueryParams = { ...va.$route.query }

  newQueryParams = omit(newQueryParams, keys)

  va.$router
    .replace({
      query: newQueryParams
    })
    .catch((e) => {
      if (e.name !== 'NavigationDuplicated') throw e
    })
}

/**
 * Helper method for getting a particular Query Parameter in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {String} key - Key that will be used as the identifier.
 * @param {*} [def] - Default value to be assign if the query param doesn't exist.
 * @param {Function} [mutator] - Method to mutate the value from the query param.
 * @returns {*}
 */
export function getQueryParam(va, key, def = null, mutator = null) {
  if (isNull(mutator)) {
    mutator = (v) => v
  }

  const queryParamValue = get(va.$route.query, key)

  return !isNil(queryParamValue) ? mutator(queryParamValue) : def
}

/**
 * Helper method for getting a particular URL Parameter in the URL.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {String} key - Key that will be used as the identifier.
 * @param {*} [def] - Default value to be assign if the query param doesn't exist.
 * @param {Function} [mutator] - Method to mutate the value from the query param.
 * @returns {*}
 */
export function getUrlParam(va, key, def = null, mutator = null) {
  if (isNull(mutator)) {
    mutator = (v) => v
  }

  const queryParamValue = get(va.$route.params, key)

  return !isNil(queryParamValue) ? mutator(queryParamValue) : def
}
