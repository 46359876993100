/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > Acknowledge Marketing Schedule
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @returns {*}
 */
export default function (context, payload) {
  const method = 'POST'

  const base_url = import.meta.env.VITE_API_BASE
  const url = `${base_url}/api/v3/core/marketings/${payload.marketing_id}/schedules/${payload.schedule_id}/acknowledge`
  let options = {}
  return reach(this, context, url, method, options, axiosInstance)
}
