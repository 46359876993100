import API from '@/modules/constants/api'
import LS from '@/modules/constants/localStorage'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '@/modules/helpers/appStorage'

export default function (context, payload = {}) {
  const appToken = appStorage.get(LS.TOKEN)
  const token = appToken.access_token ?? appToken
  const url = `${API.BOOKINGS}/${payload.booking_id}/text-feedback`

  let headers = {
    Authorization: 'Bearer ' + token
  }

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, payload, {
        headers: headers
      })
      .then((r) => {
        if (r?.data?.status === 'success') {
          resolve(r.data?.data?.text_translation_feedback)
        }
        reject(r)
      })
      .catch((e) => {
        context.commit('setSubmitError', true)
        reject(e)
      })
  })
}
