/*
|--------------------------------------------------------------------------
| Custom > Helpers > Vuex Actions
|--------------------------------------------------------------------------
|
| This file contains helpers that are most used inside Vuex Action files.
*/

import { showSmallErrorNotif } from '@/modules/helpers/notifications'
import axios from 'axios'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import includes from 'lodash/includes'
import trim from 'lodash/trim'
import qs from 'qs'

/**
 * @param {Object} options - Contains necessary values usable inside the method.
 * @param {String} options.url - Target URL to be reached out by axios.
 * @param {String} options.method - HTTP Request Method to be used.
 * @param {Object} [options.query_params={}] - List of Query Param Values to be sent with the request.
 * @param {Object} [options.data={}] - Data Payload to be sent with the request.
 * @param {Object} [options.is_data_raw=false] - Value wether to send the data payload without formatting.
 * @param {Object} [options.is_form_data=false] - Value wether to send the data as FormData.
 * @param {Boolean} [options.enable_error_notif=false] - Value whether to enable notification upon error.
 * @param {Object} [options.headers={}] - Headers to include in the axios request.
 * @returns {*|Promise}
 */
export function apiCall(options = {}, axiosInstance = null) {
  if (isNil(options.url)) {
    throw new Error('Missing required value options.url')
  }

  if (isNil(options.method)) {
    throw new Error('Missing required value options.method')
  }

  // Make sure values exists.
  options.query_params = options.query_params || {}
  options.data = options.data || {}
  options.is_data_raw = options.is_data_raw || false
  options.is_form_data = options.is_form_data || false
  options.enable_error_notif = options.enable_error_notif || false
  options.headers = options.headers || {}

  let axiosOpts = {
    url: options.url,
    method: options.method
  }

  // Add the Query Param values if they exist.
  if (!isEmpty(options.query_params)) {
    axiosOpts.params = options.query_params
  }

  // Add the data if it exists.
  if (options.is_form_data) {
    axiosOpts.data = options.data
  } else if (!isEmpty(options.data)) {
    axiosOpts.data = options.is_data_raw ? options.data : qs.stringify(options.data)
  }

  // Add headers if they exist.
  if (!isEmpty(options.headers)) {
    axiosOpts.headers = options.headers
  }

  let axiosCaller = axios
  if (axiosInstance) {
    axiosCaller = axiosInstance
  }

  return new Promise((resolve, reject) => {
    axiosCaller(axiosOpts)
      .then((r) => {
        // let successLogCss = ['background-color: #44bcfd', 'color: #333333'].join(';')

        // console.groupCollapsed('%c' + 'API Request successfull.', successLogCss)
        // console.log(r)
        // console.groupEnd()

        resolve(r)
      })
      .catch((e) => {
        let errorLogCss = ['background-color: #db3246', 'color: #ffffff'].join(';')

        console.group('%c' + 'API Request failed.', errorLogCss)
        console.error(e)
        console.groupEnd()

        // Show error notification if it is enabled.
        if (options.enable_error_notif) {
          showSmallErrorNotif(e)
        }

        reject(e)
      })
  })
}

/**
 * Action to reach to the API.
 *
 * @param {Object} va - The Vue App instance also known as "this".
 * @param {Object} context - The context of the current Vuex module.
 * @param {String} url - Target URL to be reached out by axios.
 * @param {String} method - HTTP Request Method to be used.
 * @param {Object} [options] - Contains necessary values usable inside the method.
 * @param {Object} [options.query_params={}] - List of Query Param Values to be sent with the request.
 * @param {Object} [options.data={}] - Data Payload to be sent with the request.
 * @param {Object} [options.is_data_raw=false] - Value wether to send the data payload without formatting.
 * @param {Object} [options.is_form_data=false] - Value wether to send the data as FormData.
 * @param {Boolean} [options.enable_error_notif=false] - Value whether to enable notification upon error.
 * @param {Object} [options.headers={}] - Headers to include in the axios request.
 * @param {Function} [options.on_success=null] - Callback for a successful request.
 * @param {Function} [options.on_error=null] - Callback for an errored request.
 * @param {Function} [options.on_finally=null] - Callback when the request is done.
 * @returns {*|Promise}
 */
export function reach(va, context, url, method, options = {}, axiosInstance = null) {
  // Make sure Vue App Instances is provided.
  if (isNil(va)) return

  // Make sure values exists.
  options.query_params = options.query_params || {}
  options.data = options.data || {}
  options.is_data_raw = options.is_data_raw || false
  options.is_form_data = options.is_form_data || false
  options.enable_error_notif = options.enable_error_notif || false
  options.headers = options.headers || {}
  options.on_success = options.on_success || null
  options.on_error = options.on_error || null
  options.on_finally = options.on_finally || null

  // Adding viewPortTag in payload
  options.data.created_via = context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'

  let axiosOpts = { url, method }

  // Add the Query Param values if they exist.
  if (!isEmpty(options.query_params)) {
    axiosOpts.params = options.query_params
  }

  // Add the data if it exists.
  if (options.is_form_data) {
    axiosOpts.data = options.data
  } else if (!isEmpty(options.data)) {
    axiosOpts.data = options.is_data_raw ? options.data : qs.stringify(options.data)
  }

  // Add headers if they exist.
  if (!isEmpty(options.headers)) {
    axiosOpts.headers = options.headers
  }

  // Add full url
  // Object.assign(options.query_params, {
  //   'ui-url': location.protocol + '//' + location.host + router.currentRoute.fullPath
  // });

  let axiosCaller = axios
  if (axiosInstance) {
    axiosCaller = axiosInstance
  }

  return new Promise((resolve, reject) => {
    axiosCaller(axiosOpts)
      .then((r) => {
        // Run the on_success callback if it exists.
        if (!isNil(options.on_success) && isFunction(options.on_success)) {
          options.on_success(r, context)
        }

        // console.groupCollapsed('API Request successfull.')
        // console.log(r)
        // console.groupEnd()

        // Run the on_finally callback if it exists.
        if (!isNil(options.on_finally) && isFunction(options.on_finally)) {
          options.on_finally(context)
        }

        resolve(r)
      })
      .catch((e) => {
        console.group('API Request failed.')
        console.error(e)
        console.groupEnd()

        // Show error notification if it is enabled.
        if (options.enable_error_notif) {
          showSmallErrorNotif(e)
        }

        // Run the on_error callback if it exists.
        if (!isNil(options.on_error) && isFunction(options.on_error)) {
          options.on_error(e, context)
        }

        // Run the on_finally callback if it exists.
        if (!isNil(options.on_finally) && isFunction(options.on_finally)) {
          options.on_finally(context)
        }

        reject(e)
      })
  })
}

/**
 * Action to load all items.
 *
 * @param {Object} va - The Vue App instance also known as "this".
 * @param {Object} context - The context of the current Vuex module.
 * @param {String} url - Target URL to be reached out by axios.
 * @param {String} method - HTTP Request Method to be used.
 * @param {String} [commitDataRef] - Reference for vuex mutation of setting the list state.
 * @param {Object} [options] - Contains necessary values usable inside the method.
 *                             Please refer to reach() method for the base options
 *                             params documentation.
 * @returns {Promise}
 */
export function loadAll(va, context, url, method, commitDataRef = '', options = {}, axiosInstance = null) {
  // Make sure following values exist.
  options.enable_error_notif = options.enable_error_notif || true

  // NOTE: Preferred the use of ctx instead of context for one-way direction flow.
  options.on_success =
    options.on_success ||
    function (r, ctx) {
      if (commitDataRef !== '') {
        ctx.commit(commitDataRef, r)
      }
    }

  // NOTE: Preferred the use of ctx instead of context for one-way direction flow.
  options.on_error =
    options.on_error ||
    function () {
      // Silence is golden...
    }

  options.on_finally =
    options.on_finally ||
    function () {
      // Silence is golden...
    }

  return reach(va, context, url, method, options, axiosInstance)
}

/**
 * Helper method to be used in bookings listing actions,
 * preferrably to handle the filtering conditions.
 *
 * @param {object} payload - Payload provided in the Vuex action.
 * @param {object} [options] - Values to be used inside the method.
 * @param {array} [options.exclude] - Collection of conditions swithes that should be exluded.
 *
 * @returns {object}
 */
export function bookingsListFilterOptions(payload, options = {}) {
  let query = {}
  let exclude = options.exclude || []

  if (!includes(exclude, 'page')) {
    if (!isNil(payload.page)) query.page = payload.page
  }

  if (!includes(exclude, 'sort_by')) {
    if (!isNil(payload.sort_by)) {
      query.sort = payload.sort_by
    }
  }

  if (!includes(exclude, 'sort_order')) {
    if (!isNil(payload.sort_order)) {
      const suffix = payload.sort_order === 'asc' ? '' : '-'
      query.sort = suffix + payload.sort_by
    }
  }

  if (!includes(exclude, 'filter_list_date')) {
    //applies for both the date_range and the single date filtering
    payload.sort_by = payload.sort_by === 'id' ? 'created_at' : 'due'

    if (
      !isNil(payload.filter_list_date_from) &&
      trim(payload.filter_list_date_from) !== '' &&
      !isNil(payload.filter_list_date_to) &&
      trim(payload.filter_list_date_to) !== ''
    ) {
      query[`filter[date_range]`] = [
        payload.sort_by,
        payload.filter_list_date_from + ' 00:00:00',
        payload.filter_list_date_to + ' 23:59:59'
      ].join(',')
    } else {
      // adds param for filtering today's date i.e &filter[due]=2019-11-12
      if (!isNil(payload.filter_list_date_from) && isNil(payload.filter_list_date_to)) {
        query[`filter[date_range]`] = [
          payload.sort_by,
          payload.filter_list_date_from + ' 00:00:00',
          payload.filter_list_date_from + ' 23:59:59'
        ].join(',')
      }
    }
  }

  if (!includes(exclude, 'filter_list_query')) {
    if (!isNil(payload.filter_list_query) && trim(payload.filter_list_query) !== '' && !isNil(payload.filter_list_by)) {
      query[`filter[${payload.filter_list_by}]`] = payload.filter_list_query
    }
  }

  if (!includes(exclude, 'customer_id')) {
    if (!isNil(payload.customer_id) && payload.customer_id !== '' && parseInt(payload.customer_id) !== 0) {
      query['filter[customer_id]'] = payload.customer_id
    }
  }

  return query
}
