import { APPOINTMENT_API } from '@/modules/constants/api'
import axios from 'axios'

export default function (context, languageId) {
  if (languageId) {
    let url = APPOINTMENT_API + `/api/dt-language/${languageId}/templates`
    return axios
      .get(url)
      .then((response) => {
        context.commit('setAppointmentTemplates', response.data.data)
        return response
      })
      .catch((error) => {
        return error
      })
  }
}
