/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > logoutUserSession
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { mapEndpoint } from '@/modules/helpers/common'
import i18n from '@/locales/i18nClient.js'
import store from '@/store'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { showSmallErrorNotif, showSmallSuccessNotif } from '@/modules/helpers/notifications'
const { t: $t } = i18n.global

/**
 * logout particular or all user sessions
 *
 * @param context
 * @param payload
 * @returns {Promise<*>}
 */
export default async function (context, payload) {
  payload.userId = await store.getters['auth/user'].id
  const url = mapEndpoint(payload.all ? API.USER_SESSIONS_LOGOUT_ALL : API.USER_SESSIONS_LOGOUT, payload)
  const method = 'DELETE'

  let options = {
    data: payload.data,
    is_data_raw: true,

    on_success(r, ctx) {
      showSmallSuccessNotif($t('sessions_logout_success'))
      ctx.dispatch('loadUserSessionLogs')
    },

    on_error(e) {
      showSmallErrorNotif(e)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
