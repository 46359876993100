/*
|--------------------------------------------------------------------------
| Store > TextTranslation > Actions > getCustomerMarketings
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context) {
  const url = `${API.GET_CUSTOMER_MARKETINGS}`

  const method = 'get'
  let options = {
    on_success(r, ctx) {
      const customerMarketings = r?.data?.data?.marketings ?? []
      ctx.commit('setCustomerMarketings', customerMarketings)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
