/*
|--------------------------------------------------------------------------
| Store > Booking List > Mutations
|--------------------------------------------------------------------------
|
| This file is where the Module's mutation methods are registered.
|
| Feel free to edit this file if you have mutation methods you want
| to include.
|
*/

import cloneDeep from 'lodash/cloneDeep'

export default {
  /**
   * Method to set value for state.topControls into its pristine state.
   *
   * @param {object} state - The state property of the current Vuex module.
   */
  resetTopControls(state) {
    state.topControls = cloneDeep(state.topControlsFresh)
  }
}
