<!--suppress JSUnnecessarySemicolon -->
<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="isShowModal"
      class="app-modal app-modal-secondary"
      :class="getBem(blockClass)"
      :title="modalTitle"
      top="3%"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

      <!-- Only visible during Printing -->
      <div :class="getBem(blockClass, 'print-header')">
        <!--suppress HtmlUnknownTarget -->
        <img src="@/assets/images/brand-logo.png" class="brand-logo" alt="brand-logo.png" />

        <h1>{{ $t('booking_id_short') }} # {{ bookingIssue.booking_id }}</h1>
      </div>

      <table
        v-if="bookingIssue"
        class="app-booking-details-table"
        :class="getBem(blockClass, 'table')"
        style="border-collapse: collapse"
      >
        <tbody>
          <!-- Booking ID row -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('booking_id') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingIssue.booking_id }}
            </td>
          </tr>

          <!-- Date & Time -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('date_time') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingIssue.created_at }}
            </td>
          </tr>

          <!-- Department -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('department') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingIssue.booking.customer.customer_data.department.name }}
            </td>
          </tr>

          <!-- Company -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('company') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingIssue.booking.customer.customer_data.department.company.name }}
            </td>
          </tr>

          <!-- Translator -->
          <tr v-if="bookingIssue.translator">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('translator_name') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingIssue.translator.name }}
            </td>
          </tr>

          <!-- Duration -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('duration') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ formattedDuration }}
            </td>
          </tr>

          <!-- Language -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('language') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingIssue.booking.language_name }}
            </td>
          </tr>

          <!-- Comment -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('comment') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              <span v-html="bookingReportComment" />
            </td>
          </tr>

          <!-- Rating -->
          <tr v-if="bookingIssue.booking.feedback.length > 0">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('feedback_for_translator') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              <el-rate
                v-model="bookingIssue.booking.feedback[0].rating"
                :colors="['#ffdc00', '#ffdc00', '#ffdc00']"
                disabled
              />
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <div :class="getBem(blockClass, 'actions')" class="text-right">
        <el-button
          class="app-button-secondary"
          :class="getBem(blockClass, 'print-button')"
          icon="el-icon-printer"
          @click="handleClickPrint"
        >
          {{ $t('btn_print') }}
        </el-button>

        <el-button class="app-button-default" :class="getBem(blockClass, 'close-button')" @click="handleClickClose">
          {{ $t('btn_close') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- Component DIV Wrapper -->
  </focus-trap>
</template>

<script>
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import moment from 'moment'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {},

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'report-booking-details-modal',
      isShowModal: false,
      bookingIssue: '',
      bookingReportComment: ''
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    /**
     * @returns {string}
     */
    modalTitle() {
      return this.title !== '' ? this.title : this.$t('feedback_report_title')
    },

    formattedDuration() {
      const totalMins = this.bookingIssue.booking.duration
      let hrs = (totalMins / 60) | 0
      let mins = totalMins % 60 | 0
      let time = `${hrs}:${mins}`
      return moment(time, 'HH:mm').format('HH:mm')
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    /**
     * Handler when the Print button was clicked.
     *
     * @returns {void}
     */
    handleClickPrint() {
      if (!isNil(window) && !isNil(window.print) && isFunction(window.print)) {
        window.print()
      }
    },

    /**
     * Handler when the Close button was clicked.
     *
     * @returns {void}
     */
    handleClickClose() {
      this.close()
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(payload) {
      console.log('Modal Payload: ', payload)
      this.bookingIssue = payload.booking_issue
      this.bookingReportComment = payload.booking_report_comment
      this.isShowModal = true
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
    }
  }
}
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.report-booking-details-modal {
  a,
  a:hover,
  a:focus,
  a:visited,
  a:active {
    color: var(--app-primary-color);
    text-decoration: underline;
  }

  &__table {
    width: 80%;
    margin: 0 auto;

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      span {
        word-break: break-word;
      }
    }
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .report-booking-details-modal {
    .el-dialog {
      width: 90%;
    }
  }
}

// **************************************************
// Small Viewport
// **************************************************
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .report-booking-details-modal {
    .el-dialog {
      width: 90%;
    }
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .report-booking-details-modal {
    .el-dialog {
      width: 75%;
    }
  }
}

@include print-booking-details('report');
</style>
