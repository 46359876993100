/*
|--------------------------------------------------------------------------
| Store > Earnings > Getters
|--------------------------------------------------------------------------
| File that contains the getters property for the current Vuex scope.
*/

export default {
  /**
   * Returns state.list value.
   *
   * @param state
   * @return {object}
   */
  list: (state) => state.list,

  /**
   * Returns state.list.data value.
   *
   * @param state
   * @return {object}
   */
  listData: (state) => state.list.data,

  /**
   * Returns state.list.pagination value.
   *
   * @param state
   * @return {object}
   */
  listPagination: (state) => state.list.pagination,

  /**
   * Returns total amount of earnings all in all.
   *
   * @param state
   * @return {object}
   */
  earningsTotal: (state) => state.amounts.total,

  /**
   * Returns total amount of earnings for the current year.
   *
   * @param state
   * @return {object}
   */
  earningsInYear: (state) => state.amounts.current_year,

  /**
   * Returns state.graph value.
   *
   * @param state
   * @return {array}
   */
  graph: (state) => state.graph
}
