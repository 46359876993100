import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the department opts.
 *
 * @param {object} context - Current context of the Vuex module.
 * @returns {Promise|*}
 */
export default function (context) {
  context.commit('auth/setTownOptsLoading', true, { root: true })

  const url = `${API.TOWNS_FOR_CT}`
  const method = 'GET'

  let options = {
    query_params: {
      all: true
    },
    on_finally(ctx) {
      ctx.commit('auth/setTownOptsLoading', false, { root: true })
    }
  }

  return loadAll(this, context, url, method, 'setTownOpts', options, axiosInstance)
}
