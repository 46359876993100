/*
|--------------------------------------------------------------------------
| Store > Booking > > Text Translation State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/

export default () => {
  return {
    dates: [],
    timeSlots: [],
    assignmentProbabilities: {},
    loading: false,
    availableSlotSelected: false,
    specificChoices: false
  }
}
