/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'

/**
 * Action to load customer normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  context.commit('setCustomerNormalBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    // 'customer.department',
    'translator_levels',
    'assigned_translator',
    'notification_types',
    'specific_translators',
    // 'excluded_translators',
    'video_booking',
    // 'messages.files',
    // 'convey.files',
    'booking_group.bookings',
    // 'towns',
    // 'translator_trainings',
    // 'booking_specific_preference',
    // 'translator_email',
    // 'translator_address',
    // 'translator_dob'
    'booking_time_slots.translator.translator_unavailable_times'
  ].join(',')

  const fields = [
    'id,customer_id,due,duration,from_language_id,status_id,is_immediate,gender,type,created_at,reference,booker_name,additional_field_label,additional_field,additional_field_two_label,additional_field_two,staff_name,client_phone,instructions,address,ended_at,will_end_at,booking_group_id,parent_booking_id,town_id,city,staff_phone'
  ].join(',')
  const topControls = await store.getters['bookingList/topControls']
  let statuses = topControls?.booking_statuses?.toString()
  statuses += ',14,15' // adding this because we are not showing planned/To be planned but we want them in booking list for customers
  let query_params = {
    'fields[bookings]': fields,
    include,
    // sort: 'due',
    'filter[status_id]': statuses,
    'filter[type]': 'physical,phone,video,video_physical,video_skype,convey,video_emergency'
  }

  if (payload.assignedTranslators) {
    query_params['filter[assigned_translator]'] = payload.assignedTranslators
  }

  if (payload.plannedTranslator) {
    query_params['filter[planned_translator]'] = payload.plannedTranslator
  }

  if (payload.plannedOrAssignedTranslator) {
    query_params['filter[planned_or_assigned_translator]'] = payload.plannedOrAssignedTranslator
    query_params['fields[bookings]'] = undefined
  }

  let excludeOptions = {}

  if (topControls?.show_department_bookings) {
    excludeOptions = {
      exclude: ['customer_id']
    }
  }

  let emergency = topControls?.is_immediate ?? payload?.is_immediate ?? null
  console.log('emergency', emergency)
  if (emergency) {
    query_params['filter[is_immediate]'] = emergency
  } else {
    delete query_params['filter[is_immediate]']
  }
  let type = topControls?.type ?? payload?.type ?? null
  if (type) {
    payload.filter_list_query = type
  }

  let options = {
    query_params,
    on_finally(ctx) {
      ctx.commit('setCustomerNormalBookingListLoading', false)
      ctx.commit('setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }
  const filterQueries = bookingsListFilterOptions(payload, excludeOptions)

  assignIn(options.query_params, filterQueries)

  // fix for CS-741
  if (options.query_params['filter[id]'] !== undefined && options.query_params['filter[id]'] !== null) {
    delete options.query_params['filter[status_id]']
  }

  return loadAll(this, context, url, method, 'setCustomerNormalBookingList', options, axiosInstance)
}
