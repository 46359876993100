// import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function loadTranslatorUnavailableTimes(context, payload) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = API.TRANSLATOR_UNAVAILABLE_TIMES
  const method = 'GET'
  // const options = {
  //   query_params: payload.queryParams
  // }
  let params = payload.queryParams
  context.commit('setLoadingForUnavailableTime', true)

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      params
    })
      .then((r) => {
        context.commit('setTranslatorUnavailableTimeList', r)
        context.commit('setTranslatorUnavailableTimeLoading', false)
        context.commit('setLoadingForUnavailableTime', false)

        resolve(r)
      })
      .catch((e) => {
        context.commit('setTranslatorUnavailableTimeLoading', false)
        context.commit('setLoadingForUnavailableTime', false)

        reject(e)
      })
  })
}

export default loadTranslatorUnavailableTimes
