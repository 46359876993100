/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > cancelBooking
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to cancel a bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  const url = `${API.BOOKINGS}/${payload.booking_id}/cancel`
  const method = 'POST'

  let options = {
    data: payload.data,
    cancelled_via: context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'
  }

  context.commit('setBookingDefaultLoading', true)

  const result = await reach(this, context, url, method, options, axiosInstance)

  context.commit('setBookingDefaultLoading', false)

  return result
}
