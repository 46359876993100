/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > requestResetPassword
|--------------------------------------------------------------------------
|
*/
import { getAppTenant } from '@/modules/helpers/multiTenancy'
import API from '@/modules/constants/api'
import axios from 'axios'

/**
 * Action for requesting a reset password token to the API.
 * The response of the feature expects an email to where a
 * reset link will be sent along with the reset token.
 *
 * @param {Object} context - Current context of Vuex module.
 * @return {*}
 */
export default function (context) {
  const method = 'POST'
  const url = API.PASSWORD_FORGOT
  const data = context.state.requestResetPasswordForm
  const headers = {}
  const appTenant = getAppTenant()
  if (appTenant && appTenant.uuid) {
    headers['X-Tenant'] = appTenant.uuid
    data.tenant = appTenant.uuid
  }

  context.state.isLoadingRequestResetPassword = true

  return new Promise((resolve, reject) => {
    axios({ method, url, data, headers })
      .then((r) => {
        context.state.isLoadingRequestResetPassword = false
        resolve(r)
      })
      .catch((e) => {
        context.state.isLoadingRequestResetPassword = false
        reject(e)
      })
  })
}
