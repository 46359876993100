/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > blockTranslatorForDepartment
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.department_id - Authenticated user' department id.
 * @param {Object} payload.`translator_id` - Id of the translator to be blocked.
 * @returns {*}
 */
export default function (context, payload) {
  const method = 'POST'
  const url = API.BLACKLISTED_USERS + '-no-duplicate'
  let options = {
    data: {
      customer_id: payload.department_id,
      customer_type: 'departments',
      translator_id: payload.translator_id
    },
    on_success(r, ctx) {
      ctx.dispatch('auth/readAuthUserMeta', {}, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
