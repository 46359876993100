/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > getTranslatorEmploymentStatus
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
// import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to fetch the user employment planning
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload = {}) {
  const userId = parseInt(payload.authenticated.user.id)

  // Define axios values.
  const url = `${API.TRANSLATORS}/${userId}/employment-status`

  const method = 'GET'
  let headers = {
    Authorization: `Bearer ${payload.authenticated.token}`
  }
  return axiosInstance({
    url,
    method,
    headers
  })
    .then((r) => {
      context.commit('auth/setIsEmployedTranslator', r.data.is_employed_translator, { root: true })
    })
    .catch(() => {})
}
