/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll, bookingsListFilterOptions } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'
import { STARTED, ASSIGNED, IN_REVIEW, DECLINED } from '@/modules/constants/bookingStatus'

/**
 * Action to load customer normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  //exclude from normal booking list

  context.commit('setCustomerNormalBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    'translator_levels',
    'assigned_translator',
    'specific_translators',
    'video_booking',
    'text_translation.files'
  ].join(',')
  const topControls = await store.getters['bookingList/topControls']
  if (topControls?.booking_statuses.includes(STARTED)) {
    topControls.booking_statuses.push(IN_REVIEW, DECLINED)
  }
  if (!topControls?.booking_statuses?.length) {
    topControls.booking_statuses.push(STARTED, ASSIGNED)
  }

  let statuses = topControls?.booking_statuses ?? []

  /** If statuses has 'started(3)' status then also include 'in_review(23) and declined(24)' status */
  if (statuses.includes(STARTED)) {
    if (!statuses.includes(IN_REVIEW)) {
      statuses.push(IN_REVIEW)
    }

    if (!statuses.includes(DECLINED)) {
      statuses.push(DECLINED)
    }
  }

  /** If statuses doesn't have 'started(3)' status then also remove 'in_review(23) and declined(24)' status */
  if (!statuses.includes(STARTED)) {
    if (statuses.includes(IN_REVIEW)) {
      const inreviewStatusIndex = statuses.indexOf(IN_REVIEW)
      if (inreviewStatusIndex !== -1) {
        statuses.splice(inreviewStatusIndex, 1)
      }
    }

    if (statuses.includes(DECLINED)) {
      const declinedStatusIndex = statuses.indexOf(DECLINED)
      if (declinedStatusIndex !== -1) {
        statuses.splice(declinedStatusIndex, 1)
      }
    }
  }

  let excludeOptions = {}

  if (topControls?.show_department_bookings) {
    excludeOptions = {
      exclude: ['customer_id']
    }
  }

  let options = {
    query_params: {
      include,
      'filter[status_id]': statuses?.toString(),
      'filter[type]': 'text_translation'
    },

    on_finally(ctx) {
      ctx.commit('setCustomerNormalBookingListLoading', false)
      store.commit('booking/setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }

  const filterQueries = bookingsListFilterOptions(payload, excludeOptions)

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, 'setCustomerNormalBookingList', options, axiosInstance)
}
