<template>
  <el-menu-item
    index="auth_controls"
    :class="getBem(blockClass, ['menu-item', 'auth-controls'])"
    :popper-class="getBem(blockClass, 'submenu-popper')"
  >
    <div :class="getBem(blockClass)">
      <el-collapse :class="getBem(blockClass, 'collapse')">
        <el-collapse-item>
          <!-- Collapse Title -->
          <template #title :class="getBem(blockClass, 'collapse-title')">
            <div :class="getBem(blockClass, 'collapse-avatar')">
              <!--suppress HtmlUnknownTarget -->
              <profile-avatar :src="userAvatar" />
            </div>

            <div :class="getBem(blockClass, 'dropdown-name')">
              {{ firstName }}
            </div>
          </template>

          <!-- Collapse Content Body -->
          <div :class="getBem(blockClass, 'collapse-content-body')">
            <div :class="getBem(blockClass, 'authenticated-user-actions')">
              <a
                :class="getBem(blockClass, 'authenticated-user-action-logout')"
                href="#"
                @click.prevent.stop="$router.push('/profile')"
              >
                {{ $t('nav_profile') }}
              </a>
            </div>
            <div
              v-for="user in $store.getters['auth/authenticatedUsers']"
              :key="user.id"
              :class="[getBem(blockClass, 'authenticated-user'), { unclickable: isUserIdCurrentlyLoggedIn(user.id) }]"
              @click.prevent.stop="handleClickSwitchUser(user.id)"
            >
              <div :class="getBem(blockClass, 'authenticated-user-avatar')">
                <profile-avatar src="" />
              </div>
              <div :class="getBem(blockClass, 'authenticated-user-details')">
                <span>{{ user.name }}</span>
                <span>{{ user.email }}</span>
                <span>{{ getUserType(user.type) }}</span>
              </div>
              <div :class="getBem(blockClass, 'authenticated-user-actions')">
                <a
                  :class="getBem(blockClass, 'authenticated-user-action-logout')"
                  href="#"
                  @click.prevent.stop="handleClickAuthenticatedUserLogout(user.id)"
                >
                  {{ $t('nav_logout') }}
                </a>
              </div>
            </div>
          </div>

          <!-- Collapse Content Footer -->
          <div :class="getBem(blockClass, 'collapse-content-footer')">
            <div :class="getBem(blockClass, 'add-another-user')">
              <a v-if="isShowAddAnotherUserAction" href="#" @click.prevent.stop="handleClickAddAnotherUser">
                {{ $t('auth_add_another_user') }}
              </a>
            </div>
            <div :class="getBem(blockClass, 'logout-all')">
              <a href="#" @click.prevent.stop="handleClickLogoutAll">
                {{ $t('auth_logout_all') }}
              </a>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </el-menu-item>
</template>

<script>
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import ProfileAvatar from '@/modules/auth/components/displays/ProfileAvatar'

export default {
  name: 'AuthControlsNavItem',

  /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
  components: {
    ProfileAvatar
  },

  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    mode: {
      type: String,
      default: 'dropdown' // varies between dropdown | collapse
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'auth-controls'
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * @returns {object|null}
     */
    storedUser() {
      return this.$store.getters['auth/user']
    },

    /**
     * @returns {boolean}
     */
    isStoredUserValid() {
      return !isNil(this.storedUser) && !isEmpty(this.storedUser)
    },

    /**
     * @returns {string}
     */
    firstName() {
      return this.isStoredUserValid && isString(this.storedUser.name)
        ? this.storedUser.name.split(' ')[0]
        : this.$t('login_as_placeholder')
    },

    /**
     * @returns {string}
     */
    userAvatar() {
      if (!this.isStoredUserValid) {
        return ''
      } else {
        return '' // Put avatar logic here
      }
    },

    /**
     * @returns {boolean}
     */
    isShowAddAnotherUserAction() {
      return this.$route.path !== '/login'
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    /**
     * @param {int} userId
     * @returns {boolean}
     */
    isUserIdCurrentlyLoggedIn(userId) {
      return this.isStoredUserValid && this.storedUser.id === userId
    },

    /**
     * @param {int} type
     * @returns {string}
     */
    getUserType(type) {
      if (type === 2) return this.$t('customer')
      else if (type === 3) return this.$t('translator')
    },

    /**
     * @param {int} userId
     * @returns {void}
     */
    handleClickSwitchUser(userId) {
      if (!this.isUserIdCurrentlyLoggedIn(userId)) {
        this.$store.dispatch('auth/switchAuthUser', userId, { root: true })
      } else {
        this.$router.push('/profile')
      }
    },

    /**
     * @param {int} userId
     * @returns {void}
     */
    handleClickAuthenticatedUserLogout(userId) {
      // This is the correct position for this line.
      const hasUserBeenLoggedIn = this.isUserIdCurrentlyLoggedIn(userId)
      this.$store.dispatch('auth/removeFromAuthCollection', userId, {
        root: true
      })
      const isEmptyAuthenticatedUsers = isEmpty(this.$store.getters['auth/authenticatedUsers'])

      if (hasUserBeenLoggedIn) {
        if (isEmptyAuthenticatedUsers) {
          this.$router.push({ path: '/login' })
        } else {
          this.$router.push({ path: '/login/switch-user' })
        }
      }
    },

    /**
     * @returns {void}
     */
    handleClickAddAnotherUser() {
      this.$router.push('/login')
    },

    handleClickLogoutAll() {
      this.$confirm(this.$t('auth_are_you_sure_logout_all_users'), {
        type: 'warning',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      })
        .then(() => {
          const path = this.getLocaleRoutePath('/logout-all', this)
          this.$router.push({ path })
        })
        .catch((e) => {
          if (e != null) throw e
          console.log('Logout all users cancelled')
        })
    }
  }
}
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
.auth-controls__menu-item__auth-controls {
  height: auto;
}

.auth-controls {
  width: 100%;
  padding-right: 0px;
  &__dropdown {
    &-button {
      & > div {
        display: inline-block;
      }
    }

    &-icon {
      font-size: 12px !important;
    }

    &-name {
      font-size: 13px;
      position: relative;
    }

    &-avatar,
    &-avatar .profile-avatar,
    &-avatar .profile-avatar img {
      width: 24px;
      height: 24px;
    }

    &-avatar .profile-avatar {
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      & > img {
        position: absolute;
        left: 0;
      }
    }
  }

  &__authenticated-user {
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    padding: 7px;
    transition-duration: 0.5s;

    &.unclickable {
      cursor: default;
    }

    &:last-child {
      padding-bottom: 7px;
      border-bottom: 0;
    }

    &-details,
    &-avatar {
      display: inline-block;
      vertical-align: middle;
    }

    &-avatar {
      margin-right: 12px;
    }

    &-avatar,
    &-avatar .profile-avatar,
    &-avatar .profile-avatar img {
      width: 24px;
      height: 24px;
    }

    &-avatar .profile-avatar {
      border-radius: 50%;
      overflow: hidden;
    }

    &-details {
      font-size: 12px;

      span {
        display: block;
        margin-bottom: 4px;
      }

      span:last-child {
        margin-bottom: 0;
      }
    }

    &-actions {
      text-align: right;
      padding-top: 3px;
      font-size: 12px;
    }

    &-action-logout {
      color: $app-secondary-color !important;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        color: $app-secondary-color !important;
      }
    }

    &:hover:not(.unclickable) {
      background-color: var(--app-primary-color) !important;
      color: #fff;
    }
  }

  &__popper {
    width: auto;
    min-width: 200px;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;

    &-body {
      min-height: 100px;
      max-height: 70vh;
      overflow-y: auto;
    }

    &-footer {
      border-top: 1px solid #eaeaea;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &-footer > div {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__add-another-user,
  &__logout-all {
    text-align: center;
    font-size: 11px;
  }

  &__logout-all {
    a,
    a:hover,
    a:focus,
    a:visited,
    a:active {
      color: $app-secondary-color !important;
    }
  }

  &__collapse {
    border: 0;

    .el-collapse-item {
      border: 0;
      background: transparent;
      color: #fff;

      &__header,
      &__wrap,
      &__content {
        @extend .el-collapse-item;
      }

      &__content {
        padding-top: 10px;
        /*padding-left: 20px;*/
        padding-bottom: 15px;
      }
    }

    &-avatar,
    &-avatar .profile-avatar,
    &-avatar .profile-avatar img {
      width: 24px;
      height: 24px;
    }

    &-avatar .profile-avatar {
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      & > img {
        position: absolute;
        left: 0;
      }
    }

    &-avatar {
      margin-right: 10px;
    }
  }
}

.auth-controls__authenticated-user-details {
  span {
    line-height: 1;
  }
}
</style>
