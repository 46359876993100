/*
|--------------------------------------------------------------------------
| Store > Multi-Tenancy > Mutations
|--------------------------------------------------------------------------
|
| This file is where the Module's mutation methods are registered.
|
| Feel free to edit this file if you have mutation methods you want
| to include.
|
*/

import appSessionStorage from '@/modules/helpers/appSessionStorage'
import LS from '@/modules/constants/localStorage'

export default {
  /**
   * Set the tenant loading state.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {boolean} payload - set true/false.
   * @returns {void}
   */
  setIsTenantLoading: function (state, payload) {
    state.isTenantLoading = payload
  },
  /**
   * Set the tenant domain extracted from URL.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {string} payload - tenantDomain.
   * @returns {void}
   */
  setTenantDomain: function (state, payload) {
    state.tenantDomain = payload
  },
  /**
   * Set the tenant.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Conatins tenant info... id, name, url.
   * @returns {void}
   */
  setTenant: function (state, payload) {
    state.tenant = {
      // id: payload.id,
      uuid: payload.uuid,
      name: payload.name,
      url: payload.url,
      api_endpoint: payload.api_endpoint,
      client_id: payload.client_id,
      client_secret: payload.client_secret,
      ws_url: payload.ws_url
    }
    appSessionStorage.set(LS.TENANT, state.tenant)
  },
  /**
   * Set the tenant config.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Conatins tenant config....
   * @returns {void}
   */
  setTenantConfig: function (state, payload) {
    state.tenantConfig = {
      logo: payload.logo,
      name: payload.name,
      phone: payload.phone,
      primary_color: payload.primary_color,
      secondary_color: payload.secondary_color,
      hero_text: payload.hero_text,
      timezone: payload.timezone,
      localization_languages: payload.localization_languages,
      default_locale: payload.default_locale,
      customer_contact: payload.customer_contact,
      interpreter_contact: payload.interpreter_contact,
      email: payload.email,
      address: payload.address,
      facebook_url: payload.facebook_url,
      instagram_url: payload.instagram_url,
      linkedin_url: payload.linkedin_url,
      client_logos: payload.client_logos,
      extra_data: payload.extra_data,
      // admin_url: payload.admin_url,
      scripts: payload.scripts,
      styles: payload.styles
    }
    appSessionStorage.set(LS.TENANT_CONFIG, state.tenantConfig)
  }
}
