/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load translator options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Object} payload.translator_name - Filter for getting translators with specific name(s).
 * @param {Object} payload.translator_id - Filter for getting translators with specific id(s).
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = API.TRANSLATORS + '-all'
  const method = 'GET'

  context.commit('auth/setTranslatorOptsLoading', true, { root: true })

  let options = {
    query_params: {
      'all': true,
      'fields[users]': 'id,name',
      'sort': 'name',
      'filter[enable]': 1
    },
    on_finally(ctx) {
      ctx.commit('auth/setTranslatorOptsLoading', false, { root: true })
    }
  }

  if (!isNil(payload.translator_name) && payload.translator_name !== '') {
    options.query_params['filter[name]'] = payload.translator_name
  }

  if (!isNil(payload.translator_id) && payload.translator_id !== '' && payload.translator_id !== 0) {
    options.query_params['filter[id]'] = payload.translator_id
  }

  return loadAll(this, context, url, method, 'setTranslatorOpts', options, axiosInstance)
}
