/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > checkTranslatorExpenseSettings
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @return {*}
 */
export default function (context, payload = {}) {
  if (isNil(payload.id)) console.error('payload.id is required!')

  const url = `${API.FINANCIAL}/${payload.id}/salary-setting?include=expense_setting,travel_setting`
  const method = 'get'
  let options = {
    on_success(r) {
      context.commit('setExpenseSettings', r.data.data.salaries_setting.expense_setting)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
