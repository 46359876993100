import { BOOKING_TIME_SLOT } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  const url = `${BOOKING_TIME_SLOT}`

  const method = 'get'
  let options = {
    enable_error_notif: false,
    query_params: {
      ...payload,
      ...{
        'include': 'translator.future_assigned_bookings,translator.translator_unavailable_times',
        'all': true,
        'sort': '-translator_id',
        'filter[is_valid]': true
      }
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
