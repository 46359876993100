import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { reach } from '@/modules/helpers/vuexActions'
export default function (context, payload = {}) {
  const method = 'POST'
  let url = API.CREATE_STATISTICS_SUBSCRIPTION
  let options = {
    data: payload,
    is_data_raw: true
  }

  return reach(this, context, url, method, options, axiosInstance)
}
