import LS from '@/modules/constants/localStorage'
import { SET_UI_URL } from '@/modules/constants/api'
import { axiosInstance, getBaseUrl } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '@/modules/helpers/appStorage'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import qs from 'qs'

async function setUiUrl({ next }) {
  const token = appStorage.get(LS.TOKEN)
  const isUserAuthenticated = !isNil(token) && !isNil(token.access_token) && !isEmpty(token.access_token)
  const hasBaseUrl = await getBaseUrl()

  if (isUserAuthenticated && hasBaseUrl) {
    let options = {
      url: `${SET_UI_URL}`,
      method: 'post',
      data: qs.stringify({
        'ui-url': window.location.href + window.location.search
      }),
      headers: {
        Authorization: 'Bearer ' + token.access_token
      }
    }

    axiosInstance(options)
  }

  return next()
}

export default setUiUrl
