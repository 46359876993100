function convertToMilliseconds(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number)
  return (hours * 60 + minutes) * 60 * 1000 // Convert to milliseconds
}

export default {
  getAvailableTranslatorDates: (state) => state.dates,
  getAvailableTranslatorDatesTimeSlots: (state) => state.timeSlots,
  getAssignmentProbability: (state) => state.assignmentProbabilities,
  isLoading: (state) => state.loading,
  isAvailableDateSelected: (state) => (selectedDate) => {
    return state.dates.includes(selectedDate)
  },
  isAvailableTimeSlotSelected: (state) => (selectedTimeSlot) => {
    // Convert start time and end time to milliseconds
    const startMilliseconds = convertToMilliseconds(selectedTimeSlot.start_time)
    const endMilliseconds = convertToMilliseconds(selectedTimeSlot.end_time)

    // Iterate over each timeslot
    for (let slot of state.timeSlots) {
      const slotStartMilliseconds = convertToMilliseconds(slot.start_time)
      const slotEndMilliseconds = convertToMilliseconds(slot.end_time)

      // Check if the given time range falls within the slot
      if (startMilliseconds >= slotStartMilliseconds && endMilliseconds <= slotEndMilliseconds) {
        return true // Found a matching slot
      }
    }
    return false // No matching slot found
  }
}
