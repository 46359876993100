/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > downloadPdf
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

import i18nClient from '@/locales/i18nClient.js'
const { t: $t } = i18nClient.global

/**
 * Action to fetch the booking details via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.booking_id - ID for the target entry.
 * @return {Promise|*}
 */
export default function (context, payload) {
  if (isNil(payload.booking_id)) throw new Error('Missing required value payload.booking_id!')
  if (isNil(window) || isNil(document)) {
    console.error('This feature is for browsers only')
    return
  }

  const filename = `invoice_${payload.booking_id}.pdf`
  const url = `${API.FINANCIAL}/${payload.booking_id}/invoice/pdf?path=${filename}`
  const method = 'GET'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    })
      .then((r) => {
        resolve(r)
        const blob = new Blob([r.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.click()
      })
      .catch((e) => {
        this.$notify.warning({
          title: $t('btn_costing') + ' ' + $t('error'),
          message: $t('something_went_wrong') + ' ' + $t('please_contact_your_administrator'),
          duration: 3000
        })
        reject(e)
      })
  })
}
