/*
|--------------------------------------------------------------------------
| Store > Mutations
|--------------------------------------------------------------------------
| File that contains the mutations property for the current Vuex scope.
*/
import appStorage from '@/modules/helpers/appStorage'
import includes from 'lodash/includes'

export default {
  /**
   * Method to set blog data
   * @param {*} state
   * @param {*} payload
   */

  setBlogDataList(state, payload) {
    // console.log(payload.data.data);
    state.blogs.data = payload.data.data.items
  },

  /**
   * Method to set blog data
   * @param {*} state
   * @param {*} payload
   */

  setBlogDataPagination(state, payload) {
    state.blogs.pagination = payload.data.meta.pagination
  },

  /**
   * Method to set the state.blogs.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setBlogsLoading(state, payload) {
    state.blogs.loading = payload
  },

  /**
   * Method to set featured blog data
   * @param {*} state
   * @param {*} payload
   */

  setFeaturedBlogDataList(state, payload) {
    // console.log(payload.data.data);
    state.featuredBlogs.data = payload.data.data.items
  },

  /**
   * Method to set the state.blogs.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setFeaturedBlogsLoading(state, payload) {
    state.featuredBlogs.loading = payload
  },
  /**
   *
   * @param state
   * @param payload
   */
  setBlogLoading(state, payload) {
    if (!state.blog) {
      state.blog = { loading: false }
    }
    state.blog.loading = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setBlog(state, payload) {
    state.blog = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  resetBlog(state) {
    state.blog = {}
  },

  /**
   * Setting critical Blog
   * @param state
   * @param payload
   */
  setCriticalBlogDataList(state, payload) {
    state.criticalBlogs = payload?.data?.data?.items || []
    state.loadingCriticalBlogs = false
  },
  /**
   * Checking if already seen in local storage.
   * @param state
   * @param blogList
   */
  checkIsAlreadySeenInCookie(state, blogList) {
    state.criticalBlogs.blog = blogList
      .map((blog) => {
        let seen = appStorage.get('blog_seen')
        let find = includes(seen, blog.id)
        if (!find) {
          return blog
        }
      })
      .filter((items) => {
        if (items === undefined) {
          return false
        } else {
          return items
        }
      })
    if (state.criticalBlogs.blog.length > 0) {
      state.isShowCookieAlert = true
    }
  },
  /**
   *
   * @param state
   * @param payload
   */
  setIsShowCookieAlert(state, payload) {
    state.isShowCookieAlert = payload
  }
}
