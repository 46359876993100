<template>
  <el-dialog
    v-model="showModal"
    :title="modalTitle"
    class="app-modal-popover"
    :style="{ 'max-height': '70vh', 'max-width': '20vw', 'bottom': '0', 'left': '0' }"
    :append-to-body="true"
  >
    <div class="dialog-content-wrapper">
      <div class="image-container">
        <img src="@/assets/images/rebrand/popover-icon.png" width="100" :alt="$t('inbox_label')" />
      </div>
      <div class="text-container">
        <div class="welcome-title">{{ $t('Hey', { user: user.name }) }}!</div>
        <h3 class="text-color-default">
          {{ $t('you_have_surveys_waiting_for_your_attention', { count: surveys.length }) }}
        </h3>
        <div slot="footer" class="dialog-footer text-center">
          <el-button @click="close">
            {{ $t('remind_me_later') }}
          </el-button>
          <el-button type="primary" class="app-button-secondary" @click="handleClickSurvey">
            {{ $t('go_to_surveys') }}
            <!-- ADD VERBIAGE -->
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SurveysPopover',
  data() {
    return {
      blockClass: 'inbox-modal',
      modalTitle: '',
      showModal: false
    }
  },
  computed: {
    ...mapState('auth', ['user', 'surveys']),
    ...mapState('email', ['emails'])
  },
  methods: {
    ...mapActions('email', ['fetchEmails']),
    ...mapActions('auth', ['getSurveys']),

    open() {
      this.showModal = true
      this.getSurveys()
    },
    close() {
      this.showModal = false
    },

    handleClickSurvey() {
      this.close()
      this.getSurveys().then(() => {
        this.$emit('openSurveyModal')
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.app-modal-popover {
  position: fixed !important;
  bottom: 5vh !important;
  left: 4vw !important;
  width: auto !important; /* Remove the width constraint */
  max-width: none !important; /* Remove the max-width constraint */
  margin: 0 !important;
  .welcome-title {
    font-size: 1.2em; /* Adjust the font size as needed */
  }

  h3 {
    font-size: 1.2em; /* Adjust the font size as needed */
  }

  .inbox-modal {
    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__text {
      text-align: center;
      .welcome-title {
        font-size: 1em; /* Adjust the font size as needed */
      }

      h3 {
        font-size: $app-h3-size - 5; /* Adjust the font size as needed */
      }
    }
  }
  .el-dialog__body,
  .el-dialog__header {
    border-left: 5px solid $app-primary-color;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__header .el-dialog__headerbtn {
    // display: none;
  }
  .dialog-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-container {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 10px; /* Adjust the margin as needed */
    img {
      max-width: 100%; /* Make the image responsive within its container */
      height: auto; /* Maintain the aspect ratio */
      display: block; /* Remove extra space beneath the image */
      margin-right: 10px; /* Adjust the margin as needed */
    }
  }

  .text-container {
    // flex: 2;
    justify-content: baseline;
    text-align: left;
    max-width: 20vw;
  }
  .el-dialog__headerbtn {
    .el-dialog {
      &__close {
        color: $app-secondary-color;
        font-size: 20px;

        &:hover,
        &:focus {
          color: $app-secondary-color;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    align-self: baseline;
    width: 100%;
    @media (max-width: 1600px) {
      flex-direction: column;
      align-items: center;

      .el-button {
        margin-bottom: 10px; // Adjust as needed
      }
    }
  }
}
</style>
