<template>
  <iframe
    v-if="isProduction"
    v-bind="$attrs"
    ref="srcIframe"
    class="test-iframe"
    :src="urlSrc"
    frameborder="0"
  ></iframe>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  props: {
    iframeSrc: {
      type: String,
      default: 'https://www.digitaltolk.se'
      // default: 'https://app.digitaltolk.se'
    }
  },
  data() {
    return {
      // iframeSrc: 'https://www.digitaltolk.se'
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserAuthenticated', 'isUserCustomer', 'user', 'userMetaData']),
    urlSrc() {
      return this.iframeSrc
    },
    userType() {
      return this.isUserAuthenticated && this.isUserCustomer ? 'customer' : 'interpreter'
    },
    userName() {
      return this.isUserAuthenticated ? this.user.name : ''
    },
    municipalityId() {
      return this.isUserAuthenticated && this.isUserCustomer
        ? this.userMetaData?.customer_data?.department?.company?.municipality_id
        : 0
    },
    isProduction() {
      return import.meta.env.VITE_APP_ENVIRONMENT === 'production'
    }
  },
  methods: {
    handleTrigger(payload) {
      // console.log('isProduction', this.isProduction)
      console.log('payload', payload)
      if (!this.isProduction) return

      const iframe = this.$refs['srcIframe']
      const iframeWindow = iframe.contentWindow

      iframeWindow?.postMessage(payload, this.urlSrc)
    },
    userLoginFromNonLoginEnvironment() {
      console.log('userMetaData', this.userMetaData)

      let payload = {
        type: this.userType,
        isLoggedIn: 1,
        name: this.userName,
        municipality_id: this.municipalityId
      }

      this.handleTrigger(payload)
    },

    userLogOutFromNonLoginEnvironment() {
      let payload = {
        isLoggedIn: 0
      }

      this.handleTrigger(payload)
    }
  }
}
</script>
<style lang="scss">
.test-iframe {
  display: none;
}
</style>
