/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > editFilesUsers
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for editing user files.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.file_id - User file id.
 * @returns {*}
 */
export default function (context, payload) {
  const method = 'PUT'
  const url = `${API.USERS}/${payload.file_id}/files`
  let options = {
    data: payload.data,
    is_data_raw: true
  }

  return reach(this, context, url, method, options, axiosInstance)
}
