import { reach } from '@/modules/helpers/vuexActions'
import { SALARY_FILES as API_SALARY_FILES } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function loadTranslatorSalaryFiles(context) {
  return reach(
    this,
    context,
    API_SALARY_FILES,
    'GET',
    {
      query_params: {
        all: true
      },
      on_success(r, ctx) {
        ctx.state.translatorSalaryFiles = r.data.data.salary_files
      }
    },
    axiosInstance
  )
}

export default loadTranslatorSalaryFiles
