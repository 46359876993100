<template>
  <div class="app-form" :class="getBem(blockClass)">
    <el-form ref="form" :model="iValue" :rules="formRules">
      <!-- Session Time -->
      <el-form-item
        :class="[getBem(blockClass, ['session-time-container'])]"
        :label="$t('booking_session_time')"
        prop="session_time"
      >
        <el-input
          v-model="iValue.session_time"
          :class="getBem(blockClass, ['session-time-field'])"
          placeholder="00:00"
          :tabindex="0"
        >
          <template #append> hh:mm:ss </template>
        </el-input>
      </el-form-item>

      <!-- Comment Field -->
      <el-form-item :class="getBem(blockClass, ['comment-container'])" :label="$t('comment')" prop="comment">
        <el-input
          v-model="iValue.comment"
          :class="getBem(blockClass, ['comment-field'])"
          type="textarea"
          :rows="4"
          :tabindex="0"
        />
      </el-form-item>
    </el-form>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import moment from 'moment-timezone'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    /**
     * Expected Object properties are:
     *
     * session_time: {String}
     * comment: {String}
     */
    modelValue: {
      type: Object,
      required: true
    }
  },

  emits: ['update:modelValue'],

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'booking-session-time-form',
      isValidated: false
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component >
  |--------------------------------------------------------------------------
  */
  computed: {
    /**
     * Interface for this.value.
     */
    iValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    /**
     * Returns validation rules for the form.
     *
     * @returns {Object}
     */
    formRules() {
      return {
        session_time: [
          {
            required: true,
            message: this.getRequiredRuleMsg('session_time'),
            trigger: 'change'
          },
          { validator: this.validateTimeFormat, trigger: 'change' }
        ]
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    doSomeThing() {
      console.log(`Doing Somehing...`)
      return 'This is returned value...'
    },

    /**
     * Method to validate the form.
     *
     * @returns {Boolean}
     */
    validate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isValidated = true
        } else {
          this.isValidated = false
        }
      })
    },

    /**
     * Helper method for producing a required rules message.
     *
     * @param key - Key for i18n locale.
     * @returns {string}
     */
    getRequiredRuleMsg(key) {
      const value = this.$t(key).toLowerCase()
      return this.$t('is_required', { value })
    },

    getInvalidFormatMsg(key) {
      const value = String(this.$t(key)).toLowerCase()
      return this.$t('format_invalid', { value })
    },

    /**
     * Helper for required fields condition.
     *
     * @param {*} v - value to be evaluated.
     * @returns {boolean}
     */
    requiredCondition(v) {
      return !isNil(v) && trim(v) !== ''
    },

    /**
     * @param v
     * @returns {boolean}
     */
    validateTimeFormat(rule, value, callback) {
      const isValidTime = moment(value, 'HH:mm:ss', true).isValid()

      if (!isValidTime) {
        callback(new Error(this.getInvalidFormatMsg('session_time')))
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
</style>
