import getTranslatorAvailableBookingDates from './getTranslatorAvailableBookingDates'
import getTranslatorAvailableBookingDatesTimeSlots from './getTranslatorAvailableBookingDatesTimeSlots'
import getBookingAssignmentProbability from './getBookingAssignmentProbability'
import postTranslatorAlternativeTime from './postTranslatorAlternativeTime'
import getTranslatorAlternativeTime from '@/modules/booking/store/translator-time-slots/actions/getTranslatorAlternativeTime'
import postAcceptAlternativeTime from '@/modules/booking/store/translator-time-slots/actions/postAcceptAlternativeTime'
import updateTranslatorAlternativeTime from '@/modules/booking/store/translator-time-slots/actions/updateTranslatorAlternativeTime'
export default {
  getTranslatorAvailableBookingDates,
  getTranslatorAvailableBookingDatesTimeSlots,
  getBookingAssignmentProbability,
  postTranslatorAlternativeTime,
  getTranslatorAlternativeTime,
  postAcceptAlternativeTime,
  updateTranslatorAlternativeTime
}
