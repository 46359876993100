/*
|--------------------------------------------------------------------------
| Store > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/
export default function () {
  return {
    blogs: {
      data: [],
      pagination: {},
      loading: true
    },
    featuredBlogs: {
      data: [],
      pagination: {},
      loading: true
    },
    criticalBlogs: {},
    loadingCriticalBlogs: true,
    isShowCookieAlert: false,
    blog: {
      loading: true
    }
  }
}
