/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadDepartmentUserOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the department opts.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @returns {Promise|*}
 */
export default function (context, payload = {}) {
  context.commit('auth/setCompanyOptsLoading', true, { root: true })

  let query_params = {}
  if (isNil(payload.municipality_id)) {
    query_params = {
      all: true
    }
  } else {
    query_params = {
      all: true,
      municipality_id: payload.municipality_id
    }
  }

  const url = `${API.COMPANIES}-get-for-ct`
  const method = 'GET'

  let options = {
    query_params,
    on_finally(ctx) {
      ctx.commit('auth/setCompanyOptsLoading', false, { root: true })
    }
  }

  return loadAll(this, context, url, method, 'setCompanyOpts', options, axiosInstance)
}
