import browse from './browse'
import create from './create'
import notifyAdmin from './notifyAdmin'
import show from './show'
import downloadFile from './downloadFile'
import submitTranslatedFile from './submitTranslatedFile'
import submitTranslationMode from './submitTranslationMode'
import startTranslationStatus from './startTranslationStatus'
import loadCustomerNormalBookings from './loadCustomerNormalBookings'
import loadTranslatorNormalBookings from './loadTranslatorNormalBookings'
import acceptPrice from './acceptPrice'
import requestResubmitFiles from './requestResubmitFiles'
import resubmitTranslatedFile from './resubmitTranslatedFile'
import rejectPrice from './rejectPrice'
import loadTranslatorHistoricBookings from './loadTranslatorHistoricBookings'
import loadCustomerHistoricBookings from './loadCustomerHistoricBookings'
import validateBooking from './validateBooking'
import submitBooking from './submitBooking'
import submitMultipleBooking from './submitMultipleBooking'
import submitInstantTextBooking from './submitInstantTextBooking'
import deleteFile from './deleteFile'
import startJob from './startJob'
import validateDuplicatedBooking from '@/modules/booking/store/text-translation/actions/validateDuplicatedBooking'
import loadBookingsGeneric from './loadBookingsGeneric'
import getCustomerAwaitingQuotes from './getCustomerAwaitingQuotes'
import getTextTranslationCostEstimation from './getTextTranslationCostEstimation'
import storeBookingAttempt from './storeBookingAttempt'
import getTextTranslationTestUrl from './getTextTranslationTestUrl'
import validateTextTranslator from './validateTextTranslator'
import getCustomerMarketings from './getCustomerMarketings'
import acknowledgeMarketingSchedule from './acknowledgeMarketingSchedule'
import checkDeepLBookingStatus from './checkDeepLBookingStatus'
import downloadDeepLBookingFiles from './downloadDeepLBookingFiles'
import logFileUploadError from './logFileUploadError'
import getDocumentsLanguageResults from './getDocumentsLanguageResults'
import getTextTranslationExpressHours from './getTextTranslationExpressHours'
import getTextTranslationSuggestedDeadlines from './getTextTranslationSuggestedDeadlines'
import submitTranslatorFeedback from './submitTranslatorFeedback'
import getTranslatorFeedbackByBookingId from './getTranslatorFeedbackByBookingId'
import requestQuote from './requestQuote'

export default {
  browse,
  create,
  notifyAdmin,
  show,
  downloadFile,
  submitTranslatedFile,
  submitTranslationMode,
  startTranslationStatus,
  acceptPrice,
  rejectPrice,
  loadCustomerNormalBookings,
  loadTranslatorNormalBookings,
  loadTranslatorHistoricBookings,
  loadCustomerHistoricBookings,
  validateBooking,
  validateDuplicatedBooking,
  submitBooking,
  deleteFile,
  submitMultipleBooking,
  submitInstantTextBooking,
  startJob,
  requestResubmitFiles,
  resubmitTranslatedFile,
  loadBookingsGeneric,
  getCustomerAwaitingQuotes,
  getTextTranslationCostEstimation,
  storeBookingAttempt,
  getTextTranslationTestUrl,
  validateTextTranslator,
  getCustomerMarketings,
  acknowledgeMarketingSchedule,
  checkDeepLBookingStatus,
  downloadDeepLBookingFiles,
  logFileUploadError,
  getDocumentsLanguageResults,
  getTextTranslationExpressHours,
  getTextTranslationSuggestedDeadlines,
  submitTranslatorFeedback,
  getTranslatorFeedbackByBookingId,
  requestQuote
}
