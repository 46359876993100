import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  context.commit('setSubmitLoading', true)

  const url = `${API.BOOKINGS}/${payload.id}/comments`
  const method = 'post'

  let data = {
    comment: payload.comment
  }

  let options = {
    data,
    is_data_raw: true,
    on_finally(ctx) {
      ctx.commit('setSubmitLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
