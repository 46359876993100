import cloneDeep from 'lodash/cloneDeep'
import { listSetter } from '@/modules/helpers/vuexMutations'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import remove from 'lodash/remove'
import find from 'lodash/find'
import includes from 'lodash/includes'
import assign from 'lodash/assign'
import unset from 'lodash/unset'
import { getCachedBooking } from '@/modules/booking/services/cachedBookingService'
import moment from 'moment-timezone'

/**
 * Helper for setting default values for q_specifics.
 *
 * @param {object} state - The state property of the current Vuex module.
 * @param {string} key - Reference for the q_specific object property.
 * @returns {void}
 */
function setDefsForSpecifics(state, key) {
  const v = state.confirmFormPayload.q_specifics[key]
  state.confirmFormPayload.q_specifics[key] = isNil(v) ? false : v
}

function safeDueDate(date) {
  const dueDate = moment(date, 'YYYY-MM-DD')
  const today = moment()

  return today.isAfter(dueDate) ? today.format('YYYY-MM-DD') : date
}
export default {
  /**
   * Set value for state.isLoadingSubmit.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setSubmitLoading(state, payload) {
    state.isLoadingSubmit = payload
  },

  setSubmitError(state, payload) {
    state.submitError = payload
  },
  setTextTranslationList(state, payload) {
    listSetter(state, payload, {
      data: 'list.data',
      pagination: 'list.pagination'
    })
  },
  setTextTranslationListLoading(state, payload) {
    state.list.loading = payload
  },

  setItem(state, payload) {
    state.item = payload
  },

  setFormPayload(state) {
    if (isNil(state.bookingForm.time) || state.bookingForm.time === '') {
      state.bookingForm.time = '23:59:00'
    } else {
      state.bookingForm.time =
        state.bookingForm.time.length === 8 ? state.bookingForm.time : state.bookingForm.time + ':00'
    }

    state.bookingForm.create_booking_in_past = state.bookingForm.booking_is_past

    state.bookingForm.countries = state.bookingForm.dialect

    state.bookingForm.countries_required = state.bookingForm.dialect_reason
    state.bookingForm.due = state.bookingForm.date + ' ' + state.bookingForm.time
    state.formPayload = state.bookingForm

    state.formPayload.specific_translators = state.bookingForm.specific_translators
    // Set gender
    state.formPayload.gender =
      !state.formPayload.gender.length || state.formPayload.gender.length === 2 ? '' : state.formPayload.gender[0]
    // Set Language Pair Levels
    state.formPayload.language_pair_levels = state.bookingFormFresh.language_pair_levels.filter(
      (pairLevel) => pairLevel !== 'any-qualified'
    )
  },

  /** Reset state.form to pristine.*
   *
   @param {object} state - The state property of the current Vuex module.*@returns {void}
    */
  resetForm(state) {
    state.bookingForm = cloneDeep(state.bookingFormFresh)
  },
  /** Reset state.form to pristine.*
   *
   @param {object} state - The state property of the current Vuex module.*@returns {void}
  */
  resetAiForm(state) {
    state.aiBookingFormFresh = cloneDeep(state.aiBookingFormFresh)
  },

  /**
   * Set value for state.isLoadingValidate.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setValidateLoading(state, payload) {
    state.isLoadingValidate = payload
  },

  /**
   * Method to set the bcfConfig state.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setBcfConfig(state, payload) {
    if (!isNil(payload)) {
      state.bcfConfig = payload
    }

    const refPlaceholder = state.bcfConfig.reference_placeholder
    if (
      isNil(refPlaceholder) ||
      refPlaceholder === 'null' // Seems odd :(
    ) {
      state.bcfConfig.reference_placeholder = ''
    }
  },

  /**
   * Set the state.confirmSuggestions value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - The collection of confirmation_fills.
   * @returns {void}
   */
  setConfirmSuggestions(state, payload) {
    let keys = ['booker_name', 'staff_name', 'email', 'reference', 'translator_user'] // Set the keys to get the collection.

    forEach(keys, (k) => {
      // Get each collection of suggestions based on the key's given.
      // Make sure values are unique and without empty string.
      let formatted = map(payload, (item) => {
        return {
          value: item[k],
          id: item.id
        }
      })

      remove(formatted, (o) => o.value === '')

      state.confirmSuggestions[k] = formatted
    })
  },

  /**
   * Set the state.availableTranslators value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - The collection of booking_fields configurations.
   * @returns {void}
   */
  setAvailableTranslators(state, payload) {
    state.availableTranslators = payload
  },

  // --------------------------------------------------
  // Booking Confirm Related
  // --------------------------------------------------

  /**
   * @param {object} state - Vuex context's state.
   * @param {boolean} bool
   * @return {void}
   */
  setIsConfirmFormHalted(state, bool) {
    state.isConfirmFormHalted = bool
  },

  /**
   * @param {object} state - Vuex context's state.
   * @return {void}
   */
  cacheConfirmFormValues(state) {
    state.confirmFormCache = cloneDeep(state.confirmForm)
  },

  /**
   * Method to set the Confirm Values from the API to the confirmForm state.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @param {object} payload.data - The collection of confirmation_fills.
   * @param {object} payload.user - Contains the current user and its properties.
   * @returns {void}
   */
  setConfirmForm(state, payload = {}) {
    const appUser = JSON.parse(localStorage.getItem('app_user'))
    console.log(appUser)

    // Reset the form first.
    state.confirmForm = cloneDeep(state.confirmFormFresh)

    // If confirm form was halted after booking overview
    if (state.isConfirmFormHalted) {
      state.confirmForm = cloneDeep(state.confirmFormCache)
      return
    }

    let bookingCustomer = payload.user
    if (state.bookingForm.is_created_by_admin) {
      bookingCustomer = state.bookingForm.customer_object
    }

    // The collection of customer_notification_types.
    const customerNotificationTypes = bookingCustomer.customer_notification_types

    // Define helper for getting the notification type object in collection.
    let getNotif = (k) =>
      find(customerNotificationTypes, (o) => {
        return o.notification_type === k
      })

    // Set address
    // state.confirmForm.address = payload.user.customer_data.address;

    // Set city
    // state.confirmForm.city = payload.user.customer_data.city;

    // If fax exists from the response.
    const notifFax = getNotif('fax')
    if (!isNil(notifFax) && !isNil(notifFax.default_recipient)) {
      // Set fax
      state.confirmForm.notification_confirmation_via.fax = notifFax.default_recipient
    }
    // If sms exists from the response.
    const notifSms = getNotif('sms')
    if (!isNil(notifSms) && !isNil(notifSms.default_recipient)) {
      // Set sms
      state.confirmForm.notification_confirmation_via.sms = notifSms.default_recipient
    }

    const cachedBooking = getCachedBooking(bookingCustomer.id)

    // Set cached values
    if (!isNil(cachedBooking) && !isEmpty(cachedBooking)) {
      // Set booker_name
      state.confirmForm.booker_name = cachedBooking.booker_name

      // Set staff_name
      state.confirmForm.staff_name = cachedBooking.staff_name

      // Set staff_phone
      state.confirmForm.staff_phone = cachedBooking.staff_phone

      // Set reference
      state.confirmForm.reference = cachedBooking.reference

      // Set email
      if (!isNil(cachedBooking.notification_types) && !isEmpty(cachedBooking.notification_types)) {
        // If cached booking value exist. Assign the last value that was used.
        forEach(cachedBooking.notification_types, (o) => {
          if (o.type === 'email') {
            if (
              appUser?.is_test ||
              (!appUser?.is_test &&
                !includes(o.recipient, '@digitaltolk.se') &&
                !includes(o.recipient, '@digitaltolk.com'))
            ) {
              state.confirmForm.notification_confirmation_via.email.push(o.recipient)
            }
          }
        })
      } else {
        // Else, just to the usual and assign the default recipient.
        const notifEmail = getNotif('email')
        if (!isNil(notifEmail) && !isNil(notifEmail.default_recipient)) {
          if (
            !includes(notifEmail.default_recipient, '@digitaltolk.se') &&
            !includes(notifEmail.default_recipient, '@digitaltolk.com')
          ) {
            state.confirmForm.notification_confirmation_via.email.push(notifEmail.default_recipient)
          }
        }
      }
    }

    // If Email is still empty after assigning cached. Prefill with user's email.
    if (
      !isNil(state.confirmForm.notification_confirmation_via.email) &&
      isEmpty(state.confirmForm.notification_confirmation_via.email)
    ) {
      if (
        appUser?.is_test ||
        (!appUser?.is_test &&
          !includes(bookingCustomer.email, '@digitaltolk.se') &&
          !includes(bookingCustomer.email, '@digitaltolk.com'))
      ) {
        state.confirmForm.notification_confirmation_via.email.push(bookingCustomer.email)
      }
    }
  },

  /**
   * Set value for state.isShowBookingConfirmModal
   *
   * @param {object} state - State access of the current module scope.
   * @param {Boolean} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setIsShowBookingConfirmModal(state, payload) {
    state.isShowBookingConfirmModal = payload
  },
  /**
   * Set value for state.isShowBookingConfirmModal
   *
   * @param {object} state - State access of the current module scope.
   * @param {Boolean} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setIsShowManualPriceQuote(state, payload) {
    state.isShowManualPriceQuote = payload
  },

  /**
   * Mutation to set the boolean values related to q_specifics.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @returns {void}
   */
  setBoolsForSpecifics(state, payload) {
    const isNotEnoughTranslators = state.availableTranslators < 10

    // Translator
    state.confirmForm.q_specifics.has_shortage_translator = state.bookingForm.specific_translators.length > 0

    // Gender
    state.confirmForm.q_specifics.has_shortage_gender =
      isNotEnoughTranslators || (state.bookingForm.gender.length !== 2 && state.bookingForm.gender.length !== 0)

    // Physical
    state.confirmForm.q_specifics.has_shortage_physical =
      isNotEnoughTranslators && state.bookingForm.type === 'physical'

    // Accept another translator
    state.confirmForm.q_specifics.can_accept_another_translator = payload.can_accept_another_translator
  },

  /**
   * Method to prepare the payload before sending it tru API
   * for creating/submitting the booking.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @param {object} payload.user - Contains the current user and its properties.
   * @return {object}
   */
  setConfirmFormPayload(state, payload = {}) {
    if (isNil(payload.user)) console.error('payload.user is required.')

    state.confirmFormPayload = {} // Reset the payload.
    let bookingCustomer = payload.user
    if (state.bookingForm.is_created_by_admin) {
      bookingCustomer = state.bookingForm.customer_object
    }

    // Define the key : value to be unset. Most of this came from state.confirmForm.
    let unsets = ['notification_confirmation_via', 'notification_type', 'allow_change_type']

    // Map formPayload and confirmForm into payload container.
    assign(state.confirmFormPayload, state.formPayload)
    assign(state.confirmFormPayload, state.confirmForm)

    // Assign the customer related values.
    state.confirmFormPayload.customer_id = bookingCustomer.id
    state.confirmFormPayload.created_by = payload.user.id

    // Set Status ID to PENDING
    state.confirmFormPayload.status_id = 1 // PENDING

    // Set notification types.
    // As of the moment, this is between email|fax
    let notif_type = state.confirmForm.notification_type

    forEach(notif_type, (nt) => {
      if (nt == 'email') {
        let emails = state.confirmForm.notification_confirmation_via.email // Collection of emails.

        // Instantiate the container if it's undefined.
        state.confirmFormPayload.notification_types = isNil(state.confirmFormPayload.notification_types)
          ? []
          : state.confirmFormPayload.notification_types

        forEach(emails, (email) => {
          state.confirmFormPayload.notification_types.push({
            type: 'email',
            recipient: email
          })
        })
      } else if (nt == 'fax') {
        state.confirmFormPayload.notification_types.push({
          type: 'fax',
          recipient: state.confirmForm.notification_confirmation_via['fax']
        })
      } else {
        state.confirmFormPayload?.notification_types?.push({
          type: 'sms',
          recipient: state.confirmForm.notification_confirmation_via['sms']
        })
      }
    })

    // Remain / Remove values according to their switches.
    forEach(['booker_name', 'staff_name', 'reference', 'instructions'], (v) => {
      if (!state.bcfConfig[v + '_enabled']) unsets.push(v)
    })

    // Unset fallback type if it's not allowed.
    if (
      !payload.isGuestMode &&
      (!bookingCustomer.customer_data.allow_fallback_type || !state.confirmFormPayload.allow_change_type)
    ) {
      unsets.push('fallback_type')
    }

    // Remove unnecessary keys
    forEach(unsets, (v) => unset(state.confirmFormPayload, v))

    // Set the q_specifics default values if there are not defined in the UI.
    setDefsForSpecifics(state, 'translator')
    setDefsForSpecifics(state, 'gender')
    setDefsForSpecifics(state, 'translator_level')

    //append default video_provider if type = video
    if (state.confirmFormPayload.type === 'video') {
      state.confirmFormPayload.video_provider = 'jitsi'
    }
  },

  /**
   * Method to set the createdBooking state.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value to be assigned.
   *
   * @return {void}
   */
  setCreatedBooking(state, payload) {
    state.createdBooking = payload
  },

  /**
   * Method to set the createdBooking state.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value to be assigned.
   *
   * @return {void}
   */
  setCreatedBookings(state, payload) {
    state.createdBookings.push(payload)
  },
  resetCreatedBookings(state) {
    state.createdBookings = []
  },

  // --------------------------------------------------
  // Resets
  // --------------------------------------------------

  // /**
  //  * Reset state.form to pristine.
  //  *
  //  * @param {object} state - The state property of the current Vuex module.
  //  * @returns {void}
  //  */
  // resetForm(state) {
  //   state.form = cloneDeep(state.formFresh);
  // },

  /**
   * Reset state.formPayload to pristine.\
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetFormPayload(state) {
    state.formPayload = {}
  },

  /**
   * Reset state.confirmForm to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetConfirmForm(state) {
    state.confirmForm = cloneDeep(state.confirmFormFresh)
  },

  /**
   * Reset state.confirmFormPayload to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetConfirmFormPayload(state) {
    state.confirmFormPayload = {}
  },

  /**
   * Method to set the state.customerNormalBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerNormalBookingListLoading(state, payload) {
    state.customerNormalBookingList.loading = payload
  },

  /**
   * Method to set the state.customerNormalBookingList.data and
   * state.customerNormalBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerNormalBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerNormalBookingList.data',
      pagination: 'customerNormalBookingList.pagination'
    })
  },

  // --------------------------------------------------
  // Translator Normal Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.translatorNormalBookingList.data and
   * state.translatorNormalBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorNormalBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'translatorNormalBookingList.data',
      pagination: 'translatorNormalBookingList.pagination'
    })
  },

  /**
   * Method to set the state.translatorNormalBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorNormalBookingListLoading(state, payload) {
    state.translatorNormalBookingList.loading = payload
  },

  // --------------------------------------------------
  // Customer Historic Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.customerHistoricBookingList.data and
   * state.customerHistoricBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerHistoricBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerHistoricBookingList.data',
      pagination: 'customerHistoricBookingList.pagination'
    })
  },

  /**
   * Method to set the state.customerHistoricBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerHistoricBookingListLoading(state, payload) {
    state.customerHistoricBookingList.loading = payload
  },

  // --------------------------------------------------
  // Translator Historic Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.translatorHistoricBookingList.data and
   * state.translatorHistoricBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorHistoricBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'translatorHistoricBookingList.data',
      pagination: 'translatorHistoricBookingList.pagination'
    })
  },

  /**
   * Method to set the state.translatorHistoricBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorHistoricBookingListLoading(state, payload) {
    state.translatorHistoricBookingList.loading = payload
  },

  setDuplicatedFields(state, payload) {
    state.duplicateFields = payload
  },

  setValues(state, payload) {
    let b = payload

    if (includes(state.duplicateFields, 'language')) {
      state.bookingForm.from_language_id = b.text_translation.from_language_id
      state.bookingForm.to_languages = []
      // state.bookingForm.to_language_id.push(b.text_translation.to_language_id.toString())
      state.bookingForm.to_languages.push(b.text_translation.to_language_id.toString())
    }

    if (includes(state.duplicateFields, 'date')) {
      let date = safeDueDate(b.due_date)
      state.bookingForm.date = date
      state.duplicatedBooking.date = date
    }

    if (includes(state.duplicateFields, 'time')) {
      const timeSplit = b.due_time.split(':')
      const time = `${timeSplit[0]}:${timeSplit[1]}`

      state.bookingForm.time = time
      state.duplicatedBooking.time = time
    }

    if (includes(state.duplicateFields, 'is_express')) {
      state.bookingForm.is_express = b.text_translation.is_express
      state.duplicatedBooking.is_express = b.text_translation.is_express
    }
    if (includes(state.duplicateFields, 'deliver_with')) {
      state.bookingForm.deliver_with = Number(b.text_translation.deliver_with)
      state.duplicatedBooking.deliver_with = Number(b.text_translation.deliver_with)
    }

    if (includes(state.duplicateFields, 'is_stamped')) {
      state.bookingForm.is_stamped = Number(b.text_translation.is_stamped)
      state.duplicatedBooking.is_stamped = Number(b.text_translation.is_stamped)
    }

    if (includes(state.duplicateFields, 'is_confidential')) {
      state.bookingForm.is_confidential = Number(b.text_translation.is_confidential)
      state.duplicatedBooking.is_confidential = Number(b.text_translation.is_confidential)
    }

    if (includes(state.duplicateFields, 'phone')) {
      state.bookingForm.phone = b.text_translation.phone
      state.duplicatedBooking.phone = b.text_translation.phone
    }

    if (includes(state.duplicateFields, 'deliver_with')) {
      state.bookingForm.deliver_with = b.text_translation.deliver_with
      state.duplicatedBooking.deliver_with = b.text_translation.deliver_with
    }
    if (includes(state.duplicateFields, 'additional_text')) {
      state.bookingForm.additional_text = b.text_translation.additional_text
      state.duplicatedBooking.additional_text = b.text_translation.additional_text
    }

    if (includes(state.duplicateFields, 'descriptions')) {
      state.bookingForm.descriptions = b.text_translation.descriptions
      state.duplicatedBooking.descriptions = b.text_translation.descriptions
    }

    if (includes(state.duplicateFields, 'booker_name')) {
      if (!isNil(b.booker_name)) {
        state.duplicatedBooking.booker_name = b.booker_name
      }
    }

    if (includes(state.duplicateFields, 'staff_name')) {
      if (!isNil(b.staff_name)) {
        state.duplicatedBooking.staff_name = b.staff_name
      }
    }

    if (includes(state.duplicateFields, 'staff_phone')) {
      if (!isNil(b.staff_phone)) {
        state.duplicatedBooking.staff_phone = b.staff_phone
      }
    }

    if (includes(state.duplicateFields, 'reference')) {
      if (!isNil(b.reference)) {
        state.duplicatedBooking.reference = b.reference
      }
    }

    if (includes(state.duplicateFields, 'notification_confirmation')) {
      const notifs = b.notification_types

      console.log(notifs)
      if (!isNil(notifs) && !isEmpty(notifs)) {
        const types = map(notifs, (n) => n.type)
        let emails = []
        let fax = ''
        let sms = ''

        // Collect the recipients.
        forEach(notifs, (n) => {
          if (n.type === 'email') {
            emails.push(n.recipient)
          } else if (n.type === 'fax') {
            fax = n.recipient
          } else if (n.type === 'sms') {
            sms = n.recipient
          }
        })

        state.duplicatedBooking.notification_confirmation_via = {}
        state.confirmForm.notification_confirmation_via = {}

        // modify booking type if created by colleuage
        // if (b.is_created_by_colleague) {
        //   this.form.type = 'colleague';
        //   state.duplicatedBooking.type = 'colleague';
        // }

        // Set the values to the container.
        if (includes(types, 'email')) {
          state.duplicatedBooking.notification_type = 'email'
          state.duplicatedBooking.notification_confirmation_via.email = emails
        } else if (includes(types, 'fax')) {
          state.duplicatedBooking.notification_type = 'fax'
          state.duplicatedBooking.notification_confirmation_via.fax = fax
        } else if (includes(types, 'sms')) {
          state.duplicatedBooking.notification_type = 'sms'
          state.duplicatedBooking.notification_confirmation_via.sms = sms
        }
      }
    }
  },

  /**
   * Method to set the Duplicated Confirm Values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  setDuplicatedConfirmForm(state) {
    const sd = (v, d) => (!isNil(v) ? v : d)
    const src = state.duplicatedBooking

    // Reset the form first.
    state.confirmForm = cloneDeep(state.confirmFormFresh)
    state.confirmForm.is_duplicate = true

    if (!isNil(src.message_files)) {
      state.confirmForm.booking_message = src.message_files
    }

    state.confirmForm.booker_name = sd(src.booker_name, '')
    state.confirmForm.staff_name = sd(src.staff_name, '')
    state.confirmForm.staff_phone = sd(src.staff_phone, '')
    state.confirmForm.reference = sd(src.reference, '')
    state.confirmForm.address = sd(src.address, '')
    state.confirmForm.city = sd(src.city, '')
    state.confirmForm.instructions = sd(src.instructions, '')
    state.confirmForm.notification_type = sd(src.notification_type, '')

    if (
      !isNil(src.notification_confirmation_via) &&
      !isNil(src.notification_confirmation_via.email) &&
      !isEmpty(src.notification_confirmation_via.email)
    ) {
      state.confirmForm.notification_confirmation_via.email = src.notification_confirmation_via.email
    }

    if (!isNil(src.notification_confirmation_via) && !isNil(src.notification_confirmation_via.fax)) {
      state.confirmForm.notification_confirmation_via.fax = src.notification_confirmation_via.fax
    }

    if (!isNil(src.notification_confirmation_via) && !isNil(src.notification_confirmation_via.sms)) {
      state.confirmForm.notification_confirmation_via.sms = src.notification_confirmation_via.sms
    }
  },

  /**
   * Mutation to set the boolean values related to q_specifics.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @returns {void}
   */
  setTranslatorShortages(state, payload) {
    const hasShortageIn = payload.translator_shortages

    state.translatorShortages.count = hasShortageIn.count
    state.translatorShortages.specific_translator = hasShortageIn.specific_translator
    state.translatorShortages.gender = hasShortageIn.gender
    state.translatorShortages.translator_level = hasShortageIn.translator_level
    state.translatorShortages.country = hasShortageIn.country
    state.translatorShortages.physical = hasShortageIn.physical
  },

  /**
   * Mutation to set the language pair levels
   * @param {object} state
   * @param {Array} payload
   * @returns {void}
   */
  setLanguagePairLevel(state, payload) {
    state.bookingFormFresh.language_pair_levels = payload
  },

  /**
   * Mutation to set awaiting quotes
   * @param {object} state
   * @param {Array} payload
   */
  setAwaitingQuotes(state, payload) {
    state.awaitingQuotes = payload
  },

  /**
   * Mutation to set cost estimation payload
   * @param {object} state
   * @param {object} payload
   */
  setCostEstimationPayload(state, payload) {
    state.costEstimationPayload = payload
  },

  /**
   * Mutation to set cost estimations
   * @param {object} state
   * @param {object} payload
   */
  setCostEstimations(state, payload) {
    state.costEstimations.push(payload)
  },

  /**
   * Mutation to re set cost estimations
   * @param {object} state
   * @param {object} payload
   */
  resetCostEstimations(state) {
    state.costEstimations = []
  },

  /**
   * Mutation to set cost booking attempt payload
   * @param {object} state
   * @param {object} payload
   */
  setBookingAttemptPayload(state, payload) {
    state.bookingAttemptPayload = payload
  },

  /**
   * Mutation to set cost booking attempt payload
   * @param {object} state
   * @param {object} payload
   */
  setTextTranslationTestUrl(state, payload) {
    state.textTranslationTestUrl = payload
  },
  setManualSubmissionTillDate(state, payload) {
    state.manualSubmissionTillDate = payload
  },
  setManualSubmissionEnabled(state, payload) {
    state.manualSubmissionEnabled = payload
  },
  /**
   * Mutation to set cost booking attempt payload
   * @param {object} state
   * @param {object} payload
   */
  setValidTextTranslator(state, payload) {
    state.isValidTextTranslator = payload
  },
  /**
   * Mutation to set Customer Marketings
   * @param {object} state
   * @param {object} payload
   */
  setCustomerMarketings(state, payload) {
    state.customerMarketings = payload
  },
  /**
   * Mutation to set Documents Language Results
   * @param {object} state
   * @param {object} payload
   */
  setDocumentsLanguageResults(state, payload) {
    state.documentsLanguageResults = payload
  },
  /**
   * Mutation to set Suggested Deadlines
   * @param {object} state
   * @param {object} payload
   */
  setTextTranslationSuggestedDeadlines(state, payload) {
    state.textTranslationSuggestedDeadlines = payload
  },
  /**
   * Mutation to set Express hours
   * @param {object} state
   * @param {object} payload
   */
  setTextTranslationExpressHours(state, payload) {
    state.textTranslationExpressHours = payload
  }
}
