import { BOOKINGS as API_BOOKINGS } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function cancelBookingDueToPreference(context, payload) {
  const url = `${API_BOOKINGS}/${payload.bookingId}/cancel-due-to-preference`
  const method = 'POST'
  return reach(
    this,
    context,
    url,
    method,
    {
      data: payload.data,
      query_params: {
        signature: payload.signature
      }
    },
    axiosInstance
  )
}

export default cancelBookingDueToPreference
