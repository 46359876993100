/*
|--------------------------------------------------------------------------
| Store > Booking List
|--------------------------------------------------------------------------
|
| This file is the entry point for the Vuex Module.
| Use this file to register the state, getters, mutations, and actions.
|
*/

import state from './state'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
