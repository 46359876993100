/*
|--------------------------------------------------------------------------
| Store > Mutations
|--------------------------------------------------------------------------
| File that contains the mutations property for the current Vuex scope.
*/

export default {
  /**
   * Method to set priceLists data
   * @param {*} state
   * @param {*} payload
   */

  setPriceListsData(state, payload) {
    state.priceLists.data = payload.data.data.price_list_documents
  },

  setPriceListsPagination(state, payload) {
    state.priceLists.pagination = payload.data.meta.pagination
  },

  /**
   *
   * @param state
   * @param payload
   */
  setPriceListsLoading(state, payload) {
    state.priceLists.loading = payload
  }
}
