import moment from 'moment-timezone'
import axios from 'axios'
import '@/locales/momentjs/sv'
import '@/locales/momentjs/nb'
import '@/locales/momentjs/da'

export default {
  install: (app, { store, router }) => {
    let storedLocale = localStorage.getItem('app_language')
    let emailsLocale = localStorage.getItem('emails')

    switch (storedLocale) {
      case 'no':
        moment.locale('nb')
        break
      case 'da':
        moment.locale('da')
        break
      case 'se':
        moment.locale('sv')
        break
      default:
        moment.locale('sv')
        break
    }

    if (emailsLocale) {
      store.commit('email/setEmailsData', emailsLocale)
    }

    if (store.getters['auth/isUserAuthenticated']) {
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            let user = store.getters['auth/user']
            store.dispatch('auth/removeFromAuthCollection', user.id, { root: true })
            router.push({ path: '/login' })
            // window.location.href = '/login';
          }
          return Promise.reject(error)
        }
      )
    }
  }
}
