import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function deleteTranslatorAvailableTimes(context, payload) {
  const url = `${API.TRANSLATOR_WEEKLY_SCHEDULE}/${payload.id}`
  const method = 'DELETE'
  context.commit('setLoadingForAvailableTime', true)
  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method
    })
      .then((r) => {
        context
          .dispatch('loadTranslatorAvailableTimes', {
            queryParams: {
              formatter: 'daily',
              include: 'days'
            }
          })
          .then(() => {
            context.commit('setLoadingForAvailableTime', false)
            context.commit('resetSheduleForm')
            context.commit('resetSheduleFormList')
            resolve(r) // Resolve with the result of deleteTranslatorAvailableTimes
          })
      })
      .catch((e) => {
        context.commit('setLoadingForAvailableTime', false)
        reject(e)
      })
  })
}

export default deleteTranslatorAvailableTimes
