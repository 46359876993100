<template>
  <el-tooltip
    class="item"
    placement="right"
    effect="dark"
    :disabled="!nonAcceptanceReason"
    :content="nonAcceptanceReason"
  >
    <div class="time-slot" :class="[isSelected ? 'text-color-success' : '', nonAcceptanceReason ? 'disabled' : '']">
      <span><i class="el-icon-time"></i> </span>
      <span class="start-time">{{ date }}, {{ startTime }}</span>
      <span class="arrow"> → </span>
      <span class="end-time">{{ endTime }}</span>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },

    startTime: {
      type: String,
      required: true
    },

    endTime: {
      type: String,
      required: true
    },

    isSelected: {
      type: Boolean,
      default: false
    },

    nonAcceptanceReason: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  color: $app-lighter-gray !important;
}

.time-slot {
  padding-bottom: 10px;
  font-size: inherit;

  .el-icon-time {
    padding-right: 5px;
    font-weight: 600;
  }
}
</style>
