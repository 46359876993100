/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadRecruitmentLanguageOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import appStorage from '../../../../helpers/appStorage'
import isNil from 'lodash/isNil'

/**
 * Action to load language options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise|void}
 */
export default function (context, payload = {}) {
  if (context.getters['recruitmentLanguageOpts'].length > 0) {
    return
  }
  const url = API.RECRUITMENT_LANGUAGES
  const method = 'GET'
  let languages = appStorage.get(context.state.dtRecruitmentLanguagesCookieKey)
  let updatedAt = appStorage.get(context.state.dtRecruitmentLanguagesUpdatedCookieKey)

  // context.commit('setLanguageOptsLoading', true)

  let options = {
    query_params: {
      all: 1
    },
    headers: {
      'X-Recruitment-Client': 1
    },
    on_success(r, ctx) {
      ctx.commit('setRecruitmentLanguageOpts', r)

      appStorage.set(context.state.dtRecruitmentLanguagesCookieKey, r)
      appStorage.set(context.state.dtRecruitmentLanguagesUpdatedCookieKey, context.state.newLastUpdate)
    },
    on_finally() {
      // ctx.commit('setLanguageOptsLoading', false)
    }
  }

  if (isNil(languages) || isNil(updatedAt) || payload.load) {
    return loadAll(this, context, url, method, 'setRecruitmentLanguageOpts', options)
  } else {
    return context.commit('setRecruitmentLanguageOpts', languages)
  }
}
