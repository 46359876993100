/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getTextTranslationCostEstimation
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context) {
  context.commit('setValidateLoading', true)

  const url = API.TEXT_TRANSLATION_COST_ESTIMATION
  const method = 'post'

  let translatorLevelIds = []
  if (context.state.costEstimationPayload.language_pair_levels.includes('certified')) {
    translatorLevelIds.push(2)
  }

  if (context.state.costEstimationPayload.language_pair_levels.includes('non-certified')) {
    translatorLevelIds.push(1)
  }

  const data = {
    ...context.state.costEstimationPayload,
    customer_id: context.state.costEstimationPayload?.customer_id ?? context.rootGetters['auth/userMetaData'].id,
    translator_level_ids: translatorLevelIds,
    type: 'text_json'
  }

  const options = {
    enable_error_notif: true,
    data,

    on_success(r, ctx) {
      ctx.commit('setCostEstimations', {
        from_language_id: data.from_language_id,
        to_language_id: data.to_language_id,
        invoice: r.data.data.text_translation_invoice_preview
      })
    },

    on_finally(ctx) {
      ctx.commit('setValidateLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
