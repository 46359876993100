import LS from '@/modules/constants/localStorage'
import USER_TYPE from '@/modules/constants/userType'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { getLocaleRoutePath } from '@/modules/helpers/routes'
import appStorage from '@/modules/helpers/appStorage'

/**
 * @param next
 * @param router
 * @returns {*}
 */
function customer({ next, router }) {
  const token = appStorage.get(LS.TOKEN)
  const isUserAuthenticated = !isNil(token) && !isEmpty(token)

  const user = appStorage.get(LS.USER)
  const isUserCustomer = !isNil(user) && !isEmpty(user) && user.type === USER_TYPE.CUSTOMER

  if (!isUserAuthenticated) {
    const path = getLocaleRoutePath('/login', router)
    if (!isEmpty(router?.history?._startLocation)) {
      localStorage.setItem('start_location', router.history._startLocation)
    }
    next({ path })
  } else if (!isUserCustomer) {
    const path = getLocaleRoutePath('/today-dashboard', router)
    next({ path })
  } else {
    return next()
  }
}

export default customer
