/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > validateBooking
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'
import { showSmallErrorNotif } from '@/modules/helpers/notifications.js'
import i18n from '@/locales/i18nClient.js'
const { t: $t } = i18n.global

/**
 * Action to validate the booking to make sure values are right,
 * and then return the additional values to be used for booking confirmation
 * as response.
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @param payload
 * @return {*}
 */
export default function (context, payload) {
  // Prepare the payload to be sent across the API.

  payload.user = context.rootGetters['auth/userMetaData']

  context.commit('setMultipleBookingFormPayload', payload)
  context.commit('setValidateLoading', true)

  const url = `${API.BOOKINGS}/validate`
  const method = 'post'
  // const data = context.state.formPayload
  const data = {
    ...context.state.formPayload,
    calculate_probability: true
  }

  const options = {
    enable_error_notif: false,
    data,

    on_success(r, ctx) {
      ctx.commit('resetConfirmFormPayload')

      // Set the following states.
      ctx.commit('setBcfConfig', r.data.booking_field)
      ctx.commit('setConfirmSuggestions', r.data.confirmation_fills)
      ctx.commit('setAvailableTranslators', r.data.total_translators)
      ctx.commit('setProbability', r.data.assignment_probability)
      ctx.commit('setTranslatorShortages', r.data)

      let user = null

      if (context.state.form.is_created_by_colleague) {
        // Reset the form value, but retain the formPayload value.
        context.state.form.is_created_by_colleague = false
        user = context.state.form.colleague
      } else {
        user = ctx.rootGetters['auth/userMetaData']
      }

      ctx.commit('setConfirmForm', {
        data: r.data.confirmation_fills,
        user
      })
    },
    on_error(r, ctx) {
      console.log(r?.response, ctx)
      if (r?.response?.status === 422) {
        showSmallErrorNotif({ message: $t('multiple_booking_error_message') })
      } else {
        showSmallErrorNotif(r)
      }
    },

    on_finally(ctx) {
      ctx.commit('setValidateLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
