export default {
  /**
   * @param {Object} context
   * @param {Object} payload
   * @param {string} payload.message
   * @returns {void}
   */
  show(context, payload = {}) {
    if (payload.message != null) {
      context.commit('setMessage', payload.message)
    }
    context.commit('setVisible', true)
  },

  /**
   * @param {Object} context
   * @returns {void}
   */
  hide(context) {
    context.commit('setVisible', false)
    context.commit('clearMessage')
  }
}
