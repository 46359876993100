/*
|--------------------------------------------------------------------------
| Custom > Constants > Translator Level
|--------------------------------------------------------------------------
|
| This file contains the constants for Translator Levels which value is
| equivalent to the Translator Level's ID.
|
*/
import appStorage from '@/modules/helpers/appStorage'

export const levelsOrder = [
  {
    id: 1,
    code: 'layman',
    iso: 'OT',
    order: 6
  },
  {
    id: 2,
    code: 'certified',
    iso: 'AT',
    order: 3
  },
  {
    id: 3,
    code: 'read_courses',
    iso: 'GrT',
    order: 5
  },
  {
    id: 4,
    code: 'trained',
    iso: 'UT',
    order: 4
  },
  {
    id: 5,
    code: 'certified_health',
    iso: 'ST',
    order: 2
  },
  {
    id: 6,
    code: 'certified_law',
    iso: 'RT',
    order: 1
  },
  {
    id: 7,
    code: 'deluxe',
    iso: 'DX',
    order: 8
  },
  {
    id: 9,
    code: 'senior',
    iso: 'sn',
    order: 9
  }
]

export const SENIOR_TRANSLATOR = 9
export const PROFESSIONAL_TRANSLATOR = 10

export const getTranslatorLevels = () => {
  const translatorLevelsData = appStorage.get('dt_translator_levels')
  if (translatorLevelsData) {
    const TRANSLATOR_LEVELS =
      translatorLevelsData.data.data?.translator_levels
        ?.map((level) => {
          const order = levelsOrder.find((order) => order.id === level.id)
          if (order) {
            level.order = order.order
          }
          return level
        })
        .sort((a, b) => a.order - b.order) ?? []

    if (TRANSLATOR_LEVELS && TRANSLATOR_LEVELS.length > 0) {
      const translatorLevel = {}
      TRANSLATOR_LEVELS.forEach((level) => {
        if (level.code !== 'deluxe') {
          translatorLevel[level.code.toUpperCase()] = level.id
        }
      })
      return translatorLevel
    }
  }
  return {}
}

export const getTranslatorLevelsArray = () => {
  const translatorLevelsData = appStorage.get('dt_translator_levels')
  if (translatorLevelsData) {
    const TRANSLATOR_LEVELS = translatorLevelsData.data?.data?.translator_levels
      .map((level) => {
        const order = levelsOrder.find((order) => order.id === level.id)
        if (order) {
          level.order = order.order
        }
        return level
      })
      .sort((a, b) => a.order - b.order)

    if (TRANSLATOR_LEVELS && TRANSLATOR_LEVELS.length > 0) {
      return TRANSLATOR_LEVELS.filter((tl) => tl.code !== 'deluxe')
    }
  }
  return []
}

export default getTranslatorLevels
