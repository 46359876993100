import store from '@/store'
import { getLocaleRoutePath } from '@/modules/helpers/routes'
function notPurchaseManager({ next, router }) {
  if (store.getters['auth/isPurchasingManager']) {
    const path = getLocaleRoutePath('/customer/statistics', router)
    console.log('isPurchasingManager path', path)
    window.location = path
  }

  return next()
}

export default notPurchaseManager
