/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > requestQuote
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { showSmallSuccessNotif } from '@/modules/helpers/notifications'

import i18nClient from '@/locales/i18nClient.js'
const { t: $t } = i18nClient.global

export default function (context, payload) {
  const url = `${API.TEXT_TRANSLATION}/${payload.id}/request-quote`

  const method = 'post'

  let options = {
    payload,
    is_data_raw: true,
    on_success() {
      showSmallSuccessNotif($t('quote_requested'), 'Success!')
    },
    on_finally() {}
  }
  return reach(this, context, url, method, options, axiosInstance)
}
