export default {
  getLoadingStatus: (state) => state.loading,
  getEmails: (state) => state.emails,
  getUnReadEmailCount: (state, getters) => getters.getEmails?.data.filter((v) => v.unread_reply > 0)?.length,
  getNotifications: (state) => state.notifications,
  getTotalUnreadInboxCount: (state) => state.unreadInboxCount,
  getTotalUnreadNotificationsCount: (state) => state.unreadNotificationsCount,
  getTotalUnreadImportantCount: (state) => state.unreadImportantCount,
  getTotalUnreadCount: (state) => state.unreadNotificationsCount + state.unreadInboxCount,
  getShowBookingChangesModal: (state) => state.showBookingChangesModal,
  getBookingChangesNotification: (state) => state.bookingChangesNotification,
  getGlobalModalBookingId: (state) => state.globalModalBookingId,
  getBookingChangesNotificationLoading: (state) => state.bookingChangesNotificationLoading,
  getNotificationsEndsStatus: (state) => state.isNotificationsEnd
}
