import { INVOICE_TEXT_TRANSLATION_FEE } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { mapEndpoint } from '@/modules/helpers/common'
import store from '@/store'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  const customerId = store.getters['auth/user'].id

  if (!customerId) {
    return
  }

  if (!payload?.customer_id) {
    payload = {
      customer_id: customerId
    }
  }

  const url = mapEndpoint(INVOICE_TEXT_TRANSLATION_FEE, { customerId: payload.customer_id })

  return reach(this, context, url, 'GET', null, axiosInstance)
}
