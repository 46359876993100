import appStorage from '@/modules/helpers/appStorage'
import { CUSTOMER_INTEGRATION_USER } from '@/modules/constants/localStorage'

export default {
  setCSToken(state, user) {
    appStorage.set(CUSTOMER_INTEGRATION_USER, user)
  },
  removeCSToken() {
    appStorage.remove(CUSTOMER_INTEGRATION_USER)
  }
}
