import getToken from './getToken'
import getUserWebhooks from './getUserWebhooks'
import putUserWebhooks from './putUserWebhooks'
import getWebhookHistories from './getWebhookHistories'
import showWebhookHistories from './showWebhookHistories'
import retryWebhookHistories from './retryWebhookHistories'
export default {
  getToken,
  getUserWebhooks,
  putUserWebhooks,
  getWebhookHistories,
  showWebhookHistories,
  retryWebhookHistories
}
