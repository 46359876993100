/*
|--------------------------------------------------------------------------
| Store > Earnings > Mutations
|--------------------------------------------------------------------------
| File that contains the mutations property for the current Vuex scope.
*/
import moment from 'moment'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import clone from 'lodash/clone'

export default {
  /**
   * Method to set the list data.
   *
   * @param state
   * @param payload
   */
  setListData(state, payload) {
    state.list.data = clone(payload.data.data.payrolls)
    state.list.data.reverse()
  },

  /**
   * Method to set the amounts total.
   *
   * @param state
   * @param payload
   */
  setAmountsTotal(state, payload) {
    state.amounts.total = payload.data.meta.total
  },

  /**
   * Method to set the graph data.
   *
   * @param state
   * @param payload
   */
  setGraph(state, payload) {
    const payrolls = payload.data.data.payrolls
    let col = []

    const monthLimit = moment().subtract(12, 'months') // Define the b in a to b range.

    // Collect the payrolls from this month up to last 12 months.
    forEach(payrolls, (v) => {
      const parsedDate = moment(v.month, 'YYYY-MM')
      const isMonthValid = parsedDate.isAfter(monthLimit)

      if (isMonthValid) {
        col.push({
          id: v.id,
          month: v.month,
          month_label: startCase(moment(v.month, 'YYYY-MM').format('MMM YYYY').toString()),
          payment_date: v.payment_date,
          gross_salary: v.gross_salary
        })
      }
    })

    // Set the total amount of gross_salaries in the year.
    state.amounts.current_year = sumBy(col, 'gross_salary')

    // Set Graph Data.
    state.graph = col
  }
}
