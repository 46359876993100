/*
|--------------------------------------------------------------------------
| Store > TextTranslation > Actions > getCustomerAwaitingQuotes
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context) {
  const url = `${API.CUSTOMER_TEXT_TRANSLATION}`

  const include = ['booking'].join(',')

  let options = {
    query_params: {
      include,
      'filter[awaiting_quote]': true
    },
    on_success(r, ctx) {
      const awaitingQuotes = r?.data?.data?.text_translations ?? []
      ctx.commit('setAwaitingQuotes', awaitingQuotes)
    }
  }

  const method = 'get'

  return reach(this, context, url, method, options, axiosInstance)
}
