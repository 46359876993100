import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import { loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  context.commit('setTextTranslationListLoading', true)

  const url = API.TEXT_TRANSLATION + (!isNil(payload.potential) ? '/potential' : '')
  const method = 'GET'

  const include = ['files'].join(',')

  let options = {
    query_params: {
      include
    },
    on_finally(ctx) {
      ctx.commit('setTextTranslationListLoading', false)
    }
  }

  if (!isNil(payload.page)) options.query_params.page = payload.page

  if (!isNil(payload.customer_id) && payload.customer_id !== 0) {
    options.query_params['filter[customer_id]'] = payload.customer_id
  }
  if (!isNil(payload.status)) {
    options.query_params['filter[status]'] = payload.status
  }
  if (!isNil(payload.assigned_translator)) {
    options.query_params['filter[assigned_translator]'] = payload.assigned_translator
  }

  return loadAll(this, context, url, method, 'setTextTranslationList', options, axiosInstance)
}
