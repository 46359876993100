/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > blockTranslator
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for unblock a specific translator for department.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.translator_id - Id of the translator to be unblocked.
 * @param {Object} payload.customer_id - Id of the requesting customer.
 * @returns {*}
 */
export default function (context, payload) {
  if (isNil(payload.translator_id)) {
    console.error('payload.translator_id is required!')
    return false
  }

  if (isNil(payload.department_id)) {
    console.error('payload.department_id is required!')
    return false
  }

  const url = `${API.BLACKLISTED_USERS}-no-duplicate/${payload.translator_id}/department/${payload.department_id}`
  const method = 'delete'

  let options = {
    on_success(r, ctx) {
      ctx.dispatch('auth/readAuthUserMeta', {}, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
