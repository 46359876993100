/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadBookingFilesFromProfile
|--------------------------------------------------------------------------
|
*/

export default function (context, { type, user_id }) {
  context.dispatch('auth/loadUserFiles', { user_id: user_id }, { root: true }).then((res) => {
    let message_files = []

    let bookingTypeFiles = res.data.filter((item) => {
      // Include files which are for all booking types
      if (item.users[0].pivot.use_in_booking) {
        return true
      }

      // Filter files for this booking type phone and physical
      if (item.users[0].pivot.booking_types != null) {
        const types = JSON.parse(item.users[0].pivot.booking_types)
        return types.includes(type)
      }
    })

    message_files = bookingTypeFiles.map((i) => {
      return {
        key: i.path,
        file_name: i.display_name,
        extension: i.type,
        name: i.display_name
      }
    })

    context.commit('setUserBookingFilesFromProfile', message_files)
  })
}
