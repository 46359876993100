<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="isShowModal"
      class="app-modal app-modal-secondary"
      :class="getBem(blockCls)"
      :title="$t('feedback_list')"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

      <div :class="getBem(blockCls, 'main-form')">
        <el-table :data="feedback" style="width: 100%">
          <el-table-column :label="$t('comment')">
            <template #default="{ row }">
              <div slot="reference" class="name-wrapper">
                <p>{{ row.comment }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('booking_id')">
            <template #default="{ row }">
              <div slot="reference" class="name-wrapper">
                <p>{{ row.booking_issue.booking_id }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('end_date')">
            <template #default="{ row }">
              <el-icon><Clock /></el-icon>
              <span>{{ formatCurrentDate(row.assigned_users[0].pivot.deadline_date) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('operations')">
            <template #default="{ row, $index }">
              <el-button size="mini" class="app-button-default" @click="handleClickLowFeedback($index, row)">
                {{ $t('give_feedback') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <feedback-submit-modal ref="feedback-submit-modal" />
      </div>
    </el-dialog>
  </focus-trap>
  <!-- Component DIV Wrapper -->
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import FeedbackSubmitModal from '@/modules/booking/components/displays/FeedbackSubmitModal'
export default {
  components: {
    FeedbackSubmitModal
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockCls: 'show-feedback-list',
      isShowModal: false,
      feedback: []
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('auth', ['language']),
    /**
     * It will find out the feedback which is assigned to
     * the current user because there is other assigned user as well
     */
    userFeedback() {
      return this.feedback.assigned_users.filter((item) => {
        return item.id == this.user.id
      })[0]
    },

    currentLocale() {
      return this.language === 'se' ? 'sv' : this.language
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    open(feedback) {
      this.feedback = feedback
      this.isShowModal = true
    },
    /**
     * Router push method
     */
    handleClickLowFeedback(index, feedback) {
      this.isShowModal = false
      this.$refs['feedback-submit-modal'].open(feedback)
    },
    formatCurrentDate(date) {
      return moment(date, 'YYYY-MM-DD')
        .locale(this.currentLocale)
        .format('D MMM YYYY [' + this.$i18n.t('at') + '] hh.mm')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
.show-feedback-list {
  &__main-form {
    color: var(--app-primary-color);
  }
}
</style>
