/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getBookingPublic
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to fetch the booking details via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  context.commit('setSubmitLoading', true)

  // Define axios values.
  const url = `${API.TEXT_TRANSLATION}/${payload.id}/start-job/${payload.booking_id}`
  const method = 'POST'

  let options = {
    on_finally(ctx) {
      ctx.commit('setSubmitLoading', false)
    }
  }
  return reach(this, context, url, method, options, axiosInstance)
}
