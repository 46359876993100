import appSessionStorage from '@/modules/helpers/appSessionStorage'
import LS from '@/modules/constants/localStorage'

export const getTenantBaseApiUrl = () => {
  const appTenant = getAppTenant()
  // console.log('Tenant Base...', appTenant)
  if (appTenant) {
    return appTenant.api_endpoint
  }

  return ''
}

export const getAppTenant = () => {
  const appTenant = appSessionStorage.get(LS.TENANT)
  // console.log('This is appTenant... ', appTenant)

  if (appTenant && appTenant.name) {
    return appTenant
  }
  return null
}

export const getAppTenantConfig = () => {
  const appTenantConfig = appSessionStorage.get(LS.TENANT_CONFIG)
  if (appTenantConfig) {
    return appTenantConfig
  }
  return null
}

export const mapTenantToState = (data) => {
  let apiEndpoint, clientID, websocketURL, clientSecret

  const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === 'production'

  if (process.env.CI && !isProduction) {
    apiEndpoint = 'https://api-gateway-stg.digitaltolk.net'
    clientID = '9b8e20dd-b348-4064-a754-575816ac515f'
    clientSecret = '8chpRG65Hs9Fu2L25BSUrXsZc52ZdUfM7myarlHw'
    websocketURL = 'wss://ws-stg.digitaltolk.net'
  } else if (!process.env.CI && !isProduction) {
    apiEndpoint = import.meta.env.VITE_API_BASE || 'https://api-gateway-stg.digitaltolk.net'
    clientID = import.meta.env.VITE_CLIENT_ID || '9b8e20dd-b348-4064-a754-575816ac515f'
    clientSecret = import.meta.env.VITE_CLIENT_SECRET || '8chpRG65Hs9Fu2L25BSUrXsZc52ZdUfM7myarlHw'
    websocketURL = import.meta.env.VITE_WS_URL || 'wss://ws-stg.digitaltolk.net'
  } else {
    apiEndpoint = data.api_endpoint
    clientID = data.client_id
    clientSecret = data.client_secret
    websocketURL = data.ws_url || 'wss://ws.digitaltolk.se'
  }

  return {
    uuid: data.uuid,
    name: data.name,
    url: data.url,
    api_endpoint: apiEndpoint,
    client_id: clientID,
    client_secret: clientSecret,
    ws_url: websocketURL
  }
}

export const mapTenantConfigToState = (data) => {
  return {
    logo: data.logo,
    name: data.name,
    phone: data.phone,
    primary_color: data.primary_color,
    secondary_color: data.secondary_color,
    hero_text: data.hero_text,
    timezone: data.timezone,
    localization_languages: data.localization_languages || [],
    default_locale: data.default_locale || 'en',
    customer_contact: data.customer_contact,
    interpreter_contact: data.interpreter_contact,
    email: data.email,
    address: {
      country: data.address.country,
      city: data.address.city,
      street: data.address.street,
      office: data.address.office,
      country_code: data.address.country_code
    },
    facebook_url: data.facebook_url,
    instagram_url: data.instagram_url,
    linkedin_url: data.linkedin_url,
    client_logos: data.client_logos || [],
    extra_data: data.extra_data || { favicon: '' },
    // admin_url: data.admin_url,
    scripts: data.scripts || [],
    styles: data.styles || []
  }
}

export const loadTenantScripts = () => {
  const appTenantConfig = getAppTenantConfig()
  if (appTenantConfig) {
    const tenantScripts = appTenantConfig.scripts
    if (tenantScripts && tenantScripts.length > 0) {
      tenantScripts.forEach((script) => {
        const scriptTag = document.createElement('script')
        if (script.src) {
          scriptTag.src = script.src
          scriptTag.async = script.async
          scriptTag.defer = script.defer
          document.body.appendChild(scriptTag)
        } else if (script.body) {
          scriptTag.defer = script.defer
          scriptTag.innerHTML = script.body
          document.body.appendChild(scriptTag)
        }
      })
    }
  }
}

export const loadTenantStyles = () => {
  const appTenantConfig = getAppTenantConfig()
  if (appTenantConfig) {
    const tenantStyles = appTenantConfig.styles
    if (tenantStyles && tenantStyles.length > 0) {
      tenantStyles.forEach((style) => {
        if (style.href) {
          const styleLinkTag = document.createElement('link')
          styleLinkTag.rel = 'stylesheet'
          styleLinkTag.href = style.href
          document.head.appendChild(styleLinkTag)
        } else if (style.body) {
          const styleTag = document.createElement('style')
          styleTag.innerHTML = style.body
          document.head.appendChild(styleTag)
        }
      })
    }
  }
}
