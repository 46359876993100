/*
|--------------------------------------------------------------------------
| Store > Actions
|--------------------------------------------------------------------------
| File that contains the actions property for the current Vuex scope.
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import LS from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'
import isNil from 'lodash/isNil'

export default {
  /**
   * Method to get all blogs
   * @param {*} context
   * @param {*} payload
   */

  getBlogs(context, payload = {}) {
    let storedLocale = localStorage.getItem('app_language')
    storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
    context.commit('setBlogsLoading', true)
    let url = API.BLOG
    const method = 'GET'
    let params = {
      'sort': '-created_at',
      'filter[status]': 2,
      'include': 'author',
      'filter[locale]': storedLocale
    }

    if (!isNil(payload.page)) {
      params.page = payload.page
    }

    if (!isNil(payload.per_page)) {
      params.per_page = payload.per_page
    }

    const user = appStorage.get(LS.USER)
    params['filter[tags]'] = user == null ? 'public' : user.type === 2 ? 'customer,public' : 'translator,public'

    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setBlogDataList', r)
          context.commit('setBlogDataPagination', r)
          context.commit('setBlogsLoading', false)
          resolve(r)
        })
        .catch((e) => {
          context.commit('setBlogsLoading', false)
          reject(e)
        })
    })
  },

  /**
   * Method to get featured blogs
   * @param {*} context
   * @param {*} payload
   */

  getFeaturedBlogs(context) {
    let storedLocale = localStorage.getItem('app_language')
    storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
    context.commit('setFeaturedBlogsLoading', true)
    let url = API.BLOG + '?filter[is_featured]=true'
    const method = 'GET'
    let params = {
      'sort': '-created_at',
      'include': 'author',
      'filter[status]': 2,
      'filter[locale]': storedLocale
    }

    const user = appStorage.get(LS.USER)
    params['filter[tags]'] = user == null ? 'public' : user.type === 2 ? 'customer,public' : 'translator,public'

    return new Promise((resolve, reject) => {
      context.commit('setFeaturedBlogsLoading', true)
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setFeaturedBlogDataList', r)
          context.commit('setFeaturedBlogsLoading', false)
          resolve(r)
        })
        .catch((e) => {
          context.commit('setFeaturedBlogsLoading', false)
          reject(e)
        })
    })
  },

  getSpecificBlog(context, payload) {
    let storedLocale = localStorage.getItem('app_language')
    storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
    context.commit('resetBlog')
    context.commit('setBlogLoading', true)

    let url = API.BLOG
    const method = 'GET'
    let params = {
      'sort': '-created_at',
      'include': 'author',
      'filter[status]': 2,
      'filter[locale]': storedLocale,
      'filter[slug]': payload
    }
    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setBlog', r.data.data.items[0])
          context.commit('setBlogLoading', false)
          resolve(r)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  /**
   * Method to get searched blog
   * @param {*} context
   * @param {*} payload
   */

  searchBlogs(context, payload) {
    let storedLocale = localStorage.getItem('app_language')
    storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
    context.commit('setBlogsLoading', true)

    let url = API.BLOG
    const method = 'GET'
    let params = {
      'sort': '-created_at',
      'include': 'author',
      'filter[status]': 2,
      'filter[title]': payload.search,
      'filter[locale]': storedLocale
    }

    const user = appStorage.get(LS.USER)
    params['filter[tags]'] = user == null ? 'public' : user.type === 2 ? 'customer,public' : 'translator,public'

    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setBlogDataList', r)
          context.commit('setBlogDataPagination', r)
          context.commit('setBlogsLoading', false)
          resolve(r)
        })
        .catch((e) => {
          context.commit('setBlogsLoading', false)
          reject(e)
        })
    })
  },

  getCriticalBlogs(context) {
    let storedLocale = localStorage.getItem('app_language')
    storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
    let url = API.BLOG
    const method = 'GET'
    let params = {
      'sort': '-created_at',
      'include': 'author',
      'filter[is_critical]': true,
      'filter[locale]': storedLocale
    }

    const user = appStorage.get(LS.USER)
    params['filter[tags]'] = user == null ? 'public' : user.type === 2 ? 'customer,public' : 'translator,public'

    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setCriticalBlogDataList', r)
          resolve(r)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}
