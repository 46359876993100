/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > verifyResetPassword
|--------------------------------------------------------------------------
|
*/
import { getAppTenant } from '@/modules/helpers/multiTenancy'
import API from '@/modules/constants/api'
import axios from 'axios'

/**
 * Action for verifying a reset password values to the API.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @return {*}
 */
export default function (context, payload) {
  const method = 'POST'
  const url = API.PASSWORD_RESET
  let data = context.state.verifyResetPasswordForm
  data.token = payload.token
  const headers = {}
  const appTenant = getAppTenant()
  if (appTenant && appTenant.uuid) {
    headers['X-Tenant'] = appTenant.uuid
    data.tenant = appTenant.uuid
  }

  context.state.isLoadingVerifyResetPassword = true

  return new Promise((resolve, reject) => {
    axios({ method, url, data, headers })
      .then((r) => {
        context.state.isLoadingVerifyResetPassword = false
        resolve(r)
      })
      .catch((e) => {
        context.state.isLoadingVerifyResetPassword = false
        reject(e)
      })
  })
}
