<template>
  <div class="app-layout" :class="layoutClass">
    <!-- Mobile Nav -->
    <aside
      v-if="isOnMobileViewport"
      :class="[getBem(blockClass, 'mobile-nav-container'), { 'is-show-mobile-nav': isShowMobileNav }]"
    >
      <el-row>
        <el-col :xs="24" :sm="24">
          <div :class="getBem(blockClass, 'mobile-nav-wrapper')">
            <slot name="nav">
              <!-- Set default content for slot "nav" -->
              <div :class="getBem(blockClass, 'mobile-nav-close-button-container')">
                <a
                  :class="getBem(blockClass, 'mobile-nav-close-button')"
                  href="#"
                  @click.stop.prevent="handleCloseMobileNav"
                >
                  <span>{{ $t('btn_close') }}</span>
                  <!-- <i class="el-icon-close" /> -->
                  <el-icon><Close /></el-icon>
                </a>
              </div>
              <dynamic-nav mode="vertical" @openPickUserModal="openPickUserModal" />
            </slot>
          </div>
        </el-col>
      </el-row>
    </aside>

    <!-- Main Layout -->
    <div :class="getBem(blockClass, 'wrapper')">
      <cookie-urgent-information-alert />
      <cookie-alert-partial />

      <!-- Header -->
      <header>
        <div v-if="isOnMobileViewport" :class="getBem(blockClass, 'mobile-nav-button-container')">
          <a :class="getBem(blockClass, 'mobile-nav-button')" href="#" @click.stop.prevent="handleShowMobileNav">
            <div />
            <div />
            <div />
          </a>
        </div>
        <el-row :class="getBem(blockClass, 'nav-container')" justify="center" align="middle">
          <el-col :xs="24" :sm="24" :md="24" :lg="5" :class="getBem(blockClass, 'brand-col')">
            <router-link :to="getLocaleRoutePath('/')" tabindex="0">
              <img
                :src="getTenantConfig.logo"
                :alt="$t('digitaltolk_logo')"
                width="220"
                :height="isGuest ? 20 : 44"
                :style="{ 'object-fit': 'contain' }"
              />
            </router-link>
          </el-col>
          <el-col class="hidden-xs-only" :sm="24" :md="24" :lg="19" :class="getBem(blockClass, 'nav-items-col')">
            <slot name="nav">
              <!-- Set default content for slot "nav" -->
              <dynamic-nav v-if="!isOnMobileViewport" mode="horizontal" @openPickUserModal="openPickUserModal" />
            </slot>
          </el-col>
        </el-row>
        <div
          v-if="isOnMobileViewport && $store.getters['auth/hasAuthenticatedUsers']"
          :class="getBem(blockClass, 'mobile-notification-container')"
        >
          <email-notification dentifier="default_layout" />
        </div>
      </header>

      <!-- Main Content -->
      <main>
        <slot />
      </main>

      <!-- Footer -->
      <extended-footer v-if="isShowFooter" />
    </div>
    <!-- __wrapper -->

    <customer-booking-details-modal ref="customer-booking-details-modal" />
    <BookingDetailsModalV2
      ref="booking-details-modal-v2"
      :show-edit-button="false"
      :is-translator-model="isUserTranslator"
    />

    <show-survey-list-modal ref="show-survey-list-modal" />
    <show-feedback-list-modal ref="show-feedback-list-modal" />
    <feedback-submit-modal ref="feedback-submit-modal" />
    <booking-feedback-modal ref="booking-feedback-modal" />
    <booking-session-time-modal ref="booking-session-time-modal" />
    <show-customer-report-modal ref="show-customer-report-modal" />
    <AcceptBookingSuggestedTimesModal ref="accept-booking-suggested-times-modal" />
    <translator-booking-details-modal ref="translator-booking-details-modal" />
    <BookingChangesModal />
    <booking-session-time-and-travel-time-modal ref="booking-session-travel-time-modal" />
    <pick-user-modal ref="pick-user-modal" />
    <translator-accept-booking-confirm-modal ref="translator-accept-booking-confirm-modal" />
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import BookingDetailsModalV2 from '@/modules/booking/components/v2/displays/BookingDetailsModal.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import DynamicNav from '@/components/navs/DynamicNav'
import CookieAlertPartial from '@/components/partials/CookieAlertPartial'
import ExtendedFooter from '@/components/footers/ExtendedFooter'
import CookieUrgentInformationAlert from '../partials/CookieUrgentInformationAlertPartial'
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'
import moment from 'moment-timezone'
import EventBus from '@/modules/helpers/eventBus'
import EmailNotification from '@/modules/email/components/display/EmailNotification'
import BookingChangesModal from '@/modules/email/components/display/BookingChangesModal'
import AcceptBookingSuggestedTimesModal from '@/modules/email/components/display/AcceptBookingSuggestedTimesModal'

import CustomerBookingDetailsModal from '@/modules/booking/components/displays/CustomerBookingDetailsModal'
import ShowSurveyListModal from '@/modules/communication/survey/modals/ShowSurveyListModal'
import BookingFeedbackModal from '@/modules/booking/components/displays/BookingFeedbackModal'
import ShowFeedbackListModal from '@/modules/booking/components/displays/ShowFeedbackListModal'
import BookingSessionTimeModal from '@/modules/booking/components/displays/BookingSessionTimeModal'
import ShowCustomerReportModal from '@/modules/booking/components/displays/CustomerReportDetailsModal'
import FeedbackSubmitModal from '@/modules/booking/components/displays/FeedbackSubmitModal'
import BookingSessionTimeAndTravelTimeModal from '@/modules/booking/components/displays/BookingSessionTimeAndTravelTimeModal'
import PickUserModal from '@/modules/courses/components/PickUserModal'
import { useHead } from '@unhead/vue'
import TranslatorBookingDetailsModal from '@/modules/booking/components/displays/TranslatorBookingDetailsModal'
import TranslatorAcceptBookingConfirmModal from '@/modules/booking/components/displays/TranslatorAcceptBookingConfirmModal.vue'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    TranslatorAcceptBookingConfirmModal,
    AcceptBookingSuggestedTimesModal,
    BookingChangesModal,
    CookieUrgentInformationAlert,
    DynamicNav,
    CookieAlertPartial,
    ExtendedFooter,
    EmailNotification,

    // New Modals
    BookingDetailsModalV2,

    // (async components)
    CustomerBookingDetailsModal,
    ShowSurveyListModal,
    BookingFeedbackModal,
    ShowFeedbackListModal,
    BookingSessionTimeModal,
    ShowCustomerReportModal,
    FeedbackSubmitModal,
    BookingSessionTimeAndTravelTimeModal,
    TranslatorBookingDetailsModal,
    PickUserModal
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'default-layout'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('auth', [
      'isUserAuthenticated',
      'isOnMobileViewport',
      'isShowMobileNav',
      'isUserTranslator',
      'isUserAdmin'
    ]),

    ...mapGetters('email', ['getTotalUnreadCount']),
    ...mapGetters('multiTenancy', ['getTenantConfig']),

    hasNotifications() {
      return this.getTotalUnreadCount && this.getTotalUnreadCount > 0
    },

    /**
     * Returns boolean whether to show the footer content.
     * @returns {Boolean}
     */
    isShowFooter() {
      // Only show footer for existing routes. Do not include the "Unknown Page" route.
      return this.$route.matched.length > 0
    },

    /**
     * @returns {array}
     */
    layoutClass() {
      const viewportClass = this.isOnMobileViewport ? 'on-mobile-viewport' : 'on-desktop-viewport'
      const authClass = this.isUserAuthenticated ? 'user-authenticated' : 'user-not-authenticated'
      const showMobileNavClass = this.isShowMobileNav ? 'mobile-nav-shown' : 'mobile-nav-hidden'

      if (document != null) document.body.className = [viewportClass, authClass, showMobileNavClass].join(' ')

      return [this.getBem(this.blockClass)]
    },
    isGuest() {
      return this.$route?.query?.is_guest
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  updated() {
    this.$nextTick(() => {
      this.scrollToAnchor()
    })
  },
  mounted() {
    this.initViewportListener()

    setTimeout(() => {
      if (this.isOnMobileViewport) {
        const mobileNavWrapper = document.querySelector('.' + this.getBem('default-layout', 'mobile-nav-wrapper'))
        if (mobileNavWrapper) {
          new SimpleBar(mobileNavWrapper)
        }
      }
    }, 500)

    // Notifications: Open Booking Details Modal
    EventBus.on('notifications.booking.translator.details', (booking) => {
      this.$refs?.['translator-booking-details-modal']?.open(booking)
    })

    EventBus.on('notifications.booking.details', (booking) => {
      let modal = 'booking-details-modal-v2'

      if (['convey'].includes(booking.type)) {
        modal = 'customer-booking-details-modal'
      }

      this.$refs[modal]?.open(booking)
    })

    // Notifications: Open Survey List Modal
    EventBus.on('notifications.survey.list', (surveys) => {
      this.$refs['show-survey-list-modal']?.open(surveys)
    })

    // Notifications: Open Feedback List Modal
    EventBus.on('notifications.feedback.list', ({ issue_id, feedbacks }) => {
      const feedback = feedbacks.find((item) => item.booking_issue_id == issue_id)
      this.$refs['feedback-submit-modal']?.open(feedback)
    })

    // Notifications: Give Feedback Modal
    EventBus.on('notifications.feedback.modal', (booking) => {
      this.$refs['booking-feedback-modal']?.open(booking)
    })

    // Notifications: Enter Session Time Modal
    EventBus.on('notifications.session_time.modal', (booking) => {
      const isSameMonth = moment(booking.due).isSame(moment(), 'month')
      // only for translator acct
      if (isSameMonth && ['physical', 'video_physical'].includes(booking.type) && this.isUserTranslator) {
        this.$refs['booking-session-travel-time-modal'].open(booking)
      } else {
        this.$refs['booking-session-time-modal']?.open(booking)
      }

      // this.getBookingDetails(booking.id).then((response) => {
      //   this.$refs['booking-session-travel-time-modal'].open(response.data.data.booking)
      //   // this.$refs['booking-session-time-modal'].open(response.data.data.booking)
      // })
      // },
    })

    // Notifications: Open Customer Report Modal
    EventBus.on('notifications.customer.report', (payload) => {
      this.$refs['show-customer-report-modal']?.open(payload)
    })

    // Notifications: Open Customer Report Modal
    EventBus.on('open-accept-booking-suggested-times-modal', (payload) => {
      console.log('open-accept-booking-suggested-times-modal')
      this.$refs['accept-booking-suggested-times-modal']?.open(payload)
    })
    EventBus.on('translator-auto-assign-booking', (payload) => {
      console.log('translator-auto-assign-booking')
      this.$refs['translator-accept-booking-confirm-modal']?.open(payload.booking)
    })
    this.pageMeta()
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapMutations('auth', ['setIsShowMobilenav']),

    ...mapActions('auth', ['initViewportListener']),

    // ...mapActions('booking', ['getBookingDetails']),

    /**
     * @returns {void}
     */
    handleShowMobileNav() {
      this.setIsShowMobilenav(true)
    },

    /**
     * @returns {void}
     */
    handleCloseMobileNav() {
      this.setIsShowMobilenav(false)
    },

    openPickUserModal(payload) {
      this.$refs['pick-user-modal']?.open(payload)
    },

    // Scroll to anchor on page load
    scrollToAnchor() {
      setTimeout(() => {
        const hash = this.$route.hash
        if (hash) {
          const el = document.querySelector(hash)
          el && el.scrollIntoView()
        }
      }, 100)
    },
    pageMeta() {
      useHead({
        titleTemplate:
          this.getTotalUnreadCount > 0 && this.isUserAuthenticated ? `(${this.getTotalUnreadCount}) %s` : '%s'
      })
    }
  }
}
</script>

<!--suppress CssUnknownTarget -->
<style lang="scss">
@import '@/assets/scss/index.scss';
// @import '@/assets/scss/variables/index.scss';
// @import '@/assets/scss/global/index.scss';

//.v-modal {
//  z-index: 2000 !important;
//}

.default-layout {
  width: 100%;
  margin: 0 auto;
  position: relative;

  &__wrapper {
    position: relative;
    background-color: #fff;

    header {
      position: relative;
      background-color: transparent;
      padding: 5px $app-md-container-side-padding;
    }
  }

  &__nav-container .brand-logo {
    width: 100%;
    max-width: 220px;
    height: auto;
    padding: 0;
  }

  &__brand-col {
    text-align: left;

    & > a {
      display: flex;
      picture {
        display: flex;
        img.brand-logo {
          // height: 1.434em;
          height: 1.375em;
        }
      }
    }
  }

  &__nav-items-col {
    text-align: right;
  }
}

// Style for stuff under the banner.
.app-page__content {
  padding: 20px $app-md-container-side-padding;
  background-color: #fff;
}

// Style for side nav in the content.
.app-page__content-side-nav {
  padding: 10px 30px;
  box-sizing: border-box;

  ul,
  ul > li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul > li {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    &:last-child {
      border-bottom: 0;
    }
  }
}

// Mobile Nav related
.default-layout__mobile-nav-button {
  display: inline-block;

  &-container {
    position: absolute;
    // top: 15px;
    top: 5px;
    left: 15px;
    z-index: 50;
  }

  & > div {
    width: 25px;
    height: 3px;
    margin: 4px 4px;
    background-color: $app-light-gray;
    transition-duration: 0.5s;

    &:after {
      content: '';
    }
  }

  &:hover {
    & > div {
      background-color: var(--app-primary-color);
    }
  }
}

// Notifcation Nav related
.default-layout__mobile-notification {
  display: inline-block;

  &-container {
    position: absolute;
    // top: 15px;
    top: 7px;
    right: 15px;
    z-index: 50;

    .email-notification {
      .el-dropdown-link {
        i {
          font-size: 22px !important;
        }
      }
    }
  }
}

//noinspection CssOverwrittenProperties
.default-layout__mobile-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  height: 100vh;
  overflow: scroll;
  z-index: 7000;
  background-color: $app-gray;
  transition-duration: 0.5s;
  opacity: 0;
  transform: translateX(-300px);

  &.is-show-mobile-nav {
    opacity: 1;
    transform: translateX(0);
  }

  .el-row,
  .el-row > .el-col,
  .dynamic-nav,
  .dynamic-nav > ul {
    height: 100%;
  }

  .dynamic-nav {
    box-sizing: border-box;

    & > ul {
      border-right: 0;
    }

    & > ul,
    .el-menu-item,
    .el-sub-menu__title {
      text-align: left;
      background-color: $app-gray !important;

      a,
      a:visited {
        color: #fff;
        display: block;
        transition-duration: 0.5s;
      }

      a:focus,
      a:hover {
        color: var(--app-primary-color);
      }
    }

    .el-menu-item,
    .el-sub-menu__title {
      border-bottom: 1px solid darken($app-gray, 5%);
      border-top: 1px solid lighten($app-gray, 7%);
    }
  }
}

//noinspection CssOverwrittenProperties
.default-layout__mobile-nav-wrapper {
  height: 100%;
  height: 100vh;
}

.default-layout__mobile-nav-close-button {
  display: inline-block;
  padding-left: 40px;
  padding-bottom: 10px;

  &-container {
    position: absolute;
    text-align: right;
    top: 5px;
    right: 5px;
    z-index: 50;
  }

  span,
  i {
    color: #fff;
    transition-duration: 0.5s;
  }

  &:hover {
    span,
    i {
      color: var(--app-primary-color);
    }
  }

  span {
    font-size: 12px;
    position: relative;
    top: -2px;
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .app-page__content {
    padding: 20px $app-xs-container-side-padding;
  }

  .default-layout {
    &__brand-col {
      text-align: center;

      & > a {
        display: block;
        picture {
          display: block;
          img.brand-logo {
            // height: auto;
            height: 25px;
          }
        }
      }
    }

    &__wrapper {
      header {
        padding: 5px $app-sm-container-side-padding;
      }
    }
  }
}

// **************************************************
// Small Viewport
// **************************************************
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .app-page__content {
    padding: 20px $app-sm-container-side-padding;
  }

  .default-layout {
    &__brand-col {
      text-align: center;

      & > a {
        display: block;
        picture {
          display: block;
          img.brand-logo {
            height: auto;
          }
        }
      }
    }

    &__wrapper {
      header {
        padding: 5px $app-sm-container-side-padding;
      }
    }
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .default-layout {
    &__brand-col {
      text-align: center;

      & > a {
        display: block;
        picture {
          display: block;
          img.brand-logo {
            height: auto;
          }
        }
      }
    }
  }
}
</style>
