import API from '@/modules/constants/api'
import axios from 'axios'
export default function (context, payload) {
  let data = {
    notification_type: 'rocketchat',
    template_code: 'blank',
    project_code: API.NOTIFIER_PROJECT_CODE,
    data: {
      text: `!!! WARNING !!! user ${payload.email} failed when trying to book text translation`
    },
    recipients: ['support']
  }

  const url = `${API.NOTIFIER_API_PATH}/api/v1/notifications`

  axios({ url, data }).catch((err) => {
    console.log(err)
  })
}
