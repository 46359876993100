/*
|--------------------------------------------------------------------------
| Custom > Constants > HTML
|--------------------------------------------------------------------------
|
| This file contains the list of constants that is essential for the HTML
| structure.
|
*/

/**
 * Value for the Base Title of the HTML pages.
 *
 * @type {string}
 */
export const BASE_TITLE = 'DigitalTolk - Snabb tillgång till kvalitetstolkar'

export const DEFAULT_REFERENCE_FIELD_LABEL = 'Kundreferens'

export const DEFAULT_REFERENCE_FIELD_PLACEHOLDER = 'Detta kommer synas på fakturan'

/**
 * Generates an Object to represent the meta description for the HTML.
 *
 * @param content
 * @returns {{hid: string, name: string, content: *}}
 */
export const META_DESC = function (content) {
  return {
    hid: 'description',
    name: 'description',
    content
  }
}
