<template>
  <div :class="blockClass">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      style="width: 10px; height: 10px"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
      />
    </svg>
    {{ $t('sustainable_choice') }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      blockClass: 'sustainable-badge-booking-details'
    }
  }
}
</script>
<style>
.sustainable-badge-booking-details {
  background: green;
  color: white;
  padding: 5px;
  width: 130px;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
}
</style>
