/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > acceptBooking
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to accept a bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  if (isNil(payload.booking_id)) console.error('booking_id value is required.')
  if (isNil(payload.translator_id)) console.error('translator_id value is required.')

  const url = `${API.BOOKINGS}/${payload.booking_id}/accept?include=video_booking`
  const method = 'POST'

  let data = {
    translator_id: payload.translator_id,
    accepted_via: context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'
  }

  if (!isNil(payload.withdraw_other_bookings)) {
    data['withdraw_other_bookings'] = payload.withdraw_other_bookings
  }

  if (!isNil(payload.time_slot)) {
    data['time_slot'] = payload.time_slot
  }

  if (!isNil(payload.traveling_by)) {
    data['traveling_by'] = payload.traveling_by
  }

  let options = { data }

  return reach(this, context, url, method, options, axiosInstance)
}
