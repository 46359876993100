// import cloneDeep from 'lodash/cloneDeep'
import { listSetter } from '@/modules/helpers/vuexMutations'
import { daySchedule, freshForm, initialForm } from './shared'

// import isNil from 'lodash/isNil'
// import isEmpty from 'lodash/isEmpty'
// import forEach from 'lodash/forEach'
// import map from 'lodash/map'
// import remove from 'lodash/remove'
// import find from 'lodash/find'
// import includes from 'lodash/includes'
// import assign from 'lodash/assign'
// import unset from 'lodash/unset'
// import { getCachedBooking } from '@/modules/booking/services/cachedBookingService'
import moment from 'moment-timezone'

/**
 * Helper for setting default values for q_specifics.
 *
 * @param {object} state - The state property of the current Vuex module.
 * @param {string} key - Reference for the q_specific object property.
 * @returns {void}
 */
export default {
  /**
   * Set value for state.isLoadingSubmit.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */

  setTranslatorUnavailableTimeList(state, payload) {
    listSetter(state, payload, {
      data: 'list.data',
      pagination: 'list.pagination'
    })
  },
  setTranslatorUnavailableTimeLoading(state, payload) {
    state.list.loading = payload
  },

  setLoadingForUnavailableTime(state, payload) {
    state.loading = payload
  },

  setItem(state, payload) {
    state.item = payload
  },

  resetForm(state) {
    state.form = {
      id: '',
      description: '',
      type: '',
      address: '',
      unavailable_from: '',
      unavailable_till: ''
    }
  },
  setForm(state, payload) {
    state.form = payload
  },

  //add new date range
  addNewDateRange(state) {
    state.multiSchedule.forEach((schedule) => {
      schedule.visible = false
    })
    state.multiSchedule.push(initialForm())
  },

  //remove date range
  removeDateRange(state, payload) {
    state.multiSchedule.splice(payload.key, 1)
  },

  radioClicked(state, payload) {
    state.multiSchedule.forEach((schedule, index) => {
      schedule.visible = index === payload.key
    })
  },

  //available time
  addHoursSlotTo(state, payload) {
    state.multiSchedule[payload.index].translatorSchedule[payload.day].day_schedule.push(daySchedule())
  },
  removeHoursSlotOf(state, payload) {
    state.multiSchedule[payload.key].translatorSchedule[payload.day].day_schedule.splice(payload.index, 1)
    if (state.multiSchedule[payload.key].translatorSchedule[payload.day].day_schedule.length <= 0) {
      state.multiSchedule[payload.key].translatorSchedule[payload.day].day_schedule.push(daySchedule())
      state.multiSchedule[payload.key].translatorSchedule[payload.day].active = false
    }
  },

  setLoadingForAvailableTime(state, payload) {
    state.scheduleFormList.loading = payload
  },
  resetSheduleForm(state) {
    state.multiSchedule = [initialForm()]
    // state.scheduleForm = {
    //   translatorSchedule: freshForm(),
    //   start_date: '',
    //   end_date: ''
    // }
  },
  resetSheduleFormList(state) {
    state.scheduleFormList = {
      data: [],
      pagination: {},
      loading: false
    }
  },
  setTranslatorAvailableTimeList(state, payload) {
    state.scheduleFormList.data = payload.data.translator_weekly_schedules
    state.scheduleFormList.pagination = payload.pagination

    let schedulesFrom = []
    payload.data.translator_weekly_schedules.forEach((scheduleData) => {
      let fromData = {
        translatorSchedule: freshForm(),
        start_date: scheduleData.start_date,
        end_date: scheduleData.end_date,
        id: scheduleData.id
      }
      let schedule = fromData.translatorSchedule
      const inputTranslatorSchedule = scheduleData.translatorSchedule

      for (const dayOfWeek in schedule) {
        if (inputTranslatorSchedule[dayOfWeek]) {
          schedule[dayOfWeek] = inputTranslatorSchedule[dayOfWeek]
          schedule[dayOfWeek].day_schedule.forEach((schedule) => {
            schedule.start_time = moment(schedule.start_time, 'HH:mm:ss').format('HH:mm')
            schedule.end_time = moment(schedule.end_time, 'HH:mm:ss').format('HH:mm')
          })
        }
      }

      schedulesFrom.push(fromData)
    })
    if (schedulesFrom.length) {
      state.multiSchedule = schedulesFrom
    }
  }
}
