/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > deleteConfirmFill
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for removing a whole entry or a certain entry
 * in the confirmation fills.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} [payload.field] - Specific field to delete.
 * @return {*}
 */
export default function (context, payload = {}) {
  if (isNil(payload.id)) console.error('payload.id is required!')
  if (isNil(payload.field)) console.error('payload.field is required!')

  const url = `${API.CONFIRMATION_FILLS}/${payload.id}`
  const method = 'delete'
  let options = {
    query_params: !isNil(payload.field) ? { field: payload.field } : {},
    on_success() {
      // If request is successful then remove the item from the state container.
      context.commit('deleteFromConfirmSuggestions', {
        id: payload.id,
        field: payload.field
      })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
