import { reach } from '@/modules/helpers/vuexActions'
import API from '@/modules/constants/api'
import { getAppTenant } from '@/modules/helpers/multiTenancy'

export default function (context, payload = {}) {
  const method = 'POST'
  const url = `${API.PASSWORD_FORGOT}`
  const headers = {}
  const data = payload.data
  const appTenant = getAppTenant()
  if (appTenant && appTenant.uuid) {
    headers['X-Tenant'] = appTenant.uuid
    data.tenant = appTenant.uuid
  }
  let options = {
    data,
    is_data_raw: true,
    headers
  }

  return reach(this, context, url, method, options)
}
