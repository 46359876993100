/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > readAuthUserFeedbacks
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'
import API from '@/modules/constants/api'
import isEmpty from 'lodash/isEmpty'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for getting the feedbacks for the authenticated user.
 *
 * @returns {*}
 */
export default function (context, payload = {}) {
  let endpoint = !isEmpty(payload.url) ? payload.url : 'mine'

  const url = `${API.FEEDBACK}/${endpoint}`
  const method = 'get'
  const options = {
    query_params: payload.query_params,
    on_success(r, ctx) {
      ctx.commit('auth/setUserFeedbacksData', r.data.data, { root: true })
      if (!isEmpty(r.data.meta)) {
        ctx.commit('auth/setUserFeedbacksPagination', r.data.meta.pagination, {
          root: true
        })
      }
    },

    on_finally(ctx) {
      ctx.commit('auth/setUserFeedbacksLoading', false, { root: true })
    }
  }

  context.commit('auth/setUserFeedbacksLoading', true, { root: true })
  return reach(this, context, url, method, options, axiosInstance)
}
