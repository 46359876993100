<template>
  <div tabindex="-1">
    <template v-if="mode === 'dropdown'">
      <el-select
        v-if="hasLanguageOptions"
        v-model="iLanguage"
        size="small"
        class="language-menu"
        data-cy="language-switcher"
        @change="handleChangeLocale"
      >
        <el-option
          v-for="langItem in languageOptions"
          :key="langItem.key"
          :label="langItem.key.toUpperCase()"
          :value="langItem.key"
          :disabled="langItem?.disabled"
          :data-cy="`language-switcher-option-${langItem.key}`"
        >
          <img v-if="langItem.icon" :src="imagePath(langItem.icon)" :alt="langItem.alt" width="16" height="10" />
          {{ langItem.key.toUpperCase() }}
        </el-option>
      </el-select>
    </template>
    <template v-else>
      <el-collapse>
        <el-collapse-item name="1" :class="getBem(blockClass, 'collapse')">
          <template #title :class="getBem(blockClass, 'collapse-title')">
            <template v-if="language === 'se'">
              <div>
                <img src="@/assets/images/se-flag.png" :alt="$t('eng_lang')" width="16" height="10" />
                {{ language.toUpperCase() }}
              </div>
            </template>
            <template v-else>
              <div>
                <img src="@/assets/images/en-flag.png" :alt="$t('eng_lang')" width="16" height="10" />
                {{ language.toUpperCase() }}
              </div>
            </template>
          </template>
          <div>
            <div :class="getBem(blockClass, 'collapse-content-body')">
              <div @click="handleChange('en')">
                <img src="@/assets/images/en-flag.png" :alt="$t('eng_lang')" width="16" height="10" />
                EN
              </div>
              <div @click="handleChange('se')">
                <img src="@/assets/images/se-flag.png" :alt="$t('swedish_lang')" width="16" height="10" />
                SE
              </div>
              <!-- <div @click="handleChange('no')">
                <img src="@/assets/images/no-flag.jpg" :alt="$t('no_flag')" width="16" height="10" />
                NO
              </div> -->
              <div @click="handleChange('da')">
                <img src="@/assets/images/da-flag.png" :alt="$t('danish_lang')" width="16" height="10" />
                DA
              </div>
            </div>

            <!--            <li  @click.prevent.stop="handleChange('se')">-->
            <!--               <img src="@/assets/images/se-flag.png"-->
            <!--                   alt="se-flag.png"> SE-->
            <!--              </li>-->
            <!--            <li  @click.prevent.stop="handleChange('no')">-->
            <!--              <img src="@/assets/images/no-flag.jpg"-->
            <!--                   alt="no-flag.jpg"> NO-->
            <!--            </li>-->
            <!--          </ul>-->
          </div>
        </el-collapse-item>
      </el-collapse>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'SelectLanguage',
  props: {
    mode: {
      type: String,
      default: 'dropdown'
    }
  },
  emits: ['handleChange'],
  data() {
    return {
      activeLang: 'SE',
      blockClass: 'language-select'
    }
  },
  computed: {
    ...mapState('auth', ['language']),
    ...mapGetters('multiTenancy', ['getTenantConfig']),

    iLanguage: {
      get() {
        return this.language
      },
      set(v) {
        this.saveLanguage(v)
      }
    },
    languageOptions() {
      if (this.getTenantConfig?.localization_languages) {
        return this.getTenantConfig.localization_languages.map((lang) => {
          return {
            label: lang.label,
            key: lang.value,
            icon: `assets/images/${lang.value.toLowerCase()}-flag.png`,
            alt: this.$t(lang.name)
          }
        })
      }
      return []
    },
    hasLanguageOptions() {
      return this.languageOptions?.length > 0
    }
  },
  methods: {
    ...mapMutations('auth', ['saveLanguage']),

    handleChangeLocale(e) {
      this.$emit('handleChange', e)
    },

    handleChange(e) {
      this.iLanguage = e
      this.$emit('handleChange', e)
    },

    imagePath(icon) {
      const baseURL = import.meta.env.VITE_BASE_URL || '/'
      const isPreviewMode = baseURL !== '/'
      let iconPath = `/${icon}`
      if (isPreviewMode) {
        iconPath = `${baseURL}/${icon}`
      }
      iconPath = iconPath.replace('//', '/')
      return new URL(`${iconPath}`, `${location.origin}`).href
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.language-menu {
  width: 65px;
  margin-right: 5px;

  .el-select__wrapper {
    height: 30px;
    box-shadow: none;
  }

  .el-icon {
    font-size: 15px;
  }

  .el-input__inner {
    border: none;
    font-size: 13px;
    // padding-right: 30px;
  }
}

.language-select {
  &__collapse {
    border: 0;

    .el-collapse-item {
      border: 0;
      background: transparent;
      color: #fff;
      &__header {
        & > div {
          display: flex;
          align-items: center;
          column-gap: 8px;
        }
      }
      &__header,
      &__wrap,
      &__content {
        @extend .el-collapse-item;
      }

      &__content {
        padding-top: 10px;
        /*padding-left: 20px;*/
        padding-bottom: 15px;
        .language-select__collapse-content-body {
          @extend .el-collapse-item__header;
        }
      }
    }
  }

  &__collapse-list {
    list-style: none;
  }
}
</style>
