import { CUSTOMER_BOOKING_CREATED, CUSTOMER_BOOKING_UPDATED } from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'
import unset from 'lodash/unset'
import isUndefined from 'lodash/isUndefined'

/**
 * @param {string} mode - Varies between 'create' | 'update'
 * @returns {string}
 */
function getKey(mode) {
  if (mode === 'create') return CUSTOMER_BOOKING_CREATED
  else if (mode === 'update') return CUSTOMER_BOOKING_UPDATED
}

/**
 * @param {int} [userId = 0] - User's ID
 * @param {string} [mode="create"] - Varies between 'create' | 'update'
 * @returns {Object}
 */
export function getCachedBooking(userId = 0, mode = 'create') {
  if (isUndefined(userId)) throw new Error('Missing value for o.userId')

  const key = getKey(mode)
  const stored = appStorage.get(key) || {}
  return userId === 0 ? stored : stored[userId.toString()]
}

/**
 * @param {Object} o - Container for options.
 * @param {Object} o.booking - Booking payload data that supposedly submitted to the API
 * @param {int} o.userId - User's ID
 * @param {string} [o.mode="create"] - Varies between 'create' | 'update'
 * @returns {void}
 */
export function setCachedBooking(o) {
  if (isUndefined(o.booking)) throw new Error('Missing value for o.booking')
  if (isUndefined(o.userId)) throw new Error('Missing value for o.userId')
  if (isUndefined(o.mode)) o.mode = 'create'

  const key = getKey(o.mode)
  const stored = appStorage.get(key) || {}

  stored[o.userId.toString()] = o.booking
  appStorage.set(key, stored)
}

/**
 * @param {int} userId - User's ID
 * @param {string} [mode="create"] - Varies between 'create' | 'update'
 * @returns {void}
 */
export function removeCachedBooking(userId, mode = 'create') {
  if (isUndefined(userId)) throw new Error('Missing value for o.userId')

  const key = getKey(mode)
  const stored = appStorage.get(key) || {}
  unset(stored, userId.toString())
  appStorage.set(key, stored)
}

/**
 * @param {string} [mode="create"] - Varies between 'create' | 'update'
 * @returns {void}
 */
export function clearCachedBooking(mode = 'create') {
  const key = getKey(mode)
  appStorage.remove(key)
}

export default {
  getCachedBooking,
  setCachedBooking,
  removeCachedBooking,
  clearCachedBooking
}
