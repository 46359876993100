/*
|--------------------------------------------------------------------------
| Custom > Helpers > Components
|--------------------------------------------------------------------------
|
| This file contains helpers that are most used in Vue Components.
| Feel free to edit this file if you have a helper that you want to define
| specifically for this file's purpose.
|
*/

import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import forEach from 'lodash/forEach'
import forOwn from 'lodash/forOwn'
import size from 'lodash/size'
import filter from 'lodash/filter'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import LS from '@/modules/constants/localStorage'
import { getLocaleRoutePath as getLocaleRoutePathRoutesHelper } from '@/modules/helpers/routes'
import { updateQueryParams as updateQueryParamsRoutesHelper } from '@/modules/helpers/routes'
import { removeQueryParams as removeQueryParamsRoutesHelper } from '@/modules/helpers/routes'
import { getQueryParam as getQueryParamRoutesHelper } from '@/modules/helpers/routes'
import { getUrlParam as getUrlParamRoutesHelper } from '@/modules/helpers/routes'

/**
 * Generates a class reference name using BEM convention.
 *
 * @param {string} block - the block reference
 * @param {string|array} elem - the element reference
 * @param {string} modifier - the modifier refernece
 * @returns {string}
 */
export function getBem(block, elem = null, modifier = '') {
  // Make sure parent value was provided.
  if (isNil(block)) console.error('Parent does not exist, but required.')

  let result = block

  if (elem !== null && elem !== '') {
    if (isString(elem)) {
      result += '__' + elem // Assign elem
    } else if (isArray(elem)) {
      forEach(elem, (e) => {
        result += '__' + e // Assign elem
      })
    }
  }

  if (modifier !== '') result += '--' + modifier // Assign modifier

  return result
}

// noinspection JSUnusedGlobalSymbols
/**
 * Minor validation such as making sure that the values are properly set
 * based on the rules given.
 *
 * @param {Object} rules
 *   Rules that will be the basis of validation.
 *
 * @param {Object} obj
 *   Object where the values will be evaluated from.
 *
 * @return {Array}
 */
export function validateObject(rules, obj) {
  let errors = {} // Define error container

  // Iterate tru rules and make sure form contents will pass.
  forOwn(rules, (v, i) => {
    let enabled = filter(v, (o) => o.enabled === true || o.enabled === undefined)

    if (size(enabled) > 0) {
      forEach(enabled, (rule) => {
        if (!rule.condition(get(obj, i))) {
          errors[i] = isNil(errors[i]) ? [] : errors[i]
          let msg = isNil(rule.message) ? i + ' did not met the condition' : rule.message
          errors[i].push(msg)
        }
      })
    }
  })

  errors.hasError = !isEmpty(errors)

  return errors
}

/**
 * Helper method for getting a stored Object from cookie.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {String} key - Cookie Key to be used.
 * @returns {Object}
 */
export function getStoredObject(va, key) {
  let v = va.$cookies.get(LS[key])
  return v === undefined ? {} : v
}

/**
 * Helper method for setting a stored Object to cookie.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {String} key - Cookie Key to be used.
 * @param {String} prop - Property Key reference inside the object.
 * @param {*} value - Value to be assigned and stored.
 * @returns {void}
 */
export function setStoredObject(va, key, prop, value) {
  // Get the old record if it exists.
  let v = va.$cookies.get(LS[key])
  let obj = v === undefined ? {} : v

  // Update it and save.
  obj[prop] = value
  va.$cookies.set(LS[key], obj)
}

/**
 * Helper method for setting object properties with fallbacks that considers to be
 * coming from route query param or object stored in a cookie.
 *
 * @param {Object} va - The Vue App Instance.
 * @param {Object} obj - The object container to be mutated.
 * @param {String} key - Key reference to the Object, Route Query Param, and Cookie Object.
 * @param {Object} options - Contain values necessary for the method.
 * @param {*} [options.default=null] - The default value to be used as a fallback.
 * @param {String} [options.cookie_key=''] - Key reference for the Cookie Object if ever it is needed or preferred.
 * @param {Function} [options.query_value_mutator=null] - Function to be used to mutate the value
 *                                                        from route query param.
 * @param {Function} [options.cookie_value_mutator=null] - Function to be used to mutate the value
 *                                                         from cookie stored object.
 * @returns {void} - Returns nothing as we are directly mutating the obj param.
 */
export function setObjectPropWithFallbacks(va, obj, key, options) {
  // Set default values for options.
  options.default = options.default || null
  options.cookie_key = options.cookie_key || ''
  options.query_value_mutator = options.query_value_mutator || null
  options.cookie_value_mutator = options.cookie_value_mutator || null

  // If options.cookie_key exists, pull out the object from the cookie.
  let cookieObj = null
  if (options.cookie_key !== '') {
    cookieObj = getStoredObject(va, options.cookie_key)
  }

  // If the object is defined already, there's no need to go tru the whole process.
  // so we'll make sure the value should be undefined or null.
  if (isNil(obj[key])) {
    if (!isNil(va.$route.query) && !isNil(va.$route.query[key])) {
      // If query param value exists, use its value.
      obj[key] = isNull(options.query_value_mutator)
        ? va.$route.query[key]
        : options.query_value_mutator(va.$route.query[key])
    } else if (!isNil(cookieObj) && !isNil(cookieObj[key])) {
      // If the last condition failed and settings is present, use its value.
      obj[key] = isNull(options.cookie_value_mutator) ? cookieObj[key] : options.cookie_value_mutator(cookieObj[key])
    } else {
      // If all condition failed, just define the value.
      obj[key] = options.default
    }
  }
}

/**
 * Helper method for generating <head> metas (for SEO).
 *
 * @param {object} options - contains values to be used inside the method.
 * @param {string} options.title - Title value.
 * @param {string} options.slug - Slug (url) value.
 * @param {string} options.description - Description value.
 * @param {string} options.keywords - Keywords value.
 * @param {string} [options.sub_description] - Optional description.
 * @param {string} [options.image_file="og-image-default.jpg"] - Optional image file.
 * @param {string} [options.image_width="994"] - Optional image width.
 * @param {string} [options.image_height="560"] - Optional image height.
 * @retruns {object}
 */
export function getPageMetas(options = {}) {
  let errMsg = ''

  if (isNil(options.title)) {
    errMsg = 'options.title value should exist!'
    console.error(errMsg)
    throw new Error(errMsg)
  }

  if (isNil(options.slug)) {
    errMsg = 'options.slug value should exist!'
    console.error(errMsg)
    throw new Error(errMsg)
  }

  if (isNil(options.description)) {
    errMsg = 'options.description value should exist!'
    console.error(errMsg)
    throw new Error(errMsg)
  }

  if (isNil(options.keywords)) {
    errMsg = 'options.keywords value should exist!'
    console.error(errMsg)
    throw new Error(errMsg)
  }

  if (isNil(options.sub_description) || options.sub_description === '') {
    let subDesc = ''
    let storedLocale = localStorage.getItem('app_language')
    storedLocale = !isNil(storedLocale) ? storedLocale : 'se'

    switch (storedLocale) {
      case 'en':
        subDesc = 'Välkommen till Sveriges snabbaste tolkförmedling'
        break
      case 'se':
        subDesc = 'Välkommen till Sveriges snabbaste tolkförmedling'
        break
      case 'no':
        subDesc = 'Velkommen til sveriges raskeste tolkbyrå'
        break
      case 'da':
        subDesc = 'Välkommen till Sveriges snabbaste tolkförmedling'
        break
    }

    options.sub_description = subDesc
  }

  if (isNil(options.image_file)) {
    options.image_file = 'og-image-default.jpg'
  }

  if (isNil(options.image_width)) {
    options.image_width = '994'
  }

  if (isNil(options.image_height)) {
    options.image_height = '560'
  }

  return [
    // Open Graph
    {
      hid: 'og:url',
      property: 'og:url',
      content: `https://digitaltolk.se/${options.slug}`
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: `https://digitaltolk.se/images/${options.image_file}`
    },
    {
      hid: 'og:image:width',
      property: 'og:image:width',
      content: options.image_width
    },
    {
      hid: 'og:image:height',
      property: 'og:image:height',
      content: options.image_height
    },
    {
      hid: 'og:image:alt',
      property: 'og:image:alt',
      content: `DigitalTolk - ${options.title}`
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: options.sub_description
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'DigitalTolk'
    },

    // Twitter
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: options.sub_description
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: `DigitalTolk - ${options.title}`
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: `https://digitaltolk.se/images/${options.image_file}`
    },

    // Defaults
    { hid: 'description', name: 'description', content: options.description },
    { hid: 'keywords', name: 'keywords', content: options.keywords }
  ]
}

/**
 * Helper method to generate a landing (layout) page head title and metas in a templated manner.
 *
 * @param {string} key - Identifier value.
 * @returns {object}
 */
export function getLandingPageHeadValues(key) {
  // Format the key to title case.
  const titleKey = startCase(key)

  // Set page title.
  const title = `DigitalTolk - Snabb tillgång till kvalitetstolkar - Boka ${titleKey} Tolk`

  // Set page meta values.
  const meta = getPageMetas({
    title: `Boka ${titleKey} Tolk`,
    slug: `landing/boka-${key}-tolk`,
    description: `Boka ${titleKey} Tolkar - Boka ${titleKey} Tolk, DigitalTolk`,
    keywords: `${key} tolk, boka ${key} tolk, ${key} tolkar`,
    image_file: 'og-image-landing.jpg',
    image_width: '994',
    image_height: '560'
  })

  return { title, meta }
}

/**
 * Helper method to generate a default (layout) page head title and metas in a templated manner.
 *
 * @param {string} key - Identifier value.
 * @param {string} url - Slug value.
 * @returns {object}
 */
export function getDefaultPageHeadValues(key, url) {
  // Format the key to title case.
  const titleKey = startCase(key)

  // Set page title.
  const title = `DigitalTolk - Snabb tillgång till kvalitetstolkar - ${titleKey}`

  // Set page meta values.
  const meta = getPageMetas({
    title: titleKey,
    slug: url,
    description: `Välkommen till Sveriges snabbaste tolkförmedling`,
    keywords: 'boka tolk, tolkar',
    image_file: 'og-image-default.jpg',
    image_width: '994',
    image_height: '560'
  })

  return { title, meta }
}

export const getLocaleRoutePath = getLocaleRoutePathRoutesHelper
export const updateQueryParams = updateQueryParamsRoutesHelper
export const removeQueryParams = removeQueryParamsRoutesHelper
export const getQueryParam = getQueryParamRoutesHelper
export const getUrlParam = getUrlParamRoutesHelper
