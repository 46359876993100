/*
|--------------------------------------------------------------------------
| Custom > Constants > Booking Type
|--------------------------------------------------------------------------
|
| This file contains the constants for Booking Type which value is
| equivalent to the Booking Type's code.
|
*/

export const PHYSICAL = 'physical'

export const PHONE = 'phone'

export const VIDEO = 'video'
export const VIDEO_EMERGENCY = 'video_emergency'

export const VIDEO_PHYSICAL = 'video_physical'

export const VIDEO_SKYPE = 'video_skype'

export const VIDEO_CLINIC = 'video_clinic'

export const COVEY = 'convey'

export const TEXT_TRANSLATION = 'text_translation'

export default {
  PHYSICAL,
  PHONE,
  VIDEO,
  VIDEO_PHYSICAL,
  VIDEO_CLINIC,
  VIDEO_SKYPE,
  COVEY,
  TEXT_TRANSLATION,
  VIDEO_EMERGENCY
}
