import LS from '@/modules/constants/localStorage'
import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import getAuthCollection from '@/modules/auth/store/shared/getAuthCollection'
import setAuthCollection from '@/modules/auth/store/shared/setAuthCollection'
import appStorage from '@/modules/helpers/appStorage'
import { removeCachedBooking } from '@/modules/booking/services/cachedBookingService'

function removeFromAuthCollection(context, userId) {
  let collection = getAuthCollection()

  if (!isEmpty(collection) && includes(collection, userId)) {
    // Remove the User's ID from AUTH COLLECTION localStorage.
    remove(collection, (id) => id === userId)

    // Remove the User's cached booking also.
    removeCachedBooking(userId)

    if (!isEmpty(collection)) {
      setAuthCollection(collection)
    } else {
      appStorage.remove(LS.AUTH_COLLECTION)
    }

    // Remove the User's token and data from localStorage.
    appStorage.remove(LS.AUTH_COLLECTION_USER + userId)

    context.commit('auth/setAuthenticatedUsers', collection, { root: true })

    if (context.state.user.id === userId) {
      context.commit('auth/destroyToken', null, { root: true })
      context.commit('auth/destroyUser', null, { root: true })
      context.commit('auth/clearPusher', userId, { root: true })
    }
  }
}

export default removeFromAuthCollection
