/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadDeepLLanguagesOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import appStorage from '../../../../helpers/appStorage'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { loadAll } from '../../../../helpers/vuexActions'

/**
 * Action to load language options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise|void}
 */
export default function (context) {
  if (context.getters['deepLLanguagesOpts'].length > 0) {
    return
  }
  const url = API.DEEPL_LANGUAGES
  const method = 'GET'
  let languages = appStorage.get(context.state.dtDeepLLanguagesCookieKey)
  // let updatedAt = appStorage.get(context.state.dtDeepLLanguagesUpdatedCookieKey)

  // context.commit('setLanguageOptsLoading', true)

  let options = {
    query_params: {
      all: 1
    },
    on_success(r, ctx) {
      ctx.commit('setDeepLLanguagesOpts', r.data)
      appStorage.set(context.state.dtDeepLLanguagesCookieKey, r.data)
    }
  }

  if (isNil(languages)) {
    return loadAll(this, context, url, method, 'setDeepLLanguagesOpts', options, axiosInstance)
  } else {
    return context.commit('setDeepLLanguagesOpts', languages)
  }
}
