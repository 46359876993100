/*
 |--------------------------------------------------------------------------
 | Store > Booking > Actions > acceptAnotherTranslator
 |--------------------------------------------------------------------------
 |
 */

/**
 * Action to accept another translator.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  // for now we only update q_specifics state
  context.commit('setTranslatorShortages', payload)
  return null
}
