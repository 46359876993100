/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load translator options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Object} payload.name - Filter for getting translators with specific name(s).
 * @param {Object} payload.id - Filter for getting translators with specific id(s).
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = API.TRAININGS
  const method = 'GET'

  let options = {
    query_params: {
      'all': true,
      'fields[trainings]': 'id,name',
      'sort': 'name',
      'filter[enabled]': 1
    },
    on_finally(ctx) {
      ctx.commit('auth/setTrainingOptsLoading', false, { root: true })
    }
  }

  if (!isNil(payload.name) && payload.name !== '') {
    options.query_params['filter[name]'] = payload.name
  }

  if (!isNil(payload.id) && payload.id !== '' && payload.id !== 0) {
    options.query_params['filter[id]'] = payload.id
  }

  return loadAll(this, context, url, method, 'setTrainingOpts', options, axiosInstance)
}
