import { reach } from '@/modules/helpers/vuexActions'
import { CUSTOMER_CREATE } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context) {
  const url = CUSTOMER_CREATE
  const method = 'post'
  let options = {
    data: context.state.registerForm,
    is_data_raw: true
  }

  return reach(this, context, url, method, options, axiosInstance)
}
