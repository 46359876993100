import { getBem } from '@/modules/helpers/components.js'
import { getLocaleRoutePath } from '@/modules/helpers/routes.js'

export default {
  install: (app) => {
    // insert global mixins here
    app.mixin({
      methods: {
        getBem,
        getLocaleRoutePath
      }
    })
  }
}
