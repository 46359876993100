/*
|--------------------------------------------------------------------------
| Store > Earnings > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/
export default function () {
  return {
    graph: [],

    list: {
      data: [],
      pagination: {}
    },

    amounts: {
      total: 0,
      current_year: 0
    }
  }
}
