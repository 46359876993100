/*
|--------------------------------------------------------------------------
| Custom > Helpers > Event Bus
|--------------------------------------------------------------------------
|
| This file is used as the global emitter of events.
|
*/

import mitt from 'mitt' // Import mitt

export default mitt()

// const EventBus = {
//   $emit: function (name, payload) {
//     if (payload) {
//       emitter.emit(name, payload)
//     } else {
//       emitter.emit(name)
//     }
//   },

//   $on: function (name, callback) {
//     if (callback) {
//       emitter.on(name, callback())
//     } else {
//       emitter.on(name)
//     }
//   },

//   $off: function (name, callback) {
//     if (callback) {
//       emitter.off(name, callback())
//     } else {
//       emitter.off(name)
//     }
//   }
// }

// export default EventBus
