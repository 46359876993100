/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > CountTotalBookings
|--------------------------------------------------------------------------
|
*/
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import store from '@/store'
/**
 * Action to load customer total bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @returns {Promise}
 */
export default async function (context) {
  // context.commit('setCustomerHistoricBookingListLoading', true)

  const url = `${API.BOOKINGS}`
  const method = 'GET'

  const user = await store.getters['auth/user']

  const fields = ['id,customer_id'].join(',')

  let options = {
    query_params: {
      'fields[bookings]': fields,
      // sort: '-id,-due',
      // 'filter[status_id]': statuses
      'filter[customer_id]': user.id
    },
    on_success(r, ctx) {
      ctx.commit('setCustomerTotalBookings', r.data.meta.pagination)
    }
  }

  return loadAll(this, context, url, method, 'setCustomerTotalBookings', options, axiosInstance)
}
