import cloneDeep from 'lodash/cloneDeep'

let formFresh = {
  type_id: '',
  amount: '',
  vat_amount: '',
  notes: '',
  attachments: []
}
export default () => {
  return {
    list: [],
    form: cloneDeep(formFresh),
    formFresh: cloneDeep(formFresh),
    expenseTypes: [],
    loading: false
  }
}
