<template>
  <focus-trap :active="isShowModal" :initial-focus="initFocus">
    <base-modal
      v-model="isShowModal"
      v-loading="loading"
      class="app-modal app-modal-secondary"
      style="zindex: 10000"
      :class="getBem(blockClass)"
      :title="modalTitle"
      transform-origin="center"
      preset="card"
      :width="isMobileViewPort ? '80%' : '50%'"
      :segmented="step > 1"
    >
      <base-row v-if="step === 1" :x-gap="12" :y-gap="8" :cols="isMobileViewPort ? 1 : 2">
        <base-column class="mode-item">
          <base-alert
            class="modal-alert"
            :title="$t('tt_label_auto_translation')"
            type="default"
            :class="{ 'disabled-alert': !booking.text_translation.is_trans_target }"
            @click="() => (booking.text_translation.is_trans_target ? handleOptionSelection('auto') : {})"
          >
            <template #icon>
              <base-icon>auto_awesome</base-icon>
            </template>
            <div>
              {{ $t('tt_label_review_translated_documents') }}
              <div
                v-if="incompatibleFilesCount > 0 && !booking.text_translation.is_trans_target"
                class="info-alert-chip"
              >
                <span>{{
                  $t('tt_label_documents_are_incompitable', {
                    incompatible: incompatibleFilesCount,
                    total: files.length
                  })
                }}</span>
              </div>
            </div>
          </base-alert>
        </base-column>
        <base-column class="mode-item">
          <base-alert
            class="modal-alert"
            :title="$t('tt_label_manual_translation')"
            type="default"
            @click="() => handleOptionSelection('manual')"
          >
            <template #icon>
              <base-icon>translate</base-icon>
            </template>
            <div>
              {{ $t('tt_label_download_customer_documents') }}
              <div
                v-if="isTextBookingManualSubmissionEnabled || manualSubmissionTillDate"
                class="deadline-warning-text"
              >
                <span>
                  {{
                    manualSubmissionTillDate && isManualSubmissionEnabled
                      ? $t('tt_feature_turned_till_date', { date: manualSubmissionTillDate })
                      : $t('tt_feature_enabled')
                  }}</span
                >
              </div>
            </div>
          </base-alert>
        </base-column>
      </base-row>

      <!-- Step 2 -->
      <div v-else-if="step === 2">
        <div v-if="augustDeadline" class="deadline-warning-section">
          <el-icon size="25" color="orange"><WarnTriangleFilled /></el-icon>
          <div>
            <span class="deadline-heading" v-html="$t('tt_manual_translation_deadline_heading')"></span>
            <p class="deadline-heading-note" v-html="$t('tt_manual_translation_deadline_note')"></p>
          </div>
          <div class="notification-actions">
            <el-button type="app-button-default-o outlined" size="small" @click="contactSupport">
              {{ $t('contact_support') }}
            </el-button>
          </div>
        </div>
        <base-text weight="500" type="heading" size="lg" align="left">
          {{ $t('tt_label_documents_to_translate') }}
        </base-text>
        <base-list>
          <base-list-item v-for="file in files" :key="file?.original_file?.id" class="list-item">
            <base-text type="body" size="sm" align="left">
              {{ addDotsForLongerFileName(file?.original_file?.display_name, 35) }}
            </base-text>
            <template #suffix>
              <base-button
                :disabled="downloadingFileId === file?.original_file?.id"
                :loading="downloadingFileId === file?.original_file?.id"
                class="download-btn"
                icon="download"
                size="small"
                type="secondary"
                @click="downloadSelectedFile(file?.original_file)"
              >
                {{ $t('tt_label_download_document') }}
              </base-button>
            </template>
          </base-list-item>
        </base-list>
      </div>

      <!-- Step 3 -->
      <div v-else-if="step === 3">
        <base-row :x-gap="12" :y-gap="8" :cols="1">
          <base-column>
            <base-form
              ref="formRef"
              :rules="rules"
              class="manual-translation-form"
              :model="form"
              size="medium"
              :disabled="isFormDisabled || filesSubmitted"
            >
              <!-- Formatting Hours -->
              <base-form-item :label="$t('tt_formatting_hours')" path="formatting_hours">
                <base-text-field v-model="form.formatting_hours" placeholder="0" @keydown.enter.prevent>
                </base-text-field>
              </base-form-item>
              <!-- Amount of words-->
              <base-form-item :label="$t('tt_label_amount_of_words')" path="amount_of_words">
                <base-text-field v-model="form.amount_of_words" placeholder="0" @keydown.enter.prevent>
                </base-text-field>
              </base-form-item>
            </base-form>
          </base-column>
          <base-column>
            <base-text weight="500" type="heading" size="lg" align="left">
              {{ $t('tt_label_documents_to_translate') }}
            </base-text>
          </base-column>
          <base-column>
            <!-- Files Table -->
            <base-table ref="mainTable" class="manual-translation-files-table" :data="files">
              <!-- Original column -->
              <template #orignal="data">
                <BaseTableColumn
                  column-key="orignal"
                  :title="$t('original')"
                  width="40%"
                  @mouseleave="originalFileDownloadId = null"
                  @mouseover="originalFileDownloadId = data.rowData?.original_file?.id"
                >
                  <base-space class="files-table-column">
                    <span class="original-file-name">{{
                      addDotsForLongerFileName(data.rowData?.original_file?.display_name, 18)
                    }}</span>
                    <base-button
                      v-if="originalFileDownloadId === data.rowData?.original_file?.id"
                      ghost
                      type="secondary"
                      size="tiny"
                      @click="downloadSelectedFile(data.rowData?.original_file)"
                    >
                      <base-icon class="icon" material-type="sharp" :size="22" color="#231F20"> download </base-icon>
                    </base-button>
                  </base-space>
                </BaseTableColumn>
              </template>

              <!-- Translated column -->
              <template #translated="data">
                <BaseTableColumn width="60%" column-key="translated" :title="$t('tt_label_translated')">
                  <div v-if="data.rowData.non_versioned_files_with_status.length > 0" class="translated-files-list">
                    <div
                      v-for="(file, fileIndex) in data.rowData.non_versioned_files_with_status"
                      :key="file?.id || file?.uid"
                    >
                      <base-space v-if="file?.translated_file && file?.rejected_file" class="files-table-column">
                        <div class="file-name-status-container">
                          <span class="translated-file-name">
                            {{
                              `${fileIndex + 1}. ${addDotsForLongerFileName(file?.translated_file?.display_name, 12)}`
                            }}
                          </span>
                          <base-tag
                            v-if="file?.rejected_file?.status === 'submitted'"
                            round
                            size="small"
                            :color="fileStatusTag.success"
                          >
                            {{ $t('tt_label_submitted') }}
                          </base-tag>
                        </div>
                        <div class="table-actions">
                          <aws-upload-field
                            :multiple="false"
                            :limit="1"
                            :is-upload-count-words="true"
                            :is-show-progress-bar="false"
                            :show-file-list="false"
                            class="resubmit-files-upload-field"
                            :on-change="() => (fileUploadingID = `${data.rowData.original_file?.id}-${fileIndex}`)"
                            @on-file-uploaded="
                              (uploadedFile) =>
                                handleOnFileUploaded(
                                  uploadedFile,
                                  data.rowData.original_file,
                                  fileIndex,
                                  file.translated_file
                                )
                            "
                          >
                            <template #upload="{ uploadProgress }">
                              <base-button
                                v-if="file?.rejected_file?.pivot?.submission_type === 'rejected_translation'"
                                size="tiny"
                                icon="upload"
                                type="secondary"
                                class="resubmit-btn"
                                :disabled="fileUploadingID === `${data.rowData.original_file?.id}-${fileIndex}`"
                                :loading="fileUploadingID === `${data.rowData.original_file?.id}-${fileIndex}`"
                              >
                                {{ uploadProgress > 0 ? `${uploadProgress}%` : $t('tt_label_re_submit') }}
                              </base-button>
                            </template>
                          </aws-upload-field>

                          <!-- Dropdown -->
                          <base-dropdown ref="mainDropdown" :show-arrow="true" trigger="click">
                            <div>
                              <base-button ghost type="secondary" size="tiny">
                                <base-icon class="icon" material-type="sharp" :size="22" color="#231F20">
                                  more_vert
                                </base-icon>
                              </base-button>
                            </div>
                            <template #dropdown>
                              <base-dropdown-item
                                key="download"
                                :props="{
                                  onClick: () => downloadSelectedFile(file?.rejected_file)
                                }"
                              >
                                <div>{{ $t('download') }}</div>
                                <template #icon>
                                  <base-icon size="20">download</base-icon>
                                </template>
                              </base-dropdown-item>
                            </template>
                          </base-dropdown>
                        </div>
                      </base-space>
                      <base-space v-else-if="!file?.translated_file && file?.rejected_file" class="files-table-column">
                        <div class="file-name-status-container">
                          <span class="translated-file-name">
                            {{ `${fileIndex + 1}. ${addDotsForLongerFileName(file?.rejected_file?.display_name, 12)}` }}
                          </span>
                          <base-tag
                            v-if="file?.rejected_file?.status === 'submitted'"
                            round
                            size="small"
                            :color="fileStatusTag.success"
                          >
                            {{ $t('tt_label_submitted') }}
                          </base-tag>
                          <base-tooltip v-else animated :duration="100" :overlap="false" trigger="hover">
                            <template #trigger>
                              <base-tag
                                v-if="file?.rejected_file?.pivot?.rejected_by_system_at"
                                round
                                size="small"
                                icon="error"
                                :color="fileStatusTag.rejected"
                              >
                                {{ $t('rejected_by_system') }}
                                <template #icon>
                                  <base-icon :filled="false" material-type="outlined" :size="16">
                                    {{ fileStatusTag.rejected.icon }}
                                  </base-icon>
                                </template>
                              </base-tag>
                              <base-tag v-else round size="small" icon="error" :color="fileStatusTag.rejected">
                                {{ $t('rejected') }}
                                <template #icon>
                                  <base-icon :filled="false" material-type="outlined" :size="16">
                                    {{ fileStatusTag.rejected.icon }}
                                  </base-icon>
                                </template>
                              </base-tag>
                            </template>
                            <template #default>
                              <span>{{ file?.rejected_file?.pivot?.rejection_reason }}</span>
                            </template>
                          </base-tooltip>
                        </div>
                        <div class="table-actions">
                          <aws-upload-field
                            :multiple="false"
                            :limit="1"
                            :is-upload-count-words="true"
                            :is-show-progress-bar="false"
                            :show-file-list="false"
                            class="resubmit-files-upload-field"
                            :on-change="() => (fileUploadingID = `${data.rowData.original_file?.id}-${fileIndex}`)"
                            @on-file-uploaded="
                              (uploadedFile) =>
                                handleOnFileUploaded(
                                  uploadedFile,
                                  data.rowData.original_file,
                                  fileIndex,
                                  file?.rejected_file
                                )
                            "
                          >
                            <template #upload="{ uploadProgress }">
                              <base-button
                                size="tiny"
                                icon="upload"
                                type="secondary"
                                class="resubmit-btn"
                                :disabled="fileUploadingID === `${data.rowData.original_file?.id}-${fileIndex}`"
                                :loading="fileUploadingID === `${data.rowData.original_file?.id}-${fileIndex}`"
                              >
                                {{ uploadProgress > 0 ? `${uploadProgress}%` : $t('tt_label_re_submit') }}
                              </base-button>
                            </template>
                          </aws-upload-field>

                          <!-- Dropdown -->
                          <base-dropdown ref="mainDropdown" :show-arrow="true" trigger="click">
                            <div>
                              <base-button ghost type="secondary" size="tiny">
                                <base-icon class="icon" material-type="sharp" :size="22" color="#231F20">
                                  more_vert
                                </base-icon>
                              </base-button>
                            </div>
                            <template #dropdown>
                              <base-dropdown-item
                                key="download"
                                :props="{
                                  onClick: () => downloadSelectedFile(file?.rejected_file)
                                }"
                              >
                                <div>{{ $t('download') }}</div>
                                <template #icon>
                                  <base-icon size="20">download</base-icon>
                                </template>
                              </base-dropdown-item>
                            </template>
                          </base-dropdown>
                        </div>
                      </base-space>
                      <base-space v-else-if="file?.translated_file" class="files-table-column">
                        <div class="file-name-status-container">
                          <span class="translated-file-name">
                            {{
                              `${fileIndex + 1}. ${addDotsForLongerFileName(file?.translated_file?.display_name, 18)}`
                            }}
                          </span>
                          <base-tag v-if="getIsFileSubmitted(file)" round size="small" :color="fileStatusTag.success">
                            {{ $t('tt_label_submitted') }}
                          </base-tag>
                        </div>

                        <div class="table-actions">
                          <!-- Dropdown -->
                          <base-dropdown
                            v-if="getIsFileSubmitted(file)"
                            ref="mainDropdown"
                            :show-arrow="true"
                            trigger="click"
                          >
                            <div>
                              <base-button ghost type="secondary" size="tiny">
                                <base-icon class="icon" material-type="sharp" :size="22" color="#231F20">
                                  more_vert
                                </base-icon>
                              </base-button>
                            </div>
                            <template #dropdown>
                              <base-dropdown-item
                                key="download"
                                :props="{
                                  onClick: () => downloadSelectedFile(file?.translated_file)
                                }"
                              >
                                <div>{{ $t('download') }}</div>
                                <template #icon>
                                  <base-icon size="20">download</base-icon>
                                </template>
                              </base-dropdown-item>
                            </template>
                          </base-dropdown>
                          <base-dropdown
                            v-else-if="!filesSubmitted"
                            ref="mainDropdown"
                            :show-arrow="true"
                            trigger="click"
                          >
                            <div>
                              <base-button ghost type="secondary" size="tiny">
                                <base-icon class="icon" material-type="sharp" :size="22" color="#231F20">
                                  more_vert
                                </base-icon>
                              </base-button>
                            </div>
                            <template #dropdown>
                              <base-dropdown-item
                                key="download"
                                :props="{
                                  onClick: () => downloadSelectedFile(file?.translated_file)
                                }"
                              >
                                <div>{{ $t('download') }}</div>
                                <template #icon>
                                  <base-icon size="20">download</base-icon>
                                </template>
                              </base-dropdown-item>
                              <base-dropdown-item key="divider" type="divider"></base-dropdown-item>
                              <base-dropdown-item
                                key="delete"
                                :props="{
                                  onClick: () => handleOnFileRemoved(data.rowData.original_file, fileIndex)
                                }"
                              >
                                <div>{{ $t('delete') }}</div>
                                <template #icon>
                                  <base-icon size="20">close</base-icon>
                                </template>
                              </base-dropdown-item>
                            </template>
                          </base-dropdown>
                        </div>
                      </base-space>
                    </div>
                  </div>
                  <div v-if="props.booking.translated_files_count <= 0 || hasRejectedFiles">
                    <base-space class="upload-doc-table-col">
                      <aws-upload-field
                        :multiple="true"
                        :show-file-list="false"
                        :is-upload-count-words="true"
                        :is-show-progress-bar="false"
                        :class="{
                          'upload-doc-field': data.rowData.non_versioned_files_with_status.length <= 0,
                          'attach-doc-field': data.rowData.non_versioned_files_with_status.length > 0
                        }"
                        :on-change="() => (fileUploadingID = data.rowData.original_file?.id)"
                        @on-file-uploaded="(file) => handleOnFileUploaded(file, data.rowData.original_file)"
                      >
                        <template
                          v-if="data.rowData.non_versioned_files_with_status.length <= 0"
                          #upload="{ uploadProgress }"
                        >
                          <base-button
                            size="small"
                            icon="upload"
                            class="download-btn upload-btn"
                            type="secondary"
                            :disabled="fileUploadingID === data.rowData.original_file?.id"
                            :loading="fileUploadingID === data.rowData.original_file?.id"
                          >
                            {{
                              uploadProgress > 0
                                ? `${$t('tt_label_uploading')} ${uploadProgress}%`
                                : $t('tt_label_upload_document')
                            }}
                          </base-button>
                        </template>
                        <template v-else #upload="{ uploadProgress }">
                          <base-button
                            size="small"
                            icon="upload"
                            class="attachment-upload-btn"
                            type="ghost"
                            :disabled="fileUploadingID === data.rowData.original_file?.id"
                            :loading="fileUploadingID === data.rowData.original_file?.id"
                          >
                            {{
                              uploadProgress > 0
                                ? `${$t('tt_label_uploading')} ${uploadProgress}%`
                                : $t('tt_label_attach_new_document')
                            }}
                          </base-button>
                        </template>
                      </aws-upload-field>
                    </base-space>
                  </div>
                </BaseTableColumn>
              </template>
            </base-table>
          </base-column>
        </base-row>
      </div>

      <!-- Action buttons -->
      <template v-if="step > 1" #action>
        <base-button ghost type="secondary" @click="toggleTranslationModesDialog"> {{ $t('cancel') }} </base-button>
        <base-button v-if="step === 2" type="secondary" @click="changeStep(3)">
          {{ $t('tt_label_go_next_submit_documents') }}
        </base-button>

        <base-button
          v-if="step === 3"
          type="secondary"
          :disabled="filesSubmitted || isFormDisabled || form.isSubmitting"
          :loading="form.isSubmitting"
          @click="submitForm"
        >
          {{
            props.booking.translated_files_count > 0 && hasRejectedFiles
              ? $t('tt_label_resubmit_documents')
              : $t('tt_label_submit_documents')
          }}
        </base-button>
      </template>
    </base-modal>
    <TextTranslationFeedbackSubmissionModal v-model="displayFeedbackModal" :booking="booking" />
  </focus-trap>
</template>
<script setup>
import { computed, watch, ref, defineEmits, defineExpose, defineProps, getCurrentInstance } from 'vue'
import { BaseList, BaseListItem, BaseDropdown, BaseDropdownItem } from '@dtolk/digitaltolk-ui'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'
import moment from 'moment'
// I18N
import i18n from '@/locales/i18nClient.js'

// STORE
import { useStore } from 'vuex'
import { ASSIGNED } from '../../../constants/bookingStatus'
import TextTranslationFeedbackSubmissionModal from './TextTranslationFeedbackSubmissionModal.vue'

// Use store
const store = useStore()

// Get App Instance
const app = getCurrentInstance()

// Initialize notify
const notify = app.root.appContext.config.globalProperties.$notify

// Get the user
const user = store.getters['auth/user']

// Props
const props = defineProps({
  booking: {
    type: Object,
    required: true
  }
})

// Constants
const formRef = ref()
const rules = ref({
  formatting_hours: {
    required: true,
    validator(rule, value) {
      if (!value) {
        return new Error(i18n.global.t('the_field_is_required', { attribute: i18n.global.t('tt_formatting_hours') }))
      } else if (!/^[+-]?\d+(\.\d+)?$/.test(value)) {
        return new Error(i18n.global.t('field_show_be_integer', { attribute: i18n.global.t('tt_formatting_hours') }))
      } else {
        return true
      }
    },
    trigger: ['blur', 'input']
  },
  amount_of_words: {
    required: true,
    validator(rule, value) {
      if (!value) {
        return new Error(
          i18n.global.t('the_field_is_required', { attribute: i18n.global.t('tt_label_amount_of_words') })
        )
      } else if (!/^-?\d+$/.test(value)) {
        return new Error(
          i18n.global.t('field_show_be_integer', { attribute: i18n.global.t('tt_label_amount_of_words') })
        )
      } else {
        return true
      }
    },
    trigger: ['blur', 'input']
  }
})

const displayFeedbackModal = ref(false)
const isShowModal = ref(false)
const loading = ref(false)
const blockClass = 'translation-modes-setup-modal'
const initFocus = ref(null)
const downloadingFileId = ref(null)
const step = ref(1)
const isFormDisabled = ref(true)
const originalFileDownloadId = ref(null)
const fileUploadingID = ref(null)
const form = ref({
  formatting_hours: '',
  amount_of_words: ''
})

const filesSubmitted = ref(false)
const files = ref([])
const isMobileViewPort = computed(() => {
  const isMobile = store.getters['auth/isOnMobileViewport']
  return isMobile
})

const incompatibleFilesCount = computed(() => {
  return props.booking?.text_translation?.files.filter((f) => f.pivot?.is_incompatible)?.length
})

const isManualSubmissionEnabled = computed(() => {
  return store.getters['textTranslation/getIsManualSubmissionEnabled']
})

const manualSubmissionTillDate = computed(() => {
  if (store.getters['textTranslation/getManualSubmissionTillDate']) {
    const endDate = moment(store.getters['textTranslation/getManualSubmissionTillDate'], 'YYYY-MM-DD HH:mm:ss').format(
      'DD-MM-YYYY'
    )
    return endDate
  } else {
    return null
  }
})

const isTextBookingManualSubmissionEnabled = computed(() => {
  return props.booking?.text_translation?.manual_submission_enabled
})

const hasRejectedFiles = computed(() => {
  return files.value.some((f) => f.non_versioned_files_with_status.some((status) => status.rejected_file))
})

const augustDeadline = computed(() => {
  const currentDate = new Date()
  const targetDate = new Date('2024-08-14') // August 14, 2024
  return currentDate < targetDate
})

const fileStatusTag = {
  success: { color: '#E5FFEF', textColor: '#00732A', borderColor: 'rgba(35, 31, 32, 0.04)' },
  rejected: {
    color: '#FFEBEB',
    textColor: '#8A0000',
    borderColor: 'rgba(35, 31, 32, 0.04)',
    iconColor: '#DB1F1F',
    icon: 'error'
  }
}

watch(
  () => files.value,
  (val) => {
    const hasUntranslatedFiles = val.some((f) =>
      f.non_versioned_files_with_status.some((status) => !status.translated_file)
    )

    // If there are no untranslated files, enable the form; otherwise, disable it
    isFormDisabled.value = hasUntranslatedFiles
  }
)

watch(
  () => hasRejectedFiles.value,
  (val) => {
    if (val) {
      filesSubmitted.value = false
    }
  }
)

watch(
  () => isShowModal.value,
  (val) => {
    if (!val) {
      closeModal()
    }
  }
)

const modalTitle = computed(() => {
  return step.value > 1 ? i18n.global.t('tt_label_manual_translation') : i18n.global.t('tt_label_mode_of_translation')
})

const emit = defineEmits(['auto-translation'])

function openModal() {
  if (!isTextBookingManualSubmissionEnabled.value && !isManualSubmissionEnabled.value) {
    emit('auto-translation')
    return
  }
  isShowModal.value = true
  files.value = textTranslationNewStructuredFiles()

  // If files have been submitted already
  if (props.booking.translated_files_count > 0) {
    // Set the files submitted flag to true

    filesSubmitted.value = true

    // Set the form values to the values from the booking
    form.value.amount_of_words = props.booking.text_translation?.words_amount
    form.value.formatting_hours = props.booking.text_translation?.hours_by_translator
  }
}

function closeModal() {
  isShowModal.value = false
  files.value = []
  isFormDisabled.value = true
  filesSubmitted.value = false
  form.value.amount_of_words = ''
  form.value.formatting_hours = ''
}

function toggleTranslationModesDialog(stepValue = 1) {
  if (isShowModal.value) {
    closeModal()
  } else {
    step.value = stepValue
    openModal()
  }
}

function handleOptionSelection(option) {
  // Update status
  if (props.booking.status_id === ASSIGNED) {
    const payload = {
      booking_id: props.booking?.id
    }
    store.dispatch('textTranslation/startTranslationStatus', payload)
  }

  if (option === 'auto') {
    // Do something
    emit('auto-translation')
  } else if (option === 'manual') {
    // Do something
    step.value = 2
  }
}

/**
 * Function to create a download link for the selected file
 */
function downloadSelectedFile(item) {
  if (item?.url) {
    return forceDownloadFileUrl(item?.url, item.file_name)
  }

  downloadingFileId.value = item.id

  let o = {
    params: `path=${item.path}&display_name=${item.display_name}`,
    filename: item.display_name
  }

  let url = `${API.STORAGE}/get?${o.params}`

  return axiosInstance.get(url).then((r) => {
    forceDownloadFileUrl(r.data.data.url, o.filename)
  })
}

/**
 * Function to delete translated file
 */
function handleOnFileRemoved(originalFile, index) {
  files.value = files.value.map((f) => {
    if (f.original_file.id === originalFile.id) {
      // If index is valid, remove the file at that index
      if (index >= 0 && index < f.non_versioned_files_with_status.length) {
        // if the file is rejected, set the translated file to null
        if (f.non_versioned_files_with_status[index]?.rejected_file) {
          f.non_versioned_files_with_status[index] = {
            ...f.non_versioned_files_with_status[index],
            translated_file: null
          }
        } else {
          f.non_versioned_files_with_status.splice(index, 1) // Remove the file at the given index
        }
      }
    }
    return f
  })

  // Optionally, clean up `fileUploadingID` if needed
  fileUploadingID.value = null
}

/**
 * Helper methods for handling download files to s3
 */
function forceDownloadFileUrl(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      downloadingFileId.value = null
    })
    .catch(console.error)
}

/**
 * Change the step
 */
function changeStep(stepValue) {
  step.value = stepValue
}

/**
 * Submit the form
 */
async function submitForm() {
  formRef.value.validate(async (errors) => {
    // If there are errors then do not proceed with submission
    if (errors) {
      return
    }

    // Set the form submission to true
    form.value.isSubmitting = true

    try {
      // If the booking doesn't have a mode of submission, set it to manual
      if (!props.booking.text_translation.mode_of_submission) {
        // Build payload for setting translation mode
        let payload = {
          mode_of_submission: 'manual',
          id: props.booking?.text_translation?.id,
          booking_id: props.booking?.id
        }

        // Send the payload
        await store.dispatch('textTranslation/submitTranslationMode', payload)
      }
    } catch (e) {
      form.value.isSubmitting = false
      return notify({
        title: i18n.global.t('error'),
        message: i18n.global.t('tt_label_some_error_occurred_while_submitting_files'),
        type: 'error'
      })
    }

    const tempFiles = []
    files.value.map((file) => tempFiles.push(...file.non_versioned_files_with_status))

    // Filter files with keys
    const filesWithKeys = tempFiles.filter((file) => file.translated_file?.key).map((i) => i.translated_file)

    // Build payload for file submission
    let payload = {
      files: filesWithKeys,
      hours_by_translator: parseFloat(form.value.formatting_hours),
      words_amount: parseFloat(form.value.amount_of_words),
      submission_type: 'submited_for_review',
      uploaded_by: user.id,
      id: props.booking?.text_translation?.id,
      mode_of_submission: 'manual',
      submitLoading: false
    }

    try {
      // Send the payload
      const response = await store.dispatch('textTranslation/submitTranslatedFile', payload)

      if (response?.data?.data?.text_translation?.id) {
        files.value = files.value.map((f) => {
          f.non_versioned_files_with_status = f.non_versioned_files_with_status.map((file) => {
            if (file.translated_file) {
              file.rejected_file = null
              file.translated_file = {
                ...file.translated_file,
                pivot: {
                  ...file.translated_file.pivot,
                  submission_type: 'submited_for_review'
                }
              }
            }
            return file
          })
          return f
        })

        // Emit the manual form submission
        emit('manual-form-submission')

        // display the feedback modal
        displayFeedbackModal.value = true

        filesSubmitted.value = true
        form.value.isSubmitting = false
      } else {
        notify({
          title: i18n.global.t('error'),
          message: i18n.global.t('tt_label_some_error_occurred_while_submitting_files'),
          type: 'error'
        })
      }
    } catch (e) {
      form.value.isSubmitting = false
      return notify({
        title: i18n.global.t('error'),
        message: i18n.global.t(e?.response?.data?.message),
        type: 'error'
      })
    }
  })
}

/**
 * textTranslation New Structured Files
 */
function textTranslationNewStructuredFiles() {
  const filteredFiles = props.booking?.text_translation?.files?.filter(
    (file) => file?.pivot?.submission_type === 'review'
  )

  const newStructuredFiles = filteredFiles.map((file) => {
    const originalFile = { ...file }

    let translatedFile = null
    let rejectedFile = null

    // Filter for non-versioned files to find the main translated file
    const nonVersionedFiles = file?.translated_files?.filter((tFile) => tFile?.pivot?.is_versioned === 0)

    if (nonVersionedFiles.length > 0) {
      // Take the first non-versioned file as the main translated file
      translatedFile = nonVersionedFiles[0]

      if (translatedFile.pivot.submission_type === 'rejected_translation') {
        rejectedFile = nonVersionedFiles[0]
        translatedFile = null
      } else if (props?.booking?.text_translation?.can_resubmit_file) {
        rejectedFile = nonVersionedFiles[0]
        rejectedFile.status = 'submitted'
        translatedFile = file
      }
    }

    const versionedFiles = file.translated_files.filter((tFile) => tFile?.pivot?.is_versioned === 1)

    const nonVersionedFilesWithStatus = nonVersionedFiles.map((file) => {
      let rejectedFile, translatedFile

      translatedFile = file

      if (file.pivot.submission_type === 'rejected_translation') {
        rejectedFile = file
        translatedFile = null
      } else if (props?.booking?.text_translation?.can_resubmit_file) {
        rejectedFile = file
        rejectedFile.status = 'submitted'
        translatedFile = file
      }

      return {
        id: file?.id,
        rejected_file: rejectedFile,
        translated_file: translatedFile,
        original_file: originalFile
      }
    })

    return {
      original_file: originalFile,
      translated_file: translatedFile,
      rejected_file: rejectedFile,
      versioned_files: versionedFiles,
      non_versioned_files: nonVersionedFiles || [],
      non_versioned_files_with_status: nonVersionedFilesWithStatus
    }
  })

  return newStructuredFiles
}

function addDotsForLongerFileName(string, limit) {
  let dots = '...'

  // Get the index of the last .
  var lastIndex = string.lastIndexOf('.')

  // Get the file extension
  let fileExt = string?.substr(lastIndex, string?.length + 1)

  // Get the file name
  const fileName = string?.substr(0, lastIndex)

  if (fileName?.length > limit) {
    // you can also use substr instead of substring
    string = fileName.substring(0, limit) + dots + fileExt
  }

  return string
}

function handleOnFileUploaded(file, originalFile, index, fileToReplace) {
  fileUploadingID.value = null
  files.value = files.value.map((f) => {
    if (f.original_file.id === originalFile.id) {
      if (index >= 0) {
        const newTranslatedFile = {
          ...file,
          original_file_id: originalFile.id,
          is_auto_reviewed: false,
          display_name: file?.name
        }

        if (fileToReplace) {
          newTranslatedFile.replacing_file_id = fileToReplace.id
        }

        f.non_versioned_files_with_status[index] = {
          ...f.non_versioned_files_with_status[index],
          translated_file: newTranslatedFile
        }
      } else {
        const newTranslatedFile = {
          ...file,
          original_file_id: originalFile.id,
          is_auto_reviewed: false,
          display_name: file?.name
        }

        if (fileToReplace) {
          newTranslatedFile.replacing_file_id = fileToReplace.id
        }

        f.non_versioned_files_with_status.push({
          rejected_file: null,
          translated_file: newTranslatedFile
        })
      }
    }

    return f
  })
}

function getIsFileSubmitted(file) {
  return (
    (file.translated_file && file?.translated_file?.pivot?.submission_type === 'submited_for_review') ||
    file?.translated_file?.pivot?.submission_type === 'completed'
  )
}

function contactSupport() {
  const subject = `CAT Tool support: [${user.name}]`
  const mailtoLink = `mailto:oversattning@digitaltolk.se?subject=${encodeURIComponent(subject)}`
  window.location.href = mailtoLink
}

defineExpose({
  toggleTranslationModesDialog
})
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.translation-modes-setup-modal {
  .disabled-alert {
    cursor: not-allowed !important;
    border: 1px solid $app-lightest-gray !important;
    background-color: $app-extra-lightest-gray !important;

    .n-alert-body__content {
      color: #9a9a9a !important;
    }
    .n-alert-body__title {
      color: #9a9a9a !important;
    }
  }

  .mode-item {
    height: 100%;

    .modal-alert {
      height: 100%;
      overflow: hidden;

      .n-alert-body {
        height: 100%;
        .deadline-warning-text {
          background-color: #fdf6eb;
          color: #333;
          border: 1px solid #fcedd6;
          border-radius: 8px;
          padding: 4px 2px;
          margin-top: 8px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
  .deadline-warning-section {
    display: flex;
    background-color: #fdf6eb;
    color: #333;
    border: 1px solid #fcedd6;
    border-radius: 8px;
    padding: 10px 8px;
    margin: 20px 0;
    font-size: 16px;
    text-align: start;
    gap: 18px;
    .deadline-heading {
      font-weight: 600;
    }
    .deadline-heading-note {
      font-size: 12px;
      color: #7b7979;
      margin-top: 0px;
    }
    .notification-actions {
      margin: 4px 2px 8px;
      align-content: center;
      .outlined {
        border: 1px solid !important;
        color: #231f20;
        padding: 8px 14px;
        background-color: inherit;
      }
    }
  }

  .resubmit-files-upload-field {
    height: 25px;
    max-width: 110px;
  }

  .resubmit-btn {
    width: 100%;

    .n-button__content {
      font-size: 12px;
      font-weight: lighter;
    }
  }

  .upload-doc-table-col {
    height: 32px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .table-actions {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row;
  }

  .translated-versioned-files {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .translated-files-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .files-table-column {
    display: flex;
    justify-content: space-between !important;
    align-items: center;

    .file-name-status-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    .original-file-name {
      color: #4f4c4d;

      /* text/label/md - prominent */
      font-family: 'Proxima Nova';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
    }

    .translated-file-name {
      color: #6e6e6e;

      /* text/label/md - prominent */
      font-family: 'Proxima Nova';
      font-size: 12px;
      font-style: normal;
      line-height: 16px; /* 133.333% */
    }
  }

  .manual-translation-form {
    .n-form-item {
      .n-form-item-label {
        font-weight: 500;
      }

      .n-form-item-feedback-wrapper {
        min-height: auto;
      }
    }
  }
  .manual-translation-files-table {
    tr {
      th {
        background-color: #ededed;
      }
    }
  }

  .n-card__action {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .modal-alert {
    box-shadow: unset !important;
    border: 1px solid $app-dark-gray;
    cursor: pointer;
    background-color: unset;
    min-height: 89px;
    overflow: hidden;

    :hover {
      background-color: $app-extra-lightest-gray !important;
    }

    .n-alert-body {
      padding-right: 0px !important;
      min-height: 100%;

      .info-alert-chip {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;

        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid #fcedd6;
        background: #fdf6eb;

        span {
          color: #231f20;

          /* text/label/md */
          font-family: 'Proxima Nova';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.5px;
        }
      }

      span:hover {
        background-color: #fdf6eb !important;
      }
      .info-alert-chip:hover {
        background-color: #fdf6eb !important;
      }
    }
  }
  .download-btn {
    .n-button__icon {
      order: 1;
      margin-left: 6px !important;
      margin-right: 0px;
    }
    .n-button__content {
      font-size: 12px;
    }
  }

  .upload-doc-field {
    height: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 170px;
  }

  .attach-doc-field {
    height: 18px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .upload-btn {
    height: 32px;
    width: 100%;
  }

  .attachment-upload-btn {
    height: 12px;
    width: 100%;
    padding: 8px 0px !important;

    .n-button__icon {
      order: 1;
      margin-left: 6px !important;
      margin-right: 0px;
    }

    .n-button__content {
      font-size: 12px;
      text-decoration: underline;
    }
  }
}
</style>
