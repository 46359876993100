import { BOOKING_SPECIFIC_PREFERENCES as API_BOOKING_SPECIFIC_PREFERENCES } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function redoPreferences(context, payload) {
  const url = `${API_BOOKING_SPECIFIC_PREFERENCES}/${payload.bookingSpecificPreferenceId}/redo`
  const method = 'POST'
  return reach(
    this,
    context,
    url,
    method,
    {
      data: payload.data,
      query_params: {
        signature: payload.signature
      }
    },
    axiosInstance
  )
}

export default redoPreferences
