/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerEmergencyBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'

/**
 * Action to load customer emergency bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  context.commit('setCustomerEmergencyBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    'customer',
    'translator_levels',
    'assigned_translator',
    'notification_types',
    'specific_translators',
    'excluded_translators',
    'video_booking',
    'messages.files',
    'convey.files'
  ].join(',')

  let options = {
    query_params: {
      include,
      // sort: 'due',
      'filter[status_id]': '1,2,3,11',
      'filter[is_immediate]': '1'
    },

    on_finally(ctx) {
      ctx.commit('setCustomerEmergencyBookingListLoading', false)
    }
  }

  const filterQueries = bookingsListFilterOptions(payload)

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, 'setCustomerEmergencyBookingList', options, axiosInstance)
}
