/*
|--------------------------------------------------------------------------
| Store > Expense > Actions
|--------------------------------------------------------------------------
|
*/

import { loadAll, reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'

export default {
  loadAllExpenseTypes(context) {
    const url = `/api/v3/financial/expense-types`

    // Define axios values.
    const method = 'GET'
    let options = {
      query_params: { all: true }
    }

    return loadAll(this, context, url, method, 'setExpenseTypes', options, axiosInstance)
  },

  loadBookingExpenses(context, payload) {
    context.commit('setLoading', true)
    const url = `/api/v3/financial/bookings/${payload.id}/expenses`

    const method = 'GET'
    let options = {
      enable_error_notif: true,
      query_params: {
        all: true,
        include: 'attachments,type'
      },
      on_finally(ctx) {
        ctx.commit('setLoading', false)
      }
    }

    return loadAll(this, context, url, method, 'setExpenseList', options, axiosInstance)
  },

  postExpense(context, payload) {
    context.commit('setLoading', true)

    let data = payload.data
    if (!isNil(data.attachments) && data.attachments.length > 0) {
      data.attachments_keys = data.attachments.map((i) => i.key)
    }
    let url = `/api/v3/financial/bookings/${payload.booking_id}/expenses`
    let method = 'POST'

    if (!isNil(payload.data.id)) {
      url = `/api/v3/financial/bookings/${payload.booking_id}/expenses/${payload.data.id}`
      method = 'PUT'
    }
    let options = {
      // enable_error_notif: true,
      data: data,
      on_success(r, ctx) {
        ctx.commit('resetForm')
      },
      on_error(e) {
        // console.log(e.request)
        console.log(e)
        if (e.request.status === 400) {
          showSmallErrorNotif('Cannot update expense', 'ERROR!!')
        }
      },
      on_finally(ctx) {
        ctx.commit('setLoading', false)
      }
    }

    return reach(this, context, url, method, options, axiosInstance)
  },

  deleteExpenseAttachement(context, payload) {
    let url = `/api/v3/financial/bookings/${payload.booking_id}/expenses/${payload.expense_id}/attachments/${payload.attachment_id}`
    let method = 'DELETE'
    let options = {}

    return reach(this, context, url, method, options, axiosInstance)
  }
}
