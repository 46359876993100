/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'

/**
 * Action to load translator normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  context.commit('setTranslatorNormalBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    // 'customer',
    // 'translator_levels',
    // 'assigned_translator',
    'video_booking',
    'booking_time_slots.translator.translator_unavailable_times'
    // 'messages.files',
    // 'convey.files',
    // 'translator_trainings',
    // 'direct_salary'
  ].join(',')

  const append = ['is_booking_due_greater_than_10_minutes']
  const topControls = await store.getters['bookingList/topControls']
  let statuses = topControls?.booking_statuses?.toString()
  let layout = store.getters['auth/isOnMobileViewport']
  let items_per_page = layout ? 50 : 10

  let options = {
    query_params: {
      include,
      // sort: 'due',
      'filter[status_id]': statuses,
      'filter[type]': [
        'physical',
        'phone',
        'video',
        'video_physical',
        'video_skype',
        'convey',
        'video_emergency',
        'temporary_work'
      ].join(','),
      append,
      'per_page': items_per_page
    },

    on_finally(ctx) {
      ctx.commit('setTranslatorNormalBookingListLoading', false)
      ctx.commit('setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }

  let emergency = topControls?.is_immediate ?? payload?.is_immediate ?? null
  console.log('emergency', emergency)
  if (emergency) {
    options.query_params['filter[is_immediate]'] = emergency
  } else {
    delete options.query_params['filter[is_immediate]']
  }
  let type = topControls?.type ?? payload?.type ?? null
  if (type) {
    payload.filter_list_query = type
  }

  const filterQueries = bookingsListFilterOptions(payload, {
    exclude: ['customer_id']
  })

  assignIn(options.query_params, filterQueries)

  // fix for CS-741
  if (options.query_params['filter[id]'] !== undefined && options.query_params['filter[id]'] !== null) {
    delete options.query_params['filter[status_id]']
  }

  return loadAll(this, context, url, method, 'setTranslatorNormalBookingList', options, axiosInstance)
}
