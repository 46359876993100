<template>
  <transition name="fullscreen-loader-fade">
    <div v-if="visible" :class="getBem(blockClass)" data-cy="fullscreen-loader">
      <div :class="getBem(blockClass, 'wrapper')">
        <div :class="getBem(blockClass, 'dots')">
          <div class="bounce1" />
          <div class="bounce2" />
          <div class="bounce3" />
        </div>

        <div v-if="isSlotExists('message')" :class="getBem(blockClass, 'message')">
          <slot name="message" />
        </div>

        <div v-else-if="message !== ''" :class="getBem(blockClass, 'message')">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    message: {
      type: String,
      default: ''
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'fullscreen-loader'
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    /**
     * Helper method to determine wether a Vue component slot exist.
     *
     * @param {String} slot - Name of the slot.
     * @returns {boolean}
     */
    isSlotExists(slot) {
      return !!this.$slots[slot]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';

.fullscreen-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 79999;
  text-align: center;
  display: table;
  background-color: #fff;

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__dots {
    display: inline-block;
    position: relative;
    top: -50px;
    margin: 100px auto 0;
    width: 100px;
    text-align: center;

    > div {
      width: 18px;
      height: 18px;
      background-color: var(--app-primary-color);
      margin: 0 5px;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.fullscreen-loader-fade-enter,
.fullscreen-loader-fade-leave-to {
  opacity: 0;
}

.fullscreen-loader-fade-enter-active,
.fullscreen-loader-fade-leave-active {
  transition: opacity 0.3s;
}
</style>
