/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadSalaryPreview
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  let options = {
    query_params: payload.params
  }

  // Define axios values.
  const url = `/api/v3/financial/bookings/${payload.id}/salary-preview`
  const method = 'GET'

  return reach(this, context, url, method, options, axiosInstance)
}
