<template>
  <el-dialog
    v-model="isShowModal"
    v-loading="isLoadingSubmit"
    class="app-modal app-modal-secondary"
    :class="getBem(blockClass)"
    :title="modalTitle"
    :close-on-click-modal="isMaskClosable"
    :close-on-press-escape="!isPublic"
    :show-close="!isPublic"
    top="3%"
    :before-close="handleClose"
  >
    <booking-feedback-form
      ref="booking-feedback-form"
      :key="booking.id"
      v-model="form"
      v-model:is-show-other-fields="isShowOtherFields"
      v-model:is-block-translator="iIsBlockTranslator"
      v-model:is-block-translator-for-department="iIsBlockTranslatorForDepartment"
      :is-read-only="isReadOnly"
      :booking="booking"
      :user-type="userType"
      :is-public="isPublic"
    />
    <br />

    <!-- Action Buttons -->
    <div v-if="!isReadOnly" :class="getBem(blockClass, 'actions')">
      <div>
        <template v-if="isUserCustomer">
          {{ $t('feedback_for_dt_msg') }}
          <button @click="handleClickFeedbackForDt" class="link-button">
            {{ $t('click_here') }}
          </button>
        </template>
      </div>
      <div>
        <el-button
          v-if="!isPublic"
          class="app-button-default"
          :class="getBem(blockClass, ['close-button'])"
          @click="handleClose"
        >
          {{ $t('btn_close') }}
        </el-button>

        <el-button
          :class="getBem(blockClass, ['submit-button'])"
          class="app-button-secondary"
          :disabled="submitBtnDisabled"
          @click="handleClickSubmit"
        >
          {{ $t('btn_submit') }}
        </el-button>
      </div>
    </div>
    <div v-else :class="getBem(blockClass, 'actions')" class="text-center">
      <div>
        <template v-if="isUserCustomer">
          {{ $t('feedback_for_dt_msg') }}
          <button @click="handleClickFeedbackForDt" class="link-button">
            {{ $t('click_here') }}
          </button>
        </template>
      </div>
      <el-button class="app-button app-button-secondary ok-btn" size="medium" @click="handleClose">
        {{ $t('ok') }}
      </el-button>
    </div>

    <feedback-for-dt-modal ref="feedback-for-dt-modal" />
  </el-dialog>
  <!-- Component DIV Wrapper -->
</template>

<script>
import BookingFeedbackForm from '@/modules/booking/components/forms/BookingFeedbackForm'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import { populate } from '@/modules/helpers/vuexCommons'
import { showSmallSuccessNotif, showSmallErrorNotif } from '@/modules/helpers/notifications'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > imported components
    |--------------------------------------------------------------------------
    */
  components: {
    BookingFeedbackForm
  },

  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    isPublic: {
      type: Boolean,
      default: false
    },

    userType: {
      type: Number,
      default: 2
    },

    isBlockTranslator: {
      type: Boolean
      // default: false
    },
    isBlockTranslatorForDepartment: {
      type: Boolean
    },

    isMaskClosable: {
      type: Boolean,
      default: true
    }
  },

  emits: [
    'submit',
    'increaseFeedbackCount',
    'reloadList',
    'update:isBlockTranslator',
    'update:isBlockTranslatorForDepartment'
  ],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'booking-feedback-modal',
      isShowModal: false,
      booking: {},
      form: {
        rating: 0,
        comment: '',
        reason: [],
        createdAt: '',
        translator_delay_time: ''
      },
      isShowOtherFields: false,
      currentBooking: null,
      isBlocked: false,
      isBlockedForDepartment: false
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    ...mapGetters('booking', ['isLoadingSubmit', 'customerAndTranslatorOtherBookingListData']),

    ...mapGetters('auth', ['user', 'isUserCustomer']),

    userMeta() {
      return this.$store.getters['auth/userMetaData']
    },

    /**
     * Returns boolean to check whether the Booking has feedback submitted already
     * making the form to be read-only.
     *
     * @return {Boolean}
     */
    isReadOnly() {
      if (!isNil(this.booking) && !isNil(this.booking.feedback) && !isEmpty(this.booking.feedback)) {
        const feedbackFrom = find(this.booking.feedback, {
          review_from_id: this.user.id
        })
        if (!isNil(feedbackFrom)) {
          this.form.rating = feedbackFrom.rating
          this.form.comment = feedbackFrom.comment
          this.form.createdAt = feedbackFrom.created_at
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    /**
     * Interface for the this.isBlockTranslator prop.
     */
    iIsBlockTranslator: {
      get() {
        return this.isBlockTranslator
      },
      set(v) {
        this.isBlocked = v
        this.$emit('update:isBlockTranslator', v)
      }
    },

    iIsBlockTranslatorForDepartment: {
      get() {
        return this.isBlockTranslatorForDepartment
      },
      set(v) {
        this.isBlockedForDepartment = v
        this.$emit('update:isBlockTranslatorForDepartment', v)
      }
    },

    /**
     * Value to be used as the modal title.
     *
     * @return {String}
     */
    modalTitle() {
      return this.isReadOnly ? this.$t('booking_feedback_title') : this.$t('booking_feedback_provide_title')
    },

    submitBtnDisabled() {
      return this.form.rating <= 0
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
  mounted() {
    this.isShowOtherFields = this.isPublic
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions('auth', ['readAuthUserMeta', 'blockTranslator', 'blockTranslatorForDepartment']),

    ...mapActions('booking', ['loadCustomerAndTranslatorOtherBookings', 'submitFeedback', 'getBookingDetails']),

    /**
     * Handle when the Submit button was clicked.
     *
     * @returns {void}
     */
    handleClickSubmit: debounce(function () {
      if (this.$refs['booking-feedback-form'].isReasonSelected()) {
        this.$refs['booking-feedback-form'].showReasonError = false
      } else {
        this.$refs['booking-feedback-form'].showReasonError = true
        return
      }

      if (this.$refs['booking-feedback-form'].showBlockTranslatorFields) {
        if (
          this.$refs['booking-feedback-form'].isBlockTranslator ||
          this.$refs['booking-feedback-form'].isBlockTranslatorForDepartment
        ) {
          this.$refs['booking-feedback-form'].showBlockOptionsError = false
        } else {
          this.$refs['booking-feedback-form'].showBlockOptionsError = true
          return
        }
      }

      // Security
      // if ((this.form.rating <= 2 && this.form.comment.length > 0) || this.form.rating > 2) {
      this.$refs['booking-feedback-form']?.$refs?.form?.validate((valid) => {
        if (valid) {
          // Set the value to whom the review is supposed to be.
          const reviewToId = this.isUserCustomer
            ? this.booking.assigned_translator.translator_id
            : this.booking.customer_id

          // Set payload.
          const payload = {
            booking_id: this.booking.id,
            data: {
              ...this.form,
              review_to_id: reviewToId
            }
          }

          if (this.isPublic) {
            this.$emit('submit', payload)
          } else {
            // DEAL WITH SUBMISSION IN THE MODAL
            this.$store.commit('booking/setSubmitLoading', true)

            // Do the Submit Feedback Action.
            this.submitFeedback(payload)
              .then(() => {
                this.$store.commit('booking/setSubmitLoading', false)
                this.close()
                this.$emit('increaseFeedbackCount')
                showSmallSuccessNotif(this.$t('booking_feedback_success'))
                this.$emit('reloadList')
                window._paq?.push(['trackEvent', 'actions', 'feedback_given', this.$store.getters['auth/user'].id])
              })
              .catch((e) => {
                this.$store.commit('booking/setSubmitLoading', false)
                this.close()
                this.$emit('increaseFeedbackCount')
                let errTitle = `${this.$t('error')} - ${this.$t('booking_feedback_fail')}`
                showSmallErrorNotif(e, errTitle)
              })

            // Do the Blacklist Translator if it's preferred.
            if (this.isBlocked) {
              this.blockTranslator({
                user_id: this.user.id,
                translator_id: payload.data.review_to_id
              })
                .then(() => {
                  showSmallSuccessNotif(this.$t('booking_feedback_success'))
                })
                .catch((e) => {
                  let errTitle = `${this.$t('error')} - ${this.$t('booking_feedback_fail')}`
                  showSmallErrorNotif(e, errTitle)
                })
            }
            console.log('BFM: trying to block for department', this.isBlockTranslatorForDepartment)
            // Do the Blacklist Translator Department if it's preferred.
            if (this.isBlockedForDepartment) {
              console.log('BookingFeedbackModal::user', this.user)
              this.blockTranslatorForDepartment({
                department_id: this.userMeta.customer_data.department_id,
                translator_id: payload.data.review_to_id
              })
                .then(() => {
                  showSmallSuccessNotif(this.$t('booking_feedback_success'))
                })
                .catch((e) => {
                  let errTitle = `${this.$t('error')} - ${this.$t('booking_feedback_fail')}`
                  showSmallErrorNotif(e, errTitle)
                })
            }
          }
        }
      })
      // } else {
      //   this.$refs['booking-feedback-form'].validate()
      // }
    }, 500),

    /**
     * Handler when the Close button was clicked.
     *
     * @returns {void}
     */
    handleClose() {
      this.close()
      // this.form.rating = 0; // Reset Rating.
    },

    handleClickFeedbackForDt() {
      this.$refs['feedback-for-dt-modal'].open(this.booking)
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking) {
      let promise = this.isPublic
        ? new Promise((r) => r({ data: { data: { booking } } }))
        : this.getBookingDetails({ id: booking.id })

      promise.then((response) => {
        this.booking = response.data.data.booking
        this.isShowModal = true
        this.getJobs()
        if (isNil(this.currentBooking) || this.currentBooking.id !== booking.id) {
          this.form.rating = this.isPublic && !isNil(this.$route.query.rating) ? parseInt(this.$route.query.rating) : 0
          this.form.comment = ''
          this.form.reason = []
          this.form.createdAt = ''
          this.currentBooking = booking
          this.form.translator_delay_time = ''
        }
      })
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
    },

    /**
     * Retrieves bookings assigned to a specific translator.
     *
     * @returns {void}
     */
    getJobs() {
      this.readAuthUserMeta().then(() => {
        // TODO: seems like this endpoint only accessible to customer, that's why i add the condition
        if (this.isUserCustomer) {
          populate(this, 'loadCustomerAndTranslatorOtherBookings', 'customerAndTranslatorOtherBookingListData', {
            label: 'Customer and Translator Other Booking List Data',
            force: true,
            payload: {
              translator_id: this.booking.assigned_translator.translator.id,
              customer_id: this.user.id
            }
          })
        }
      })
    },

    reloadList() {
      this.close()
      this.$emit('reloadList')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.booking-feedback-modal,
.booking-feedback-modal .el-dialog {
  width: 60%;
}

.booking-feedback-modal__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .link-button {
    color: $app-primary-color;
    cursor: pointer;
    text-decoration: underline;
    background: none;
    padding: 0;
  }
}

.booking-feedback-modal p {
  text-align: center;
}

@media (max-width: $screen-xs-max) {
  .booking-feedback-modal .el-dialog {
    width: 90%;
  }
}
</style>
