/*
|--------------------------------------------------------------------------
| Store > Actions
|--------------------------------------------------------------------------
| File that contains the actions property for the current Vuex scope.
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

export default {
  getPriceLists(context, payload = {}) {
    context.commit('setPriceListsLoading', true)

    let url = API.PRICE_LISTS
    const method = 'GET'
    let params = {
      'per_page': 5,
      'sort': '-updated_at',
      'filter[id]': '',
      'filter[body_title]': '???'
    }

    if (!isNil(payload.page)) {
      params.page = payload.page
    }

    if (!isNil(payload.per_page)) {
      params.per_page = payload.per_page
    }

    url = url + '?all'
    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setPriceListsData', r)
          context.commit('setPriceListsPagination', r)
          context.commit('setPriceListsLoading', false)
          resolve(r)
        })
        .catch((e) => {
          context.commit('setPriceListsLoading', false)
          reject(e)
        })
    })
  },

  /**
   * Method to get searched priceLists
   * @param {*} context
   * @param {*} payload
   */
  searchPriceLists(context, payload) {
    context.commit('setPriceListsLoading', true)

    let url = API.PRICE_LISTS
    const method = 'GET'
    let params = {
      'per_page': 5,
      'sort': '-updated_at',
      'filter[id]': payload.id,
      'filter[status]': '1',
      'filter[body_title]': payload.search,
      'page': payload.page
    }

    return new Promise((resolve, reject) => {
      axiosInstance({
        url,
        method,
        params
      })
        .then((r) => {
          context.commit('setPriceListsData', r)
          context.commit('setPriceListsPagination', r)
          context.commit('setPriceListsLoading', false)
          resolve(r)
        })
        .catch((e) => {
          context.commit('setPriceListsLoading', false)
          reject(e)
        })
    })
  }
}
