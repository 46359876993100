/*
|--------------------------------------------------------------------------
| Store > Booking > Text Translation > Actions > submitBooking
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload = {}) {
  const url = `${API.TEXT_TRANSLATION}`
  context.commit('setSubmitLoading', true)

  let headers = {
    'Content-Type': 'multipart/form-data'
  }

  return new Promise((resolve, reject) => {
    axiosInstance({ url, method: 'POST', data: payload, headers })
      .then((r) => {
        context.commit('setSubmitLoading', false)
        resolve(r)
      })
      .catch((e) => {
        context.commit('setSubmitError', true)
        context.commit('setSubmitLoading', false)
        reject(e)
      })
  })
}
