import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance.js'

export default function (context, payload = {}) {
  const url = `${API.BOOKINGS}/${payload.id}/travel-calculation`
  const method = 'get'

  let options = {}

  return reach(this, context, url, method, options, axiosInstance)
}
