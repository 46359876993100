<template>
  <el-dialog
    class="app-modal app-modal-danger"
    :class="getBem(blockCls)"
    :title="$t('warning')"
    v-model="isShowModal"
    :append-to-body="true"
    top="5%"
    width="350px"
    :close-on-click-modal="false"
  >
    <div style="word-break: normal !important" :class="getBem(blockCls, 'wrapper')">
      Vänligen ge låg feedback (1-3 stjärnor) endast om tolkanvändaren varit otrevlig, ljudet vart lågt eller om
      upplevelsen på annat sätt varit dålig
      <!--  <a href="javascript:;" @click="handleChange">här</a>-->
      <b>Om kunden ej ringt, klicka istället </b>
      <el-button type="text" @click="handleChange"> här</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    booking: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isShowModal: false,
      blockCls: 'feedback-message'
    }
  },

  methods: {
    ...mapActions('booking', ['notCarriedOutToCustomer']),
    handleChange() {
      this.notCarriedOutToCustomer(this.booking).then(() => {
        this.close()
        this.$notify({
          type: 'success',
          message: 'Vi har nu registrerat att kunden ej har ringt eller att klienten ej kommit'
        })
      })
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @returns {void}
     */
    open() {
      this.isShowModal = true
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
      window.location.reload()
    },

    /**
     * Method to close the modal window (without refresh)
     * This will close the modal.
     *
     * @returns {void}
     */
    closeSilently() {
      this.isShowModal = false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.feedback-message {
  &__wrapper {
    .el-button {
      padding: 0 !important;
    }
  }
}
</style>
