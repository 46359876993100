/*
|--------------------------------------------------------------------------
| Store > Expense > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/
export default {
  /**
   *
   * @param state
   * @returns {[]}
   */
  expenseTypes: (state) => state.expenseTypes,
  list: (state) => state.list,
  loading: (state) => state.loading
}
