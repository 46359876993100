/*
|--------------------------------------------------------------------------
| Store > Payment > Actions > deletePaymentMethod
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to delete payment method
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  if (isNil(payload.id)) console.error('payment method id value is required.')

  const url = API.CUSTOMER_PAYMENT_METHODS
  const method = 'DELETE'

  let data = {
    payment_method_id: payload.id
  }

  let options = {
    is_data_raw: true,
    data
  }

  return reach(this, context, url, method, options, axiosInstance)
}
