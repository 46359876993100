<template>
  <div class="app-layout" :class="layoutClass">
    <div :class="getBem(layoutClass, 'wrapper')">
      <main>
        <fullscreen-loader
          :visible="$store.getters['fullscreenLoader/visible']"
          :message="$store.getters['fullscreenLoader/message']"
        />
        <slot />
      </main>
    </div>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import FullscreenLoader from '@/modules/auth/components/displays/FullscreenLoader'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    FullscreenLoader
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'blank-layout'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    /**
     * @returns {array}
     */
    layoutClass() {
      const isMaintenanceMode = import.meta.env.VITE_MAINTENANCE_ENABLED || this.$route.path === '/maintenance-mode'

      const viewportClass = this.isOnMobileViewport ? 'on-mobile-viewport' : 'on-desktop-viewport'
      const authClass = this.isUserAuthenticated ? 'user-authenticated' : 'user-not-authenticated'
      const showMobileNavClass = this.isShowMobileNav ? 'mobile-nav-shown' : 'mobile-nav-hidden'
      const maintenanceModeClass = isMaintenanceMode ? 'is-maintenance-mode' : ''

      if (document != null)
        document.body.className = [viewportClass, authClass, showMobileNavClass, maintenanceModeClass].join(' ')

      return [this.getBem(this.blockClass)]
    }
  }
}
</script>

<!--suppress CssUnknownTarget -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.v-modal {
  z-index: 2000 !important;
}
</style>
