import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { reach } from '@/modules/helpers/vuexActions'
export default function (context) {
  const method = 'GET'
  let url = API.LOAD_STATISTICS_SUBSCRIPTIONS
  let options = {
    on_success: (r) => {
      console.log('on_success', r)
      context.commit('setStatisticsSubscriptions', r.data.data.subscriptions)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
