/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > removeTranslatorsFromBooking
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to remove translators from a booking.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {Array} payload.booking_ids - IDs for the target booking
 *
 * @return {Promise}
 */
export default function (context, payload) {
  const { booking_ids } = payload

  // Make error loggers to make sure required values are filled in.
  if (isNil(booking_ids)) {
    console.error('Booking IDs should exist!')
  }

  let options = {
    data: {
      booking_ids
    },
    on_success(r, ctx) {
      ctx.dispatch('auth/readAuthUserMeta', {}, { root: true })
    }
  }

  // Define axios values.
  const url = `${API.BOOKINGS}/customer/remove-translators`
  const method = 'POST'

  return reach(this, context, url, method, options, axiosInstance)
}
