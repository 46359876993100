/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitBooking
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import isEmpty from 'lodash/isEmpty'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context) {
  // Prepare the payload to be sent across the API.
  context.commit('setConfirmFormPayload', {
    user: context.rootGetters['auth/userMetaData'],
    fallbackBookingType: context.rootGetters['auth/fallbackBookingType'],
    isGuestMode: context.rootGetters['auth/isGuestMode']
  })
  // context.commit('setSubmitLoading', true);

  let url = API.BOOKINGS
  if (context.rootGetters['auth/isGuestMode']) {
    url = API.GUEST_BOOKINGS
  }
  const method = 'post'

  let data = { ...context.state.confirmFormPayload }
  data.guest_user = context.rootState.booking.guestPayloadForm

  const isAutoPriceEnabled = context.rootGetters['auth/isAutoPriceEnabled']
  const isNonAutoPricingTextTranslation = ['image', 'audio', 'video'].includes(data.text_translation_type)
  const hasUnsupportedWordsCountDocument = context.rootGetters['textTranslation/hasUnsupportedWordsCountDocument']
  const isManualPriceQuote = context.rootGetters['textTranslation/isShowManualPriceQuote']

  if (
    isAutoPriceEnabled &&
    data.is_asking_price &&
    !isNonAutoPricingTextTranslation &&
    !hasUnsupportedWordsCountDocument
  ) {
    data = {
      ...data,
      is_asking_price: 0
    }
  }
  if (isManualPriceQuote) {
    data = {
      ...data,
      is_asking_price: 1
    }
  }
  const typeToFileMap = {
    document: 'text_translation_document_files',
    image: 'text_translation_image_files',
    audio: 'text_translation_audio_files',
    video: 'text_translation_video_files'
  }

  if (typeToFileMap.hasOwnProperty(data.text_translation_type) && !data?.is_ai_flow) {
    data = {
      ...data,
      text_translation_files: data[typeToFileMap[data.text_translation_type]]
    }
  }

  if (isEmpty(data.descriptions)) {
    data.descriptions = 'text translation'
  }

  if (!isEmpty(data.additional_text)) {
    data = {
      ...data,
      additional_text_words_count: data?.ai_text_translation_type
        ? data?.additional_text_words_count
        : context.rootGetters['textTranslation/totalWords']
    }
  }

  data.created_via = context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'

  if (data?.ai_text_translation_type) {
    data.text_translation_type = data.ai_text_translation_type
  }

  Object.keys(typeToFileMap).forEach((key) => {
    if (data.hasOwnProperty(typeToFileMap[key])) {
      delete data[typeToFileMap[key]]
    }
  })

  let options = {
    data,
    is_data_raw: true,
    //
    on_success(r, ctx) {
      ctx.commit('setCreatedBookings', r.data.data.booking)
      //   ctx.commit('cacheBookingValues', {booking: data, key: 'create'});
      //   //   ctx.commit('resetForm');
      //   //   ctx.commit('resetFormPayload');
      //   //   ctx.commit('resetConfirmForm');
      //   //   ctx.commit('resetConfirmFormPayload');
    }
    //   //
    //   // on_finally (ctx) {
    //   //   ctx.commit('setSubmitLoading', false);
    //   // },
  }

  //
  return reach(this, context, url, method, options, axiosInstance)
}
