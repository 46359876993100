<template>
  <div :class="getBem(blockClass)">
    <!--suppress HtmlUnknownTarget -->
    <img
      v-if="src === ''"
      :class="[getBem(blockClass, 'img'), getBem(blockClass, 'img-placeholder')]"
      src="@/assets/images/avatar-placeholder.jpg"
      :alt="$t('user_avatar')"
    />

    <div
      v-else
      :class="[getBem(blockClass, 'img'), getBem(blockClass, 'img-actual')]"
      :style="{ backgroundImage: `url(${src})` }"
      :alt="$t('user_avatar')"
    />
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
export default {
  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    src: {
      type: String,
      default: ''
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'profile-avatar'
    }
  }
}
</script>
