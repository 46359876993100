<template>
  <focus-trap :active="showModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="showModal"
      :title="$t('booking_changes')"
      :close-on-click-modal="false"
      class="app-modal app-modal-secondary"
      :class="blockClass"
      :append-to-body="true"
      width="50%"
      :before-close="handleModalClose"
    >
      <a ref="init_focus" style="font-size: 1px; display: flex" tabindex="-1" href="#">.</a>
      <div v-loading="getBookingChangesNotificationLoading || loading" class="modal-wrapper">
        <h5 class="heading">{{ $t('assigned_booking') }}:</h5>
        <p class="old-booking-details">
          <b>
            Booking #{{ notificationData.booking_id }} | {{ languageOpts[oldBookingDetails.from_language_id] }} |
            {{ $t(`booking_type_${oldBookingDetails.type}`) }} | {{ formatDateTime(oldBookingDetails.due) }} -
            {{ oldBookingDetails.end_time }}
          </b>
        </p>

        <p>{{ modalText }}</p>

        <el-table :data="bookingChanges">
          <!--Col name-->
          <el-table-column label="" align="center">
            <template #default="{ row }">
              {{ $t(row.column) }}
            </template>
          </el-table-column>

          <!--From-->
          <el-table-column :label="$t('from')" align="center">
            <template #default="{ row }">
              <div v-html="transformValue(row.from, row.column)"></div>
            </template>
          </el-table-column>

          <!--Arrow-->
          <el-table-column width="70" align="center">
            <template #default>
              <span> → </span>
            </template>
          </el-table-column>

          <!--To-->
          <el-table-column :label="$t('to')" align="center">
            <template #default="{ row }">
              <div v-html="transformValue(row.to, row.column)"></div>
            </template>
          </el-table-column>
        </el-table>

        <div class="action-buttons">
          <el-button class="app-button-default" @click="handleModalClose">
            {{ canAccept ? $t('btn_ignore') : $t('btn_close') }}
          </el-button>
          <el-button v-if="canAccept" class="app-button-danger" @click="handleRejectBooking">
            {{ $t('reject') }}
          </el-button>
          <el-button v-if="canAccept" class="app-button-success" @click="handleAcceptBooking">
            {{ $t('accept') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-trap>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { each, isArray } from 'lodash'
import findKey from 'lodash/findKey'
import isNil from 'lodash/isNil'
import getTranslatorLevels from '@/modules/constants/translatorLevel'
import { showSmallErrorNotif, showSmallSuccessNotif } from '@/modules/helpers/notifications'
import isEmpty from 'lodash/isEmpty'
import EventBus from '@/modules/helpers/eventBus'
import EVENT from '@/modules/constants/event'
import API from '@/modules/constants/api'
import { apiCall } from '@/modules/helpers/vuexActions'
import { formatDateTime } from '@/modules/helpers/common'
import { acceptBookingCommon } from '@/modules/helpers/booking'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'booking-changes-modal',
      bookingChanges: [],
      booking: {},
      notificationData: {},
      loading: false
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('booking', ['languageOpts']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('email', [
      'getLoadingStatus',
      'getGlobalModalBookingId',
      'getShowBookingChangesModal',
      'getBookingChangesNotification',
      'getBookingChangesNotificationLoading'
    ]),

    showModal: {
      get() {
        return this.getShowBookingChangesModal
      },
      set(v) {
        this.$store.commit('email/setBookingChangesModal', v)
      }
    },

    notificationType() {
      return this.notificationData?.notification_type
    },

    bookingAlreadyAccepted() {
      return !isEmpty(this.booking.assigned_translator)
    },

    canAccept() {
      return (
        !this.notificationType?.includes('not') &&
        !this.notificationType?.includes('assigned') &&
        !this.bookingAlreadyAccepted
      )
    },

    modalText() {
      let modalText = ''

      if (this.canAccept) {
        modalText = this.$t('translator_match_after_customer_changes_modal_text')
      } else if (this.notificationType?.includes('assigned')) {
        modalText = this.$t('translator_assigned_after_customer_changes_modal_text')
      } else {
        modalText = this.$t('translator_did_not_match_after_customer_changes_modal_text')
      }

      return modalText
    },

    oldBookingDetails() {
      return this.notificationData.old_booking ?? {}
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > watch
  |--------------------------------------------------------------------------
  */
  watch: {
    async getBookingChangesNotification(bookingChangesNotification) {
      if (!bookingChangesNotification) {
        return
      }
      this.setLoading(true)
      this.notificationData = bookingChangesNotification
      await this.loadLanguageOpts()
      await this.getBookingDetails(this.notificationData.booking_id).then((r) => {
        this.booking = r.data?.data?.booking ?? {}
      })
      this.transformBookingChanges()
      this.setLoading(false)
    },

    async getGlobalModalBookingId(bookingId) {
      if (!bookingId) {
        return
      }
      await this.getLatestBookingDatabaseNotification(bookingId)
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('booking', ['getBookingDetails', 'acceptBooking', 'rejectBooking', 'loadLanguageOpts']),

    formatDateTime,
    acceptBookingCommon,
    transformBookingChanges() {
      const keysToRemove = ['due', 'will_end_at', 'specific_translators']
      each(this.getBookingChangesNotification.changes, (change, key) => {
        if (!keysToRemove.includes(key)) {
          change.column = key
          this.bookingChanges.push(change)
        }
      })
    },

    setLoading(state) {
      this.$store.commit('email/setBookingChangesNotificationLoading', state)
    },

    transformValue(value, column) {
      try {
        let responseValue = null

        if ((isEmpty(value) && isArray(value)) || !value) {
          return ['gender'].includes(column) ? this.$t('all') : this.$t('none')
        }
        switch (column) {
          case 'from_language_id':
          case 'to_language_id':
            responseValue = this.languageOpts[value]
            break

          case 'translator_levels':
            // eslint-disable-next-line no-case-declarations
            let levels = ''
            each(value, (level) => {
              levels += `<span class="el-tag el-tag--light">${this.findTranslatorLevel(level)}</span>`
            })
            responseValue = levels
            break

          case 'specific_translators_names':
            // eslint-disable-next-line no-case-declarations
            let names = ''
            each(value, (name) => {
              name = name === 'Anonym' ? this.$t('anonym') : name
              names += `<span class="el-tag el-tag--light">${name}</span>`
            })
            responseValue = names
            break

          case 'type':
            responseValue = this.$t(`booking_type_${value}`)
            break

          case 'gender':
            if (value.includes(',')) {
              value = value.split(',')
            }
            responseValue = isArray(value) ? `${this.$t(value[0])}, ${this.$t(value[1])}` : this.$t(value)
            break

          default:
            responseValue = value
            break
        }
        return responseValue
      } catch (err) {
        console.log('transform error', err)
      }
    },

    findTranslatorLevel(levelId) {
      const key = findKey(getTranslatorLevels(), (id) => id === parseInt(levelId))
      return !isNil(key) ? this.$t(`translator_level_${key.toLowerCase()}`) : ''
    },

    handleModalClose() {
      this.bookingChanges = []
      this.$store.commit('email/setBookingChangesNotification', null)
      this.$store.commit('email/setGlobalModalBookingId', null)
      this.showModal = false
      this.setLoading(false)
    },

    handleAcceptBooking() {
      this.setLoading(true)
      const payload = {
        booking_id: this.notificationData.booking_id,
        translator_id: this.user.id,
        time_slots: this.booking.booking_time_slots
      }

      this.acceptBookingCommon(payload).finally(() => {
        this.setLoading(false)
        this.handleModalClose()
      })
    },

    handleRejectBooking() {
      this.setLoading(true)
      const payload = {
        booking_id: this.notificationData.booking_id,
        translator_id: this.user.id
      }

      this.rejectBooking(payload)
        .then(() => {
          showSmallSuccessNotif(this.$t('booking_reject_success'))
          EventBus.emit(EVENT.BOOKING_NOT_MATCH)
        })
        .catch((e) => {
          let errTitle = `${this.$t('error')} - ${this.$t('booking_reject_fail')}`
          showSmallErrorNotif(e, errTitle)
        })
        .finally(() => {
          this.setLoading(false)
          this.handleModalClose()
        })
    },

    async getLatestBookingDatabaseNotification(bookingId) {
      await apiCall(
        {
          url: `${API.BOOKINGS}/${bookingId}/last-notification`,
          method: 'GET'
        },
        axiosInstance
      ).then((r) => {
        const notification = r.data?.data?.notification
        this.$store.commit('email/setBookingChangesNotification', notification.data)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.booking-changes-modal {
  .modal-wrapper,
  p,
  b,
  div {
    word-break: break-word !important;
  }

  .modal-wrapper {
    .heading {
      margin-bottom: 0 !important;
    }

    .old-booking-details {
      margin-bottom: 20px;
    }
  }

  .action-buttons {
    text-align: center;
    margin-top: 50px;
  }

  .el-tag {
    background-color: #e4e7ed;
    border-color: #e4e7ed;
    color: #545454;
    font-size: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 10px;
  }
}
</style>
