import loadTranslatorUnavailableTimes from './loadTranslatorUnavailableTimes'
import addTranslatorUnavailableTimes from './addTranslatorUnavailableTimes'
import deleteTranslatorUnavailableTimes from './deleteTranslatorUnavailableTimes'
import getByIdTranslatorUnavailableTimes from './getByIdTranslatorUnavailableTimes'
import updateTranslatorUnavailableTimes from './updateTranslatorUnavailableTimes'

//Available Time
import addTranslatorAvailableTimes from './addTranslatorAvailableTimes'
import loadTranslatorAvailableTimes from './loadTranslatorAvailableTimes'
import deleteTranslatorAvailableTimes from './deleteTranslatorAvailableTimes'
import updateTranslatorAvailableTimes from './updateTranslatorAvailableTimes'
export default {
  loadTranslatorUnavailableTimes,
  addTranslatorUnavailableTimes,
  deleteTranslatorUnavailableTimes,
  getByIdTranslatorUnavailableTimes,
  updateTranslatorUnavailableTimes,

  //Available times
  addTranslatorAvailableTimes,
  loadTranslatorAvailableTimes,
  deleteTranslatorAvailableTimes,
  updateTranslatorAvailableTimes
}
