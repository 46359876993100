import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload = {}) {
  const url = `/api/v3/core/verify/${payload.token}`
  const method = 'get'

  const options = {
    on_success(r, ctx) {
      ctx.state.registerForm.email = r.data.data.verify_customer.email
      ctx.state.registerLabels.company_label = r.data.data.verify_customer.company_label
      ctx.state.registerLabels.department_label = r.data.data.verify_customer.department_label
      ctx.state.registerForm.token = payload.token
      if (!isNil(r.data.data.verify_customer.municipality_ids)) {
        ctx.state.registerMunicipalities = JSON.parse(r.data.data.verify_customer.municipality_ids)
        if (ctx.state.registerMunicipalities.length === 1) {
          ctx.state.registerForm.municipality_id = ctx.state.registerMunicipalities[0].id
          ctx.dispatch('loadCompanyOpts', {
            municipality_id: ctx.state.registerForm.municipality_id
          })
        }
      }
      return r
    },

    on_error(e, ctx) {
      ctx.state.registerForm.token = payload.token
      ctx.state.registerForm.error = true
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
