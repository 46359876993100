// import { reach } from '@/modules/helpers/vuexActions'
// import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function addTranslatorAvailableTimes(context, payload) {
  const url = `${API.TRANSLATOR_WEEKLY_SCHEDULE}?formatter=daily`
  const method = 'POST'
  // const options = {
  //   query_params: payload.queryParams
  // }
  context.commit('setLoadingForAvailableTime', true)
  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      data: payload
    })
      .then((r) => {
        context.commit('setLoadingForAvailableTime', false)
        resolve(r) // Resolve with the result of loadTranslatorUnavailableTimes
      })
      .catch((e) => {
        context.commit('setLoadingForAvailableTime', false)
        reject(e)
      })
  })
}

export default addTranslatorAvailableTimes
