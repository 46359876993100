<template>
  <div class="translator-picker-field">
    <el-select
      v-if="!disabled"
      ref="translator-picker-field"
      v-model="iValue"
      :class="[getBem(blockClass), pickerCls]"
      :placeholder="placeholder"
      :popper-class="popperCls"
      filterable
      clearable
      :filter-method="handleQuerySpecificTranslators"
      default-first-option
      :multiple="isModeMultiple"
      :disabled="disabled"
      @change="handleChange"
      @remove-tag="handleRemoveTag"
    >
      <el-option
        v-for="v in translatorOptsContainer"
        :key="parseInt(v.id)"
        :value="parseInt(v.id)"
        :label="`${v.name} - ${v.id}`"
      />
    </el-select>

    <ul v-else class="simple-list">
      <li v-for="v in translatorOptsContainer" :key="parseInt(v.id)">
        {{ `${v.name} - ${v.id}` }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import size from 'lodash/size'
import remove from 'lodash/remove'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import some from 'lodash/some'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'
import EventBus from '@/modules/helpers/eventBus'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    /**
     * The v-model
     */
    modelValue: {
      type: [Array, String],
      default() {
        return []
      }
    },

    /**
     * The picker's mode which varies between single and multiple select.
     */
    mode: {
      type: String,
      default: 'multiple'
    },

    /**
     * Placeholder value for the field.
     */
    placeholder: {
      type: String,
      default: ''
    },

    /**
     * Value to make the field as read-only.
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue', 'change', 'remove-tag'],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'translator-picker-field',
      translatorOptsContainer: []
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    ...mapGetters('auth', ['translatorOpts']),

    /**
     * Interface for this.value.
     */
    iValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    /**
     * return {string}
     */
    popperCls() {
      const def = this.getBem(this.blockClass, 'popper')
      return size(this.translatorOptsContainer) === 0 ? `${def} ${def}--hidden` : def
    },

    /**
     * return {string}
     */
    pickerCls() {
      const def = this.getBem(this.blockClass, '', 'initial')
      return size(this.iValue) < 2 ? def : ''
    },

    /**
     * Returns boolean to determine if the component mode is single.
     *
     * @returns {boolean}
     */
    isModeMultiple() {
      return this.mode === 'multiple'
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > watch
    |--------------------------------------------------------------------------
    */
  watch: {
    /**
     * Watcher for this.iValue.
     */
    iValue() {
      if (this.isModeMultiple && !isEmpty(this.iValue)) {
        this.loadOptions({ translator_id: this.iValue.join(',') })
      } else if (!this.isModeMultiple && this.iValue !== '') {
        this.loadOptions({ translator_id: this.iValue })
      }
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
  mounted() {
    this.setInitialFieldValue()
    this.adjustHeight()

    EventBus.on('modal.opened', () => {
      setTimeout(() => {
        this.adjustHeight()
      }, 1000)
    })
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions('auth', ['loadTranslatorOpts']),

    /**
     * Handles the loading for translator options.
     *
     * @param payload - Contains filters to be queried from the API.
     * @param isInitial - Boolean to determine if the method is for setting the initial field value.
     * @returns {void}
     */
    loadOptions(payload, isInitial = false) {
      this.loadTranslatorOpts(payload)
        .then((r) => {
          const translators = r.data.data[Object.keys(r.data.data)[0]]

          if (!isNil(translators) && !isEmpty(translators)) {
            // if (this.isModeMultiple) {

            forEach(translators, (translator) => {
              if (isInitial) {
                // If the loader is for initial value assignment,
                // just assign the response to the container.
                this.translatorOptsContainer.push(translator)
              } else {
                // Else if the loader is for filter selection,
                // Append the items that are not existing this.iValue and in the popper.
                if (
                  !includes(this.iValue, translator.id) &&
                  !some(this.translatorOptsContainer, { id: translator.id })
                ) {
                  this.translatorOptsContainer.push(translator)
                }
              }
            })

            // } else if (!this.isModeMultiple) {
            //   // Soon to be implemented.
            // }
          }
        })
        .catch((e) => {
          showSmallErrorNotif(e)
        })
    },

    /**
     * Clears the translator opts container. And again, for the same reason:
     * Just did this only for the purpose that the select won't display
     * all the options on focus.
     *
     * @returns {void}
     */
    clearOptions() {
      // Remove everything, except what's in the this.iValue.
      remove(this.translatorOptsContainer, (o) => {
        return !includes(this.iValue, o.id)
      })
    },

    /**
     * Method to remove the option for the collection of translators options.
     *
     * @param id - The ID for the translator to be removed.
     * @returns {void}
     */
    removeOption(id) {
      remove(this.translatorOptsContainer, (o) => o.id === id)
      if (isArray(this.iValue) && !isEmpty(this.iValue)) {
        this.$delete(this.iValue, this.iValue.indexOf(id))
      }
    },

    /**
     * Handles the querying of specific translators.
     *
     * @returns {void}
     */
    handleQuerySpecificTranslators: debounce(function (q) {
      if (q !== '' && q.length > 0) {
        let payload = !isNaN(q) ? { translator_id: q } : { translator_name: q }
        this.clearOptions()
        this.loadOptions(payload)
      }
    }, 750),

    /**
     * Handler when the value in the translator picker was changed.
     *
     * @param selected
     * @returns {void}
     */
    handleChange(selected) {
      // Sent an event and pass along the current value.

      this.$emit('change', selected)

      // Adjust field height
      this.adjustHeight()
    },

    adjustHeight() {
      if (this.isModeMultiple && !this.disabled) {
        setTimeout(() => {
          const parent = this.$refs['translator-picker-field'].$el

          const options = parent.querySelector('.el-select__tags')
          const optionsHeight = options.offsetHeight

          parent.querySelector('.el-input').style.height = optionsHeight + 'px'
        }, 250)
      }
    },

    /**
     * Handler when a tag has been removed from the picker.
     *
     * @param removed
     * @returns {void}
     */
    handleRemoveTag(removed) {
      this.removeOption(removed)
      this.clearOptions()
      this.$emit('remove-tag', removed)
    },

    /**
     * Method to set the inital value for the picker.
     *
     * @returns {void}
     */
    setInitialFieldValue() {
      if (this.isModeMultiple && !isEmpty(this.iValue)) {
        this.loadOptions({ translator_id: this.iValue.join(',') }, true)
      } else if (!this.isModeMultiple && this.iValue !== '') {
        this.loadOptions({ translator_id: this.iValue }, true)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.booking-aws-file-upload-field__inline {
  display: flex;
  align-items: center;
  .el-upload--text {
    margin-right: 15px;
  }
}
.translator-picker-field {
  .el-select__tags {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .el-input__suffix {
    display: none;
  }

  .el-tag {
    margin-left: 5px;
    margin-right: 5px;
    word-wrap: break-word;
    white-space: pre-line;
    height: auto;
    line-height: 1.6;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &__popper {
    &--hidden {
      display: none;
    }
  }

  ul.simple-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    padding-left: 0;
  }
}
</style>
