import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { reach } from '@/modules/helpers/vuexActions'
import { mapEndpoint } from '@/modules/helpers/common'
export default function (context, subscriptionId) {
  const method = 'DELETE'
  const url = mapEndpoint(API.DELETE_STATISTICS_SUBSCRIPTIONS, { subscriptionId: subscriptionId })
  let options = {
    on_success: (r) => {
      console.log('on_success', r)
      context.commit('setStatisticsSubscriptions', r.data.data.subscriptions)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
