/*
|--------------------------------------------------------------------------
| Booking Status
|--------------------------------------------------------------------------
|
| Contains the Key - Value pair for the booking status.
| Key is the Booking Status Name, and the Value is the Booking Status ID.
|
*/

/*
|--------------------------------------------------------------------------
| Normal Booking STATUSES
|--------------------------------------------------------------------------
*/
import omit from 'lodash/omit'

export const TEMPORARY_PENDING = 11
export const PENDING = 1
export const TO_BE_PLANNED = 14
export const PLANNED = 15
export const OFFERED = 16
export const ASSIGNED = 2
export const STARTED = 3

export const COMPLETED = 4
export const CANCELLED = 5
export const LATE_CANCELLED = 6
export const COMPENSATION = 7
export const EXPIRED = 8
export const NOT_CARRIED_OUT_TRANSLATOR = 9
export const NOT_CARRIED_OUT_CUSTOMER = 10
export const LATE_CANCELLED_CUSTOMER = 12
export const LATE_CANCELLED_CUSTOMER_TRANSLATOR = 13
export const CANCELLED_DUE_TO_PREFERENCE = 17
export const EXPIRED_DUE_TO_PREFERENCE = 18
export const CANCELLED_QUOTE_UNANSWERED = 21
export const CANCELLED_QUOTE_DECLINED = 22
export const IN_REVIEW = 23
export const DECLINED = 24
export const DELAYED = 20

export const NORMAL_BOOKING_PENDING_STATUSES = {
  booking_status_pending: PENDING, // 1
  booking_status_assigned: ASSIGNED, // 2
  booking_status_started: STARTED, // 3
  booking_status_temporary_pending: TEMPORARY_PENDING, // 11
  // booking_status_to_be_planned: TO_BE_PLANNED, //14
  booking_status_planned: PLANNED //15
  // 'offered': OFFERED,
}

export const NORMAL_BOOKING_HISTORIC_STATUSES = {
  booking_status_completed: COMPLETED, // 4
  booking_status_cancelled: CANCELLED, // 5
  // late_cancelled: LATE_CANCELLED, // 6
  // booking_status_compensation: COMPENSATION, // 7
  booking_status_expired: EXPIRED, // 8
  // booking_status_not_carried_out_translator: NOT_CARRIED_OUT_TRANSLATOR // 9
  booking_status_not_carried_out_customer: NOT_CARRIED_OUT_CUSTOMER, // 10
  booking_status_late_cancelled_customer: LATE_CANCELLED_CUSTOMER, // 12
  booking_status_late_cancelled_customer_translator: LATE_CANCELLED_CUSTOMER_TRANSLATOR // 13
  // booking_status_cancelled_due_to_preference: CANCELLED_DUE_TO_PREFERENCE, // 17
  // booking_status_expired_due_to_preference: EXPIRED_DUE_TO_PREFERENCE // 18
  //
}

/*
|--------------------------------------------------------------------------
| Text Booking STATUSES
|--------------------------------------------------------------------------
*/
export const AWAITING_PRICE = 0
export const PENDING_TRANSLATOR = 1
export const BEING_TRANSLATED = 2
export const SUBMITTED_FOR_REVIEW = 3
export const TEXT_IN_REVIEW = 4
export const SUBMITTED_FROM_REVIEW = 5
export const NEED_TO_REVISED = 6
export const TEXT_COMPLETED = 7
export const TEXT_CANCELLED = 8
export const PRICE_SUBMITTED = 9
export const PRICE_REJECTED = 10

export const TEXT_BOOKING_PENDING_STATUSES = NORMAL_BOOKING_PENDING_STATUSES
// export const TEXT_BOOKING_PENDING_STATUSES = omit(NORMAL_BOOKING_PENDING_STATUSES, [
//   'booking_status_to_be_planned',
//   'booking_status_planned'
// ]) //commented for future use
export const TRANSLATOR_TEXT_BOOKING_PENDING_STATUSES = {
  booking_status_pending: PENDING, // 1
  booking_status_assigned: ASSIGNED, // 2
  booking_status_started: STARTED, // 3
  booking_status_temporary_pending: TEMPORARY_PENDING, // 11
  // booking_status_to_be_planned: TO_BE_PLANNED, //14
  booking_status_planned: PLANNED, //15
  booking_status_declined: DECLINED,
  booking_status_in_review: IN_REVIEW
}
export const TEXT_BOOKING_HISTORIC_STATUSES = {
  ...omit(NORMAL_BOOKING_HISTORIC_STATUSES, [
    'booking_status_expired_due_to_preference',
    'booking_status_cancelled_due_to_preference'
  ]),
  booking_status_cancelled_quote_unanswered: CANCELLED_QUOTE_UNANSWERED,
  booking_status_cancelled_quote_declined: CANCELLED_QUOTE_DECLINED,
  booking_status_delayed: DELAYED
}

export default {
  NORMAL_BOOKING_PENDING_STATUSES,
  TEMPORARY_PENDING,
  TO_BE_PLANNED,
  PENDING,
  OFFERED,
  PLANNED,

  NORMAL_BOOKING_HISTORIC_STATUSES,
  ASSIGNED,
  STARTED,
  COMPLETED,
  CANCELLED,
  LATE_CANCELLED,
  COMPENSATION,
  EXPIRED,
  NOT_CARRIED_OUT_TRANSLATOR,
  NOT_CARRIED_OUT_CUSTOMER,
  LATE_CANCELLED_CUSTOMER,
  LATE_CANCELLED_CUSTOMER_TRANSLATOR,
  EXPIRED_DUE_TO_PREFERENCE,
  CANCELLED_DUE_TO_PREFERENCE,
  DECLINED,
  DELAYED,

  TEXT_BOOKING_PENDING_STATUSES,
  TRANSLATOR_TEXT_BOOKING_PENDING_STATUSES,
  TEXT_BOOKING_HISTORIC_STATUSES,
  PENDING_TRANSLATOR,
  AWAITING_PRICE,
  BEING_TRANSLATED,
  SUBMITTED_FOR_REVIEW,
  IN_REVIEW,
  TEXT_IN_REVIEW,
  SUBMITTED_FROM_REVIEW,
  NEED_TO_REVISED,
  TEXT_COMPLETED,
  TEXT_CANCELLED,
  PRICE_SUBMITTED,
  PRICE_REJECTED
}
