import * as Sentry from '@sentry/browser'
import appStorage from '@/modules/helpers/appStorage'
import LS, { CUSTOMER_INTEGRATION_USER } from '@/modules/constants/localStorage'
import { CUSTOMER_INTEGRATION_API } from '@/modules/constants/api'

import apiCall from '@/modules/helpers/apiCall'
import isString from 'lodash/isString'

export default function (context, payload = {}) {
  const userToken = appStorage.get(LS.TOKEN)
  const user = appStorage.get(LS.USER)

  let token = isString(userToken) ? userToken : userToken?.access_token

  const existingCSUser = appStorage.get(CUSTOMER_INTEGRATION_USER)
  if (existingCSUser && existingCSUser.dt_user_id === user.id) {
    return
  }
  const url = `${CUSTOMER_INTEGRATION_API}/api/dt-users`

  let data = {
    dt_user_id: payload.dt_user_id || user.id,
    token
  }

  return apiCall({
    isShowLogs: false,
    url,
    data,
    method: 'POST',
    onFinallyCallback: (r) => {
      if (!r.data.data) {
        Sentry.setContext('userMeta', r.data.data)
        throw new Error(`User not defined in response to ${url}`)
      }

      context.commit('setCSToken', r.data.data?.user)
    }
  })
}
