import { SALARY_FILES as API_SALARY_FILES } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function downloadTranslatorSalaryFile(context, payload) {
  const filename = payload.fileName
  const url = `${API_SALARY_FILES}/${payload.salaryFileId}/download`
  const method = 'GET'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    })
      .then((r) => {
        resolve(r)
        const blob = new Blob([r.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.click()
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export default downloadTranslatorSalaryFile
