<template>
  <footer class="app-footer" :class="getBem(blockClass)">
    <div class="top-footer">
      <div class="container app-container">
        <div class="widget widget-1">
          <h4 class="title">
            {{ $t('widget_services_title') }}
          </h4>
          <ul class="links">
            <li v-for="(link, idx) in infoLinks" :key="idx">
              <a :href="link.path" target="_blank">
                {{ $t(link.label) }}
              </a>
            </li>
          </ul>
        </div>
        <div class="widget widget-2">
          <h4 class="title">
            {{ $t('widget_about_us_title') }}
          </h4>
          <ul class="links">
            <li v-for="(link, idx) in aboutLinks" :key="idx">
              <a v-if="link.type !== 'external'" :href="link.path" target="_blank">
                {{ $t(link.label) }}
              </a>

              <a v-else :href="link.path" target="_blank">
                {{ $t(link.label) }}
              </a>
            </li>
          </ul>
        </div>
        <div class="widget widget-3">
          <h4 class="title">
            {{ $t('widget_about_site_title') }}
          </h4>
          <ul class="links">
            <li v-for="(link, idx) in siteLinks" :key="idx">
              <a :href="link.path" target="_blank">
                {{ $t(link.label) }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="!isUKTenant" class="widget widget-recent-blog">
          <h4 class="title">
            {{ $t('widget_title_latest_news') }}
          </h4>
          <ul class="recent-blogs">
            <li v-for="(post, idx) in getBlogListData" :key="idx" class="post">
              <h4 class="title">
                <router-link
                  tabindex="0"
                  :to="{ name: 'blog.details', params: { slug: post.slug } }"
                  @click.prevent="scrollToTop"
                >
                  {{ post.title }}
                </router-link>
              </h4>
              <span class="meta">{{ formatDate(post.created_at) }}</span>
              <p class="excerpt">{{ trimString(post.content, 171) }}...</p>
            </li>
          </ul>
        </div>
        <div class="widget widget-contact">
          <h4 class="title">
            {{ $t('nav_contact') }}
          </h4>
          <div class="content">
            <address class="address">
              {{ getTenantConfig.name }}<br />
              {{ getTenantConfig.address.office }}<br />
              {{ getTenantConfig.address.street }}<br />
              {{ getTenantConfig.address.city }}<br />
              {{ getTenantConfig.address.country }}<br />
            </address>
            <ul class="phone-numbers">
              <li class="support-tel">
                {{ $t('label_customer_support') }} :
                <a :href="`tel:${getTenantConfig.customer_contact}`">{{ getTenantConfig.customer_contact }}</a>
              </li>
              <li class="interpreter-contact">
                {{ $t('label_interpreter_contact') }} :
                <a :href="`tel:${getTenantConfig.interpreter_contact}`">{{ getTenantConfig.interpreter_contact }}</a>
              </li>
            </ul>
            <router-link :to="{ name: 'contact' }" class="contact" @click.prevent="scrollToTop">
              {{ $t('label_click_to_email') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-footer">
      <div class="container">
        <p class="copyright">© {{ currentYear }} {{ getTenantConfig.name }} - All rights reserved</p>
        <ul class="social-links">
          <li v-for="(link, idx) in socialLinks" :key="idx">
            <a :href="link.path">
              <img :src="imagePath(link.icon)" :alt="link.label" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { populate } from '@/modules/helpers/vuexCommons'
import moment from 'moment'
import isNil from 'lodash/isNil'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'extended-footer'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapState('auth', ['language']),
    ...mapGetters('blog', ['blogListData', 'isLoadingBlogList', 'isBlogsLoading']),
    ...mapGetters('multiTenancy', ['getTenantConfig', 'isUKTenant']),

    getBlogListData() {
      return this.blogListData.filter((v) => !isNil(v.slug))
    },

    currentLocale() {
      return this.language === 'se' ? 'sv' : this.language
    },

    currentYear() {
      const d = new Date()
      return d.getFullYear()
    },

    socialLinks() {
      // let links = []
      // if (this.getTenantConfig.facebook_url) {
      //   links.push({
      //     label: 'Facebook',
      //     path: this.getTenantConfig.facebook_url,
      //     icon: 'facebook-icon.svg'
      //   })
      // }
      // if (this.getTenantConfig.instagram_url) {
      //   links.push({
      //     label: 'Instagram',
      //     path: this.getTenantConfig.instagram_url,
      //     icon: 'instagram-icon.svg'
      //   })
      // }
      // if (this.getTenantConfig.linkedin_url) {
      //   links.push({
      //     label: 'LinkedIn',
      //     path: this.getTenantConfig.linkedin_url,
      //     icon: 'linked-in-icon.svg'
      //   })
      // }
      // return links

      if (this.isUKTenant) {
        return [
          {
            label: 'Facebook',
            path: 'https://www.facebook.com/profile.php?id=61564276577214',
            icon: 'facebook-icon.svg'
          },
          { label: 'Instagram', path: 'https://www.instagram.com/digitaltolk/?hl=en', icon: 'instagram-icon.svg' },
          { label: 'LinkedIn', path: 'https://www.linkedin.com/company/tolk-co-uk', icon: 'linked-in-icon.svg' }
        ]
      } else {
        return [
          { label: 'Facebook', path: 'https://www.facebook.com/digitaltolk', icon: 'facebook-icon.svg' },
          { label: 'Instagram', path: 'https://www.instagram.com/digitaltolk/', icon: 'instagram-icon.svg' },
          { label: 'LinkedIn', path: 'https://www.linkedin.com/company/digitaltolk/', icon: 'linked-in-icon.svg' }
        ]
      }
    },

    siteLinks() {
      if (this.isUKTenant) {
        return [
          { label: 'dt_contact', path: 'https://www.tolk.co.uk/contact' },
          { label: 'nav_privacy', path: 'https://www.digitaltolk.se/legal/integritetspolicy' }
        ]
      } else {
        return [
          { label: 'dt_contact', path: 'https://www.digitaltolk.se/kontakt' },
          { label: 'nav_privacy', path: 'https://www.digitaltolk.se/legal/integritetspolicy' }
        ]
      }
    },

    aboutLinks() {
      if (this.isUKTenant) {
        return [
          { label: 'dt_about_us', path: 'https://www.tolk.co.uk/about-us' },
          { label: 'dt_careers', path: 'https://www.tolk.co.uk/careers' }
        ]
      } else {
        return [
          { label: 'dt_about_us', path: 'https://www.digitaltolk.se/om-oss' },
          { label: 'dt_careers', path: 'https://www.digitaltolk.se/karriar' },
          { label: 'dt_media', path: 'https://www.digitaltolk.se/media' },
          { label: 'dt_press', path: 'https://www.mynewsdesk.com/se/digitaltolk' }
        ]
      }
    },

    infoLinks() {
      if (this.isUKTenant) {
        return [
          { label: 'dt_interpretation', path: 'https://www.tolk.co.uk/interpreting' },
          { label: 'dt_translation', path: 'https://www.tolk.co.uk/translation' },
          { label: 'dt_messaging', path: 'https://www.tolk.co.uk/messaging-services' }
        ]
      } else {
        return [
          { label: 'dt_interpretation', path: 'https://www.digitaltolk.se/tolkning' },
          { label: 'dt_translation', path: 'https://www.digitaltolk.se/oversattning' },
          { label: 'dt_messaging', path: 'https://www.digitaltolk.se/meddelandeservice' }
        ]
      }
    }
  },

  mounted() {
    if (this.blogListData.length === 0) {
      this.loadList()
    }
  },

  /*
   |--------------------------------------------------------------------------
   | Component > Methods
   |--------------------------------------------------------------------------
   */
  methods: {
    ...mapActions('blog', ['getBlogs']),

    scrollToTop() {
      window.scrollTo(0, 0)
    },
    loadList(payload = {}, force = true) {
      payload.tags = 'public'
      payload.per_page = 3
      populate(this, 'getBlogs', 'blogListData', {
        label: 'Blog List Data',
        force,
        payload: payload
      })
    },

    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').locale(this.currentLocale).format('MMMM DD, Y')
    },

    trimString(value, limit) {
      // const stripHTML = value.replace(/(<([^>]+)>)/gi, '')
      // return stripHTML.slice(0, limit)
      const stripHTML = value.replace(/<[^>]*>/g, ' ')
      return stripHTML.trim().slice(0, limit)
    },
    imagePath(icon) {
      return new URL(`/src/assets/images/${icon}`, import.meta.url).href
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
.extended-footer {
  background: #ecf1fa;
  color: $app-primary-text-color;
  // padding: 60px 0 0 0;

  .top-footer {
    margin-bottom: 45px;

    .container {
      display: flex;
      justify-content: space-between;

      @media (max-width: $screen-xs-max) {
        padding: 0 30px;
        flex-wrap: wrap;
      }

      @media (max-width: 1280px) {
        padding: 0 30px;
        flex-wrap: wrap;
      }

      .widget {
        min-width: 200px;

        @media (max-width: $screen-xs-max) {
          min-width: 100%;
          margin-bottom: 45px;
        }

        @media (max-width: 1280px) {
          margin-bottom: 45px;
        }

        &.widget-recent-blog {
          width: 300px;

          ul.recent-blogs {
            list-style: none;
            margin: 0;
            padding: 0;

            li.post {
              border-bottom: 1px solid #d3d8e2;
              padding-bottom: 15px;
              margin-bottom: 15px;

              &:last-child,
              &:nth-child(3) {
                border: 0;
                padding-bottom: 0;
              }

              &:nth-child(n + 4) {
                display: none;
              }

              .title {
                font-size: 14px;
                margin: 0 0 3px 0;
                font-weight: 500;
              }

              .meta {
                display: inline-block;
                font-size: 11px;
                opacity: 0.7;
                margin-bottom: 5px;
              }

              .excerpt {
                font-size: 14px;
                margin: 0;
              }
            }
          }
        }

        &.widget-contact {
          width: 300px;

          .content {
            font-size: 14px;
            line-height: 1.6;

            .address {
              font-style: normal;
              display: flex;

              &:before {
                display: inline-block;
                content: '';
                width: 20px;
                height: 20px;
                margin-right: 5px;
                background: url('@/assets/images/map-icon.svg') no-repeat;
              }
            }

            ul.phone-numbers {
              list-style: none;
              margin: 15px 0;
              padding: 0;

              li {
                display: flex;
                align-items: center;

                &:before {
                  display: inline-block;
                  content: '';
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  background: url('@/assets/images/phone-icon.svg') no-repeat;
                }
                a {
                  margin-left: 10px;
                }
              }
            }

            .contact {
              text-decoration: underline;
              display: flex;

              &:before {
                display: inline-block;
                content: '';
                width: 20px;
                height: 20px;
                margin-right: 5px;
                background: url('@/assets/images/mail-icon.svg') no-repeat;
              }
            }
          }
        }

        .title {
          font-size: 20px;
          font-weight: 600;
        }

        ul.links {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            font-size: 14px;
            line-height: 2;
          }
        }
      }
    }
  }

  .bottom-footer {
    // border-top: 1px solid #d3d8e2;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      max-width: 1360px;
      font-size: 14px;

      @media (max-width: 1280px) {
        padding: 0 30px;
      }

      @media (max-width: $screen-xs-max) {
        padding: 0 30px;
        flex-wrap: wrap;
        padding-bottom: 20px;
      }

      ul.social-links {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
