/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getBookingPublic
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to fetch the booking details via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context) {
  let options = {
    on_success(r, ctx) {
      // Set the following states.
      ctx.commit('setConfirmSuggestions', r.data?.data['confirmation-fills'])
    }
  }

  // Define axios values.
  const url = `${API.CONFIRMATION_FILLS}`
  const method = 'GET'

  return reach(this, context, url, method, options, axiosInstance)
}
