import { EXPORT_GDPR as API_EXPORT_GDPR } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function exportGdprCertificate(context, payload) {
  const url = `${API_EXPORT_GDPR}/${payload.translator_id}`
  const method = 'POST'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      data: payload,
      is_data_raw: true
    })
      .then((r) => {
        resolve(r)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export default exportGdprCertificate
