<template>
  <base-modal
    v-model="isShowModal"
    v-loading="loading"
    class="app-modal app-modal-secondary"
    :class="getBem(blockClass)"
    :title="$t('tt_label_how_was_your_experience_manual_translation')"
    transform-origin="center"
    preset="card"
    :width="isMobileViewPort ? '80%' : '36%'"
    :segmented="true"
  >
    <el-form ref="feedbackForm" :model="form" :rules="rules" label-width="auto" class="feedback-form" status-icon>
      <div class="header">
        <span class="heading-text">
          {{ $t('tt_label_how_was_your_overall_experience_ct') }}
        </span>
        <el-form-item prop="rating" class="feedback-rating-container">
          <el-rate v-model="form.rating" class="feedback-rating"></el-rate>
        </el-form-item>
      </div>
      <div class="body header">
        <span class="heading-text mt-8">
          {{ $t('tt_label_reason_for_choosing_manual_submition_over_cat_tool') }}
        </span>
        <el-form-item prop="other" class="free-textarea">
          <el-input
            v-model="form.experiences['tt_label_reason_for_choosing_manual_submition_over_cat_tool']"
            :autosize="{ minRows: 3, maxRows: 3 }"
            type="textarea"
            :required="true"
            :placeholder="$t('tt_label_reason_for_choosing_manual_submition_over_cat_tool_placeholder')"
          />
        </el-form-item>
        <span class="heading-text mt-14">
          {{ $t('tt_label_prefer_cat_tool_over_manual_submission') }}
        </span>
        <el-form-item prop="other" class="free-textarea">
          <el-input
            v-model="form.experiences['tt_label_prefer_cat_tool_over_manual_submission']"
            :autosize="{ minRows: 3, maxRows: 3 }"
            type="textarea"
            :required="true"
            :placeholder="$t('tt_label_prefer_cat_tool_over_manual_submission_placeholder')"
          />
        </el-form-item>
      </div>
    </el-form>

    <!-- Action buttons -->
    <template #action>
      <base-button @click="closeModal" ghost type="secondary">
        {{ $t('tt_label_i_do_not_wish_to_response') }}
      </base-button>
      <base-button type="secondary" @click="submitFeedback">
        {{ $t('submit') }}
      </base-button>
    </template>
  </base-modal>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps, defineExpose, getCurrentInstance, watch } from 'vue'

// STORE
import { useStore } from 'vuex'

const app = getCurrentInstance()
const notify = app.root.appContext.config.globalProperties.$notify

// I18N
import i18n from '@/locales/i18nClient.js'

// Use store
const store = useStore()

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  booking: {
    type: Object,
    required: true
  }
})

const emit = defineEmits()

const blockClass = 'text-translation-feedback-submission-modal'
const loading = ref(false)
const form = ref({
  rating: 0,
  experiences: {
    tt_label_reason_for_choosing_manual_submition_over_cat_tool: '',
    tt_label_prefer_cat_tool_over_manual_submission: ''
  }
})

const isShowModal = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

function mapFeedbackToForm(feedback) {
  const mappedForm = {
    rating: feedback.rating || 0,
    experiences: {}
  }

  feedback.experiences.forEach((exp) => {
    mappedForm.experiences[exp.question] = exp.answer
  })

  return mappedForm
}

watch(
  () => props.modelValue,
  () => {
    getFeedback()
  }
)

async function getFeedback() {
  if (props.modelValue) {
    loading.value = true
    const payload = {
      booking_id: props.booking.id
    }
    const feedback = await store.dispatch('textTranslation/getTranslatorFeedbackByBookingId', payload)
    if (feedback) {
      form.value = mapFeedbackToForm(feedback)
    }
    loading.value = false
  }
}

const closeModal = () => {
  emit('update:modelValue', false)
}

const isMobileViewPort = computed(() => {
  const isMobile = store.getters['auth/isOnMobileViewport']
  return isMobile
})

async function submitFeedback() {
  try {
    loading.value = true
    const payload = {
      rating: form.value.rating,
      booking_id: props.booking.id,
      platform: 'CT',
      experiences: [
        {
          question: 'tt_label_reason_for_choosing_manual_submition_over_cat_tool',
          type: 'input',
          answer: form.value.experiences['tt_label_reason_for_choosing_manual_submition_over_cat_tool']
        },
        {
          question: 'tt_label_prefer_cat_tool_over_manual_submission',
          type: 'input',
          answer: form.value.experiences['tt_label_prefer_cat_tool_over_manual_submission']
        }
      ]
    }

    await store.dispatch('textTranslation/submitTranslatorFeedback', payload)
    loading.value = false

    setTimeout(() => {
      notify({
        message: i18n.global.t('tt_label_thanks_for_your_valuable_feedback'),
        position: 'bottom-left',
        duration: 1500,
        type: 'success'
      })
    }, 1000)
    closeModal()
  } catch (error) {
    loading.value = true
    console.error(error)
  }
}

defineExpose({
  closeModal
})
</script>

<style lang="scss">
.text-translation-feedback-submission-modal {
  display: flex;

  .feedback-form {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .heading-text {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    width: 100%;

    .el-rate__icon {
      font-size: 32px !important;
    }

    .feedback-rating-container {
      margin-left: 0px;
      .feedback-rating {
        display: flex;
        column-gap: 6px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 18px;
    width: 100%;

    .features-selection {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .free-textarea {
      width: 100%;
    }

    .free-textarea .el-textarea__inner {
      resize: none;
      background-color: #fbfbfb;
    }

    .free-textarea .el-textarea__inner:focus {
      outline: none;
      border-color: black;
      box-shadow: 0 0 0 1px black;
    }
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-form-item__content {
    line-height: normal;
    margin-left: 0 !important;
  }

  .custom-size .el-checkbox__input {
    transform: scale(1.3); /* Adjust the scale value as needed */
  }

  .n-card__action {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .mt-14 {
    margin-top: 14px;
  }

  .mt-8 {
    margin-top: 8px;
  }
}
</style>
