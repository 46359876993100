/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > rejectPrice
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'
import { showSmallSuccessNotif } from '@/modules/helpers/notifications'

import i18nClient from '@/locales/i18nClient.js'
const { t: $t } = i18nClient.global

export default function (context, payload) {
  const signature = payload?.signature
  const url = isNil(signature)
    ? `${API.TEXT_TRANSLATION}/${payload.id}/reject-price`
    : `${API.TEXT_TRANSLATION}/${payload.id}/reject-price-signed`

  const method = 'post'

  let options = {
    payload,
    is_data_raw: true,
    on_success() {
      showSmallSuccessNotif($t('quote_rejected'), 'Success!')
    },
    on_finally() {}
  }

  if (!isNil(signature)) {
    options = {
      ...options,
      query_params: {
        signature
      }
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
