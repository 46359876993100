/*
|--------------------------------------------------------------------------
| Store > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/
export default {
  // --------------------------------------------------
  // Blog List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  blogListData: (state) => state.blogs.data,

  /**
   * @returns {Object}
   */
  blogListPagination: (state) => state.blogs.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingBlogList: (state) => state.blogs.loading,

  // --------------------------------------------------
  // Featured Blog List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  featuredBlogListData: (state) => state.featuredBlogs.data,

  /**
   * @returns {Object}
   */
  featuredBlogListPagination: (state) => state.featuredBlogs.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingFeaturedBlogList: (state) => state.featuredBlogs.loading,

  isBlogsLoading: (state) => state.blogs.loading
}
