/*
|--------------------------------------------------------------------------
| Store > Statistics > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/

export default function () {
  return {
    customerStatistics: {
      data: {},
      loading: false
    },

    translatorStatistics: {
      data: {},
      loading: false
    },

    exportOptions: [
      'booking_overview',
      'stat_booking_type',
      'stat_expired',
      'quality',
      'stat_not_carried_out_due_to_customer',
      'all_bookings_created_via',
      'night_bookings_created_via',
      'assignment_time_for_emergency_bookings',
      'levels_of_emergency_translators',
      'stat_most_due_time',
      'stat_10_most_popular_langages',
      'stat_causes_for_low_feedback',
      'translator_levels'
    ],
    subscriptions: []
  }
}
