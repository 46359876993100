/*
|--------------------------------------------------------------------------
| Store > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/
export default function () {
  return {
    priceLists: {
      data: [],
      pagination: {},
      loading: true
    },
    loadingPriceLists: true,
    isShowCookieAlert: false
  }
}
