/*
|--------------------------------------------------------------------------
| Store > Multi-Tenancy > Actions
|--------------------------------------------------------------------------
|
| This file is where the Module's action methods are registered.
|
| Feel free to edit this file if you have action methods you want
| to include.
|
*/

import loadTenant from './loadTenant'

export default {
  loadTenant
}
