/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitBooking
|--------------------------------------------------------------------------
|
*/
import { BOOKINGS_PRICE_QUOTE } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  context.commit('setSubmitLoading', true)

  const url = BOOKINGS_PRICE_QUOTE
  const method = 'post'

  let data = payload
  data.user_id = context.rootGetters['auth/userMetaData'].id

  let options = {
    data,
    on_finally(ctx) {
      ctx.commit('setSubmitLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
