import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'

const appSessionStorage = {
  get(key, format = '') {
    let value = sessionStorage.getItem(key)

    if (format === 'csv') {
      return value.split(',')
    } else if (format === 'object') {
      return JSON.parse(value)
    } else {
      try {
        return JSON.parse(value)
      } catch {
        return value
      }
    }
  },

  set(key, value) {
    if (isNil(value)) return

    let stored

    if (isObject(value)) {
      stored = JSON.stringify(value)
    } else {
      stored = value
    }

    sessionStorage.setItem(key, stored)
  },

  remove(key) {
    sessionStorage.removeItem(key)
  }
}

export default appSessionStorage
