import router from '../index'

/**
 * @param context
 * @param middleware
 * @param index
 * @returns {Function|*}
 */
function nextFactory(context, middleware, index) {
  const currentMiddleware = middleware[index]

  if (currentMiddleware) {
    return (...parameters) => {
      context.next(...parameters)
      const nextMiddleware = nextFactory(context, middleware, index + 1)
      currentMiddleware({ ...context, next: nextMiddleware })
    }
  } else {
    return context.next
  }
}

/**
 * @param to
 * @param from
 * @param next
 * @returns {Function|*}
 */
function middlewares(to, from, next) {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = { from, next, router, to }

    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
}

export default middlewares
