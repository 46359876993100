/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > Resubmit Translated File
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { ElNotification } from 'element-plus'
import i18nClient from '@/locales/i18nClient.js'
const { t: $t } = i18nClient.global

export default function (context, payload) {
  const fileId = payload.file_id ? payload.file_id : null
  let payloadData = {}
  if (payload.reason) {
    payloadData = { rejection_reason: payload.reason }
  }
  const url = `${API.TEXT_TRANSLATION}/${payload.id}/reject/${fileId}?include=files,customer.customer_data,translator,potential_translators.user,comments.user`
  const method = 'post'

  let options = {
    data: payloadData,
    is_data_raw: true,
    on_success() {
      ElNotification.success($t('requested_successfully'))
    },
    on_error() {
      ElNotification.error($t('something_went_wrong'))
    },
    on_finally() {}
  }

  return reach(this, context, url, method, options, axiosInstance)
}
