/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > storeBookingAttempt
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isEmpty from 'lodash/isEmpty'

export default function (context) {
  const url = API.BOOKING_ATTEMPT
  const method = 'post'

  let data = {
    ...context.state.bookingAttemptPayload,
    customer_id: context.state.bookingAttemptPayload?.customer_id ?? context.rootGetters['auth/userMetaData'].id
  }

  const typeToFileMap = {
    document: 'text_translation_document_files',
    image: 'text_translation_image_files',
    audio: 'text_translation_audio_files',
    video: 'text_translation_video_files'
  }

  const bookingForm = context.state.bookingForm
  const textTranslationType = bookingForm?.text_translation_type

  if (typeToFileMap.hasOwnProperty(textTranslationType)) {
    data = {
      ...data,
      files: bookingForm[typeToFileMap[textTranslationType]]
    }
  }

  if (!isEmpty(bookingForm.additional_text)) {
    data = {
      ...data,
      additional_text: bookingForm.additional_text
    }
  }

  const options = {
    enable_error_notif: true,
    is_form_data: true,
    data
  }

  return reach(this, context, url, method, options, axiosInstance)
}
