<template>
  <div class="app-partial" :class="getBem(blockClass)">
    <h4>
      <span v-html="$t('select_contact_form')" />
    </h4>
    <el-collapse v-model="activeNames" accordion @change="handleChange">
      <el-collapse-item :title="$t('request_contact_form')" name="request">
        <contact-form v-model="form" type="request" @submit="handleSubmit" />
      </el-collapse-item>

      <el-collapse-item :title="$t('ekonomi_contact_form')" name="ekonomi">
        <contact-form v-model="form" type="ekonomi" @submit="handleSubmit" />
      </el-collapse-item>
      <el-collapse-item :title="$t('booking_contact_form')" name="booking">
        <contact-form v-model="form" type="booking" @submit="handleSubmit" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactForm from '@/components/partials/ContactForm'
import debounce from 'lodash/debounce'
import { showSmallSuccessNotif, showSmallErrorNotif } from '@/modules/helpers/notifications'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default {
  components: {
    ContactForm
  },

  emits: ['submit-success', 'submit-fail'],

  data() {
    return {
      blockClass: 'contact-form-partial',
      activeNames: '',
      form: {
        name: '',
        email: '',
        subject: '',
        message: '',
        booking_id: '',
        recipients: ''
      }
    }
  },
  computed: {
    ...mapGetters('multiTenancy', ['getTenantConfig'])
  },
  mounted() {
    this.activeNames = this.$route.query?.action
    if (this.activeNames === 'request') {
      // 'nykund@digitaltolk.se'
      this.form.recipients = this.getTenantConfig.email
      this.notifyIp()
    }
    if (this.activeNames === 'ekonomi') {
      // 'ekonomi@digitaltolk.se'
      this.form.recipients = this.getTenantConfig.email
      this.form.subject = this.$t('contacts_financial_subject')
    }
    this.form.name = this.$route.query?.name
    this.form.email = this.$route.query?.email
    this.form.booking_id = this.$route.query?.booking_id
  },

  methods: {
    handleSubmit: debounce(function () {
      const url = '/api/v3/core/contacts'
      const method = 'POST'

      axiosInstance({ url, method, data: this.form })
        .then(() => {
          showSmallSuccessNotif(this.$t('message_send_success'), this.$t('success'))
          this.form = {
            booking_id: '',
            company: '',
            email: '',
            message: '',
            name: '',
            phone: '',
            recipients: this.getTenantConfig.email /*'info@digitaltolk.se'*/,
            subject: ''
          }
          this.$emit('submit-success')
        })
        .catch((e) => {
          console.error('Error sending contact form: ', e)
          showSmallErrorNotif(this.$t('message_send_failed'), this.$t('error'))
          this.$emit('submit-fail')
          throw new Error('Error sending contact form')
        })
    }, 750),
    handleChange(v) {
      if (v === 'request') {
        //send ip address notification MM
        this.notifyIp()
        // 'nykund@digitaltolk.se'
        this.form.recipients = this.getTenantConfig.email
      } else if (v === 'ekonomi') {
        // 'ekonomi@digitaltolk.se'
        this.form.recipients = this.getTenantConfig.email
      } else {
        // 'info@digitaltolk.se'
        this.form.recipients = this.getTenantConfig.email
      }
    },
    notifyIp() {
      const url = '/api/v3/core/contacts-notify'
      const method = 'POST'
      axiosInstance({ url, method, data: {} })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.contact-form-partial {
  .el-collapse-item__header {
    background-color: #ebeef5 !important;
    border-bottom: 1px solid #fff !important;
    padding-left: 10px;
  }
}
</style>
