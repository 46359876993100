import LS from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'

/**
 * @param {int[]} collection
 */
function setAuthCollection(collection) {
  appStorage.set(LS.AUTH_COLLECTION, collection.join(','))
}

export default setAuthCollection
