import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

// import USER_TYPE from '@/modules/constants/userType';

function loadBookingsGeneric(context, payload = {}) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  let url = API.BOOKINGS
  const method = 'GET'

  payload.queryParams['filter[type]'] = [
    'physical',
    'phone',
    'video',
    'video_physical',
    'video_skype',
    'convey',
    'video_emergency'
  ].join(',')
  if (payload.bookingCategory === 'historic') {
    url += '/historic'
    payload.queryParams['filter[status_id]'] = [4, 5, 6, 7, 8, 9, 10, 12, 13].join(',')
  } else if (payload.bookingCategory === 'potential') {
    url += '/potential'
  } else {
    payload.queryParams['filter[status_id]'] = [1, 2, 3, 11].join(',')
  }

  let options = {
    query_params: payload.queryParams
  }

  return reach(this, context, url, method, options, axiosInstance)
}

export default loadBookingsGeneric
