/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > validateConveyBooking
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { getStoredToken } from '@/modules/helpers/vuexCommons'
import API from '@/modules/constants/api'

/**
 * Action to validate the booking to make sure values are right,
 * and then return the additional values to be used for booking confirmation
 * as response.
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @return {*}
 */
export default function (context) {
  // Prepare the payload to be sent across the API.
  context.commit('setFormPayload', {
    user: context.rootGetters['auth/userMetaData']
  })
  context.commit('setValidateLoading', true)

  const url = `${API.BOOKINGS}/validate`
  const method = 'post'
  // const data = context.state.formPayload
  const data = {
    ...context.state.formPayload,
    calculate_probability: true
  }
  let headers = {
    Authorization: 'Bearer ' + getStoredToken()
  }

  const options = {
    enable_error_notif: true,
    data,
    is_data_raw: true,
    headers,

    on_success(r, ctx) {
      ctx.commit('resetConfirmFormPayload')

      // Set the following states.
      ctx.commit('setBcfConfig', r.data.booking_field)
      ctx.commit('setConfirmSuggestions', r.data.confirmation_fills)
      ctx.commit('setAvailableTranslators', r.data.total_translators)
      ctx.commit('setProbability', r.data.assignment_probability)
      ctx.commit('setConfirmForm', {
        data: r.data.confirmation_fills,
        user: ctx.rootGetters['auth/userMetaData']
      })
      ctx.commit('setTranslatorShortages', r.data)
    },

    on_finally(ctx) {
      ctx.commit('setValidateLoading', false)
      ctx.commit('setBookingDefaultLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
