/* eslint-disable */
/**
 * @returns {void}
 */
function registerChatwootLive() {
  const isAppModeDev = import.meta.env.VITE_MODE === 'development'

  /*
   ** Only run in production mode
   */
  if (isAppModeDev) return
  ;(function (d, t) {
    var BASE_URL = 'https://helpdesk.digitaltolk.net'
    var g = d.createElement(t),
      s = d.getElementsByTagName(t)[0]
    g.src = BASE_URL + '/packs/js/sdk.js'
    g.defer = true
    g.async = true
    s?.parentNode.insertBefore(g, s)
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: import.meta.env.VITE_CHATWOOT_TOKEN || 'SZzpAMhoCzJ2kNRoj8WnjVmE',
        baseUrl: BASE_URL
      })
    }
  })(document, 'script')
}

export default registerChatwootLive
