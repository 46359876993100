/*
|--------------------------------------------------------------------------
| Store > Earnings
|--------------------------------------------------------------------------
| File that contains all the necessary properties for Vuex scope.
*/

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
