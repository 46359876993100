import getAuthCollection from '@/modules/auth/store/shared/getAuthCollection'

function initAuthCollection(context) {
  const collection = getAuthCollection()

  //  console.log('Current auth collection of User IDs : ', collection)
  context.commit('auth/setAuthenticatedUsers', collection, { root: true })
}

export default initAuthCollection
