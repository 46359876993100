/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > blockTranslator
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.user_id - Authenticated User ID.
 * @param {Object} payload.translator_id - Id of the translator to be blocked.
 * @returns {*}
 */
export default function (context) {
  const method = 'GET'
  const url = API.CHECK_CREDIT_WORTHINESS
  let options = {
    on_success(r, ctx) {
      ctx.commit('auth/setCreditWorthiness', r.data, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
