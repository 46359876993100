import DefaultLayout from '@/components/layouts/DefaultLayout'
import BlurLayout from '@/components/layouts/BlurLayout'
import LandingLayout from '@/components/layouts/LandingLayout'
import BlankLayout from '@/components/layouts/BlankLayout'

const components = {
  DefaultLayout,
  BlurLayout,
  LandingLayout,
  BlankLayout
}

export default {
  install: (app) => {
    // global/layout components register here
    Object.entries(components).forEach(([name, path]) => app.component(name, path))
  }
}
