import * as API from '@/modules/constants/api.js'
import { reach } from '@/modules/helpers/vuexActions.js'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance.js'

export default function (context, payload = {}) {
  const url = `${API.MARKETING_SITE_LOG}`
  const method = 'post'

  let options = {
    data: payload,
    is_data_raw: true
  }
  return reach(this, context, url, method, options, axiosInstance)
}
