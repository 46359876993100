// import cloneDeep from 'lodash/cloneDeep'

/**
 * Helper for setting default values for q_specifics.
 *
 * @param {object} state - The state property of the current Vuex module.
 * @param {string} key - Reference for the q_specific object property.
 * @returns {void}
 */
export default {
  /**
   * Set value for state.isLoadingSubmit.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setAvailableDatesLoading(state, payload) {
    state.loading = payload
  },

  setAvailableDates(state, payload) {
    state.dates = payload.dates
  },

  setAvailableDatesTimeSlots(state, payload) {
    state.timeSlots = payload.timeslots
  },

  setAssignmentProbability(state, payload) {
    state.assignmentProbabilities = payload
  },

  resetAvailableDatesAndTimeSlots(state) {
    state.dates = []
    state.timeSlots = []
    state.assignmentProbabilities = {}
  },

  setAvailableSlotSelected(state, payload) {
    state.availableSlotSelected = !!payload
  },

  setSpecificChoices(state, payload) {
    state.specificChoices = !!payload
  }
}
