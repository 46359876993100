/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitBooking
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  // Prepare the payload to be sent across the API.
  context.commit('setMultipleConfirmFormPayload', {
    user: context.rootGetters['auth/userMetaData'],
    data: payload
  })
  context.commit('setSubmitLoading', true)

  const url = API.MULTIPLE_BOOKING
  const method = 'POST'

  let data = {
    bookings: context.state.multipleBookingConfirmFormPayload,
    same_translator: payload.same_translator
  }

  data.created_via = context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'

  let options = {
    data,
    is_data_raw: true,

    on_success(r) {
      return r
    },

    on_finally(ctx) {
      // ctx.commit('resetMultipleBookingForm')
      ctx.commit('resetFormPayload')
      ctx.commit('resetMultipleBookingConfirmFormPayload')
      ctx.commit('setIsConfirmFormHalted', false)
      ctx.commit('setSubmitLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
