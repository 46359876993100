/*
|--------------------------------------------------------------------------
| Store > Multi-Tenancy > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/

export default {
  /**
   * Check if Tenant is loaded or not.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {boolean} -   true/false.
   */
  isTenantLoaded: function (state) {
    if (state.tenantDomain && state.tenant && state.tenant.uuid && state.tenant.name) {
      return true
    }
    return false
  },
  /**
   * Get Tenant Domain.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {string} - tenantDomain... sweden.digitaltolk.se
   */
  getTenantDomain: function (state) {
    return state.tenantDomain
  },
  /**
   * Get Tenant Config.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {object} - tenantConfig
   */
  getTenantConfig: function (state) {
    return state.tenantConfig
  },
  /**
   * Get Tenant.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {object} - tenant
   */
  getTenant: function (state) {
    return state.tenant
  },

  isUKTenant: function () {
    return window.location.hostname.includes('.uk')
  },

  isSwedishTenant: function () {
    return window.location.hostname.includes('.se')
  }
}
