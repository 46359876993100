/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > checkDeepLBookingStatus
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'

/**
 * Action to get deepl booking status.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, bookingID) {
  const url = `${API.BOOKING}/${bookingID}/deepl-document-status`
  const method = 'GET'
  let options = {
    data: {}
  }

  return reach(this, context, url, method, options, axiosInstance)
}
