<template>
  <el-dialog
    v-model="dialogVisible"
    :title="$t('new_existing_user_course_by_entering_your_name')"
    width="450px"
    class="course-dialog"
  >
    <el-select
      v-model="name"
      allow-create
      clearable
      default-first-option
      filterable
      :placeholder="$t('write_your_name')"
    >
      <el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="app-button-default close-button" @click="dialogVisible = false">{{ $t('close') }}</el-button>
        <el-button :disabled="!name" type="primary" @click="handleNavigateToCourse">{{ $t('proceed') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import API from '@/modules/constants/api'

export default {
  data() {
    return {
      dialogVisible: false,
      name: '',
      users: [],
      courseId: null,
      course: 'interpreter-user-training'
    }
  },
  methods: {
    handleNavigateToCourse() {
      this.$router.push({ path: `/courses/${this.course}`, query: { name: this.name?.trim(), id: this.courseId } })
      this.dialogVisible = false
    },
    open(id) {
      this.courseId = id
      this.course = id === 1 ? 'interpreter-user-training' : 'use-of-video-interpretation'
      this.dialogVisible = true
      this.getUsers()
    },
    getUsers: debounce(async function () {
      await axiosInstance.get(`${API.USER_PARTICIPANTS_PROGRESS}?filter[course_id]=${this.courseId}`).then((r) => {
        this.users = map(r?.data?.data?.participants, (val) => {
          return {
            value: val.participant_name,
            label: val.participant_name
          }
        }).reverse()
      })
    }, 750)
  }
}
</script>
<style lang="scss">
.course-dialog.el-dialog {
  text-align: left;
  @media (max-width: 772px) {
    width: 300px !important;
  }

  .el-dialog__header {
    margin-right: 0;
    background-color: var(--app-primary-color);
    .el-dialog__title {
      color: #fff;
      display: block;
      width: 310px;

      @media (max-width: 772px) {
        width: 210px !important;
      }
    }
    .el-dialog__headerbtn {
      // top: 0;
      // right: 0;
      .el-dialog__close {
        color: #fff;
      }

      .el-dialog__close:hover {
        color: #fff;
      }
    }
  }

  .el-dialog__body {
    .el-select {
      width: 100%;
    }
  }

  .el-button {
    &.is-disabled {
      background-color: #e1e1e1;
      border-color: #bdbdbd;
      color: #bdbdbd;
    }

    &.close-button {
      background-color: #e4e7ed;
      border-color: #e4e7ed;
      padding-right: 7.5px;
      padding-left: 7.5px;
    }
  }
}
</style>
