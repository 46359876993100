import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { mapEndpoint } from '@/modules/helpers/common'

export default {
  getReferralCode(context, payload = {}) {
    const url = mapEndpoint(API.GET_TRANSLATOR_REFERRAL_CODE, {
      translatorId: payload.translator_id
    })
    const method = 'GET'
    let options = {
      on_success(r, ctx) {
        ctx.commit('setTranslatorReferralCode', r.data.data.referral_code)
      }
    }

    return reach(this, context, url, method, options, axiosInstance)
  },
  getReferrals(context, payload = {}) {
    const url = mapEndpoint(API.GET_TRANSLATOR_REFERRALS, {
      translatorId: payload.translator_id
    })
    const method = 'GET'
    let options = {
      on_success(r, ctx) {
        ctx.commit('setReferrals', r.data.data)
      }
    }
    return reach(this, context, url, method, options, axiosInstance)
  },
  getReferralSettings(context) {
    const url = API.SETTINGS
    const method = 'GET'
    let options = {
      query_params: {
        'filter[name]': 'referral_settings_interpreter'
      },
      on_success(r, ctx) {
        ctx.commit('setSettings', r.data.data.settings[0].value)
      }
    }
    return reach(this, context, url, method, options, axiosInstance)
  },

  getInvitations(context) {
    const url = API.USER_REFERRAL_INVITATIONS
    const method = 'GET'
    let options = {
      on_success(r, ctx) {
        ctx.commit('setInvitations', r.data.data)
      }
    }
    return reach(this, context, url, method, options, axiosInstance)
  },

  sendInvite(context, payload) {
    const url = API.SEND_REFERRAL_INVITE
    const method = 'POST'
    let options = {
      data: payload,
      is_data_raw: true,
      on_success() {
        console.log('referral invite sent')
      }
    }
    return reach(this, context, url, method, options, axiosInstance)
  },

  getReferralTransactions(context, payload) {
    const url = mapEndpoint(API.BOOKING_BONUSES_LIST, {
      translatorId: payload.translator_id
    })

    const method = 'GET'
    let options = {
      on_success(r, ctx) {
        console.log('getReferralTransactions', r.data)
        ctx.commit('setTransactions', r.data.data.items)
      }
    }
    return reach(this, context, url, method, options, axiosInstance)
  },

  getReferralDiscounts(context, payload) {
    const url = mapEndpoint(API.BOOKING_DISCOUNTS_LIST, {
      translatorId: payload.translator_id
    })

    const method = 'GET'
    let options = {
      on_success(r, ctx) {
        ctx.commit('setDiscounts', r.data.data.items)
      }
    }
    return reach(this, context, url, method, options, axiosInstance)
  },

  getDiscountsForBookings(context, payload) {
    const url = mapEndpoint(API.DISCOUNTS_FOR_BOOKING_LIST, {
      bookingId: payload.booking_id
    })

    const method = 'GET'

    let options = {}

    return reach(this, context, url, method, options, axiosInstance)
  },

  getDiscountEligiblity(context) {
    const url = API.DISCOUNT_ELIGIBILITY

    const method = 'GET'

    let options = {
      on_success(r, ctx) {
        console.log('getDiscountEligiblity', r.data.data)
        if (r.data.data.is_eligible) ctx.commit('booking/setPriceQuoteDiscount', r.data.data, { root: true })
      }
    }

    return reach(this, context, url, method, options, axiosInstance)
  }
}
