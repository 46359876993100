<template>
  <div :class="blockClass">
    <el-popover placement="bottom" width="200" trigger="hover" @show="handlePopOverShow">
      <template #reference>
        <span class="timeSlots"> +{{ bookingTimeSlots.length }}</span>
      </template>
      <!-- booking_time_slots -->
      <div>
        <div class="value" v-for="(slot, index) in bookingTimeSlots" :key="index">
          {{ slot.date }}, {{ slot.start_time }} - {{ slot.end_time }}
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  components: {},

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    booking: {
      type: Object,
      default: () => {}
    },

    showSelectedStartTime: {
      type: Boolean,
      default: false
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'flexible-booking-time-slots-popover',
      fontSize: '12px',
      loading: false,
      newBooking: {}
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('auth', ['isUserCustomer']),

    getBooking() {
      return !isEmpty(this.newBooking) ? this.newBooking : this.booking
    },
    bookingTimeSlots() {
      return this.booking.booking_time_slots ?? []
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('booking', ['getBookingDetails']),

    isEmpty,

    handlePopOverShow() {}
  }
}
</script>

<style lang="scss">
.timeSlots {
  margin-left: 5px;
  color: var(--app-primary-color);
}
.booking-time-slots-popover {
  display: inline-block;
  .value {
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #736e69;
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    &.link {
      display: inline;
      font-weight: 700;
      text-decoration-line: underline;
      color: var(--app-primary-color);
    }
  }

  img {
    cursor: pointer;
  }
}
</style>
