/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadLanguageOpts
|--------------------------------------------------------------------------
|
*/

import { loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '../../../../helpers/appStorage'
import isNil from 'lodash/isNil'
import { COUNTRIES } from '@/modules/constants/countries'
import { getAppTenant } from '@/modules/helpers/multiTenancy'

function extractCountriesFromLangs(r) {
  let dialects = []
  // set the dialects.
  for (let i = 0; i < r.data.data.languages.length; i++) {
    const lang = r.data.data.languages[i]
    if (lang.countries) {
      for (let j = 0; j < lang.countries.length; j++) {
        const countryIso = lang.countries[j]
        dialects.push({
          id: countryIso,
          name: COUNTRIES[countryIso],
          language_id: lang.id
        })
      }
    }
  }

  return dialects
}

/**
 * Action to load language options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise|void}
 */
export default function (context, payload = {}) {
  if (context.getters['languageOpts'].length > 0) {
    return
  }

  const tenantID = getAppTenant()?.uuid

  const base = import.meta.env.VITE_LANGUAGES_BASE || `https://languages-stg-api.digitaltolk.net`
  const url = base + `/api/v3/languages-app/${tenantID}/languages`

  const method = 'GET'
  let languages = appStorage.get(context.state.dtLanguagesCookieKey)
  let updatedAt = appStorage.get(context.state.dtLanguagesUpdatedCookieKey)

  context.commit('setLanguageOptsLoading', true)

  let query_params = {
    'all': true,
    'include': '',
    'fields[languages]': 'id,name,iso_6391,iso_6393,call_us'
  }
  if (!context.getters['auth/isUserAdmin']) {
    query_params['filter[active]'] = true
  }
  let options = {
    query_params,
    on_success(r, ctx) {
      ctx.commit('setLanguageOpts', r)

      let dialects = extractCountriesFromLangs(r)
      ctx.commit('setCountryOpts', dialects)
      appStorage.set(context.state.dtLanguagesCookieKey, r)
      appStorage.set(context.state.dtLanguagesUpdatedCookieKey, context.state.newLastUpdate)
    },
    on_finally(ctx) {
      ctx.commit('setLanguageOptsLoading', false)
    }
  }

  if (isNil(languages) || isNil(updatedAt) || payload.load) {
    return loadAll(this, context, url, method, 'setLanguageOpts', options, axiosInstance)
  } else {
    context.commit('setCountryOpts', extractCountriesFromLangs(languages))
    return context.commit('setLanguageOpts', languages)
  }
}
