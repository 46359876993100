/*
|--------------------------------------------------------------------------
| Store > Booking List
|--------------------------------------------------------------------------
|
| This file is the entry point for the Vuex Module.
| Use this file to register the state, getters, mutations, and actions.
|
*/

export default {
  topControls: (state) => state.topControls,
  topControlsFormOptions: (state) => state.topControlsFormOptions
}
