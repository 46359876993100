/*
|--------------------------------------------------------------------------
| Store > Multi-Tenancy > Actions > loadTenant
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { apiCall } from '@/modules/helpers/vuexActions'
import {
  getAppTenant,
  getAppTenantConfig,
  mapTenantConfigToState,
  mapTenantToState
} from '@/modules/helpers/multiTenancy'

/**
 * Action to load the department opts.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @returns {Promise|*}
 */
export default function (context, payload = { domain: '' }) {
  const appTenant = getAppTenant()
  const appTenantConfig = getAppTenantConfig()

  console.log('This is app tenant...', appTenant)
  console.log('This is app tenant config...', appTenantConfig)

  if (appTenant) {
    context.commit(
      'multiTenancy/setTenant',
      {
        ...mapTenantToState(appTenant)
      },
      { root: true }
    )
  }
  if (appTenantConfig) {
    context.commit(
      'multiTenancy/setTenantConfig',
      {
        ...mapTenantConfigToState(appTenantConfig)
      },
      { root: true }
    )
  } else {
    context.commit('multiTenancy/setIsTenantLoading', true, { root: true })
    if (payload.domain) {
      let domainOrUUID = payload.domain

      const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === 'production'
      const isPipeline = import.meta.env.VITE_IS_PIPELINE

      console.log('this is process.env.CI value...', isPipeline)

      if (isPipeline && !isProduction) {
        domainOrUUID = '256637be-aa87-4ecf-874e-8d98c985f655'
      } else if (!isPipeline && !isProduction) {
        const swedenTenantIDStg = '256637be-aa87-4ecf-874e-8d98c985f655'
        domainOrUUID = import.meta.env.VITE_TENANT_ID || swedenTenantIDStg
      } else if (isPipeline && isProduction) {
        const swedenTenantIDPrd = 'a2a3e698-38e1-4b06-b920-56c6d9d2afbe'
        domainOrUUID = import.meta.env.VITE_TENANT_ID || swedenTenantIDPrd
      }

      return apiCall({
        url: `${API.TENANT}/${domainOrUUID}`,
        method: 'GET'
      })
        .then((r) => {
          context.commit(
            'multiTenancy/setTenant',
            {
              ...mapTenantToState(r.data)
            },
            { root: true }
          )
          context.commit(
            'multiTenancy/setTenantConfig',
            {
              ...mapTenantConfigToState(r.data)
            },
            { root: true }
          )
          context.commit('multiTenancy/setIsTenantLoading', false, { root: true })
          window.location.reload()
          return r
        })
        .catch((e) => {
          context.commit('multiTenancy/setIsTenantLoading', false, { root: true })
          return e
        })
    }
  }
}
