import { INSURANCE_CERTIFICATE as API_INSURANCE_CERTIFICATE } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function dowloadInsuranceCertificate(context, payload) {
  const url = `${API_INSURANCE_CERTIFICATE}/${payload.translator_id}/download-insurance-certificate?requested_via=dt-ct&type=${payload.type}`
  const method = 'POST'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    })
      .then((r) => {
        const blob = new Blob([r.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = payload.type + '-certificate.pdf'
        link.click()
        resolve(r)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export default dowloadInsuranceCertificate
