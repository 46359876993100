import axios, { CancelToken } from 'axios'
import appStorage from '@/modules/helpers/appStorage'
import LS from '@/modules/constants/localStorage'
import { getTenantBaseApiUrl, getAppTenant } from '@/modules/helpers/multiTenancy'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
// import {AUTH} from '@/modules/constants/api.js'
import store from '@/store'
import router from '@/router/index.js'

export const axiosInstance = axios.create()

export function getBaseUrl() {
  // some logic to determine base URL here
  return Promise.resolve(getTenantBaseApiUrl())
}

axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl()
    const token = appStorage.get(LS.TOKEN)
    let user = appStorage.get(LS.USER)
    let accessToken = ''
    let tokenExpiry = ''
    let isValidToken = false
    if (token) {
      if (isString(token)) {
        accessToken = token
      } else if (isObject(token)) {
        accessToken = token.access_token
      } else {
        console.error('Unknown token format!')
      }

      tokenExpiry = getExpirationTime(accessToken)

      if (tokenExpiry) {
        const currentTime = new Date().getTime()
        const expirationTime = tokenExpiry * 1000
        if (currentTime < expirationTime) {
          isValidToken = true
        }
      }
      if (!isValidToken) {
        logout(user.id)
        return Promise.reject(new Error('Token invalid! Redirecting to login page...'))
      }

      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    const appTenant = getAppTenant()
    if (appTenant && appTenant.uuid) {
      config.headers['X-Tenant'] = appTenant.uuid
    }
    // TODO: SET UI URL Visited no CORS issue
    // config.headers['X-UI-URL'] = window.location.href + window.location.search
    return {
      ...config,
      cancelToken: new CancelToken(() => {
        if (!accessToken) {
          // cancel('Cancel request: Not authenticated')
        }
      })
    }
  },
  async (error) => {
    const originalRequest = error.config
    console.log('originalRequest', originalRequest)
    return await Promise.reject(error)
  }
)

// let isRefreshing = false
// let failedQueue = []
//
// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error)
//     } else {
//       prom.resolve(token)
//     }
//   })
//
//   failedQueue = []
// }

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config
//     if ([401, 403].includes(error.response.status) && !originalRequest._retry) {
//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           failedQueue.push({ resolve, reject })
//         })
//           .then((token) => {
//             originalRequest.headers['Authorization'] = 'Bearer ' + token
//             return axiosInstance(originalRequest)
//           })
//           .catch((err) => {
//             return Promise.reject(err)
//           })
//       }
//
//       originalRequest._retry = true
//       isRefreshing = true
//
//       let user = appStorage.get(LS.USER)
//       let userId = user?.id ?? null
//       const refreshToken = appStorage.get(LS.REFRESH_TOKEN)
//       if (refreshToken) {
//         try {
//           const appTenant = getAppTenant()
//           const { data } = await axios.post(AUTH, {
//             grant_type: 'refresh_token',
//             refresh_token: appStorage.get(REFRESH_TOKEN),
//             client_id: appTenant.client_id,
//             client_secret: appTenant.client_secret,
//             tenant: appTenant.uuid
//           })
//
//           appStorage.set(LS.TOKEN, data.access_token)
//           appStorage.set(LS.REFRESH_TOKEN, data.refresh_token)
//           axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token
//           originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token
//           store.state.auth.isUserAuthenticated = true
//
//           processQueue(null, data.access_token)
//           return axiosInstance(originalRequest)
//         } catch (err) {
//           processQueue(err, null)
//           logout(userId)
//           return Promise.reject(err)
//         } finally {
//           isRefreshing = false
//         }
//       } else {
//         processQueue(error, null)
//         logout(userId)
//       }
//     }
//     return Promise.reject(error)
//   }
// )

function getExpirationTime(jwtToken) {
  if (!jwtToken) {
    return null // Token is missing
  }

  const [, payloadBase64] = jwtToken.split('.') // Extract the payload part of the JWT
  const payload = JSON.parse(atob(payloadBase64)) // Decode base64 payload and parse JSON

  return payload.exp // Return the expiration time (exp claim)
}

// function validToken() {
//   const accessToken = appStorage.get(LS.TOKEN)
//   let tokenExpiry = getExpirationTime(accessToken)
//
//   if (!tokenExpiry) {
//     return false
//   }
//   const currentTime = new Date().getTime()
//   const expirationTime = tokenExpiry * 1000
//   return currentTime < expirationTime
// }

function logout(userId) {
  if (userId) {
    store.dispatch('auth/removeFromAuthCollection', userId, {
      root: true
    })
  }
  // window.location.href = '/login'
  router.push({ path: '/login' })
}
