/*
|--------------------------------------------------------------------------
| Store > Statistics > Actions > loadCustomerStatistics
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for getting payment methods.
 *
 * @param {object} context - Current context of Vuex module.
 * @returns {*}
 */
export default function (context) {
  const method = 'GET'
  let url = API.TRANSACTION_HISTORY

  return new Promise((resolve, reject) => {
    axiosInstance({ method, url })
      .then((r) => {
        context.commit('setTransactionHistory', r.data)
        resolve(r)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
