import isNil from 'lodash/isNil'
import LS from '@/modules/constants/localStorage'
import map from 'lodash/map'
import appStorage from '@/modules/helpers/appStorage'

function getAuthCollection() {
  const collection = !isNil(appStorage.get(LS.AUTH_COLLECTION)) ? appStorage.get(LS.AUTH_COLLECTION, 'csv') : []
  return map(collection, (num) => parseInt(num))
}

export default getAuthCollection
