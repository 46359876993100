import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import i18nData from '@/locales/i18nData.js'

export const SUPPORT_LOCALES = ['se', 'en']

const defaultOptions = {
  // mode: 'legacy',
  // legacy: false, // you must set `false`, to use Composition API
  locale: import.meta.env.VITE_I18N_LOCALE || 'se', // e.g. "en", "se", "da" etc
  fallbackLocale: ['en', 'se'],
  // messages: _, will be set by `loadLocaleMessages()` instead,
  missingWarn: false
}

export function setupI18n(options = defaultOptions) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  // await loadLocaleMessages(i18n, options.locale) // this will result in promise, so don't do it
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale, localesToLoad = ['en']) {
  // load locale messages with dynamic import
  const messages = await i18nData(locale, localesToLoad)

  // set locale and locale message
  if (i18n.global && i18n.global.setLocaleMessage) {
    i18n.global.setLocaleMessage(locale, messages)
    setI18nLanguage(i18n, locale)
  }

  return nextTick()
}

export default setupI18n()
