/*
|--------------------------------------------------------------------------
| TextTranslation Quote Status
|--------------------------------------------------------------------------
|
| Contains the Key - Value pair for the text translation quote status.
| Key is the TextTranslation Status Name, and the Value is the TextTranslation Status ID.
|
*/
export const AUTO_QUOTE_ACCEPTED = 1
export const MANUAL_QUOTE_PENDING = 2
export const MANUAL_QUOTE_SENT = 3
export const MANUAL_QUOTE_ACCEPTED = 4
export const MANUAL_QUOTE_REJECTED = 5
export const MANUAL_QUOTE_EXPIRED = 6
export const MANUAL_QUOTE_REQUESTED = 7

export default {
  AUTO_QUOTE_ACCEPTED,
  MANUAL_QUOTE_PENDING,
  MANUAL_QUOTE_SENT,
  MANUAL_QUOTE_ACCEPTED,
  MANUAL_QUOTE_REJECTED,
  MANUAL_QUOTE_EXPIRED,
  MANUAL_QUOTE_REQUESTED
}
