<template>
  <focus-trap v-model="visible" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-bind="$attrs"
      v-model="iValue"
      :class="[blockClass, 'app-modal', 'app-modal-secondary']"
      :title="title"
      :append-to-body="true"
      :center="true"
      top="5%"
      @update:visible="$emit('closed')"
      @closed="$emit('closed')"
    >
      <template #title>
        <div class="header">
          <slot name="header"> Dialog </slot>
        </div>
      </template>
      <a ref="init_focus" class="intial_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>
      <div class="popup-content">
        <slot></slot>
      </div>
      <template #footer>
        <slot name="footer">
          <div class="actions">
            <slot name="footer-actions">
              <el-button type="default" size="large" @click="$emit('closed')">
                {{ $t('cancel') }}
              </el-button>

              <!-- Save buttons -->
              <el-button type="primary" class="app-button-secondary" @click="$emit('confirmed')">
                {{ $t('confirm') }}
              </el-button>
            </slot>
          </div>
        </slot>
      </template>
    </el-dialog>
  </focus-trap>
</template>

<script>
export default {
  inheritAttrs: false,
  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: 'Confirmation'
    }
  },
  emits: ['confirmed', 'closed'],
  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'dt-dialog',
      visible: false
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    iValue: {
      get() {
        return this.show
      },
      set(v) {
        this.$emit('closed', v)
      }
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {}
}
</script>

<style lang="scss">
.dt-dialog.el-dialog {
  border-radius: 5px;
  overflow: auto;
  max-width: 1000px;
  width: 90%;
  .el-dialog__header {
    padding: 22px 20px;
    position: relative;
    z-index: 3000;

    .header {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 125%;
      // color: #ffffff;
      color: var(--app-primary-color);
    }

    .el-dialog__headerbtn {
      // top: 20px;
      right: 15px;
      .el-dialog__close {
        // font-size: 30px;
        font-weight: 600;
      }
    }
  }

  .el-dialog__body {
    padding: 0px;
    a.intial_focus {
      width: 1px;
      height: 0px;
      display: block;
    }

    .popup-content {
      height: 486px;
      max-height: 486px;
      overflow: auto;
      padding: 20px;
    }
  }

  .el-dialog__footer {
    padding: 20px;
    border-top: 1px solid #ebeef5;

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;

      .el-button {
        padding: 16px 30px;
      }
    }
  }
}
.dt-dialog {
  * {
    box-sizing: border-box;
  }

  .visible {
    z-index: 2020 !important;
  }

  .invisible {
    z-index: 2000 !important;
  }
}
</style>
