<template>
  <el-dialog
    v-model="isShowModal"
    class="app-modal app-modal-secondary"
    :class="getBem(blockClass)"
    :title="$t('feedback_for_dt_modal_title')"
    top="3%"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="formRules" @submit.prevent>
      <el-form-item :class="[getBem(blockClass, 'rating-container'), { 'auth-lines': isReadOnly }]" prop="rating">
        <label v-if="!isReadOnly" :class="getBem(blockClass, 'rating-label')">
          {{ $t('how_was_dt_experience') }}
        </label>
        <!-- already had feedback -->
        <template v-else>
          <p :class="getBem(blockClass, 'submitted-already-label')">
            {{ $t('booking_feedback_already_submitted') }}
          </p>
          <!-- <label :class="getBem(blockClass, 'rating-label')">
            {{ $t('your_feedback') }}
          </label> -->
        </template>

        <!-- Stars Rating Field -->
        <el-rate
          ref="booking-feedback-form-rating-field"
          v-model="form.rating"
          :class="`${getBem(blockClass, 'rating-field')} ${getBem(blockClass, ratingLevel)}`"
          void-icon-class="el-icon-star-on star-void"
          void-color="#FFFFFF"
          :colors="['#FFFFFF', '#FFFFFF', '#FFFFFF']"
          @change="handleChangeRating"
        />
        <!-- <el-icon><StarFilled /></el-icon> -->
      </el-form-item>

      <el-form-item :class="getBem(blockClass, 'comment-field-container')" prop="comment">
        <label :class="getBem(blockClass, 'comment-label')">
          {{ $t('your_feedback') }}
        </label>
        <el-input
          v-model="form.comment"
          type="textarea"
          :label="$t('details')"
          :class="getBem(blockClass, ['comment-field'])"
          :rows="4"
          tabindex="0"
        />
      </el-form-item>

      <div class="text-right">
        <el-button
          v-if="!isPublic"
          class="app-button-default"
          :class="getBem(blockClass, ['close-button'])"
          @click="handleClose"
        >
          {{ $t('btn_close') }}
        </el-button>

        <el-button
          :class="getBem(blockClass, ['submit-button'])"
          class="app-button-secondary"
          :disabled="submitBtnDisabled"
          @click="handleClickSubmit"
        >
          {{ $t('btn_submit') }}
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { showSmallSuccessNotif, showSmallErrorNotif } from '@/modules/helpers/notifications'
export default {
  props: {},
  data() {
    return {
      blockClass: 'feedback-for-dt-modal',
      isShowModal: false,
      booking: null,
      form: {
        rating: null,
        comment: ''
      },
      formRules: {
        rating: [{ required: true, message: this.$t('rating_required'), trigger: 'blur' }],
        comment: [{ required: true, message: this.$t('comment_required'), trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters({
      // user: 'auth/user',
      // translatorOtherJobs: 'booking/customerAndTranslatorOtherBookingListData',
      isCustomer: 'auth/isUserCustomer',
      isTranslator: 'auth/isUserTranslator'
    }),
    commentFieldLabel() {
      return this.isCustomer ? this.$t('rating_desc_for_customer') : this.$t('rating_desc_for_translator')
    },
    submitBtnDisabled() {
      return !this.form.rating || !this.form.comment
    }
  },
  methods: {
    ...mapActions('booking', ['submitFeedbackToDT']),
    open(booking) {
      console.log('open', booking)
      this.booking = booking
      this.isShowModal = true
    },
    close() {
      this.isShowModal = false
    },
    handleClickSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('valid', valid, this.booking)
          // Set payload.
          const payload = {
            booking_id: this.booking.id,
            data: {
              ...this.form
            }
          }
          // DEAL WITH SUBMISSION IN THE MODAL
          this.$store.commit('booking/setSubmitLoading', true)

          // Do the Submit Feedback Action.
          this.submitFeedbackToDT(payload)
            .then(() => {
              this.$store.commit('booking/setSubmitLoading', false)
              this.close()
              this.$emit('increaseFeedbackCount')
              showSmallSuccessNotif(this.$t('booking_feedback_success'))
              this.$emit('reloadList')
            })
            .catch((e) => {
              this.$store.commit('booking/setSubmitLoading', false)
              this.close()
              this.$emit('increaseFeedbackCount')
              let errTitle = `${this.$t('error')} - ${this.$t('booking_feedback_fail')}`
              showSmallErrorNotif(e, errTitle)
            })
        }
      })
    },
    handleClose() {
      this.isShowModal = false
    }
  }
}
</script>

<style lang="scss">
.feedback-for-dt-modal {
  &__rating-container {
    .el-form-item__content {
      flex-direction: column;
      align-items: start;
    }
    .el-rate {
      height: 65px;
    }

    .el-form-item__label {
      text-align: center;
      float: none;
      padding: 0;
      margin: 0;
      color: #a0a0a0;
    }

    .el-rate__icon {
      font-size: 45px;
      padding: 5px;
      border-radius: 5px;
      background-color: #c6d1de;
      color: #ffffff;

      svg:nth-child(1) {
        display: block !important;
      }
      svg:nth-child(2) {
        display: none !important;
      }
    }

    .el-rate__icon.el-icon-star-on.star-void {
      background-color: #c6d1de !important;
    }
  }

  &__rating-field[aria-valuenow='5'],
  &__rating-field[aria-valuenow='4'] {
    .el-rate__icon.el-icon-star-on,
    .el-rate__icon.is-active {
      // background-color: var(--app-primary-color);
      background-color: #55a37c;
    }
  }

  &__rating-field[aria-valuenow='3'] {
    .el-rate__icon.el-icon-star-on,
    .el-rate__icon.is-active {
      background-color: #fedb19;
    }
  }

  &__rating-field[aria-valuenow='1'],
  &__rating-field[aria-valuenow='2'] {
    .el-rate__icon.el-icon-star-on,
    .el-rate__icon.is-active {
      background-color: #ef6e0d;
    }
  }

  &__rating-label,
  &__comment-label {
    text-align: center;
    font-weight: 600;
    color: $app-form-gray;
  }

  &__rating-note {
    font-size: 14px;
    font-weight: normal;
    margin-top: 0;
  }
}
</style>
