/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadCompanyUserOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the company user opts according to the given company id.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @param {string|int} payload.company_id - The target company id in which the set of users should be under.
 * @param {object} payload.queryParams
 * @returns {Promise|*}
 */
export default function (context, payload = {}) {
  if (isNil(payload.company_id) || payload.company_id === '' || payload.company_id === 0) {
    const errMsg = 'payload.company_id value Should exist!'
    console.error(errMsg)
    throw new Error(errMsg)
  }

  context.commit('auth/setCompanyUserOptsLoading', true, { root: true })

  const url = `${API.COMPANIES}/${payload.company_id}/customers`
  const method = 'GET'

  let options = {
    query_params: !isNil(payload.queryParams) ? payload.queryParams : {},
    on_success(r, ctx) {
      ctx.commit('auth/setCompanyUserOpts', r.data.data, { root: true })
    },
    on_finally(ctx) {
      ctx.commit('auth/setCompanyUserOptsLoading', false, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
