/*
|--------------------------------------------------------------------------
| Custom > Constants > Local Storage
|--------------------------------------------------------------------------
|
| This file contains the list of keys that should reference values
| inside localStorage.
|
*/

export const TOKEN = 'app_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER = 'app_user'
export const AUTH_COLLECTION = 'app_authenticated'
export const AUTH_COLLECTION_USER = 'app_authenticated_' // will serve as prefix
export const COOKIE_USAGE_AGREEMENT = 'app_cookie_usage_agreement'
export const CUSTOMER_BOOKING_LIST_SETTINGS = 'app_customer_booking_list_settings'
export const CUSTOMER_BOOKING_CREATED = 'app_customer_booking_created'
export const CUSTOMER_BOOKING_UPDATED = 'app_customer_booking_updated'
export const EMERGENCY_COUNTDOWN = 'app_emergency_countdown'
export const FEEDBACK_PUBLIC_SIGNATURE = 'app_feedback_public_signature'
export const TRAVEL_TIME_PUBLIC_SIGNATURE = 'app_travel_time_public_signature'
export const IS_NOTIFY_AT_PREFERENCE_TIMEOUT = 'app_is_notify_at_preference_timeout'
export const IS_EMPLOYED_TRANSLATOR = 'is_employed_translaor'
export const TENANT = 'app_tenant'
export const TENANT_CONFIG = 'app_tenant_config'
export const CUSTOMER_INTEGRATION_USER = 'customer_integration_user'
export const USER_BOOKING_TYPE = 'user_booking_type'
export default {
  TOKEN,
  REFRESH_TOKEN,
  USER,
  AUTH_COLLECTION,
  AUTH_COLLECTION_USER,
  COOKIE_USAGE_AGREEMENT,
  CUSTOMER_BOOKING_LIST_SETTINGS,
  CUSTOMER_BOOKING_CREATED,
  CUSTOMER_BOOKING_UPDATED,
  EMERGENCY_COUNTDOWN,
  FEEDBACK_PUBLIC_SIGNATURE,
  TRAVEL_TIME_PUBLIC_SIGNATURE,
  IS_NOTIFY_AT_PREFERENCE_TIMEOUT,
  IS_EMPLOYED_TRANSLATOR,
  TENANT,
  TENANT_CONFIG,
  CUSTOMER_INTEGRATION_USER,
  USER_BOOKING_TYPE
}
