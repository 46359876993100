/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > setUserNotificationPreference
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { showSmallNotif } from '@/modules/helpers/notifications'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.user_id - Authenticated User ID.
 * @returns {*}
 */
export default function (context, payload) {
  console.log(payload)
  const method = 'POST'
  const url = `${API.NOTIFICATION_PREFERENCE}`
  context.commit('setUserNotificationLoading', true)
  let options = {
    data: {
      user_id: payload.user_id,
      templates: payload.templates
    },
    is_data_raw: true,
    on_success(r, ctx) {
      ctx.commit('auth/setUserNotificationPreference', r.data.data.item.preferences, { root: true })
      showSmallNotif('success', {
        title: 'Klart',
        message: 'Inställning sparad.'
      })
    },
    on_finally(ctx) {
      ctx.commit('auth/setUserNotificationLoading', false, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
