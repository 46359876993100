/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getSuggestedTravelTime
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to fetch the travel time suggestions for the booking.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  const { booking_id } = payload
  const { traveled_by } = payload
  const { two_way_trip } = payload

  let options = {}

  // Make error loggers to make sure required values are filled in.
  if (isNil(booking_id)) {
    console.error('Booking ID should exist!')
  }
  const url = `${API.BOOKINGS}/${booking_id}/suggested-time?travel_by=${traveled_by}&two_way_trip=${two_way_trip}`

  return reach(this, context, url, 'GET', options, axiosInstance)
}
