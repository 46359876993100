/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > checkDeepLBookingStatus
|--------------------------------------------------------------------------
|
*/

import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'

/**
 * Action to get deepl booking status.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, bookingID) {
  const url = `${API.BOOKING}/${bookingID}/deepl-document-download`
  // const url = `${API.BOOKINGS}/16382?include=customer.department.company.municipality,translator_levels,countries,assigned_translator,notification_types,specific_translators,excluded_translators,video_booking,messages.files,convey.files,towns,translator_trainings,booking_specific_preference,translator_email,translator_address,translator_dob,text_translation.files,customer.booking_field,feedback,excluded_towns,language_pair_levels,booking_time_slots&append=acceptable_booking_dates`
  const method = 'GET'
  let options = {
    data: {}
  }

  return reach(this, context, url, method, options, axiosInstance)
}
