// import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function getByIdTranslatorUnavailableTimes(context, payload) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = `${API.TRANSLATOR_UNAVAILABLE_TIMES}/${payload.id}`
  const method = 'GET'
  // const options = {
  //   query_params: payload.queryParams
  // }
  let params = payload.queryParams

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      params
    })
      .then((r) => {
        let data = r?.data?.data?.translator_unavailable_time
        let bookingTypes = data.translator_unavailable_booking_types.map((item) => item.booking_type)
        let p = {
          id: data?.id,
          description: data?.description || '',
          type: bookingTypes,
          address: data?.address || '',
          unavailable_from: data?.unavailable_from,
          unavailable_till: data?.unavailable_until
        }
        context.commit('setForm', p)
        resolve(r)
      })
      .catch((e) => {
        context.commit('setTranslatorUnavailableTimeLoading', false)
        reject(e)
      })
  })
}

export default getByIdTranslatorUnavailableTimes
