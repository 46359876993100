/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitBooking
|--------------------------------------------------------------------------
|
*/
import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import actionAfterSubmitBooking from '@/modules/booking/store/shared/actionAfterSubmitBooking'

export default function (context) {
  // Prepare the payload to be sent across the API.
  context.commit('setConfirmFormPayload', {
    user: context.rootGetters['auth/userMetaData'],
    fallbackBookingType: context.rootGetters['auth/fallbackBookingType']
  })
  context.commit('setSubmitLoading', true)

  const url = API.BOOKINGS
  const method = 'post'

  let data = context.state.confirmFormPayload
  data.created_via = context.rootGetters['auth/isOnMobileViewport'] ? 'ct-responsive' : 'ct-desktop'

  let options = {
    data,
    is_data_raw: true,

    on_success(r, ctx) {
      actionAfterSubmitBooking(ctx, r, {
        booking: data,
        subject: 'text-translation'
      })
    },

    on_finally(ctx) {
      ctx.commit('setSubmitLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
