/*
|--------------------------------------------------------------------------
| Store > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/
export default {
  // --------------------------------------------------
  // PriceLists
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  priceListData: (state) => state.priceLists.data,

  /**
   * @returns {Object}
   */
  priceListsPagination: (state) => state.priceLists.pagination,

  /**
   * @returns {Boolean}
   */
  priceListsLoading: (state) => state.priceLists.loading
}
