/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadMunicipalityUserOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the municipality user opts according to the given municipality id.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @param {string|int} payload.municipality_id - The target municipality id in which the set of users should be under.
 * @param {object} payload.queryParams
 * @returns {Promise|*}
 */
export default function (context) {
  const url = `${API.MUNICIPALITIES}?all=trueç`
  const method = 'GET'

  let options = {
    on_success(r, ctx) {
      ctx.commit('auth/setMunicipalities', r.data.data, { root: true })
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
