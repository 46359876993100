/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > validateBooking
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'

/**
 * Action to validate the booking to make sure values are right,
 * and then return the additional values to be used for booking confirmation
 * as response.
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @param payload
 * @return {*}
 */
export default function (context, payload = {}) {
  const url = `${API.FINANCIAL}/${payload.id}/invoice-lcr`
  const method = 'get'

  let options = {}

  return reach(this, context, url, method, options, axiosInstance)
}
