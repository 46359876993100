/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > Towns
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @returns {*}
 */
export default function (context) {
  const method = 'GET'
  const url = API.TOWNS

  let options = {
    query_params: {
      'all': true,
      'fields[towns]': 'id,name',
      'sort': 'name'
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
