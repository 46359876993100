import { reach } from '@/modules/helpers/vuexActions'
import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function loadTranslatorUnavailableTimesGeneric(context, payload) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = API.TRANSLATOR_UNAVAILABLE_TIMES
  const method = 'GET'
  const options = {
    query_params: payload.queryParams
  }

  return reach(this, context, url, method, options, axiosInstance)
}

export default loadTranslatorUnavailableTimesGeneric
