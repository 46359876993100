export default {
  /**
   *
   * @param state
   * @param payload
   */
  setEmailsData(state, payload) {
    state.emails.data = payload.data
    state.emails.pagination = {
      total: payload.total,
      current_page: payload.current_page,
      per_page: parseInt(payload.per_page)
    }
    localStorage.setItem('emails', payload)
  },

  /**
   *
   * @param state
   * @param payload
   */
  setEmail(state, payload) {
    state.email = payload.data
  },

  /**
   *
   * @param state
   * @param payload
   */
  setGlobalModalBookingId(state, payload) {
    state.globalModalBookingId = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setBookingChangesNotification(state, payload) {
    state.bookingChangesNotification = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setBookingChangesNotificationLoading(state, payload) {
    state.bookingChangesNotificationLoading = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setBookingChangesModal(state, payload) {
    state.showBookingChangesModal = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setLoadingStatus(state, payload) {
    state.loading = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setNotifications(state, payload) {
    state.notifications = payload.data.data.notifications
  },

  /**
   *
   * @param state
   * @param payload
   */
  setUnreadInboxCount(state, payload) {
    state.unreadInboxCount = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setUnreadNotificationsCount(state, payload) {
    state.unreadNotificationsCount = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setUnreadImportantCount(state, payload) {
    state.unreadImportantCount = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setNotificationsEndStatus(state, payload) {
    state.isNotificationsEnd = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  setLoading(state, payload) {
    state.emails.loading = payload
  }
}
