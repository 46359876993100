/*
|--------------------------------------------------------------------------
| Store > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/

export default () => {
  return {
    loading: false,
    error: '',
    emails: {
      data: [],
      pagination: {
        total: 0,
        current_page: 0,
        per_page: 0
      },
      loading: false
    },
    email: {},
    notifications: {},
    unreadInboxCount: 0,
    unreadNotificationsCount: 0,
    unreadImportantCount: 0,
    isNotificationsEnd: false,
    // booking changes modal
    globalModalBookingId: null,
    showBookingChangesModal: false,
    bookingChangesNotification: {},
    bookingChangesNotificationLoading: false
  }
}
