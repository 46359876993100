import axios from 'axios'
import useStorage from '@/composables/useStorage'

const localStorage = useStorage('local')

export default async function (locale, locales = ['en']) {
  const DEFAULT_BASE = import.meta.env.VITE_VERBIAGE_BASE || 'https://verbiage-api.digitaltolk.com'
  const GENERATE = '/api/v3/generate' // responds with latest verbiage for given param
  const LAST_UPDATE = '/api/v3/last-update' // returns timestamp for when verbiage MS was last updated

  const LOCALES = locales // the locale to load
  const TAG = 'dt-ct' // the tag to load

  const params = {
    locales: LOCALES.join(','),
    tag: TAG
  }

  let shouldRefetch = false

  // Fetch and update verbiage last updated timestamp
  try {
    const { data } = await axios.get(`${DEFAULT_BASE}${LAST_UPDATE}`)
    const localTimestamp = localStorage.get('app_verbiages_last_update')
    const localVerbiages = localStorage.get(`app_verbiages_terms_${locale}`)

    if (data.terms !== localTimestamp || !localVerbiages) {
      shouldRefetch = true
      localStorage.set('app_verbiages_last_update', data.terms)
    } else {
      const response = localStorage.get(`app_verbiages_terms_${locale}`)
      return response
    }
  } catch (err) {
    console.error('Got this error while getting verbiage update date...', err)
  }

  try {
    let response

    if (shouldRefetch) {
      const { data } = await axios.get(`${DEFAULT_BASE}${GENERATE}`, { params })
      response = data

      locales.forEach((langLocale) => {
        localStorage.set(`app_verbiages_terms_${langLocale}`, response[langLocale])
      })
    }

    response = localStorage.get(`app_verbiages_terms_${locale}`)

    return response
  } catch (error) {
    console.error('Got this error while fetching i18n data...', error)
  }
}
