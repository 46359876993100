/*
|--------------------------------------------------------------------------
| Store > Statistics > Getters
|--------------------------------------------------------------------------
| File that contains the getters property for the current Vuex scope.
*/

export default {
  /**
   * @return {Object}
   */
  customerStatisticsData: (state) => state.customerStatistics.data,

  /**
   * @return {Boolean}
   */
  customerStatisticsLoading: (state) => state.customerStatistics.loading,

  /**
   * @return {Object}
   */
  translatorStatisticsData: (state) => state.translatorStatistics.data,

  /**
   * @return {Boolean}
   */
  translatorStatisticsLoading: (state) => state.translatorStatistics.loading
}
