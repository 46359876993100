/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadUserSessionLogs
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll } from '@/modules/helpers/vuexActions'
import { mapEndpoint } from '@/modules/helpers/common'
import store from '@/store'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * load current logged in user session logs
 *
 * @param context
 * @param payload
 * @returns {*}
 */
export default async function (context, payload = {}) {
  payload.userId = await store.getters['auth/user'].id
  const url = mapEndpoint(API.USER_SESSIONS, payload)
  const method = 'GET'

  context.commit('auth/setUserSessionLogsLoading', true, { root: true })

  let options = {
    on_success(r, ctx) {
      ctx.commit('auth/setUserSessionLogsData', r.data, { root: true })
    },

    on_finally(ctx) {
      ctx.commit('auth/setUserSessionLogsLoading', false, { root: true })
    }
  }

  return loadAll(this, context, url, method, 'loadUserSessionLogs', options, axiosInstance)
}
