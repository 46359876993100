<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="isShowModal"
      class="app-modal app-modal-secondary"
      :class="getBem(blockClass)"
      :title="$t('session_time_generic')"
      :close-on-click-modal="isMaskClosable"
      :close-on-press-escape="!isPublic"
      :show-close="!isPublic"
      top="3%"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

      <booking-session-time-and-travel-time-form
        ref="form"
        v-model="form"
        :booking="booking"
        @close="handleClickClose"
        @submit="handleClickUpdate"
      />

      <br />
    </el-dialog>
    <!-- Component DIV Wrapper -->
  </focus-trap>
</template>

<script>
import BookingSessionTimeAndTravelTimeForm from '../forms/BookingSessionTimeAndTravelTimeForm'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import { showSmallSuccessNotif, showSmallErrorNotif } from '@/modules/helpers/notifications'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

export default {
  name: 'BookingSessionTimeAndTravelTimeModal',
  components: {
    BookingSessionTimeAndTravelTimeForm
  },

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    isPublic: {
      type: Boolean,
      default: false
    },

    isMaskClosable: {
      type: Boolean,
      default: true
    }
  },

  emits: ['submit'],

  /*
   |--------------------------------------------------------------------------
   | Component > data
   |--------------------------------------------------------------------------
   */
  data() {
    return {
      blockClass: 'booking-session-time-modal',
      isShowModal: false,
      booking: {},

      form: {
        pay_travel_time: true,
        time: '00:00',
        travel_time: '',
        session_time: '',
        comment: '',
        submit_travel_time: false,
        submit_session_time: false,
        submit_inconvenience: false,
        break_minutes: '',
        travel_distance: 0,
        traveled_by: 'transit',
        inconvenience: {
          quantity: ''
        },
        comment_travel_time: '',
        session_comment_translator: '',
        use_suggested_travel_time: false,
        suggested_travel_time: null,
        is_discrepancy: 0,
        discrepancy_reason: '',
        way_of_travel: 'two_way'
      }
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('auth', ['user'])
  },
  /*
   |--------------------------------------------------------------------------
   | Component > methods
   |--------------------------------------------------------------------------
   */
  methods: {
    ...mapActions('booking', [
      'updateSessionTime',
      'calculateTravel',
      'submitPrefillSalaryItems',
      'loadSalarySetting',
      'updateTravelTime',
      'getBookingTravelTime',
      'getSuggestedTravelTime',
      'submitTravelTimeMessage'
    ]),
    /**
     * Handle when the update button was clicked.
     *
     * @returns {void}
     */
    async handleClickUpdate() {
      this.form.suggested_travel_time = await moment.duration(this.form.suggested_travel_time).asMinutes()
      if (this.form.submit_session_time === true && this.form.session_time != this.booking.session_time) {
        this.form.session_comment_translator = this.form.comment
        let payload = {
          booking_id: this.booking.id,
          data: this.form
        }
        await this.updateSessionTime(payload)
          .then(() => {
            showSmallSuccessNotif(this.$t('booking_session_time_success'))
          })
          .catch((e) => {
            let errTitle = `${this.$t('error')} - ${this.$t('booking_session_time_fail')}`
            showSmallErrorNotif(this.$t(e.response.data.message), errTitle)
          })
      }
      if (this.form.submit_travel_time === true && this.form.time != '00:00') {
        if (this.form.traveled_by != 'driving') {
          this.form.travel_distance = 0
        }
        this.form.comment = this.form.comment_travel_time
        const travelTime = {
          data: {
            entity_type: 'bookings',
            entity_id: this.booking.id,
            due: this.booking.due,
            by_user_id: this.user.id,
            ...this.form
          }
        }
        if (isNil(this.booking.travel_time)) {
          Object.assign(travelTime.data, { travel_time_update_counter: 2 })
          await this.calculateTravel(travelTime)
            .then(() => {
              showSmallSuccessNotif(this.$t('booking_travel_time_success'))
            })
            .catch((e) => {
              let errTitle = `${this.$t('error')} - ${this.$t('booking_travel_time_fail')}`

              if (!isEmpty(e.response.data) && e.response.data.code === 400) {
                e = e.response.data.message
              }
              showSmallErrorNotif(e, errTitle)
            })
        } else {
          if (this.form.time != this.booking.travel_time.travel_time) {
            travelTime.data.id = this.booking.travel_time.id
            await this.updateTravelTime(travelTime)
              .then(() => {
                showSmallSuccessNotif(this.$t('booking_travel_time_success'))
              })
              .catch((e) => {
                let errTitle = `${this.$t('error')} - ${this.$t('booking_travel_time_fail')}`

                if (!isEmpty(e.response.data) && e.response.data.code === 400) {
                  e = e.response.data.message
                }
                showSmallErrorNotif(e, errTitle)
              })
          }
        }
      }
      if (this.form.submit_inconvenience === true) {
        const inconvenience = {
          id: this.booking.id,
          data: {
            ...this.form.inconvenience
          }
        }
        this.submitPrefillSalaryItems(inconvenience).then(() => {
          showSmallSuccessNotif(this.$t('booking_inconvenience_success'))
        })
      }
      if (
        this.form.comment !== this.booking.session_comment_translator &&
        this.form.session_time == this.booking.session_time
      ) {
        this.form.session_comment_translator = this.form.comment
        let payload = {
          booking_id: this.booking.id,
          data: this.form
        }
        await this.updateSessionTime(payload)
          .then(() => {
            showSmallSuccessNotif(this.$t('booking_session_time_success'))
          })
          .catch((e) => {
            let errTitle = `${this.$t('error')} - ${this.$t('booking_session_time_fail')}`
            showSmallErrorNotif(this.$t(e.response.data.message), errTitle)
          })
      }

      if (this.form.is_discrepancy) {
        this.submitTravelTimeMessage({
          booking_id: this.booking.id,
          user_id: this.user.id
        })
      }
      this.$emit('submit')
      this.isShowModal = false
    },

    /**
     * Handler when the Close button was clicked.
     *
     * @returns {void}
     */
    handleClickClose() {
      // redirect if public modal
      if (this.isPublic) {
        this.$router.push({ path: '/' })
      } else {
        this.close()
      }
    },

    /**
     * Helper method for setting the initial values to the form.
     *
     * @param {object} booking - The current target booking.
     * @returns {void}
     */
    setInitalValues(booking) {
      this.form.session_time =
        booking.type === 'convey'
          ? moment('00:00:00', 'HH:mm:ss').add(booking.duration, 'minutes').format('HH:mm:ss')
          : moment(booking.session_time, 'HH:mm:ss').format('HH:mm:ss')
      this.form.break_minutes = booking.break_minutes
      this.form.comment_travel_time = ''
      this.form.comment = ''
      if (booking.session_comment_translator) {
        this.form.session_comment_translator = booking.session_comment_translator
        this.form.comment = booking.session_comment_translator
      }

      if (this.isShowTravelTimeButton(booking)) {
        const travelSetting = booking.salary_settings[0]?.salary_setting?.travel_setting
        this.form.pay_travel_time = travelSetting?.pay_travel_time
        this.form.pay_travel_distance = travelSetting?.pay_travel_distance
        if (booking.travel_time) {
          this.form.traveled_by = booking.travel_time.traveled_by ?? 'transit'
          this.form.travel_time = booking.travel_time.travel_time
          this.form.travel_distance = booking.travel_time.travel_distance
          this.form.comment_travel_time = booking.travel_time.comment
          this.form.time = moment('00:00', 'HH:mm').add(booking.travel_time.travel_time, 'minutes').format('HH:mm')
        }
      }
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking) {
      Promise.all([
        this.loadSalarySetting(booking),
        this.getBookingTravelTime({ booking_id: booking.id }),
        this.getSuggestedTravelTime({
          booking_id: booking.id,
          traveled_by: 'transit',
          two_way_trip: this.form.way_of_travel === 'two_way'
        })
      ]).then((responses) => {
        let travelTimes = (responses[1].data.data.items || []).sort(function (a, b) {
          return b.id - a.id
        })

        booking['salary_settings'] = []
        booking['salary_settings'][0] = {
          salary_setting: responses[0].data.data.salaries_setting
        }

        booking.travel_time = travelTimes[0] || null

        this.booking = booking
        this.form.session_time = booking.session_time
        if (!isNil(responses[2]) && !isNil(responses[2].data.data.suggestions)) {
          const duration = moment.duration(responses[2].data.data.suggestions.temp_travel_time, 'minutes')
          this.form.time = moment.utc(duration.asMilliseconds()).format('HH:mm')
          this.form.suggested_travel_time = moment.utc(duration.asMilliseconds()).format('HH:mm')
          this.form.use_suggested_travel_time = true
          this.form.travel_time = moment.duration(this.form.time).asMinutes()
        }

        this.setInitalValues(this.booking)
        this.isShowModal = true
      })
    },

    /**
     *
     * @param {Object} booking - Object to be evaluated.
     * @returns {Boolean}
     */
    isShowTravelTimeButton(booking) {
      const isSameMonth = moment(booking.due).isSame(moment(), 'month')
      if (
        (isSameMonth || booking.travel_time == null) &&
        (booking.status.code === 'completed' || booking.status.code === 'not_carried_out_customer') &&
        ['physical', 'video_physical'].includes(booking.type)
      ) {
        return true
      }
      return false
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';

.booking-session-time-modal {
  .el-dialog {
    width: 600px;
  }

  // **************************************************
  // Extra Small Viewport
  // **************************************************
  @media (max-width: $screen-xs-max) {
    .el-dialog {
      width: 90%;
    }
  }

  // **************************************************
  // Small Viewport
  // **************************************************
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .el-dialog {
      width: 80%;
    }
  }

  // **************************************************
  // Medium Viewport
  // **************************************************
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .el-dialog {
      width: 70%;
    }
  }
}
</style>
