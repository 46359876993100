/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getBookingPublic
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to fetch the booking details via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.booking_id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 * @param {int} payload.translator_id - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  const { booking_id, signature, translator_id } = payload

  // Make error loggers to make sure required values are filled in.
  if (isNil(booking_id)) {
    console.error('Booking ID should exist!')
  } else if (isNil(signature)) {
    console.error('Signature should exist!')
  }

  let options = {
    query_params: {
      signature,
      translator_id
    }
  }

  // Define axios values.
  const url = `${API.BOOKINGS}/${booking_id}/details`

  const method = 'GET'

  return reach(this, context, url, method, options, axiosInstance)
}
