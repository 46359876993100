import { BOOKING_TIME_SLOT } from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'

import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload) {
  const url = `${BOOKING_TIME_SLOT}`

  const method = 'POST'
  let options = {
    enable_error_notif: false,
    data: payload,
    is_data_raw: true
  }

  return reach(this, context, url, method, options, axiosInstance)
}
