/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > downloadPdf
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import i18nClient from '@/locales/i18nClient.js'
const { t: $t } = i18nClient.global

/**
 * Action to fetch the booking details via public manner, but
 * with a special signature as an access to get through.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.booking_id - ID for the target entry.
 * @return {Promise|*}
 */
export default function (context, payload) {
  const url = `${API.BASE}/api/v3/financial/invoices/download`
  const method = 'POST'

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      data: payload
    })
      .then((r) => {
        console.log(r)
        resolve(r)
      })
      .catch((e) => {
        this.$notify.warning({
          title: $t('btn_costing') + ' ' + $t('error'),
          message: $t('something_went_wrong') + ' ' + $t('please_contact_your_administrator'),
          duration: 3000
        })
        reject(e)
      })
  })
}
