<template>
  <focus-trap v-model="showModal" :initial-focus="() => $refs.init_focus" :class="blockClass">
    <el-dialog
      :append-to-body="true"
      v-model="showModal"
      custom-class="resubmit-file-modal"
      :before-close="close"
      width="80%"
      height="80%"
      :title="$t('please_specify_reason')"
    >
      <div class="form-section">
        <el-form :model="form" ref="reject-reason-form">
          <el-form-item
            :label="$t('specify_reason_to_translator')"
            prop="reason"
            :rules="{
              required: true,
              message: $t('reason_required'),
              trigger: 'change'
            }"
          >
            <el-input
              type="textarea"
              v-model="form.reason"
              maxlength="200"
              show-word-limit
              :placeholder="$t('tt_translation_reject_field_placeholder')"
            ></el-input>
          </el-form-item>
          <!-- form action buttons -->
          <div class="action-btns">
            <el-button type="default" class="app-button-default-o" @click="close">
              {{ $t('cancel') }}
            </el-button>
            <el-button type="primary" class="app-button-secondary" :disabled="isSaveDisabled" @click="submitForm">
              {{ $t('submit') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </focus-trap>
</template>
<script>
export default {
  /*
          |--------------------------------------------------------------------------
          | Component > data
          |--------------------------------------------------------------------------
          */
  data() {
    return {
      loading: false,
      showModal: false,
      isSaveDisabled: false,
      data: '',
      form: {
        reason: ''
      }
    }
  },
  /*
          |--------------------------------------------------------------------------
          | Component > methods
          |--------------------------------------------------------------------------
          */
  methods: {
    open(data) {
      this.data = data
      this.showModal = true
    },

    // submit form
    async submitForm() {
      this.loading = true
      this.isSaveDisabled = true
      this.$refs['reject-reason-form'].validate(async (valid) => {
        if (!valid) {
          this.loading = false
          this.isSaveDisabled = false
          return
        }
        const payload = { id: this.data.id, reason: this.form.reason }
        try {
          this.$emit('submit', payload)

          this.$notify({
            type: 'success',
            title: 'Success!',
            message: this.$t('file_rejected_with_reason')
          })
        } catch (err) {
          this.$notify({
            type: 'error',
            title: 'Error!',
            message: this.$t('filed_rejection_failed') // Consider using a more specific error message if possible
          })
          console.error(err) // Log the error for debugging
        } finally {
          this.loading = false
          this.isSaveDisabled = false
          this.close()
        }
      })
    },

    // close modal
    close() {
      this.form.reason = ''
      this.showModal = false
    }
  }
}
</script>
```
<style lang="scss">
.resubmit-file-modal {
  width: 50%;
  border-radius: 1rem;
  .el-dialog__header {
    .el-dialog__title {
      font-weight: 700;
      color: rgba(112, 112, 112, 1);
    }
  }
  .el-form {
    display: flex;
    flex-direction: column;
    .el-form-item {
      margin-bottom: 1rem;
      display: block;
      .el-form-item__label {
        font-size: 14px;
        font-weight: 700;
        color: rgba(85, 85, 85, 1);
        line-height: 17.5px;
        text-align: left;
      }
      .el-textarea {
        .el-textarea__inner {
          width: 90% !important;
          height: 8rem;
        }
      }
    }
    .action-btns {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      border: none;
      .save-btn {
        margin-top: 1rem;
        background: rgba(51, 122, 183, 1);
        color: #fff;
        border: none;
        padding: 12px 30px;
      }
      .close-btn {
        margin-top: 1rem;
        border: none;
        background: rgba(238, 241, 247, 1);
      }
    }
  }
}
</style>
