import { setCachedBooking } from '@/modules/booking/services/cachedBookingService'

/**
 * @param {Object} context - Vuex context
 * @param {Object} o - Container for options
 * @param {Object} response - Response values from the API.
 * @param {Object} [o.booking = {}] - Payload data that's supposedly sent to the API.
 * @param {string} [o.subject = "default"] - Current target module. Varies on the BOOKING constant above.
 */
function actionAfterSubmitBooking(context, response, o) {
  if (o.booking == null) o.booking = {}
  if (o.subject == null) o.subject = 'default'

  const user = context.rootGetters['auth/user']

  setCachedBooking({
    booking: o.booking,
    userId: user.id
  })

  context.commit('setCreatedBooking', response.data.data.booking)
  context.commit('resetForm')
  context.commit('resetFormPayload')
  context.commit('resetConfirmForm')
  context.commit('resetConfirmFormPayload')
  context.commit('setIsConfirmFormHalted', false)
  // context.state.form.is_immediate = o.booking.is_immediate

  if (o.subject === 'default') {
    context.commit('resetTranslatorShortages')
  }
}

export default actionAfterSubmitBooking
