/*
|--------------------------------------------------------------------------
| Custom > Constants > User Type
|--------------------------------------------------------------------------
|
| This file contains the constants for User Type which value is
| equivalent to the User Type's ID.
|
*/

export const PUBLIC = 0

export const ADMIN = 1
export const SUPER_ADMIN = 4

export const CUSTOMER = 2

export const TRANSLATOR = 3

export default {
  PUBLIC,
  ADMIN,
  CUSTOMER,
  TRANSLATOR,
  SUPER_ADMIN
}
