<template>
  <!-- <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus"> -->
  <el-dialog
    v-model="isShowModal"
    class="app-modal app-modal-secondary"
    :class="getBem(blockClass)"
    :title="$t('booking_submited')"
    data-cy="emergency-booking-submit-success-modal"
    :close-on-click-modal="false"
  >
    <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

    <div :class="getBem(blockClass, 'wrapper')">
      <div :class="getBem(blockClass, 'icon')">
        <!-- <i class="el-icon-circle-check" /> -->
        <el-icon>
          <CircleCheck />
        </el-icon>
      </div>

      <div :class="getBem(blockClass, 'title')">
        <h3>
          {{ $t('warning') }}
        </h3>
      </div>

      <div :class="getBem(blockClass, 'verbiage')">
        <p v-html="$t('booking_started_prepare_your_self', { url: booking.video_booking.url })" />
      </div>

      <div :class="getBem(blockClass, 'actions')">
        <el-button
          :class="'app-button-secondary'"
          tabindex="0"
          data-cy="emergency-booking-submit-success-ok-btn"
          @click="handleClickOk"
        >
          {{ $t('btn_ok') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
  <!-- Component DIV Wrapper -->
  <!-- </focus-trap> -->
</template>

<script>
import { BOOKING_EXPIRED, BOOKING_ACCEPTED } from '@/modules/constants/event'
import { mapGetters, mapMutations } from 'vuex'
import EventBus from '@/modules/helpers/eventBus'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'emergency-booking-confirm-notif-modal',
      isShowModal: false,
      booking: {},
      bookingFromEvent: {},
      isBookingAccepted: false,
      isBookingExpired: false
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('auth', ['isUserAdmin', 'isUserTranslator']),
    ...mapGetters('multiTenancy', ['getTenantConfig']),

    /**
     * Returns the currently assigned translator's name.
     *
     * @returns {string}
     */
    translatorName() {
      return !isNil(this.bookingFromEvent) && !isEmpty(this.bookingFromEvent)
        ? this.bookingFromEvent.assigned_translator.name
        : ''
    },

    /**
     * Returns the currently assigned translator's mobile.
     *
     * @returns {string}
     */
    translatorMobile() {
      return !isNil(this.bookingFromEvent) && !isEmpty(this.bookingFromEvent)
        ? this.bookingFromEvent.assigned_translator.mobile
        : ''
    },

    /**
     * @returns {Boolean}
     */
    isValidBooking() {
      return !isNil(this.booking) && !isEmpty(this.booking)
    },

    /**
     * @returns {int}
     */
    bookingId() {
      return this.isValidBooking && !isNil(this.booking.id) ? this.booking.id : 0
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  mounted() {
    EventBus.on(BOOKING_ACCEPTED, this.handleBookingAccepted)
    EventBus.on(BOOKING_EXPIRED, this.handleBookingExpired)
  },

  /*
  |--------------------------------------------------------------------------
  | Component > beforeUnmount
  |--------------------------------------------------------------------------
  */
  beforeUnmount() {
    EventBus.off(BOOKING_ACCEPTED, this.handleBookingAccepted)
    EventBus.off(BOOKING_EXPIRED, this.handleBookingExpired)
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapMutations('booking', ['resetForm', 'resetConfirmForm']),
    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking) {
      this.booking = booking
      this.isShowModal = true
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
    },

    /**
     * Handler when the Booking Accepted global event was emitted.
     *
     * @param {object} event - contains event values.
     * @returns {void}
     */
    handleBookingAccepted(event) {
      if (this.booking === undefined || event.booking === undefined) {
        console.warn('Booking or event is undefined')
        return
      }
      if (this.booking.id === event.booking.id) {
        this.bookingFromEvent = event.booking
        this.isBookingAccepted = true

        const self = this

        setTimeout(() => {
          self.$router.push({
            path: self.getLocaleRoutePath('/customer/bookings'),
            query: {
              activity: 'create_booking',
              created_booking: event.booking.id
            }
          })
        }, 10000)
      }
    },

    /**
     * Handler when the Booking Expired global event was emitted.
     *
     * @param {object} event - contains event values.
     * @returns {void}
     */
    handleBookingExpired(event) {
      if (this.booking.id === event.booking.id) {
        this.bookingFromEvent = event.booking
        this.isBookingExpired = true
      }
    },

    /**
     * Handler when the OK button was clicked.
     *
     * @returns {void}
     */
    handleClickOk() {
      if (this.isUserAdmin) {
        this.resetForm()
        this.resetConfirmForm()
        this.close()
      } else if (this.isUserTranslator) {
        this.close()
      } else {
        this.$router.push({
          path: this.getLocaleRoutePath('/customer/bookings'),
          query: {
            created_booking: this.bookingId
          }
        })
      }
    },
    handleClickBookingDetails() {
      if (this.bookingId) {
        const bookingDetailURL = `${import.meta.env.VITE_DT_ADMIN_URL}/booking/${this.bookingId}/details`
        window.open(bookingDetailURL, '_blank')
      }
    }
  }
}
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.emergency-booking-confirm-notif-modal.el-dialog {
  width: 370px;
  padding: 0 15px 20px;
  // background-color: var(--app-primary-color);
  color: $app-secondary-color;

  .el-dialog__header {
    display: none;
  }
}

.emergency-booking-confirm-notif-modal {
  &__icon,
  &__title,
  &__booking,
  &__actions {
    color: $app-secondary-color;
    text-align: center;
  }

  &__icon {
    font-size: 100px;
    color: var(--app-primary-color);
  }

  &__title {
    font-size: 28px;
  }

  &__booking-label {
    font-size: 18px;
    margin-bottom: 2px;
  }

  &__booking-id {
    font-size: 50px;
  }

  &__actions {
    margin-top: 15px;
  }

  &__verbiage {
    text-align: center;
    color: $app-secondary-color;

    &-translator {
      &-name,
      &-mobile {
        display: block;
        font-weight: 600;
      }

      &-name {
        padding-top: 10px;
      }
    }
  }

  &__is-create-another-checkbox {
    margin-bottom: 15px;
    color: $app-secondary-color;

    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: $app-secondary-color;
      border-color: $app-secondary-color;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: $app-secondary-color;
    }
  }

  &__ok-button {
    font-size: 18px;
    padding: 10px 25px;
  }
}
</style>
