import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function getTranslatorAvailableBookingDatesTimeSlots(context, payload) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = API.AVAILABLE_TRANSLATOR_BOOKING_DATES_TIME_SLOTS
  const method = 'GET'

  let params = payload.queryParams
  context.commit('setAvailableDatesLoading', true)

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      params
    })
      .then((r) => {
        context.commit('setAvailableDatesLoading', false)
        context.commit('setAvailableDatesTimeSlots', r.data.data)
        resolve(r)
      })
      .catch((e) => {
        context.commit('setAvailableDatesLoading', false)

        reject(e)
      })
  })
}

export default getTranslatorAvailableBookingDatesTimeSlots
