export const CUSTOMER_AFTER_LOGIN = '/'
export const TRANSLATOR_AFTER_LOGIN = '/today-dashboard'
export const ADMIN_AFTER_LOGIN = '/'
export const PUBLIC_LOGIN = '/login'

export default {
  CUSTOMER_AFTER_LOGIN,
  TRANSLATOR_AFTER_LOGIN,
  ADMIN_AFTER_LOGIN,
  PUBLIC_LOGIN
}
