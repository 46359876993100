/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getBookingByTranslatorAndCustomer
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to fetch the booking details by customer and its assigned translator.
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.translator_id - ID for the target translator
 * @param {string} payload.customer_id - ID for the target customer
 *
 * @return {Promise}
 */
export default function (context, payload) {
  const { translator_id, customer_id } = payload

  // Make error loggers to make sure required values are filled in.
  if (isNil(translator_id)) {
    console.error('Translator ID should exist!')
  } else if (isNil(customer_id)) {
    console.error('Customer ID should exist!')
  }

  let options = {
    query_params: {
      translator_id,
      customer_id
    }
  }

  // Define axios values.
  const url = `${API.BOOKINGS}/customer/jobs-by-translator`
  const method = 'GET'

  return reach(this, context, url, method, options, axiosInstance)
}
