/*
|--------------------------------------------------------------------------
| Store > Auth
|--------------------------------------------------------------------------
|
| This file is the entry point for the Vuex Module.
| Use this file to register the state, getters, mutations, and actions.
|
*/

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
