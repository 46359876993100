import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function updateTranslatorAvailableTimes(context, payload) {
  const url = `${API.TRANSLATOR_WEEKLY_SCHEDULE}/${payload.id}?formatter=daily`
  const method = 'PUT'
  const headers = {
    'Content-Type': 'application/json' // Set the JSON content type
  }

  context.commit('setLoadingForAvailableTime', true)

  return new Promise((resolve, reject) => {
    axiosInstance({
      url,
      method,
      headers, // Pass the headers
      data: JSON.stringify(payload.data) // Convert the data to a JSON string
    })
      .then((r) => {
        context.commit('setLoadingForAvailableTime', false)
        resolve(r) // Resolve with the result of the PUT request
      })
      .catch((e) => {
        context.commit('setLoadingForAvailableTime', false)
        reject(e) // Reject with the error if the request fails
      })
  })
}

export default updateTranslatorAvailableTimes
