import LS from '@/modules/constants/localStorage'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import getAuthCollection from '@/modules/auth/store/shared/getAuthCollection'
import appStorage from '@/modules/helpers/appStorage'
import { clearCachedBooking } from '@/modules/booking/services/cachedBookingService'

function clearAuthCollection(context) {
  const collection = getAuthCollection()

  if (!isEmpty(collection)) {
    forEach(collection, (userId) => {
      appStorage.remove(LS.AUTH_COLLECTION_USER + userId)

      if (window.Echo) {
        context.commit('auth/clearPusher', userId, { root: true })
      }
    })
  }

  appStorage.remove(LS.AUTH_COLLECTION)
  clearCachedBooking()
  context.commit('auth/resetAuthenticatedUsers', null, { root: true })
  context.commit('auth/destroyToken', null, { root: true })
  context.commit('auth/destroyUser', null, { root: true })
}

export default clearAuthCollection
