<template>
  <div class="custom-divider"></div>
</template>

<style lang="scss" scoped>
.custom-divider {
  border: 0px;
  border-bottom: 1px solid #ebeef5;
}
</style>
