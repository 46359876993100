/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > validateBooking
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'

/**
 * Action to validate the booking to make sure values are right,
 * and then return the additional values to be used for booking confirmation
 * as response.
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @return {*}
 */
export default function (context) {
  // Prepare the payload to be sent across the API.
  context.commit('setFormPayload', {
    user: context.rootGetters['auth/userMetaData']
  })
  context.commit('setValidateLoading', true)

  const isoCodes = context.rootGetters['booking/languageIsoCodes']
  const iso6393 = isoCodes[context.state.formPayload.from_language_id]

  let url = `${API.BOOKINGS}/validate`
  console.log('validateBooking', context.rootGetters['auth/isGuestMode'])
  if (context.rootGetters['auth/isGuestMode']) {
    url = `${API.BOOKINGS}/validate-on-guest`
  }
  const method = 'post'
  // const data = context.state.formPayload
  const data = {
    ...context.state.formPayload,
    from_language_iso6393: iso6393,
    calculate_probability: true
  }

  // if (data.is_immediate) {
  delete data.city
  delete data.address
  // }

  if (!context.state.formPayload.from_language_id || !context.state.formPayload.duration) return

  const options = {
    enable_error_notif: true,
    data,

    on_success(r, ctx) {
      ctx.commit('resetConfirmFormPayload')

      // Set the following states.
      ctx.commit('setBcfConfig', r.data.booking_field)
      ctx.commit('setConfirmSuggestions', r.data.confirmation_fills)
      ctx.commit('setAvailableTranslators', r.data.total_translators)
      ctx.commit('setTranslatorShortages', r.data)
      ctx.commit('setProbability', r.data.assignment_probability)
      ctx.commit('setConfirmBooking', false)

      let user = null

      if (context.state.form.is_created_by_colleague) {
        // Reset the form value, but retain the formPayload value.
        context.state.form.is_created_by_colleague = false
        user = context.state.form.colleague
      } else {
        user = ctx.rootGetters['auth/userMetaData']
      }
      if (context.rootGetters['auth/isGuestMode']) {
        ctx.commit('setGuestConfirmForm', {
          data: r.data.confirmation_fills,
          user
        })
      } else {
        ctx.commit('setConfirmForm', {
          data: r.data.confirmation_fills,
          user
        })
      }
    },

    on_finally(ctx) {
      ctx.commit('setValidateLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
