/*
|--------------------------------------------------------------------------
| Store > Statistics > Mutations
|--------------------------------------------------------------------------
| File that contains the mutations property for the current Vuex scope.
*/

export default {
  /**
   * Method to set the state.customerStatistics.data.
   *
   * @param {Object} state - The state property of the current Vuex module.
   * @param {Object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerStatisticsData(state, payload) {
    state.customerStatistics.data = payload
  },

  /**
   * Method to set the state.customerStatistics.loading.
   *
   * @param {Object} state - The state property of the current Vuex module.
   * @param {Boolean} payload - Value to be assigned.
   * @returns {void}
   */
  setCustomerStatisticsLoading(state, payload) {
    state.customerStatistics.loading = payload
  },

  /**
   * Method to set the state.translatorStatistics.data.
   *
   * @param {Object} state - The state property of the current Vuex module.
   * @param {Object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorStatisticsData(state, payload) {
    state.translatorStatistics.data = payload
  },

  /**
   * Method to set the state.translatorStatistics.loading.
   *
   * @param {Object} state - The state property of the current Vuex module.
   * @param {Boolean} payload - Value to be assigned.
   * @returns {void}
   */
  setTranslatorStatisticsLoading(state, payload) {
    state.translatorStatistics.loading = payload
  },

  setStatisticsSubscriptions(state, payload) {
    state.subscriptions = payload
  }
}
