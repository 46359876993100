/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > submitFeedback
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for ending a booking.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = `${API.BOOKINGS}/${payload.booking_id}/feedback_dt`
  const method = 'POST'

  let options = {
    data: payload.data
  }

  return reach(this, context, url, method, options, axiosInstance)
}
