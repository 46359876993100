import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { reach } from '@/modules/helpers/vuexActions'
import API from '../../../../constants/api'

export default function (context, payload = {}) {
  const url = `${API.GET_TEXT_TRANSLATION_DEADLINE_SUGGESTIONS}`

  const method = 'get'
  let options = {
    query_params: payload,
    on_success(r, ctx) {
      const suggestedDeadlines = r?.data?.data?.deadline_suggestion_settings ?? []
      ctx.commit('setTextTranslationSuggestedDeadlines', suggestedDeadlines)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
