export default {
  setTranslatorReferralCode(state, payload) {
    state.translator_referral_code = payload
  },
  setReferrals(state, payload) {
    state.referrals = payload
  },
  setSettings(state, payload) {
    state.settings = payload
  },
  setInvitations(state, payload) {
    state.invitations = payload
  },
  setTransactions(state, payload) {
    state.transactions = payload
  },
  setTransactionSearchTerm(state, payload) {
    state.transaction_search_term = payload
  },
  setTransactionSearchDate(state, payload) {
    state.transaction_search_date = payload
  },
  setDiscounts(state, payload) {
    state.discounts = payload
  },
  setSendInviteLoading(state, payload) {
    state.send_invite_loading = payload
  }
}
