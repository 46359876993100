export default {
  /**
   * @param {Object} state
   * @param {boolean} visible
   */
  setVisible(state, visible) {
    state.visible = visible
  },

  /**
   * @param {Object} state
   * @param {string} message
   */
  setMessage(state, message) {
    state.message = message
  },

  /**
   * @param {Object} state
   */
  clearMessage(state) {
    state.message = ''
  }
}
