/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { loadAll, bookingsListFilterOptions } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'

/**
 * Action to load customer upcoming bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  context.commit('setCustomerUpcomingBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = [
    // 'customer.department',
    // 'translator_levels',
    // 'assigned_translator',
    // 'notification_types',
    'specific_translators',
    // 'excluded_translators',
    'video_booking',
    // 'messages.files',
    // 'convey.files',
    // 'text_translation.files',
    'booking_time_slots.translator.translator_unavailable_times'
  ].join(',')

  let options = {
    query_params: {
      include,
      'sort': 'due',
      'filter[status_id]': '1,2,3,11',
      'filter[customer_id]': payload.customer_id,
      'page': 1,
      'per_page': 6
    },

    on_finally(ctx) {
      ctx.commit('setCustomerUpcomingBookingListLoading', false)
    }
  }

  const filterQueries = bookingsListFilterOptions(payload)

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, 'setCustomerUpcomingBookingList', options, axiosInstance)
}
